import { Col, Row } from "antd";
import "./dashboard.css";
import { motion } from "framer-motion";
// import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { HeaderBar } from "../components/Dashboard/HeaderBar";
import InfoCard from "../components/Dashboard/SuspendTeamDashboard/InfoCard";
import Tasks from "../components/Dashboard/SuspendTeamDashboard/Tasks";
import { AmazonSuspendedApplicantList } from "../components/Dashboard/SuspendTeamDashboard/AmazonSuspendedApplicantList";
import SuspendTeamCard from "../components/Dashboard/SuspendTeamDashboard/SuspendTeamCard";

export const SuspendTeamDashboard = () => {
  // const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<any>([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line
  const roleName = localStorage.getItem("roleName");

  useEffect(() => {
    setLoading(true);
    const [startDate, endDate] = dateRange;

    let formattedDateRange;
    if (startDate && endDate) {
      // eslint-disable-next-line
      formattedDateRange = `startDate=${startDate}T00:00:00&endDate=${endDate}T00:00:00`;
    }
    if (startDate === undefined || endDate === undefined) {
      return;
    }

    setLoading(false);
  }, [dateRange]);

  return (
    <div
      style={{ background: "#F0F0F0", padding: "30px" }}
      className="acm-dashboard"
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
      >
        <HeaderBar setDateRange={setDateRange}></HeaderBar>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col xs={24} sm={24} md={12} lg={8}>
            <SuspendTeamCard
              title="X Chart"
              dateRange={dateRange}
              //   description={`${totalCount}`}
              // callTeamUsers={allCallTeamUsers}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={7}>
            <InfoCard
              title="X Card"
              dateRange={dateRange}
              // callTeamUsers={allCallTeamUsers}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={9}>
            <Tasks dateRange={dateRange} />
          </Col>
        </Row>
        <AmazonSuspendedApplicantList />
      </motion.div>
    </div>
  );
};
