import http from "../http-common";

const getReportOfStatus = (
  startDate: string,
  endDate: string,
  roleName: string,
  statusType: string
) => {
  return http.get<Array<any>>(
    `/reports/status?startDate=${startDate}&endDate=${endDate}&roleName=${roleName}&statusType=${statusType}`
  );
};

const getReportOfStatusWithParent = (
  startDate: string,
  endDate: string,
  roleName: string,
  statusType: string
) => {
  return http.get<Array<any>>(
    `/reports/status-with-parent?startDate=${startDate}&endDate=${endDate}&roleName=${roleName}&statusType=${statusType}`
  );
}

const getReportOfAmazonSuspendedApplicantStatus = (
  startDate: string,
  endDate: string,
  roleName: string,
  statusType: string
) => {
  return http.get<Array<any>>(
    `/amazon-suspended-applicant/status-report?startDate=${startDate}&endDate=${endDate}&roleName=${roleName}&statusType=${statusType}`
  );
};

const ReportOfStatusService = {
  getReportOfStatus,
  getReportOfStatusWithParent,
  getReportOfAmazonSuspendedApplicantStatus
};

export default ReportOfStatusService;
