import {
  Card,
  Col,
  Row,
  Typography,
  Table,
  Breadcrumb,
  Button,
  Input,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import DateRange from "../../Common/DateRange";
import { ReportsRouter } from "../ReportsRouter";
import * as XLSX from "xlsx";
import ReportOfStatusService from "../../../services/ReportOfStatusService";

const { Title } = Typography;

export const StmStatusReport: React.FC<any> = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const location = useLocation();

  const getStatusTypeAndTitles = () => {
    if (location.pathname.includes("/stm-reports/learner-status")) {
      return {
        roleName: "SUCCESS_TEAM",
        statusType: "LEARNER_STATUS",
        columnTitle: "Success Team Member",
        breadcrumbTitle: "Success Team Reports",
        breadcrumbSubTitle: "Learner Status Report",
        cardTitle: "Success Team Member",
        cardSubTitle: "Learner Status Report",
      };
    } else if (location.pathname.includes("/stm-reports/retention-status")) {
      return {
        roleName: "SUCCESS_TEAM",
        statusType: "CUSTOMER_STATUS",
        columnTitle: "Success Team Member",
        breadcrumbTitle: "Success Team Reports",
        breadcrumbSubTitle: "Retention Status Report",
        cardTitle: "Success Team Member",
        cardSubTitle: "Retention Status Report",
      };
    } else
      return {
        roleName: "",
        statusType: "",
        columnTitle: "Unknown Role",
        breadcrumbTitle: "Unknown Reports",
        breadcrumbSubTitle: "Unknown Report",
        cardTitle: "Unknown Member",
        cardSubTitle: "Unknown Report",
      };
  };

  useEffect(() => {
    const { roleName, statusType } = getStatusTypeAndTitles();
    if (
      dateRange[0] !== undefined &&
      dateRange[1] !== undefined &&
      roleName !== "" &&
      roleName !== undefined &&
      statusType !== "" &&
      statusType !== undefined
    ) {
      getReport(
        `${dateRange[0]}T00:00:00`,
        `${dateRange[1]}T23:59:59`,
        roleName,
        statusType
      );
    }
    // eslint-disable-next-line
  }, [dateRange, location.pathname]);

  const getReport = (
    startDate: string,
    endDate: string,
    roleName: string,
    statusType: string
  ) => {
    setLoading(true);
    ReportOfStatusService.getReportOfStatus(
      startDate,
      endDate,
      roleName,
      statusType
    )
      .then((response) => {
        const filteredResponseData = response.data.filter(
          (item: any) =>
            item.user.username !== "az.success.team.crm@oneamz.com" &&
            item.user.username !== "stm@oneamz.com"
        );
        setData(filteredResponseData);
        setFilteredData(filteredResponseData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUniqueStatusDataKeys = (data: any[]) => {
    const allKeys = data.reduce((keys, item) => {
      return keys.concat(Object.keys(item.statusMap));
    }, []);
    return Array.from(new Set(allKeys));
  };

  const statusDataKeys = getUniqueStatusDataKeys(data);

  const {
    columnTitle,
    breadcrumbTitle,
    breadcrumbSubTitle,
    cardTitle,
    cardSubTitle,
  } = getStatusTypeAndTitles();

  const extractNumericValue = (value: string): number => {
    const match = value.match(/^(\d+)/);
    return match ? parseFloat(match[1]) : 0;
  };

  const capitalize = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const columns: ColumnsType<any> = [
    {
      title: columnTitle,
      dataIndex: "user",
      key: "user",
      fixed: "left",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => a?.user?.firstName.localeCompare(b?.user?.firstName),
      defaultSortOrder: "ascend",
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.user?.firstName} ${record?.user?.lastName}`}
          >
            <b>
              {record?.user?.firstName} {record?.user?.lastName}
            </b>
          </div>
        );
      },
    },
    ...statusDataKeys.map((key: any) => {
      return {
        title: capitalize(key),
        dataIndex: ["statusMap", key],
        key: key,
        width: 150,
        align: "center" as const,
        sorter: (a: any, b: any) => {
          const aValue = extractNumericValue(a?.statusMap?.[key] || "0");
          const bValue = extractNumericValue(b?.statusMap?.[key] || "0");
          return aValue - bValue;
        },
        render: (text: string, record: any) => {
          const valueString = record?.statusMap?.[key] || "0 (0)";
          const [outerValue, innerValue] = valueString
            .replace(/[()]/g, "")
            .split(" ")
            .map(Number);

          let sign = "";
          let color = "inherit";

          if (outerValue > innerValue) {
            sign = "+";
            color = "green";
          } else if (outerValue < innerValue) {
            sign = "-";
            color = "red";
          }

          return (
            <span style={{ color }}>
              {sign} {outerValue} ({innerValue}) qty
            </span>
          );
        },
      };
    }),
  ];

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item: any) => {
        const fullName =
          `${item?.user?.firstName} ${item?.user?.lastName}`.toLowerCase();
        return fullName.includes(value);
      });
      setFilteredData(filtered);
    }
  };

  const downloadExcel = () => {
    // Kolon başlıklarını belirle
    const headers = columns
      .filter(
        (col: any) => col.dataIndex !== "user" && col.dataIndex !== "statusMap"
      )
      .map((col) => col.title as string);

    // İlk kolon başlığı için `user` adını ve soyadını ekle
    headers.unshift("Member");

    // Kolon verilerini ayarlamak
    const formattedData = filteredData.map((item: any) => {
      // user ve statusMap kolonlarını kaldır
      const { user, statusMap, ...rest } = item;

      // Status map'in anahtarlarını içeren bir nesne oluştur
      const statusData: any = statusDataKeys.reduce((acc: any, key: any) => {
        acc[key] = `${statusMap[key] || "0 (0)"} qty`; // Varsayılan değer
        return acc;
      }, {} as { [key: string]: string });

      // `user` bilgilerini birleştir
      const userInfo = `${user?.firstName || ""} ${user?.lastName || ""}`;

      // Birleştirilmiş veri nesnesi
      return { Member: userInfo, ...rest, ...statusData };
    });

    // Summary için toplamları hesapla
    const statusDataTotals = statusDataKeys.map((key: any) => {
      // Hem ana hem de parantez içindeki değerlerin toplamlarını hesaplayalım
      const totals = filteredData.reduce(
        (total: any, record: any) => {
          const value = record.statusMap[key];
          const match = value?.match(/^(\d+)\s\((\d+)\)$/); // Ana değer ve parantez içindeki değeri yakalar
          if (match) {
            total.current += parseFloat(match[1]); // Ana değeri toplar
            total.previous += parseFloat(match[2]); // Parantez içindeki değeri toplar
          }
          return total;
        },
        { current: 0, previous: 0 } // Toplamları tutmak için başlangıç değerleri
      );
      return `${totals.current} (${totals.previous}) qty`; // Toplamları formatlı olarak döndürür
    });

    // Summary nesnesini oluştur
    const summaryRow: any = {
      Member: "Total",
      ...statusDataKeys.reduce<Record<string, string>>(
        (acc: any, key: any, index: any) => {
          acc[key] = statusDataTotals[index];
          return acc;
        },
        {}
      ),
    };

    // Summary satırını `formattedData` listesine ekle
    formattedData.push(summaryRow);

    // Tablo verilerini almak
    const ws = XLSX.utils.json_to_sheet(formattedData, {
      header: headers,
    });

    // Çalışma kitabı oluşturmak
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${cardSubTitle}`);

    // Excel dosyasını indirmek
    XLSX.writeFile(wb, `${cardSubTitle}.xlsx`);
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: breadcrumbTitle,
              },
              {
                title: breadcrumbSubTitle,
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                {cardTitle}
              </div>
              <div className="customer-card-info">{cardSubTitle}</div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search By Name"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Button
              style={{ marginLeft: "20px" }}
              size={"large"}
              icon={<DownloadOutlined />}
              onClick={downloadExcel}
              type="primary"
            >
              Export
            </Button>
          </Row>
        }
        bordered={true}
        style={{ marginTop: "30px" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ margin: "0 0 5px 10px" }}>
            <span>
              {" "}
              * The values in parentheses represent the previous period based on
              the range selected from the date filter.
            </span>
          </Col>
        </Row>
        <Table
          loading={isLoading}
          scroll={{ x: "100%" }}
          sticky
          rowKey={(record) => record.user.id.toString()}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          summary={(pageData) => {
            // Status Data Totals
            const statusDataTotals = statusDataKeys.map((key: any) => {
              // Hem ana hem de parantez içindeki değerlerin toplamlarını hesaplayalım
              const totals = pageData.reduce(
                (total, record) => {
                  const value = record.statusMap[key];
                  const match = value?.match(/^(\d+)\s\((\d+)\)$/); // Ana değer ve parantez içindeki değeri yakalar
                  if (match) {
                    total.current += parseFloat(match[1]); // Ana değeri toplar
                    total.previous += parseFloat(match[2]); // Parantez içindeki değeri toplar
                  }
                  return total;
                },
                { current: 0, previous: 0 } // Toplamları tutmak için başlangıç değerleri
              );
              return `${totals.current} (${totals.previous}) qty`; // Toplamları formatlı olarak döndürür
            });

            return (
              <>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {/* Status Data Totals */}
                  {statusDataKeys.map((key: any, index: any) => (
                    <Table.Summary.Cell
                      index={index + 1}
                      key={index}
                      align="center"
                      className="summary-cell"
                    >
                      <b>{statusDataTotals[index]}</b>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Card>
    </>
  );
};
