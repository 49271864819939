import {
  Card,
  Col,
  Row,
  Typography,
  Input,
  Table,
  Button,
  Breadcrumb,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ReportService from "../../../services/ReportService";
import BonusPoliciesService from "../../../services/BonusPoliciesService";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import DateRange from "../../Common/DateRange";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../ReportsRouter";

const { Title } = Typography;

export const StmSuccessTeamReport = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState<any>(
    dayjs().subtract(6, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(dayjs().format("YYYY-MM-DD"));
  const [dateRange, setDateRange] = useState<any>([]);

  function downloadExcel(jsonData: any[]) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }

    // Save the workbook to a file
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Use FileSaver to save the file
    saveAs(blob, `success-team-report-${startDate}-${endDate}.xlsx`);
  }

  const columns: ColumnsType<any> = [
    {
      title: "Success Team Members",
      dataIndex: "name",
      key: "action",
      fixed: "left",
      width: 270,
      render: (_: any, row: any) => (
        <Row justify={"start"} align={"middle"} style={{ width: 270 }}>
          {/* <Col>
            <Title className="customer-fullname" level={5}> */}
              <b>{row.fullname}</b>
            {/* </Title>
            <span className="customer-fullname">{row.username}</span>
          </Col> */}
        </Row>
      ),
      sorter: (a: any, b: any) => a.fullname.localeCompare(b.fullname),
    },
    {
      title: "Total Learner",
      dataIndex: "totalLearner",
      key: "totalLearner",
      align: "center",
      width: 150,
      sorter: (a: any, b: any) => a.totalLearner - b.totalLearner,
    },
    {
      title: "Total Customer",
      dataIndex: "totalCustomer",
      key: "totalCustomer",
      align: "center",
      width: 150,
      sorter: (a: any, b: any) => a.totalCustomer - b.totalCustomer,
    },
    {
      title: "Ratio (%)",
      key: "ratio",
      align: "center",
      width: 150,
      render: (text: any, record: any) => {
        const { totalLearner, totalCustomer } = record;
        if (totalCustomer === 0 || totalLearner === 0) {
          return "0%";
        }
        const ratio = (totalCustomer / totalLearner) * 100;
        return `${ratio.toFixed(2)}%`;
      },
      sorter: (a: any, b: any) => {
        const ratioA =
          a.totalCustomer === 0 || a.totalLearner === 0
            ? 0
            : a.totalCustomer / a.totalLearner;
        const ratioB =
          b.totalCustomer === 0 || b.totalLearner === 0
            ? 0
            : b.totalCustomer / b.totalLearner;
        return ratioA - ratioB;
      },
    },
  ];

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const getReport = () => {
    setLoading(true);

    Promise.all([
      BonusPoliciesService.getAll(),
      ReportService.successTeamReport(
        `?startDate=${startDate}&endDate=${endDate}`
      ),
      ReportService.learnerStatisticReport(
        `?startDate=${startDate}&endDate=${endDate}`
      ),
    ])
      .then(([policyResponse, teamResponse, learnerStatsResponse]) => {
        const policies = policyResponse.data;
        const teamData = teamResponse.data;
        const learnerStats = learnerStatsResponse.data;

        const filteredTeamData = teamData.filter(
          (item: any) => !["az.success.team.crm@oneamz.com", "stm@oneamz.com"].includes(item?.username)
        );

        let highest = policies.reduce(
          (max, item) => (item.id > max.id ? item : max),
          policies[0]
        );

        let fixedBonusPerCustomer = highest?.fixedBonusPerCustomer;
        let fixedPremium = highest?.fixedPremium;
        let customerThreshold = highest?.customerThreshold;
        let totalCustomers = teamData.reduce(
          (sum: any, item: any) => sum + item.totalCustomer,
          0
        );

        const learnerMap = new Map(
          learnerStats.map((item: any) => [item.userId, item.totalLearner])
        );

        let reportData = filteredTeamData.map((row: any) => {
          let totalCustomer = Number(row.totalCustomer);
          let fixedBonus = Number(fixedBonusPerCustomer);
          let earnings = totalCustomer * fixedBonus;

          let bonus =
            row.totalCustomer >= customerThreshold
              ? (fixedPremium / totalCustomers) * row.totalCustomer
              : 0;

          let totalEarnings = earnings + bonus;

          return {
            id: row.userId,
            fullname: `${row.firstName} ${row.lastName}`,
            username: row.username,
            salesTarget: row.salesTarget,
            totalLearner: learnerMap.get(row.userId) || 0,
            totalCustomer: row.totalCustomer,
            earnings: earnings,
            salesTargetRatio: (
              (row.totalCustomer / row.salesTarget) *
              100
            ).toFixed(0),
            fixedBonusPerCustomer: fixedBonusPerCustomer,
            bonus: bonus,
            totalEarnings: totalEarnings,
          };
        });

        setData(reportData);
        setFilteredData(reportData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (e: any) => {
    const value = e.target.value;
    if (value === "") {
      setSearchText(value);
      setFilteredData(data);
    } else {
      setSearchText(value);
      const filtered = data.filter((item: any) =>
        item.fullname.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    }
  }, [dateRange]);

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Success Team Reports",
              },
              {
                title: "Success Team",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Success Team
              </div>
              <div className="customer-card-info">Status</div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        bordered={true}
        extra={
          <>
            <Row align={"middle"} justify={"end"}>
              <DateRange onChangeDateRange={setDateRange} />
              <Button
                icon={<DownloadOutlined />}
                style={{ marginLeft: 16 }}
                size="large"
                onClick={() => downloadExcel(filteredData)}
                type="primary"
              >
                Export
              </Button>
            </Row>
          </>
        }
      >
        <Table
          loading={loading}
          key={"id"}
          sticky
          scroll={{ x: "100%" }}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
        />
      </Card>
    </>
  );
};
