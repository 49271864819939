import { Card, Col, Row, Typography, Input, Table, Button, Avatar, Tag, DatePicker } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { WhatsAppOutlined } from '@ant-design/icons';
// import '../pages/customers.css';
import TaskDetail from "../../components/Task/TaskDetail";
import TaskService from '../../services/TaskService';
import { useEffect, useState } from 'react';
import { CopyClipboard } from '../General/CopyClipboard';
import moment from 'moment';
import { StartCall } from '../General/StartCall';
import { can } from '../../utils';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

const { Title } = Typography;

export const TaskList = () => {
    const [value, setValue] = useState(() => dayjs());
    const [tasks, setTasks] = useState<any[]>([])
    useEffect(() => {
        getTasks();
    }, [])

    const columns: ColumnsType<any> = [
        {
            title: 'Learner',
            dataIndex: 'id',
            key: 'action',
            render: (_, row) => (
                <Row justify={'start'} align={'middle'}>
                    {/* <Col className='mr-15'>
                        <Avatar style={{ background: '#87B2B2' }} size="large" >
                            {row.learner?.firstName.charAt(0)}
                        </Avatar>
                    </Col> */}
                    <Col>
                        <Title className='customer-fullname' level={5}><b>{row.learner?.firstName} {row.learner?.lastName}</b></Title>
                        <span className='customer-fullname'> <CopyClipboard text={row.learner?.email}></CopyClipboard> </span>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'id',
            render: (_, row) => {
                return (
                    <TaskDetail item={row} onHandleTaskList={handeTaskList} type={0}/>
                );
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'Phone',
            render: (_, row) => {
                return (
                    <StartCall text={row.learner?.phoneNumber} />
                );
            },
        },
        {
            title: 'Tag',
            dataIndex: 'status',
            key: 'status',
            render: (_, row) =>  {
                if (row.tag) {
                    return <>#{row.tag}</>;
                }
            },
        },
        // {
        //     title: 'title',
        //     dataIndex: 'title',
        //     render: (_, row) => (
        //         <Button type="primary" style={{ background: '#25D366' }}>
        //             <WhatsAppOutlined />
        //         </Button>
        //     ),
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, row) => (
                <Tag color={'orange'} >
                    {row.status.toUpperCase()}
                </Tag>
            ),
        },

        {
            title: 'Due date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            width: 50,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
            render: (_, row) => {
                return moment(row.dueDate).format("DD/MM/YYYY HH:mm");
            }
        },
    ];

    const handeTaskList = () => {
        getTasks();
    }
    const getTasks = () => {
        const { startDueDate, endDueDate } = calculateMonthRange(value);
        const criteria = `?startDueDate=${startDueDate}&endDueDate=${endDueDate}`;
        if (can("learners:getAll")) {
        TaskService.getAllCriteria(criteria)
            .then((response: any) => {
                let taskData = response.data;
                const todoTasks = taskData.filter((task:any) => task.status === "TODO");
                setTasks(todoTasks);
            })
            .catch((e: Error) => {
                console.log(e);
            });
        }else{
            TaskService.getAllMyTask('')
            .then((response: any) => {
                let taskData = response.data;
                const todoTasks = taskData.filter((task:any) => task.status === "TODO");
                setTasks(todoTasks);
            })
            .catch((e: Error) => {
                console.log(e);
            });
        }
    };

    const calculateMonthRange = (date: Dayjs) => {
        const startOfMonth = date.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
        const endOfMonth = date.endOf('month').format('YYYY-MM-DDT23:59:59');
        return { startDueDate: startOfMonth, endDueDate: endOfMonth };
      };
   



    return (
        <Card className='card-customers' style={{ marginTop: '30px' }} title="Task List"
            bordered={true}
            extra={<>
                {/* <Row justify={'end'}><DatePicker placeholder='Month' style={{ width: "100px" }} picker="date" format={"DD/MM/YYYY"} /></Row> */}
            </>}>
            <Table scroll={{ x: '100%' }}  style={{ width: '100%' }} columns={columns} dataSource={tasks} />
        </Card>
    )
}
