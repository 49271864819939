import { Button, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "./../../../assets/XCircle.svg";
import { openNotification } from "../../../utils";
import CustomerService from "../../../services/CustomerService";

const { Item } = Form;

const StmMultiStatus: React.FC<any> = ({ selectedRowKeys }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState([]);
  // eslint-disable-next-line
  const [selectedStatus, setSelectedStatus] = useState();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [isModalVisible]);

  const updateStatus = (values: any) => {
    let status = {
      statusId: values.status,
      ids: selectedRowKeys,
    };
    CustomerService.updateMultiStatus(status)
      .then((response: any) => {
        openNotification("success", "Success", "Status Updated");
        setIsModalVisible(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setIsModalVisible(false);
      });
  };

  useEffect(() => {
    form.resetFields(["Selected Customers"]);
    form.setFieldsValue({
      "Selected Customers": selectedRowKeys.length.toString(),
    });
  }, [selectedRowKeys, isModalVisible, form]);

  useEffect(() => {
    CustomerService.getAllStatus()
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <Row justify={"end"}>
        <Button
          type="primary"
          style={{ marginRight: 15 }}
          onClick={() => setIsModalVisible(true)}
        >
          Change Status
        </Button>
      </Row>
      <Modal
        title={<>Change Status</>}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={updateStatus} layout="vertical">
          <Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: "note is required",
              },
            ]}
          >
            <Select
              onChange={(selectedStatus) =>
                setSelectedStatus(selectedStatus.value)
              }
              options={customerStatusOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              placeholder={"Select"}
            ></Select>
          </Item>
          <Item name="Selected Customers" label="Selected Customers">
            <Input disabled />
          </Item>

          <Item>
            <Row>
              <Button
                type="primary"
                className=" w-100 border-radius-8"
                style={{ width: "100%" }}
                htmlType="submit"
              >
                OK
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default StmMultiStatus;
