import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Typography,
  Input,
  Button,
  Drawer,
  Select,
  Form,
  DatePicker,
  notification,
  Calendar,
  Switch,
  Table,
  Tag,
  Space,
  Tooltip,
  Popconfirm,
} from "antd";
import "./customers.css";
import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import type { CalendarProps, DatePickerProps } from "antd";
import type { ITaskResponseData } from "../types/Task";
import TaskService from "../services/TaskService";
import { can, openNotification } from "../utils";
import { Link, NavLink } from "react-router-dom";
import "./task.css";
import UserService from "../services/UserService";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import DateRange from "../components/Common/DateRange";
import TaskDetailCustomer from "../components/Task/TaskDetailCustomer";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

export const Task = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [loadingForm, setLoadingForm] = useState(true);
  const [createLoadingForm, setCreateLoadingForm] = useState(false);
  const [tasks, setTasks] = useState<ITaskResponseData[]>([]);
  const [cellTasks, setCellTasks] = useState();
  // eslint-disable-next-line
  const [learnerOptions, setLearnerOptions] = useState<any>([]);
  const [api, contextHolder] = notification.useNotification();
  const [assigneeId, setAssigneeId] = useState<any>(null);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredTasks, setFilteredTasks] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>([]);
  const prevDateRangeRef = useRef();
  const roleName = localStorage.getItem("roleName");
  const authId = localStorage.getItem("id");
  // eslint-disable-next-line
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [statusToggle, setStatusToggle] = useState(false);
  const [value, setValue] = useState(() => dayjs());
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = useState(() => dayjs());

  const columns: ColumnsType<any> = [
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (_, row) => (
        <>
          {row.reporter?.id === row.assignee?.id ? (
            <Tag color={row?.status === "TODO" ? "orange" : "green"}>
              {row?.status}
            </Tag>
          ) : (
            <Tag color={row?.status === "TODO" ? "yellow" : "blue"}>
              {row?.status}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "id",
      key: "action",
      render: (_, row) => (
        <Row justify={"start"} align={"middle"}>
          <Col>
            {row?.customer !== null ? (
              <>
                <span className="customer-fullname">
                  <b>{row?.customer?.fullName}</b>
                </span>
                <br />
              </>
            ) : row?.learner !== null ? (
              <>
                <span className="customer-fullname">
                  <b>
                    {row?.learner?.firstName} {row?.learner?.lastName}
                  </b>
                </span>
                <br />
              </>
            ) : row?.prospectCustomer !== null ? (
              <>
                <span className="customer-fullname">
                  <b>{row?.prospectCustomer?.fullName}</b>
                </span>
                <br />
              </>
            ) : row?.amazonSuspendedApplicant !== null ? (
              <>
                <span className="customer-fullname">
                  <b>{row?.amazonSuspendedApplicant?.fullName}</b>
                </span>
                <br />
              </>
            ) : null}
          </Col>
        </Row>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, row) => (
        <Tooltip title={row?.description}>
          <span
            style={{
              color:
                (roleName === "CALL_TEAM" ||
                  roleName === "ACCOUNT_MANAGER" ||
                  roleName === "SUCCESS_TEAM" ||
                  roleName === "SUSPEND_TEAM") &&
                row?.reporter?.id !== row?.assignee?.id
                  ? "red"
                  : "inherit",
            }}
          >
            {text}
          </span>
        </Tooltip>
      ),
    },

    {
      title: "Due date",
      dataIndex: "dueDate",
      key: "dueDate",
      width: 180,
      defaultSortOrder: "ascend",
      sorter: (a, b) => moment(a?.dueDate).unix() - moment(b?.dueDate).unix(),
      render: (_, row) => {
        return moment(row?.dueDate).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <Space size="middle">
          {record?.learner?.customerId !== 0 &&
          (roleName === "ACCOUNT_MANAGER" ||
            roleName === "ACCOUNT_MANAGER_MANAGER") ? (
            <Link to={`/customers/detail/${record?.customer?.id}`}>
              <Button type="default">Customer Detail</Button>
            </Link>
          ) : roleName === "CALL_TEAM" || roleName === "CALL_TEAM_MANAGER" ? (
            <Link
              to={
                record?.customer?.id
                  ? `/users/detail/${record?.customer?.id}`
                  : `/users/detail/${record?.learner?.id}`
              }
            >
              <Button type="default">User Detail</Button>
            </Link>
          ) : roleName === "SUCCESS_TEAM" || roleName === "SUCCESS_TEAM_MANAGER" ? (
            <Link
              to={
                record?.customer?.id
                  ? `/users/detail/${record?.customer?.id}`
                  : `/users/detail/${record?.learner?.id}`
              }
            >
              {record?.customer?.id ? (
                <Button type="default">Customer Detail</Button>
              ): (
                <Button type="default">Learner Detail</Button>
              )}
            </Link>
          ) : roleName === "SUSPEND_TEAM" ||
            roleName === "SUSPEND_TEAM_MANAGER" ? (
            <Link
              to={`/amazon-suspended-applicants/detail/${record?.amazonSuspendedApplicant?.id}`}
            >
              <Button type="default">Applicant Detail</Button>
            </Link>
          ) : (
            <Link to={`/learners/detail/${record?.learner?.id}`}>
              <Button type="default">Learner Detail</Button>
            </Link>
          )}
          <TaskDetailCustomer
            item={record}
            onHandleTaskList={handleTaskList}
            type={1}
          />
          <Popconfirm
            onConfirm={() =>
              confirmDeleteTask(
                record?.id,
                record?.assignee?.id,
                record?.status
              )
            }
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const confirmDeleteTask = (
    id: number,
    assigneeId: number,
    status: string
  ) => {
    if (status === "DONE") {
      TaskService.remove(id)
        .then((response: any) => {
          openNotification("success", "Success", "Deleted task");
          getTasks();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else {
      if (roleName === "ACCOUNT_MANAGER") {
        if (Number(authId) === Number(assigneeId)) {
          TaskService.remove(id)
            .then((response: any) => {
              openNotification("success", "Success", "Deleted task");
              getTasks();
            })
            .catch((e: Error) => {
              console.log(e);
            });
        } else {
          openNotification(
            "error",
            "Error",
            "You cannot delete a task you did not open"
          );
        }
      } else {
        TaskService.remove(id)
          .then((response: any) => {
            openNotification("success", "Success", "Deleted task");
            getTasks();
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    let currentUserId = localStorage.getItem("id");
    setAssigneeId(currentUserId);
    getUsers();
    // eslint-disable-next-line
  }, []);

  const handleTaskList = () => {
    getTasks();
  };

  const getUsers = () => {
    if (
      roleName === "SUCCESS_TEAM_MANAGER" ||
      roleName === "SUCCESS_TEAM" ||
      roleName === "ADMIN"
    ) {
      UserService.getAll("?roleName=SUCCESS_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item?.firstName} ${item?.lastName}`;
            return { value: item?.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else if (
      roleName === "ACCOUNT_MANAGER_MANAGER" ||
      roleName === "ACCOUNT_MANAGER"
    ) {
      UserService.getAll("?roleName=ACCOUNT_MANAGER")
        .then((response: any) => {
          let data = response;

          const newArray = data.map((item: any) => {
            let fullName = `${item?.firstName} ${item?.lastName}`;
            return { value: item?.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      UserService.getAll("?roleName=CALL_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item?.firstName} ${item?.lastName}`;
            return { value: item?.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else if (
      roleName === "SUSPEND_TEAM_MANAGER" ||
      roleName === "SUSPEND_TEAM"
    ) {
      UserService.getAll("?roleName=SUSPEND_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item?.firstName} ${item?.lastName}`;
            return { value: item?.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  // const getLearners = () => {
  //   setLoadingForm(true);

  //   if (
  //     roleName === "SUCCESS_TEAM_MANAGER" ||
  //     roleName === "SUCCESS_TEAM" ||
  //     roleName === "ADMIN"
  //   ) {
  //     if (roleName === "SUCCESS_TEAM") {
  //       LearnerService.getAllLearnersLiteMe()
  //         .then((response: any) => {
  //           let data = response.data;

  //           let learnersList = data.map((user: any) => ({
  //             value: user?.id,
  //             label: `${user?.firstName} ${user?.lastName}`,
  //           }));

  //           setLearnerOptions(learnersList);
  //           setLoadingForm(false);
  //         })
  //         .catch((e: Error) => {
  //           console.log(e);
  //           setLoadingForm(false);
  //         });
  //     } else {
  //       LearnerService.getAllLearnersLite()
  //         .then((response: any) => {
  //           let data = response.data;

  //           let learnersList = data.map((user: any) => ({
  //             value: user?.id,
  //             label: `${user?.firstName} ${user?.lastName}`,
  //           }));

  //           setLearnerOptions(learnersList);
  //           setLoadingForm(false);
  //         })
  //         .catch((e: Error) => {
  //           console.log(e);
  //           setLoadingForm(false);
  //         });
  //     }
  //   } else if (
  //     roleName === "ACCOUNT_MANAGER_MANAGER" ||
  //     roleName === "ACCOUNT_MANAGER"
  //   ) {
  //     if (roleName === "ACCOUNT_MANAGER_MANAGER") {
  //       CustomerService.getAllCustomersLite()
  //         .then((response: any) => {
  //           let data = response.data;
  //           let learnersList = data.map((user: any) => ({
  //             value: user?.id,
  //             label: `${user?.fullName} `,
  //           }));

  //           setLearnerOptions(learnersList);
  //           setLoadingForm(false);
  //         })
  //         .catch((e: Error) => {
  //           console.log(e);
  //           setLoadingForm(false);
  //         });
  //     } else if (roleName === "ACCOUNT_MANAGER") {
  //       CustomerService.getAllCustomersLiteMe()
  //         .then((response: any) => {
  //           let data = response.data;
  //           let learnersList = data.map((user: any) => ({
  //             value: user?.id,
  //             label: `${user?.fullName}`,
  //           }));

  //           setLearnerOptions(learnersList);
  //           setLoadingForm(false);
  //         })
  //         .catch((e: Error) => {
  //           console.log(e);
  //           setLoadingForm(false);
  //         });
  //     }
  //   } else if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
  //     if (roleName === "CALL_TEAM") {
  //       Promise.allSettled([
  //         LearnerService.getAllLearnersLiteMe(),
  //         CustomerService.getAllCustomersLiteMe(),
  //       ])
  //         .then((results) => {
  //           const [learnersResult, customersResult] = results;
  //           let combinedOptions: any = [];
  //           if (learnersResult?.status === "fulfilled") {
  //             let learnersData = learnersResult?.value?.data.map(
  //               (user: any) => ({
  //                 value: user?.id,
  //                 label: `${user?.firstName} ${user?.lastName}`,
  //                 type: "learner",
  //               })
  //             );
  //             combinedOptions = combinedOptions.concat(learnersData);
  //           } else {
  //             console.error("Error fetching learners:", learnersResult.reason);
  //           }
  //           if (customersResult?.status === "fulfilled") {
  //             let customersData = customersResult?.value?.data.map(
  //               (user: any) => ({
  //                 value: user?.id,
  //                 label: `${user?.fullName}`,
  //                 type: "customer",
  //               })
  //             );
  //             combinedOptions = combinedOptions.concat(customersData);
  //           } else {
  //             console.error(
  //               "Error fetching customers:",
  //               customersResult.reason
  //             );
  //           }
  //           setLearnerOptions(combinedOptions);
  //         })
  //         .catch((error) => {
  //           console.error("An unexpected error occurred:", error);
  //         })
  //         .finally(() => {
  //           setLoadingForm(false);
  //         });
  //     } else {
  //       Promise.allSettled([
  //         LearnerService.getAllLearnersLite(),
  //         CustomerService.getAllCustomersLite(),
  //       ])
  //         .then((results) => {
  //           const [learnersResult, customersResult] = results;
  //           let combinedOptions: any = [];
  //           if (learnersResult?.status === "fulfilled") {
  //             let learnersData = learnersResult?.value?.data.map(
  //               (user: any) => ({
  //                 value: user?.id,
  //                 label: `${user?.firstName} ${user?.lastName}`,
  //                 type: "learner",
  //               })
  //             );
  //             combinedOptions = combinedOptions.concat(learnersData);
  //           } else {
  //             console.error("Error fetching learners:", learnersResult.reason);
  //           }
  //           if (customersResult?.status === "fulfilled") {
  //             let customersData = customersResult?.value?.data.map(
  //               (user: any) => ({
  //                 value: user.id,
  //                 label: `${user?.fullName}`,
  //                 type: "customer",
  //               })
  //             );
  //             combinedOptions = combinedOptions.concat(customersData);
  //           } else {
  //             console.error(
  //               "Error fetching customers:",
  //               customersResult.reason
  //             );
  //           }
  //           setLearnerOptions(combinedOptions);
  //         })
  //         .catch((error) => {
  //           console.error("An unexpected error occurred:", error);
  //         })
  //         .finally(() => {
  //           setLoadingForm(false);
  //         });
  //     }
  //   }
  // };

  // const showDrawer = () => {
  //   setOpen(true);
  //   if (learnerOptions.length === 0) {
  //     getLearners();
  //   }
  // };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const getTasks = () => {
    setLoading(true);
    const { startDueDate, endDueDate } = calculateMonthRange(value);
    const criteria = `?startDueDate=${startDueDate}&endDueDate=${endDueDate}`;
    if (roleName === "ADMIN") {
      TaskService.getAllCriteria(criteria)
        .then((response: any) => {
          let taskData = response.data;          
          const reversedTaskData = [...taskData].reverse();
          setFilteredTasks(reversedTaskData);
          setTasks(reversedTaskData);
          forNote(reversedTaskData);
          toggleStatusChange();
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    } else if (roleName === "SUCCESS_TEAM_MANAGER") {
      TaskService.getAllLearners(criteria)
        .then((response: any) => {
          let taskData = response.data;
          const filteredTaskData = taskData.filter(
            (task: any) => task.learner !== null
          );
          const reversedTaskData = [...filteredTaskData].reverse();
          setFilteredTasks(reversedTaskData);
          setTasks(reversedTaskData);
          forNote(reversedTaskData);
          toggleStatusChange();
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    } else if (roleName === "ACCOUNT_MANAGER_MANAGER") {
      TaskService.getAllCustomers(criteria)
        .then((response: any) => {
          let taskData = response.data;
          const filteredTaskData = taskData.filter(
            (task: any) => task.customer !== null
          );
          const reversedTaskData = [...filteredTaskData].reverse();
          setFilteredTasks(reversedTaskData);
          setTasks(reversedTaskData);
          forNote(reversedTaskData);
          toggleStatusChange();
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      TaskService.getAllMyTask(criteria)
        .then((response: any) => {
          let taskData = response.data;
          const reversedTaskData = [...taskData].reverse();
          setFilteredTasks(reversedTaskData);
          setTasks(reversedTaskData);
          forNote(reversedTaskData);
          toggleStatusChange();
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  const forNote = (tasklist: any) => {
    const taskHistory: any = [];
    for (let index = 0; index < tasklist.length; index++) {
      const element = tasklist[index];
      let obj = element;
      taskHistory.push(obj);
    }
    setCellTasks(taskHistory);
  };

  const getListData = (x: Dayjs) => {
    const dateData: any = cellTasks || [];
    let matchedItems = [];
    for (let item of dateData) {
      if (
        x.format("YYYY-MM-DD") === dayjs(item?.dueDate).format("YYYY-MM-DD")
      ) {
        matchedItems.push({ type: "success", content: item, id: item.id });
      }
    }
    return matchedItems.length > 0 ? matchedItems : [];
  };

  const getMonthData = (value: Dayjs) => {
    if (value.month() === 8) {
      return 1394;
    } else if (value.month() === 3) {
      return 2323;
    }
  };

  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Task done</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    let currentListData;
    if (statusToggle === false) {
      currentListData = listData.filter(
        (task) => task?.content?.status === "TODO"
      );
    } else {
      currentListData = listData.filter(
        (task) => task?.content?.status === "DONE"
      );
    }
    return (
      <ul style={{ paddingLeft: "0px", margin: "0px" }} className="events">
        {currentListData.map((item) => (
          <li style={{ listStyle: "none", marginBottom: "5px" }} key={item.id}>
            <TaskDetailCustomer
              item={item.content}
              onHandleTaskList={handleTaskList}
              type={0}
            />
          </li>
        ))}
      </ul>
    );
  };

  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  const onSelect = (newValue: Dayjs) => {
    setValue(newValue);
    setSelectedValue(newValue);
  };

  const calculateMonthRange = (date: Dayjs) => {
    const startOfMonth = date.startOf("month").format("YYYY-MM-DDTHH:mm:ss");
    const endOfMonth = date.endOf("month").format("YYYY-MM-DDT23:59:59");
    return { startDueDate: startOfMonth, endDueDate: endOfMonth };
  };

  const onPanelChange = (value: Dayjs) => {
    const { startDueDate, endDueDate } = calculateMonthRange(value);
    const criteria = `?startDueDate=${startDueDate}&endDueDate=${endDueDate}`;

    if (roleName === "ACCOUNT_MANAGER_MANAGER") {
      TaskService.getAllCriteria(criteria)
        .then((response: any) => {
          const filteredTaskData = response.data.filter(
            (task: any) => task?.customer !== null
          );
          setFilteredTasks(filteredTaskData);
          setTasks(filteredTaskData);
          forNote(filteredTaskData);
          toggleStatusChange();
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    } else if (roleName === "SUCCESS_TEAM_MANAGER") {
      TaskService.getAllCriteria(criteria)
        .then((response: any) => {
          const filteredTaskData = response.data.filter(
            (task: any) => task?.learner !== null
          );
          setFilteredTasks(filteredTaskData);
          setTasks(filteredTaskData);
          forNote(filteredTaskData);
          toggleStatusChange();
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      TaskService.getAllMyTask(criteria)
        .then((response: any) => {
          let taskData = response.data;
          setFilteredTasks(taskData);
          setTasks(taskData);
          forNote(taskData);
          toggleStatusChange();
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  const openNotificationWithIcon = () => {
    api["success"]({
      message: "Registration Successful",
      description: "Task Registration Successful",
    });
    setOpen(false);
  };

  const createTask = (values: any) => {
    setCreateLoadingForm(true);

    const dueDate = dayjs(values?.dueDate).format("YYYY-MM-DDTHH:mm");
    let taskData: any;
    if (
      roleName === "ACCOUNT_MANAGER_MANAGER" ||
      roleName === "ACCOUNT_MANAGER"
    ) {
      taskData = {
        title: values?.title,
        description: values?.desc,
        tag: values?.tag,
        dueDate: dueDate,
        assigneeId: roleName === "ACCOUNT_MANAGER" ? authId : assigneeId,
        customerId: values?.learner,
        source: "WEB",
      };
    } else if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      if (values?.learnerType && values?.learnerType === "learner") {
        taskData = {
          title: values?.title,
          description: values?.desc,
          tag: values?.tag,
          dueDate: dueDate,
          assigneeId: assigneeId,
          learnerId: values?.learner,
          source: "WEB",
        };
      } else {
        taskData = {
          title: values?.title,
          description: values?.desc,
          tag: values?.tag,
          dueDate: dueDate,
          assigneeId: assigneeId,
          customerId: values?.learner,
          source: "WEB",
        };
      }
    } else {
      taskData = {
        title: values?.title,
        description: values?.desc,
        tag: values?.tag,
        dueDate: dueDate,
        assigneeId: assigneeId,
        learnerId: values?.learner,
        source: "WEB",
      };
    }

    TaskService.create(taskData)
      .then((response: any) => {
        openNotificationWithIcon();
        getTasks();
        form.resetFields();
        setCreateLoadingForm(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setCreateLoadingForm(false);
      });
  };

  const toggleView = (checked: boolean) => {
    setLoading(true);
    setShowTable(checked);
  };

  const toggleStatus = (checked: boolean) => {
    setLoading(true);
    setStatusToggle(checked);
  };

  useEffect(() => {
    if (tasks.length > 0) {
      toggleStatusChange();
    }
    // eslint-disable-next-line
  }, [statusToggle]);

  useEffect(() => {
    if (tasks.length > 0) {
      toggleStatusChange();
    }
    // eslint-disable-next-line
  }, [tasks]);

  const toggleStatusChange = () => {
    let filteredTasks;
    if (statusToggle === false) {
      filteredTasks = tasks.filter((task) => task?.status === "TODO");
      setFilteredTasks(filteredTasks);
    } else {
      filteredTasks = tasks.filter((task) => task?.status === "DONE");
      setFilteredTasks(filteredTasks);
    }
    setLoading(false);
  };

  const handleSearch = (search: any) => {
    setSearchText(search);
    const filteredTasks = tasks.filter((task) => {
      const fullName = `${task?.learner?.firstName} ${task?.learner?.lastName}`;
      return (
        fullName.toLowerCase().includes(search.toLowerCase()) ||
        task?.title.toLowerCase().includes(search.toLowerCase())
      );
    });

    setFilteredTasks(filteredTasks);
  };

  useEffect(() => {
    setFilteredTasks(tasks);
    setLoading(false);
    toggleStatusChange();
    // eslint-disable-next-line
  }, [showTable]);

  useEffect(() => {
    setLoading(true);
    const prevDateRange = prevDateRangeRef.current;

    const [startDate, endDate] = dateRange;

    let formattedDateRange;
    if (startDate && endDate) {
      formattedDateRange = `?startDueDate=${startDate}T00:00:00&endDueDate=${endDate}T23:59:59`;
    }
    console.log("formattedDateRange", formattedDateRange);

    if (startDate === undefined || endDate === undefined) {
      return;
    }
    if (
      prevDateRange?.[0] !== dateRange?.[0] ||
      prevDateRange?.[1] !== dateRange?.[1]
    ) {
      if (roleName === "ACCOUNT_MANAGER_MANAGER") {
        TaskService.getAllCriteria(formattedDateRange)
          .then((response: any) => {
            const filteredTaskData = response.data.filter(
              (task: any) => task?.customer !== null
            );
            setFilteredTasks(filteredTaskData);
            setTasks(filteredTaskData);
            forNote(filteredTaskData);
            toggleStatusChange();
            setLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setLoading(false);
          });
      } else if (roleName === "SUCCESS_TEAM_MANAGER") {
        TaskService.getAllCriteria(formattedDateRange)
          .then((response: any) => {
            const filteredTaskData = response.data.filter(
              (task: any) => task?.learner !== null
            );
            setFilteredTasks(filteredTaskData);
            setTasks(filteredTaskData);
            forNote(filteredTaskData);
            toggleStatusChange();
            setLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setLoading(false);
          });
      } else if (
        roleName === "CALL_TEAM_MANAGER" ||
        roleName === "SUSPEND_TEAM_MANAGER" ||
        roleName === "ADMIN"
      ) {
        TaskService.getAllCriteria(formattedDateRange)
          .then((response: any) => {
            let taskData = response.data;
            setFilteredTasks(taskData);
            setTasks(taskData);
            forNote(taskData);
            toggleStatusChange();
            setLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setLoading(false);
          });
      } else {
        TaskService.getAllMyTask(formattedDateRange)
          .then((response: any) => {
            let taskData = response.data;
            setFilteredTasks(taskData);
            setTasks(taskData);
            forNote(taskData);
            toggleStatusChange();
            setLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setLoading(false);
          });
      }
    }
    prevDateRangeRef.current = dateRange;
    // eslint-disable-next-line
  }, [dateRange]);

  const handleMonthChange = (date: any) => {
    if (!date) return;

    const startOfMonth = date.startOf("month").format("YYYY-MM-DD");
    const endOfMonth = date.endOf("month").format("YYYY-MM-DD");

    setDateRange([startOfMonth, endOfMonth]);
  };

  const handleUserChange = (value: any) => {
    setSelectedUserId(value);
    filterTasksByAssignee(value);
  };

  const filterTasksByAssignee = (userId: number) => {
    const filtered = tasks.filter((task) => task?.assignee?.id === userId);
    setFilteredTasks(filtered);
  };

  useEffect(() => {
    setSelectedMonth(dayjs());
    handleMonthChange(selectedMonth);
    // eslint-disable-next-line
  }, []);

  const handleSelectChange = (value: any, option: any) => {
    const selectedType = option.type;

    form.setFieldsValue({ learnerType: selectedType });
  };

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      {contextHolder}
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              Task List
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Task",
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Card className="task">
              <Row
                gutter={[16, 16]}
                justify="space-between"
                style={{ marginBottom: 16 }}
              >
                <Col>
                  {/* <Button type="primary" onClick={showDrawer}>
                    New Task
                  </Button> */}
                </Col>
                <Col style={{ display: "flex" }}>
                  <Space size={5} align="center">
                    <Switch
                      checkedChildren="DONE"
                      unCheckedChildren="TODO"
                      onChange={toggleStatus}
                      style={{ marginRight: 10 }}
                    />
                    <div
                      style={
                        !showTable ? { display: "flex" } : { display: "none" }
                      }
                    >
                      <Space size={5} align="center">
                        <DateRange onChangeDateRange={setDateRange} />
                        <Input
                          placeholder="Search Name or Title"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchText}
                        />
                        {roleName === "SUCCESS_TEAM_MANAGER" ? (
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select a user"
                            optionFilterProp="children"
                            onChange={handleUserChange}
                            filterOption={(input: any, option: any) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {userOptions?.map((user) => (
                              <Option key={user.value} value={user.value}>
                                {user.label}
                              </Option>
                            ))}
                          </Select>
                        ) : null}
                      </Space>
                    </div>
                    <Switch
                      checkedChildren="Calendar"
                      unCheckedChildren="List"
                      onChange={toggleView}
                    />
                  </Space>
                </Col>
              </Row>

              {!showTable ? (
                <>
                  <Table
                    dataSource={filteredTasks}
                    columns={columns}
                    loading={loading}
                    rowKey="id"
                    scroll={{ x: 1300 }}
                  />
                </>
              ) : (
                <Calendar
                  mode="month"
                  cellRender={cellRender}
                  key={tasks?.length}
                  value={value}
                  onSelect={onSelect}
                  onPanelChange={onPanelChange}
                />
              )}
            </Card>
          </Col>
          <Col span={24}>
            <Drawer
              title="New Task"
              placement="right"
              onClose={onClose}
              open={open}
            >
              <Card
                className="new-task-card"
                style={{ border: "none" }}
                loading={loadingForm}
              >
                <Form form={form} onFinish={createTask} layout="vertical">
                  <Item
                    name="title"
                    label="Title"
                    rules={[
                      { required: true, message: "Please enter a title!" },
                      {
                        max: 255,
                        message: "You can enter a maximum of 255 characters",
                      },
                      {
                        pattern: new RegExp(/\S/),
                        message: "Title cannot be empty or spaces only",
                      },
                    ]}
                  >
                    <Input />
                  </Item>

                  <Item
                    name="desc"
                    label="Description"
                    rules={[
                      {
                        max: 255,
                        message: "You can enter a maximum of 255 characters",
                      },
                      {
                        pattern: new RegExp(/\S/),
                        message: "Description cannot be empty or spaces only",
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="" />
                  </Item>

                  <Item
                    name="tag"
                    label="Tag"
                    rules={[
                      {
                        max: 255,
                        message: "You can enter a maximum of 255 characters",
                      },
                      {
                        pattern: new RegExp(/\S/),
                        message: "Tag cannot be empty or spaces only",
                      },
                    ]}
                  >
                    <Input />
                  </Item>

                  <Item
                    name="learner"
                    label={
                      roleName === "SUCCESS_TEAM_MANAGER" ||
                      roleName === "SUCCESS_TEAM" ||
                      roleName === "ADMIN"
                        ? "Learner"
                        : "Customer"
                    }
                    rules={[
                      { required: true, message: "Please select a learner!" },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      showSearch
                      optionFilterProp="children"
                      options={learnerOptions}
                      onChange={handleSelectChange}
                      filterOption={(input, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Item>
                  <Form.Item name="learnerType" noStyle>
                    <input type="hidden" />
                  </Form.Item>

                  {can("learners:getAll") &&
                  roleName !== "ACCOUNT_MANAGER" &&
                  roleName !== "HI5_ACM_MEMBER" &&
                  roleName !== "CALL_TEAM" &&
                  roleName !== "SUCCESS_TEAM" &&
                  roleName !== "SUSPEND_TEAM" ? (
                    <Item name="assigneeId" label="Assigne">
                      <Select
                        onChange={(selectedUser) => setAssigneeId(selectedUser)}
                        options={userOptions}
                        placeholder={"Select"}
                        style={{ width: "100%" }}
                      ></Select>
                    </Item>
                  ) : null}

                  <Item
                    name="dueDate"
                    label="Due Date"
                    rules={[
                      { required: true, message: "Please select a date!" },
                    ]}
                  >
                    <DatePicker
                      showTime={{
                        defaultValue: dayjs("00:00", "HH:mm"),
                        minuteStep: 5,
                      }}
                      style={{ width: "100%" }}
                      disabledDate={disabledDate}
                      format={"DD/MM/YYYY HH:mm"}
                      placeholder="Select Date"
                    />
                  </Item>

                  <Item>
                    <Row justify={"end"}>
                      <Button
                        type="default"
                        onClick={onClose}
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={createLoadingForm}
                        type="primary"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Row>
                  </Item>
                </Form>
              </Card>
            </Drawer>
          </Col>
        </Row>
      </div>
    </motion.div>
  );
};
