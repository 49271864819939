import http from "../http-common";

const callTeamEarningReport = (criteria: any) => {
  return http.get<any>(`/commissions/call-team-members${criteria}`);
};

const accountManagerEarningReport = (criteria: any) => {
  return http.get<any>(`/commissions/account-manager-members${criteria}`);
};

const hi5AccountManagerEarningReport = (criteria: any) => {
  return http.get<any>(`/commissions/hi5-acm-members${criteria}`);
};

const successTeamEarningReport = (criteria: any) => {
  return http.get<any>(`/commissions/success-team-members${criteria}`);
};

const earningReport = (teamName: string, startDate: string, endDate: string) => {
  return http.get<any>(`/commissions/${teamName}?startDate=${startDate}&endDate=${endDate}`);
};

const CommissionService = {
  callTeamEarningReport,
  accountManagerEarningReport,
  hi5AccountManagerEarningReport,
  successTeamEarningReport,
  earningReport
};

export default CommissionService;
