import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CallTeamService from '../../services/CallTeamService';

interface SalesCardProps {
  title: string;
  description: string;
  orderCount: number;
  id: number;
  ratio: any;
  learner:any;
}

const SalesCard: React.FC<SalesCardProps> = ({ title, description, id, ratio,learner }) => {
  const [orderCount, setOrderCount] = useState(0);
  const [salesData, setSalesData] = useState<any[]>([]);
  const options: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          download: false
        },
      },
    },
    grid: {
      show: false,
    },
    colors: ['#ffcb2b'],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2023-01-01T00:00:00.000Z', '2023-01-02T00:00:00.000Z', '2023-01-03T00:00:00.000Z', '2023-01-04T00:00:00.000Z', '2023-01-05T00:00:00.000Z', '2023-01-06T00:00:00.000Z', '2023-01-07T00:00:00.000Z',
      ],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yyyy',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    }
  };


  const getCustomerSalesDetail = () => {
    let phone = learner?.phoneNumber;
    CallTeamService.callCardChart(`?phoneNumber=${phone}`)
    .then((response) => {
      const sortedEntries = Object.entries(response.data).sort(([keyA], [keyB]) => new Date(keyA).getTime() - new Date(keyB).getTime());

      const formattedData = sortedEntries.map(([date, value]) => {
        const dateParts = date.split('-').map(part => parseInt(part, 10));
        const utcDate = Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2]);
        return {
          x: utcDate,
          y: value,
        };
      });

      setSalesData([{ name: "Call", data: formattedData }]);
    })
    .catch((error) => {
      console.error(error);
    });
  }
  useEffect(() => {
    getCustomerSalesDetail();
  }, [])
  
  return (
    <Card bordered={false} className={'sales-card'}>
      <p className='title'>{title}</p>
      <p className='description'>{description}</p>
      <p  style={{fontSize:16}}>Last 7 Days</p>
      <p className='sales-card-info' style={{ marginTop: 40 }}><span style={{ fontSize: 32 }}>{0}</span> <br />Last Login </p>
      <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
        <ReactApexChart
          options={options}
          series={salesData}
          type="area"
          height={175}
        />
      </div>
    </Card>
  );
};

export default SalesCard;
