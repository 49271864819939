import axios from "axios";
import http from "../http-common";
import { IFeedbackData } from "../types/Feedback";

const baseURL = process.env.REACT_APP_API_BASE_URL;

// Feedback gönderir (submit)
const submit = (
  data: Omit<IFeedbackData, "customerEmail" | "callDate" | "callerId" | "callerType" | "roleId" | "startDate" | "endDate" | "roleName">
) => {
  return axios.post(`${baseURL}/feedback/submit`, data);
};

// Yeni feedback oluşturur
const create = (data: Omit<IFeedbackData, "token" | "startDate" | "endDate" | "roleName">) => {
  return http.post<IFeedbackData>("/feedback/create", data);
};

// Belirli bir token ile feedback verilerini alır
const getByToken = (token: string) => {
  return http.get<IFeedbackData>(`/feedback?token=${token}`);
};

// Feedback raporlarını alır
const getReport = (startDate: string, endDate: string, roleName: string) => {
  return http.get(
    `/feedback/report?startDate=${startDate}&endDate=${endDate}&roleName=${roleName}`
  );
};

// İlgili memberın detaylı feedback raporlarını alır
const getDetailsByUserId = (userId: number, startDate: string, endDate: string) => {
  return http.get<IFeedbackData>(`/feedback/details/${userId}?startDate=${startDate}&endDate=${endDate}`);
};

const FeedbackService = {
  submit,
  create,
  getByToken,
  getReport,
  getDetailsByUserId
};

export default FeedbackService;
