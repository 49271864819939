import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Col, Row, Space } from "antd";
import "./acm-dashboard.css";
import DashboardService from "../../../services/DashboardService";
import ReactApexChart from "react-apexcharts";
import { getProfilePhotoPath } from "../../../utils";
import goldIcon from "../../../assets/gold.svg";
import silverIcon from "../../../assets/silver.svg";
import bronzIcon from "../../../assets/bronz.svg";
import UserDetailsModal from "./UserDetailsModal";

interface InfoCardProps {
  title: string;
  dateRange: any;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, dateRange }) => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [salesSummary, setSalesSummary] = useState<any>([]);
  // eslint-disable-next-line
  const [salesSummaryMe, setSalesSummaryMe] = useState<any>([]);
  const [allCallTeamUsers, setAllCallTeamUsers] = useState<any>([]);
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [total, setTotal] = useState<number>(0);
  const [totalCommission, setTotalCommission] = useState<number>(0);
  const [totalTurnover, setTotalTurnover] = useState<number>(0);
  const [salesRatio, setSalesRatio] = useState<number>(0);
  const [totalMe, setTotalMe] = useState<number>(0);
  const [totalCommissionMe, setTotalCommissionMe] = useState<number>(0);
  const [totalTurnoverMe, setTotalTurnoverMe] = useState<number>(0);
  // eslint-disable-next-line
  const [salesRatioMe, setSalesRatioMe] = useState<number>(0);
  // eslint-disable-next-line
  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#86A4C8",
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "15.8px",
          },
        },
      },
    },
    labels: ["Commission"],
    colors: ["#C88686"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 130,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const roleName = localStorage.getItem("roleName");

  const handleUserClick = (user: any) => {
    setSelectedUser(user);
    setUserDetailsVisible(true);
  };

  const fetchSalesSummary = async (formattedDateRange: string) => {
    try {
      if (roleName === "CALL_TEAM_MANAGER") {
        const summaryResponse = await DashboardService.salesSummary(
          `${formattedDateRange}`
        );
        const {
          salesPackageCount,
          totalCommissionAmount,
          totalTurnoverAmount,
        } = summaryResponse.data;
        setSalesSummary(summaryResponse.data);
        const UserData = summaryResponse.data.userList.sort(
          (a: any, b: any) => b.salesPackageCount - a.salesPackageCount
        );
        setAllCallTeamUsers(UserData);
        setTotal(salesPackageCount);
        setTotalCommission(totalCommissionAmount);
        setTotalTurnover(totalTurnoverAmount);
        const ratio = (
          (totalCommissionAmount / totalTurnoverAmount) *
          100
        ).toFixed(0);
        setSalesRatio(isNaN(parseFloat(ratio)) ? 0 : parseFloat(ratio));
      } else {
        const summaryMeResponse = await DashboardService.salesSummaryMe(
          `${formattedDateRange}`
        );
        const {
          salesPackageCount,
          totalCommissionAmount,
          totalTurnoverAmount,
        } = summaryMeResponse.data;
        setSalesSummaryMe(summaryMeResponse.data);
        setTotalMe(salesPackageCount);
        setTotalCommissionMe(totalCommissionAmount);
        setTotalTurnoverMe(totalTurnoverAmount);
        const ratio = (
          (totalCommissionAmount / totalTurnoverAmount) *
          100
        ).toFixed(0);
        setSalesRatio(isNaN(parseFloat(ratio)) ? 0 : parseFloat(ratio));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      const [startDate, endDate] = dateRange;
      if (startDate && endDate) {
        const formattedDateRange = `?startDate=${startDate}&endDate=${endDate}`;
        setLoading(true);
        fetchSalesSummary(formattedDateRange);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, roleName]);

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <Row>
        <p
          className="title"
          style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
        >
          {title}
        </p>
      </Row>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={13} sm={12} md={11}>
          <ReactApexChart
            options={options}
            series={
              roleName === "CALL_TEAM_MANAGER" ? [salesRatio] : [salesRatioMe]
            }
            type="radialBar"
            height={212}
          />
        </Col>
        <Col xs={11} sm={12} md={13}>
          <Row align="middle" style={{ height: "180px" }}>
            <Space direction="vertical">
              <Badge
                color="gray"
                style={{whiteSpace: 'nowrap'}}
                text={
                  <>
                    Total:{" "}
                    <b>{roleName === "CALL_TEAM_MANAGER" ? total : totalMe}</b>
                  </>
                }
              />
              <Badge
                color="rgb(200, 134, 134)"
                style={{whiteSpace: 'nowrap'}}
                text={
                  <>
                    Commission:{" "}
                    <b>
                      $
                      {roleName === "CALL_TEAM_MANAGER"
                        ? totalCommission
                        : totalCommissionMe}
                    </b>
                  </>
                }
              />
              <Badge
                color="rgb(134, 164, 200)"
                style={{whiteSpace: 'nowrap'}}
                text={
                  <>
                    Turnover:{" "}
                    <b>
                      $
                      {roleName === "CALL_TEAM_MANAGER"
                        ? totalTurnover
                        : totalTurnoverMe}
                    </b>
                  </>
                }
              />
            </Space>
          </Row>
        </Col>
      </Row>
      {roleName === "CALL_TEAM_MANAGER" && (
        <>
          <Row
            justify={"space-between"}
            className="rowItem"
            style={{
              padding: "10px 25px",
            }}
          >
            <h4 style={{ margin: "0px", fontSize: 12 }}>Call Team Member</h4>
            <h4 style={{ margin: "0px", fontSize: 12 }}>Sales</h4>
          </Row>
          <Card
            style={{ height: 160, overflow: "auto", border: "none" }}
            className="call-team-top-ten"
          >
            {allCallTeamUsers?.map((dataItem: any, index: any) => {
              return (
                <Card
                  key={index}
                  bordered={false}
                  style={{ margin: 0, padding: 0, cursor: "pointer" }}
                  onClick={() => handleUserClick(dataItem)}
                >
                  <Row
                    justify={"space-between"}
                    align={"middle"}
                    style={{ padding: 0 }}
                  >
                    <Row align={"middle"}>
                      <Avatar
                        style={{ marginRight: 5 }}
                        size={30}
                        src={getProfilePhotoPath(
                          dataItem?.user?.profilePhoto || 1
                        )}
                      />
                      <p style={{ margin: "0px" }}>
                        {dataItem?.user?.firstName} {dataItem?.user?.lastName}
                      </p>
                    </Row>
                    <Row align={"middle"} justify={"space-between"}>
                      <img
                        src={
                          index === 0
                            ? goldIcon
                            : index === 1
                            ? silverIcon
                            : index === 2
                            ? bronzIcon
                            : ""
                        }
                        alt="icon"
                        style={{ marginRight: 16 }}
                      />
                      <h4
                        style={{
                          margin: "0px",
                          width: "10px",
                          textAlign: "right",
                        }}
                      >
                        {dataItem?.salesPackageCount}
                      </h4>
                    </Row>
                  </Row>
                </Card>
              );
            })}
          </Card>
          <UserDetailsModal
            visible={userDetailsVisible}
            onClose={() => setUserDetailsVisible(false)}
            userDetails={selectedUser}
          />
        </>
      )}
    </Card>
  );
};

export default InfoCard;
