import { Input, Row, Space } from 'antd'
import React, { Component, useCallback, useEffect, useState } from 'react'
import Filter from './Filter'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import _ from 'lodash';



export const FilterField: React.FC<any> = ({ onChangeSearch, setFilterText, pageSize, setFormFields, formFields }) => {
    const [searchText, setSearchText] = useState('');

    const debouncedSearch = useCallback(_.debounce(onChangeSearch, 500), [onChangeSearch]);

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearchText(newValue);
        debouncedSearch(newValue);
    };

    const clearInput = () => {
        setSearchText('');
        debouncedSearch('');
    };

    const handleOnChangeFilter = (filterForm: any) => {
        let filterQuery = `?page=0&size=${pageSize}&search=${searchText}&state=Active`;

        if (filterForm.state && filterForm.state != null) {
            filterQuery += `&sessionStatus=${filterForm.state}`;
        }

        if (filterForm.accountManagerMember && filterForm.accountManagerMember != null) {
            filterQuery += `&userId=${filterForm.accountManagerMember}`;
        }

        if (filterForm.status && filterForm.status != null) {
            filterQuery += `&callStatusIds=${filterForm.status}`;
        }

        if (filterForm.currentPackage && filterForm.currentPackage != null) {
            filterQuery += `&currentPackage=${filterForm.currentPackage}`;
        }

        if (filterForm.startPassiveDay) {
            filterQuery += `&startPassiveDays=${filterForm.startPassiveDay}`;
        }

        if (filterForm.endPassiveDay) {
            filterQuery += `&endPassiveDays=${filterForm.endPassiveDay}`;
        }

        if (filterForm.sellerLevel) {
            filterQuery += `&sellerLevel=${filterForm.sellerLevel}`;
        }

        if (filterForm.freeDays) {
            filterQuery += `&freeDays=${filterForm.freeDays}`;
        }
        if (filterForm.level) {
            filterQuery += `&level=${filterForm.level}`;
        }
        if (filterForm.lastSalesDay !== undefined) {
            if (filterForm.lastSalesDay.length > 0) {
                let startDate = dayjs(filterForm.lastSalesDay[0]).format('YYYY-MM-DDT00:00:00');
                let endDate = dayjs(filterForm.lastSalesDay[1]).format('YYYY-MM-DDT23:59:59');
                filterQuery += `&startLastSalesDate=${startDate}&endLastSalesDate=${endDate}`;
            }
        }

        if (filterForm.createdAtRange !== undefined) {
            if (filterForm.createdAtRange.length > 0) {
                let startDate = dayjs(filterForm.createdAtRange[0]).format('YYYY-MM-DDT00:00:00');
                let endDate = dayjs(filterForm.createdAtRange[1]).format('YYYY-MM-DDT23:59:59');
                filterQuery += `&startCreatedAt=${startDate}&endCreatedAt=${endDate}`;
            }
        }

        setFilterText(filterQuery);

    };


    return (
        <Row justify="end">
            <Space align='start'>
                <Input
                    prefix={<SearchOutlined />}
                    suffix={
                        searchText && (
                            <CloseCircleOutlined
                                onClick={clearInput}
                                style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                            />
                        )
                    }
                    placeholder="Search"
                    value={searchText}
                    onChange={onSearchChange}
                    style={{ maxWidth: 200 }}
                />
                <Filter onChangeFilter={handleOnChangeFilter} setFormFields={setFormFields} formFields={formFields} />
            </Space>
        </Row>
    )
}
