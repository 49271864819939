import { Breadcrumb, Col, Row, Typography } from "antd";
import "./reviews.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import SettingsRouter from "../components/settings/SettingsRouter";
const { Title } = Typography;

export const Settings = () => {
  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              Settings
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />
      </div>
    </motion.div>
  );
};
