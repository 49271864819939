import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Drawer, Popconfirm, Row, Tooltip } from 'antd';
import './info-card.css';
import { PushpinOutlined, PushpinFilled, DeleteFilled, DeleteOutlined, EditOutlined, PhoneOutlined, GlobalOutlined } from '@ant-design/icons';
import { getProfilePhotoPath, openNotification } from '../../utils';
import PhoneCall from "./../../assets/PhoneCall.svg";
import Pen from "./../../assets/Pen.svg";
import moment from 'moment';
import LearnerNoteService from '../../services/LearnerNoteService';
import CustomerNoteCreate from './CustomerNoteCreate';


const Notes: React.FC<any> = ({ notes, id, setTriggerUpdate }) => {
    const [open, setOpen] = useState(false);
    const authUserId = localStorage.getItem('id');
    const roleName = localStorage.getItem('roleName');
    const sortNotes = (notesArray: any) => {
        return notesArray.sort((a: any, b: any) => {
            if (a.pinned === b.pinned) {
                return new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime();
            }
            return a.pinned ? -1 : 1;
        });
    };


    const [allNotes, setAllNotes] = useState(sortNotes(Array.isArray(notes) ? notes : []));

    const handleNoteAdded = () => {
        setTriggerUpdate((prev: number) => prev + 1);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const confirmDeleteNote = (id: any, userId: any) => {
        if (authUserId == userId) {
            LearnerNoteService.remove(id)
                .then((response: any) => {
                    openNotification('success', 'Success', 'Note deleted');
                    handleNoteAdded();
                }).catch((e: Error) => {
                });
        } else {
            openNotification('error', 'Error', 'You cannot delete notes that you did not create');
        }

    }

    const togglePin = (id: number) => {
        LearnerNoteService.pin(id)
            .then(() => {
                handleNoteAdded();
            })
            .catch((error) => {
                console.error('Error pinning the note:', error);
            });
    };

    useEffect(() => {
        if (Array.isArray(notes)) {
            setAllNotes(sortNotes(notes));
        }
    }, [notes]);

    return (
        <>

            <Drawer width={450} placement="right" closable={true} onClose={onClose} open={open}>
                <CustomerNoteCreate id={id} onNoteAdded={handleNoteAdded} onClose={onClose} />
            </Drawer>
            <Card bordered={false} className={'card'}>
                <Row justify={'space-between'}>
                    <p className='title'>Notes</p>
                    {roleName !== "SUCCESS_TEAM_REPORTER" ?
                        <Button type='primary' onClick={showDrawer}>Add Note</Button>
                        : null}
                </Row>
                <p className='description'>{notes?.length} Notes</p>
                <div className="notes-container">
                    <Row justify="space-between" align="middle" className="note-row-title">
                        <Col span={2} className='note-table-title'></Col>
                        <Col span={12} className='note-table-title'>TITLE</Col>
                        <Col span={4} className='note-table-title'>NOTE TYPE</Col>
                        <Col span={3} className='note-table-title'>DATE</Col>
                        <Col span={3} className='note-table-title'></Col>
                    </Row>
                    {allNotes?.map((item: any) => (
                        <Row key={item.id} justify="space-between" align="middle" className="note-row">
                            <Col span={2} className='note-text'>
                                <Tooltip title={item.user?.firstName + ' ' + item.user?.lastName} placement="bottom">
                                    <Avatar style={{ marginRight: '15px' }} size={40} src={getProfilePhotoPath(item.user?.profilePhoto ?? 1)} />
                                </Tooltip>
                            </Col>
                            <Col span={12} className='note-text' style={{ paddingRight: 25, whiteSpace: 'pre-wrap' }}>
                                {item.description}
                            </Col>
                            <Col span={4} className='note-text' >
                                <Tooltip title={item.type} placement='bottom'>
                                    {item.type === 'CONTACT' ? <img src={PhoneCall} alt='call-icon' style={{ marginRight: 5, marginTop: 0, marginLeft: 30 }} /> : (item.type === 'INTERNAL' ? <img src={Pen} alt='call-icon' style={{ marginRight: 5, marginTop: 0, marginLeft: 30 }} /> : <GlobalOutlined style={{ marginRight: 5, marginTop: 0, marginLeft: 30 }} />)}
                                </Tooltip>
                            </Col>
                            <Col span={3} className='note-text'>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</Col>

                            <Col span={3} className='note-icon-container'>
                                {roleName !== "SUCCESS_TEAM_REPORTER" ?
                                    <>
                                        <div className={`icon-wrapper ${item.pinned ? 'checked' : ''}`}
                                            onClick={() => togglePin(item.id)}>
                                            {item.pinned ? <PushpinFilled /> : <PushpinOutlined />}
                                        </div>
                                        <Popconfirm
                                            title="Delete the note"
                                            description="Are you sure to delete this note?"
                                            onConfirm={() => confirmDeleteNote(item.id, item.user.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <div className={`icon-wrapper ${item.isChecked ? 'checked' : ''}`}>

                                                <DeleteOutlined style={{}} />
                                            </div>
                                        </Popconfirm>
                                    </>
                                    : null}
                            </Col>

                        </Row>
                    ))}
                </div>
            </Card>
        </>

    );
};

export default Notes;
