import {
  Button,
  Card,
  Pagination,
  PaginationProps,
  Row,
  Switch,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CallSalesPackageService from "../../services/CallSalesPackageService";
import { convertDate, openNotification } from "../../utils";
import ProspectCustomerService from "../../services/ProspectCustomerService";
import { ColumnsType } from "antd/es/table";
import { CopyClipboard } from "../General/CopyClipboard";
import { StartCall } from "../General/StartCall";
import UserService from "../../services/UserService";
import moment from "moment";

const { Title } = Typography;

export const SalesApproval: React.FC<any> = () => {
  // const [users, setUsers] = useState<any[]>([]);
  // const [packages, setPackages] = useState<{ [key: number]: string }>({});
  // const [callTeam, setCallTeam] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [salesLogs, setSalesLogs] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const roleName = localStorage.getItem("roleName");
  const authUserId = localStorage.getItem("id");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const [filterText, setFilterText] = useState<string>(
    `?page=${lastCurrentPage}&size=${lastPageSize}${
      roleName === "CALL_TEAM" ? `&callCenterMemberId=${authUserId}` : ""
    }`
  );

  useEffect(() => {
    getCallCenterSalesLogs();
  }, []);

  const handleSaleApproval = (id: any, approveStatus: boolean) => {
    setIsLoading(true);
    CallSalesPackageService.approveSalesLog(id, approveStatus)
      .then((response: any) => {
        openNotification("success", "Success", "Sale Approved");
        const updatedSalesLogs = salesLogs.map((log) =>
          log.id === id ? { ...log, approveStatus } : log
        );
        setSalesLogs(updatedSalesLogs);
        getCallCenterSalesLogs();
      })
      .catch((e: Error) => {
        console.log(e);
        openNotification("error", "Error", "Sale Approval Failed");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCallCenterSalesLogs = () => {
    setIsLoading(true);

    CallSalesPackageService.getAllSalesLog()
      .then((response: any) => {
        const url = window.location.href;

        let filteredLogs = [];

        if (
          url.includes("prospect-customers") &&
          response.data.some(
            (log: any) =>
              log.prospectCustomer &&
              log.customer === null &&
              log.learner === null
          )
        ) {
          filteredLogs = response.data.filter(
            (log: any) =>
              log.prospectCustomer &&
              log.customer === null &&
              log.learner === null
          );
        } else if (
          url.includes("learners") &&
          response.data.some(
            (log: any) =>
              log.learner &&
              log.prospectCustomer === null &&
              log.customer === null
          )
        ) {
          filteredLogs = response.data.filter(
            (log: any) =>
              log.learner &&
              log.prospectCustomer === null &&
              log.customer === null
          );
        } else if (
          url.includes("customers") &&
          response.data.some(
            (log: any) =>
              log.customer &&
              log.prospectCustomer === null &&
              log.learner === null
          )
        ) {
          filteredLogs = response.data.filter(
            (log: any) =>
              log.customer &&
              log.prospectCustomer === null &&
              log.learner === null
          );
        }

        if (filteredLogs.length > 0) {
          const sortedSalesLogs = filteredLogs.sort((a: any, b: any) => {
            if (!a.approvalDate && b.approvalDate) {
              return -1;
            } else if (a.approvalDate && !b.approvalDate) {
              return 1;
            } else if (!a.approvalDate && !b.approvalDate) {
              return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
              );
            }
            const approvalDateComparison =
              new Date(a.approvalDate).getTime() -
              new Date(b.approvalDate).getTime();
            if (approvalDateComparison !== 0) {
              return approvalDateComparison;
            }
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          });
          setSalesLogs(sortedSalesLogs);
        } else {
          setSalesLogs([]);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCallCenterSalesLogs();
  }, []);

  const useCustomerType = () => {
    const location = useLocation();
    if (location.pathname.includes("prospect-customers")) {
      return "prospectCustomer";
    } else if (
      location.pathname.includes("customers") ||
      location.pathname.includes("suspended-customers")
    ) {
      return "customer";
    } else if (location.pathname.includes("learners")) {
      return "learner";
    } else {
      return "prospectCustomer"; // Default value if none match
    }
  };

  const customerType = useCustomerType();

  const columns: ColumnsType<string> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
      width: 110,
      render: (text: string) => <p>{convertDate(text)}</p>,
    },
    {
      title: "Username",
      dataIndex: customerType,
      key: "fullName",
      fixed: "left",
      sorter: (a: any, b: any) =>
        a[customerType]?.fullName.localeCompare(b[customerType]?.fullName),
      width: 180,
      render: (customerType: any) => <p>{customerType?.fullName}</p>,
    },
    {
      title: "Email",
      dataIndex: customerType,
      key: "email",
      sorter: (a: any, b: any) =>
        a[customerType]?.email.localeCompare(b[customerType]?.email),
      width: 190,
      render: (customerType: any) => (
        <CopyClipboard text={customerType?.email}></CopyClipboard>
      ),
    },
    {
      title: "Phone",
      dataIndex: customerType,
      key: "phone",
      sorter: (a: any, b: any) =>
        a[customerType]?.phone.localeCompare(b[customerType]?.phone),
      width: 190,
      render: (customerType: any) => (
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {customerType?.phone}
          <p>
            <StartCall text={customerType?.phone} />
          </p>
        </p>
      ),
    },
    {
      title: "Call Team Member",
      dataIndex: "user",
      key: "teamMember",
      width: 150,
      sorter: (a: any, b: any) => {
        const fullNameA = `${a.firstName} ${a.lastName}`;
        const fullNameB = `${b.firstName} ${b.lastName}`;
        return fullNameA.localeCompare(fullNameB);
      },
      render: (user: any) => (
        <span>{user ? `${user?.firstName} ${user?.lastName}` : "-"}</span>
      ),
    },
    {
      title: "Approval Date",
      dataIndex: "approvalDate",
      key: "approvalDate",
      width: 150,
      sorter: (a: any, b: any) =>
        new Date(a.approvalDate).getTime() - new Date(b.approvalDate).getTime(),
      render: (approvalDate: string) => (
        <span>{approvalDate ? convertDate(approvalDate) : "-"}</span>
      ),
    },
    {
      title: "Package Name",
      dataIndex: "callCenterSalesPackage",
      key: "callCenterSalesPackage",
      width: 200,
      sorter: (a: any, b: any) =>
        a.callCenterSalesPackage.name.localeCompare(
          b.callCenterSalesPackage.name
        ),
      render: (callCenterSalesPackage: any) => (
        <span>{callCenterSalesPackage?.name}</span>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   fixed: "right",
    //   width: 120,
    //   sorter: (a: any, b: any) => {
    //     // Varsayılan olarak onay durumuna göre sırala
    //     return a.approveStatus - b.approveStatus;
    //   },
    //   render: (text: any, record: any) => (
    //     <span>
    //       <Switch
    //         checked={record.approveStatus}
    //         onChange={(checked: boolean) =>
    //           handleSaleApproval(record.id, checked)
    //         }
    //         checkedChildren="Approve"
    //         unCheckedChildren="Reject"
    //       />
    //     </span>
    //   ),
    // },
  ];

  useEffect(() => {
    localStorage.removeItem("customerFilterText");
    localStorage.removeItem("hi5CustomerFilterText");
    localStorage.removeItem("learnerFilterText");
    localStorage.removeItem("prospectFilterText");
    localStorage.removeItem("softwareSuspendedFilterText");
    localStorage.removeItem("amazonSuspendedFilterText");
  }, []);

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"              
            >
              <Row justify={"start"} align={"middle"}>
                <Button
                  type="default"
                  onClick={() => navigate(-1)}
                  style={{ marginRight: 16 }}
                >
                  <ArrowLeftOutlined />
                </Button>
                <p style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}>Sales Approval List</p>
              </Row>
            </div>
            <div className="customer-card-info">
              <b>{salesLogs.length} </b>Sales
            </div>
          </div>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          // rowKey="id"
          loading={isLoading}
          scroll={{ x: "100%" }}
          // key={"id"}
          columns={columns}
          dataSource={salesLogs}
          // pagination={false}
          // onChange={handleTableChange}
        />
        {/* <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row> */}
      </Card>
    </>
  );
};

export default SalesApproval;
