import { Col, Row } from "antd";
import "./dashboard.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { HeaderBar } from "../components/Dashboard/HeaderBar";
import InfoCard from "../components/Dashboard/AcmDashboard/InfoCard";
import SalesCard from "../components/Dashboard/AcmDashboard/SalesCard";
import DashboardService from "../services/DashboardService";
import { Hi5CustomerList } from "../components/Dashboard/Hi5AcmDashboard/Hi5CustomerList";
import { motion } from "framer-motion";

export const Hi5AcmDashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<any>([]);
  const [activeCustomersCount, setActiveCustomersCount] = useState<any>(0);
  const [feedbackRating, setFeedbackRating] = useState<any>(0);
  const [oneamzOrderCount, setOneamzOrderCount] = useState<any>(0);
  const [totalOrderCount, setTotalOrderCount] = useState<any>(0);
  const [loading, setLoading] = useState(true);
  const roleName = localStorage.getItem("roleName");

  useEffect(() => {
    setLoading(true);
    const [startDate, endDate] = dateRange;

    let formattedDateRange;

    let formattedDateRange2;
    if (startDate && endDate) {
      formattedDateRange = `startDate=${startDate}T00:00:00&endDate=${endDate}T23:59:59`;
      formattedDateRange2 = `startDate=${startDate}&endDate=${endDate}`;
    }

    if (startDate === undefined || endDate === undefined) {
      return;
    }

    if (roleName === "HI5_ACM_MANAGER") {
      DashboardService.customerSummary(`?${formattedDateRange ?? ""}`)
        .then((response) => {
          setActiveCustomersCount(response?.data?.activeCustomer);
        })
        .catch((e) => {
          console.error(e);
        });

      DashboardService.customerTotalOrderCount(`?${formattedDateRange2 ?? ""}`)

        .then((response) => {
          setTotalOrderCount(response?.data?.orderCount ?? 0);
          setOneamzOrderCount(response?.data?.oneamzOrderCount ?? 0);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      DashboardService.customerSummaryMe(`?${formattedDateRange ?? ""}`)
        .then((response) => {
          setActiveCustomersCount(response?.data?.activeCustomer ?? 0);
        })
        .catch((e) => {
          console.error(e);
        });

      DashboardService.customerTotalOrderCountMe(
        `?${formattedDateRange2 ?? ""}`
      )

        .then((response) => {
          setTotalOrderCount(response?.data?.orderCount ?? 0);
          setOneamzOrderCount(response?.data?.oneamzOrderCount ?? 0);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    setLoading(false);
  }, [dateRange, roleName]);

  useEffect(() => {
    if (roleName === "HI5_ACM_MANAGER") {
      DashboardService.customerSummaryFeedbackAll()
        .then((response) => {
          if (response.data) {
            setFeedbackRating(parseFloat(response.data).toFixed(2));
          } else {
            setFeedbackRating(0);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      DashboardService.customerSummaryFeedbackMe()
        .then((response) => {
          if (response.data) {
            setFeedbackRating(parseFloat(response.data).toFixed(2));
          } else {
            setFeedbackRating(0);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [roleName]);

  return (
    <div
      style={{ background: "#F0F0F0", padding: "30px" }}
      className="acm-dashboard"
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
        className="acm-dashboard"
      >
        <HeaderBar setDateRange={setDateRange} thisMonth={true}></HeaderBar>

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col sm={24} md={8} style={{}}>
            <SalesCard
              title="Sales Chart"
              description={`${activeCustomersCount} Customers`}
              dateRange={dateRange}
            />
          </Col>
          <Col sm={24} md={16}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <InfoCard
                  title="Active"
                  description="Customers"
                  count={activeCustomersCount}
                  dateRange={dateRange}
                  loading={loading}
                />
              </Col>
              <Col xs={24} sm={12}>
                <InfoCard
                  title="OneAMZ Order Count"
                  description="Customers"
                  count={oneamzOrderCount}
                  dateRange={dateRange}
                  loading={loading}
                />
              </Col>
              <Col xs={24} sm={12} className="bottom-cards">
                <InfoCard
                  title="Order Count"
                  description="Total"
                  count={totalOrderCount}
                  dateRange={dateRange}
                  loading={loading}
                />
              </Col>
              <Col xs={24} sm={12} className="bottom-cards">
                <InfoCard
                  title="Feedback Ratings"
                  description="Average"
                  count={feedbackRating}
                  dateRange={dateRange}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Hi5CustomerList />
      </motion.div>
    </div>
  );
};
