import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "./../../../assets/XCircle.svg";
import { openNotification } from "../../../utils";
import TaskService from "../../../services/TaskService";
import dayjs from "dayjs";
import UserService from "../../../services/UserService";

const { Item } = Form;

const CustomerMultiTask: React.FC<any> = ({ selectedRowKeys }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assigneeId, setAssigneeId] = useState();
  const roleName = localStorage.getItem("roleName");
  const authId = localStorage.getItem("id");
  const [userOptions, setUserOptions] = useState<any[]>([]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [isModalVisible]);

  const createTask = (values: any) => {
    const dueDate = dayjs(values.dueDate).format("YYYY-MM-DDTHH:mm");

    let taskData: any = {
      title: values.title,
      description: values.desc,
      tag: values.tag,
      dueDate: dueDate,
      assigneeId: assigneeId ?? authId,
      customerIds: selectedRowKeys,
      source: "WEB",
      url: "customers/detail/",
    };

    TaskService.createTaskMulti(taskData)
      .then((response: any) => {
        openNotification("success", "Success", "New task created");
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    form.resetFields(["Selected Customers"]);
    form.setFieldsValue({
      "Selected Customers": selectedRowKeys.length.toString(),
    });
  }, [selectedRowKeys, isModalVisible, form]);

  const getUsers = () => {
    if (roleName === "MAS_TEAM_MANAGER" || roleName === "ADMIN") {
      UserService.getAll("?roleName=MAS_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().startOf("day");
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row justify={"end"}>
        <Button
          type="primary"
          style={{ marginRight: 15 }}
          onClick={() => setIsModalVisible(true)}
        >
          Create Task
        </Button>
      </Row>
      <Modal
        title={<>Create Task</>}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={createTask} layout="vertical">
          <Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: "Please enter a title!" },
              {
                max: 255,
                message: "You can enter a maximum of 255 characters",
              },
              {
                pattern: new RegExp(/\S/),
                message: "Title cannot be empty or spaces only",
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="desc"
            label="Description"
            rules={[
              {
                max: 255,
                message: "You can enter a maximum of 255 characters",
              },
              {
                pattern: new RegExp(/\S/),
                message: "Description cannot be empty or spaces only",
              },
            ]}
          >
            <Input.TextArea placeholder="" />
          </Item>
          {roleName === "MAS_TEAM_MANAGER" || roleName === "ADMIN" ? (
            <Item name="assigneeId" label="Assigne">
              <Select
                onChange={(selectedUser) => setAssigneeId(selectedUser)}
                options={userOptions}
                placeholder={"Select"}
                style={{ width: "100%" }}
              ></Select>
            </Item>
          ) : null}
          <Item
            name="tag"
            label="Tag"
            rules={[
              {
                max: 255,
                message: "You can enter a maximum of 255 characters",
              },
              {
                pattern: new RegExp(/\S/),
                message: "Tag cannot be empty or spaces only",
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="dueDate"
            label="Due Date"
            rules={[{ required: true, message: "Please select a date!" }]}
          >
            <DatePicker
              showTime={{
                defaultValue: dayjs("00:00", "HH:mm"),
                minuteStep: 5,
              }}
              style={{ width: "100%" }}
              disabledDate={disabledDate}
              format={"DD/MM/YYYY HH:mm"}
              placeholder="Select Date"
            />
          </Item>
          <Item>
            <Row>
              <Button
                type="primary"
                className=" w-100 border-radius-8"
                style={{ width: "100%" }}
                htmlType="submit"
              >
                OK
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerMultiTask;
