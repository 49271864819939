import http from "../http-common";

const getAll = (query = '') => {
  return http.get<Array<any>>(`/call-center-sales-package-servey${query}`);
};

const get = (id: any) => {
  return http.get<Array<any>>(`/call-center-sales-package-servey/${id}`);
};

const create = (data: any) => {
  return http.post<any>("/call-center-sales-package-servey", data);
};

const update = (id: any, data: any) => {
  return http.patch<any>(`/call-center-sales-package-servey/${id}`, data);
};

const createLog = (data: any) => {
  return http.put<any>(`/call-center-sales-package-servey-log`, data);
};

const getLog = (data: any) => {
  return http.get<any>(`/call-center-sales-package-servey-log${data}`);
};

const SalesPackageServeyService = {
  getAll,
  get,
  create,
  update,
  createLog,
  getLog
};

export default SalesPackageServeyService;