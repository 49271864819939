import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Tag,
  Input,
  Popconfirm,
  Tooltip,
  Select,
  Modal,
  Table,
} from "antd";
import "./task-detail.css";
import { useNavigate, useParams } from "react-router-dom";
import InfoCard from "../components/CustomerDetail/InfoCard";
import SalesCard from "../components/CustomerDetail/SalesCard";
import CustomerInfo from "../components/CustomerDetail/CustomerInfo";
import CustomerService from "../services/CustomerService";
import phoneIcon from "../assets/phone.svg";
import smsIcon from "../assets/sms.svg";
import callIcon from "../assets/call.svg";
import Notes from "../components/CustomerDetail/Notes";
import LearnerService from "../services/LearnerService";
import {
  PhoneOutlined,
  CloseCircleOutlined,
  ShoppingCartOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { openNotification } from "../utils";
import Tasks from "../components/CustomerDetail/Tasks/Tasks";
import { ICustomerStatusResponseData } from "../types/CustomerStatus";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CopyClipboard } from "../components/General/CopyClipboard";
import StoreInformation from "../components/CustomerDetail/StoreInformation";
import moment from "moment";
import CallSalesPackageService from "../services/CallSalesPackageService";
import AdditionalPackageHistoryCard from "../components/CustomerDetail/AdditionalPackageHistoryCard";
import UserService from "../services/UserService";
import { FiPhone } from "react-icons/fi";
import CallHistory from "../components/UserDetail/CallHistory";
import StatusService from "../services/StatusService";

const { Item } = Form;

const CustomerDetail = () => {
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const { id } = useParams<{ id: string }>();
  const [customer, setCustomer] = useState<any>(null);
  const [learnerDetail, setLearnerDetail] = useState<any>(null);
  const [form] = Form.useForm();
  const [gsmPortalEmployeeId, setGsmPortalEmployeeId] = useState("");
  const [customerStatusOptions, setCustomerStatusOptions] = useState<
    ICustomerStatusResponseData[]
  >([]);
  const [customerACMStatusOptions, setCustomerACMStatusOptions] = useState<
    ICustomerStatusResponseData[]
  >([]);
  const [customerHi5ACMStatusOptions, setCustomerHi5ACMStatusOptions] =
    useState<ICustomerStatusResponseData[]>([]);
  const [feedbackStatusOptions, setFeedbackStatusOptions] = useState<any[]>([]);
  const [feedbackChannelOptions, setFeedbackChannelOptions] = useState<any[]>(
    []
  );
  const [softwareSuspendedStatusOptions, setSoftwareSuspendedStatusOptions] =
    useState<any[]>([]);
  const [
    packageOfInterestStatusForStmOptions,
    setPackageOfInterestStatusForStmOptions,
  ] = useState<any[]>([]);
  const [
    oldPackageOfInterestStatusForStmOptions,
    setOldPackageOfInterestStatusForStmOptions,
  ] = useState<any[]>([]);
  // eslint-disable-next-line
  const [packageData, setPackageData] = useState<[]>([]);
  const navigate = useNavigate();
  // const location = useLocation();
  // const currentPage = location.state?.currentPage ?? 0;
  // const currentPageSize = location.state?.currentPageSize ?? 0;
  // const currentFilterText = location.state?.filterText ?? "";
  // eslint-disable-next-line
  const [isCustomer, setIsCustomer] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [salesLog, setSalesLog] = useState<any>([]);
  // eslint-disable-next-line
  const [packageNames, setPackageNames] = useState<any>({});
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [userOptions, setUserOptions] = useState<any>([]);
  const [adminUserOptions, setAdminUserOptions] = useState<any>([]);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [contactForm] = Form.useForm();
  const roleName = localStorage.getItem("roleName");

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line
  }, [triggerUpdate]);

  const getCustomerStatus = () => {
    CustomerService.getAllStatus(`?active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
    if (id) {
      CustomerService.getLearnerDetail(id)
        .then((response: any) => {
          setLearnerDetail(response?.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const getCustomerACMStatus = () => {
    StatusService.getAll("statusType=ACM_CUSTOMER_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerACMStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerHi5ACMStatus = () => {
    StatusService.getAll("statusType=HI5_ACM_CUSTOMER_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerHi5ACMStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getFeedbackStatus = () => {
    StatusService.getAll("statusType=MAS_TEAM_FEEDBACK_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setFeedbackStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getFeedbackChannel = () => {
    StatusService.getAll("statusType=MAS_TEAM_FEEDBACK_CHANNEL", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setFeedbackChannelOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getSoftwareSuspendedStatus = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    setLoading(true);
    StatusService.getAll("statusType=SOFTWARE_SUSPENDED_STATUS")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setSoftwareSuspendedStatusOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPackageOfInterestStatusForStm = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=PACKAGE_OF_INTEREST_STM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setPackageOfInterestStatusForStmOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getOldPackageOfInterestStatusForStm = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=OLD_PACKAGE_OF_INTEREST_STM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setOldPackageOfInterestStatusForStmOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let netGsmId = localStorage.getItem("gsmPortalEmployeeId");
    // eslint-disable-next-line
    if (netGsmId != null && netGsmId != "") {
      setGsmPortalEmployeeId(netGsmId);
    }
    getCustomerStatus();
    if (
      customer?.state === "Active" &&
      customer?.memberFlag === "SUCCESS_TEAM"
    ) {
      getPackageOfInterestStatusForStm();
      getOldPackageOfInterestStatusForStm();
    }
    if (
      customer?.state === "Suspended" &&
      customer?.memberFlag === "SUCCESS_TEAM"
    ) {
      getSoftwareSuspendedStatus();
    }
    if (
      customer?.state === "Active" &&
      customer?.memberFlag === "ACCOUNT_MANAGER"
    ) {
      getCustomerACMStatus();
    }
    if (customer?.memberFlag === "HI5_ACM_MEMBER") {
      getCustomerHi5ACMStatus();
    }
    if (customer?.memberFlag === "MAS_TEAM_MEMBER") {
      getFeedbackStatus();
      getFeedbackChannel();
    }
    // CustomerService.getSalesPackages(id)
    //   .then((response: any) => {
    //     setPackageData(response.data);
    //     console.log(response.data);
    //   })
    //   .catch((e: Error) => {
    //     console.log(e);
    //   });
    // eslint-disable-next-line
  }, [customer]);

  const getCustomer = () => {
    CustomerService.get(id)
      .then((response: any) => {
        setCustomer(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  form.setFieldsValue({
    phoneNumber: customer?.phone,
  });

  const handleStartCall = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        openNotification("success", "Calling", values.phoneNumber, 5);
        LearnerService.learnerCall(gsmPortalEmployeeId, values.phoneNumber)
          .then((response: any) => {
            if (response.data?.status === "Success") {
              openNotification(
                "success",
                response.data.message,
                values.phoneNumber,
                5
              );
            } else {
              openNotification(
                "error",
                response.data.message,
                values.phoneNumber,
                5
              );
            }
          })
          .catch((e: Error) => {});
      }
    } catch (error) {
      openNotification(
        "error",
        "Start a call",
        "Phone number must comply with the format"
      );
    }
  };

  const callForm = () => {
    // eslint-disable-next-line
    if (
      // eslint-disable-next-line
      gsmPortalEmployeeId != "" &&
      // eslint-disable-next-line
      gsmPortalEmployeeId != null &&
      !isNaN(Number(gsmPortalEmployeeId)) &&
      // eslint-disable-next-line
      gsmPortalEmployeeId != "null" &&
      gsmPortalEmployeeId
    ) {
      return (
        <Popconfirm
          title="Customer the call"
          description={
            <Form form={form} layout="vertical">
              Are you sure you want to call the customer?
              <Item
                style={{ marginTop: "10px" }}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Phone number must be only digits!",
                  },
                  {
                    len: 11,
                    message: "Phone number must be 11 digits long!",
                  },
                ]}
              >
                <Input />
              </Item>
            </Form>
          }
          okText={
            <>
              <PhoneOutlined /> Call
            </>
          }
          cancelText="No"
          onConfirm={() => handleStartCall()}
        >
          <Button
            type="primary"
            style={{
              marginLeft: 8,
              fontSize: 10,
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
            }}
          >
            <FiPhone style={{ fontSize: "13px", marginRight: "5px" }} />
            Call
          </Button>
        </Popconfirm>
      );
    }

    return (
      <Tooltip
        placement="top"
        title={"Contact your administrator to make a call"}
      >
        <Button
          type="default"
          style={{
            marginLeft: 16,
            fontSize: 10,
            fontWeight: "bold",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={callIcon}
            alt="call-icon"
            style={{ marginRight: 5, marginTop: 0 }}
          />
          Call
        </Button>
      </Tooltip>
    );
  };
  const getLevelColor = (level: number) => {
    switch (level) {
      case 0:
        return "red";
      case 1:
        return "volcano";
      case 2:
        return "orange";
      case 3:
        return "gold";
      case 4:
        return "lime";
      case 5:
        return "green";
      case 6:
        return "cyan";
      default:
        return "gray";
    }
  };

  const handleChangeStatus = (status: any, id: any) => {
    setLoading(true);
    CustomerService.update(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeACMStatus = (status: any, id: any) => {
    setLoading(true);
    CustomerService.updateACMStatus(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeHi5ACMStatus = (status: any, id: any) => {
    setLoading(true);
    CustomerService.updateHi5ACMStatus(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeFeedbackStatus = (status: any, id: any) => {
    setLoading(true);
    CustomerService.updateMasFeedbackStatus(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeFeedbackChannel = (channel: any, id: any) => {
    setLoading(true);
    CustomerService.updateMasFeedbackChannel(id, { statusId: channel })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeSoftwareSuspendedStatus = (status: any, id: any) => {
    let data: any = {
      suspendStatusId: status,
      customerIds: [id],
    };
    setLoading(true);
    CustomerService.assignCustomerToSoftwareSuspendStatus(data)
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Changed software suspend status"
        );
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePackageOfInterestStatusForStm = (
    statusId: any,
    id: any
  ) => {
    setLoading(true);
    CustomerService.assignCustomerToPackageOfInterestStatusForStm(id, {
      statusId,
    })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Changed package of interest status"
        );
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeOldPackageOfInterestStatusForStm = (
    statusId: any,
    id: any
  ) => {
    setLoading(true);
    CustomerService.assignCustomerToOldPackageOfInterestStatusForStm(id, {
      statusId,
    })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Changed old package of interest status"
        );
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFeedback = () => {
    if (customer?.id) {
      let data: any = {
        id: customer?.id,
      };
      CustomerService.sendFeedbackRequest(data)
        .then((response: any) => {
          openNotification(
            "success",
            "Successs",
            "The feedback request was sent successfully"
          );
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification(
            "error",
            "Error",
            "The feedback request failed to send"
          );
        });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
    getSoldPackages();
    getPackages();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getUsers = () => {
    const roleMapping: { [key: string]: string } = {
      CALL_TEAM_MANAGER: "CALL_TEAM",
      SUCCESS_TEAM_MANAGER: "SUCCESS_TEAM",
      ACCOUNT_MANAGER_MANAGER: "ACCOUNT_MANAGER",
      HI5_ACM_MANAGER: "HI5_ACM",
      SUSPENDED_TEAM_MANAGER: "SUSPENDED_TEAM",
      MAS_TEAM_MANAGER: "MAS_TEAM",
    };

    if (roleName) {
      const teamRole = roleMapping[roleName];
      if (teamRole) {
        UserService.getAll(`?roleName=${teamRole}`)
          .then((response: any) => {
            const newArray = response.map((item: any) => ({
              value: item.id,
              label: `${item.firstName} ${item.lastName}`,
            }));

            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
    if (roleName === "ADMIN") {
      UserService.getAll("?roleName=ADMIN")
        .then((response: any) => {
          const newArray = response.map((item: any) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          }));
          setAdminUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAssigneUser = (userId: any, row: any) => {
    const payload = {
      callCenterSalesPackageId: row?.callCenterSalesPackage?.id,
      learnerId: row?.learner?.id || null,
      customerId: row?.customer?.id || null,
      prospectCustomerId: row?.prospectCustomer?.id || null,
      userId: userId,
    };

    CallSalesPackageService.updateSalesLog(row.id, payload)
      .then((response: any) => {
        openNotification("success", "Success", "Changed assigned user");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const salesLogColumns = [
    {
      title: "Package Name",
      dataIndex: "callCenterSalesPackage",
      key: "callCenterSalesPackage",
      width: "150px",
      render: (callCenterSalesPackage: any) =>
        callCenterSalesPackage?.name || "-",
    },
    {
      title: "Approval Date",
      dataIndex: "approvalDate",
      key: "approvalDate",
      width: "150px",
      render: (text: any) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    ...(roleName === "CALL_TEAM_MANAGER" ||
    roleName === "SUCCESS_TEAM_MANAGER" ||
    roleName === "SUSPEND_TEAM_MANAGER" ||
    roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "HI5_ACM_MANAGER" ||
    roleName === "MAS_TEAM_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            title: "Team Member",
            dataIndex: "user",
            key: "user",
            width: "150px",
            render: (_: any, row: any) => {
              const userOption = userOptions.find(
                (option: any) => option.value === row?.user?.id
              );
              const displayName = userOption
                ? userOption.label
                : `${row.user.firstName} ${row.user.lastName}`;
              return (
                <span>
                  {userOption ? (
                    <Select
                      showSearch
                      filterOption={(input, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(selectedUser) =>
                        handleChangeAssigneUser(selectedUser, row)
                      }
                      options={userOptions}
                      key={row.id}
                      {...(row?.user?.id && {
                        defaultValue: row.user.id,
                      })}
                      placeholder={"Select"}
                      style={{ width: "180px" }}
                    ></Select>
                  ) : (
                    displayName
                  )}
                </span>
              );
            },
          },
        ]
      : []),
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50px",
      render: (_: any, row: any) => (
        // !row.approvalDate && (
        <Tooltip placement="topLeft" title="Delete">
          <CloseCircleOutlined
            style={{ marginTop: 5, marginLeft: 10, height: 24 }}
            onClick={() => showDeleteSalesLogModal(row.id)}
          >
            Delete
          </CloseCircleOutlined>
        </Tooltip>
      ),
      // ),
    },
  ];

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        let data = response.data;
        const soldPackageIds = salesLog.map(
          (log: any) => log.callCenterSalesPackageId
        );

        let filteredData = data.filter((item: any) => {
          const isSold = soldPackageIds.includes(item.id);
          const hasCommissionRate =
            roleName === "SUCCESS_TEAM_MANAGER" || roleName === "SUCCESS_TEAM"
              ? item.stmCommissionRate !== null
              : roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM"
              ? item.ctmCommissionRate !== null
              : roleName === "SUSPEND_TEAM_MANAGER" ||
                roleName === "SUSPEND_TEAM"
              ? item.suspendCommissionRate !== null
              : roleName === "ACCOUNT_MANAGER_MANAGER" ||
                roleName === "ACCOUNT_MANAGER"
              ? item.acmCommissionRate !== null
              : roleName === "HI5_ACM_MANAGER" || roleName === "HI5_ACM_MEMBER"
              ? item.hi5AcmCommissionRate !== null
              : roleName === "MAS_TEAM_MANAGER" || roleName === "MAS_TEAM"
              ? item.masCommissionRate !== null
              : true;

          return !isSold && hasCommissionRate;
        });

        const newArray = filteredData.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            disabled: !item.isActive,
          };
        });

        const packageNamesMap = response.data.reduce((acc: any, curr: any) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {});

        setPackageNames(packageNamesMap);
        setOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getSoldPackages = () => {
    let filter =
      isCustomer === true
        ? `customerId=${customer?.id}`
        : `learnerId=${customer?.id}`;
    CallSalesPackageService.getSalesLog(`?${filter}`)
      .then((response: any) => {
        setSalesLog(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleAddSaleLog = () => {
    const selectedPackageId = form.getFieldValue("selectedPackage");
    const customerOrLearnerId = customer?.id;
    if (!selectedPackageId || !customerOrLearnerId) {
      console.error("Selected package or customer ID is missing!");
      return;
    }
    const packageData = {
      callCenterSalesPackageId: selectedPackageId,
      customerId: isCustomer ? customerOrLearnerId : undefined,
      learnerId: !isCustomer ? customerOrLearnerId : undefined,
    };

    // Gereksiz undefined alanları kaldırmak için
    Object.keys(packageData).forEach(
      (key) =>
        packageData[key as keyof typeof packageData] === undefined &&
        delete packageData[key as keyof typeof packageData]
    );
    setLoading(true);
    CallSalesPackageService.createSalesLog(packageData)
      .then((response) => {
        if (response.status === 200) {
          openNotification(
            "success",
            "Success",
            "Sales log created successfully"
          );
          getSoldPackages();
          // setIsModalVisible(false);
        } else {
          openNotification("error", "Error", "Failed to create sales log");
        }
      })
      .catch((error) => {
        console.error("Error creating sales log:", error);
        openNotification("error", "Error", "Failed to create sales log");
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteSaleLog = (salesLogId: number) => {
    setLoading(true);
    CallSalesPackageService.removeSalesLog(salesLogId)
      .then((response) => {
        openNotification(
          "success",
          "Success",
          "Sales log deleted successfully"
        );
        getSoldPackages();
      })
      .catch((error) => {
        console.error("Error deleting sales log:", error);
        openNotification("error", "Error", "Failed to delete sales log");
      })
      .finally(() => setLoading(false));
  };

  const showDeleteSalesLogModal = (salesLogId: any) => {
    Modal.confirm({
      title: "Delete Sale Log",
      content: "Are you sure you want to delete this sale log?",
      onOk: () => handleDeleteSaleLog(salesLogId),
      onCancel: () => {},
    });
  };

  const showAddPackageModal = () => {
    Modal.confirm({
      title: "Package Selection",
      content: (
        <Select
          style={{ width: "100%" }}
          placeholder="Package select"
          onChange={(value) => form.setFieldsValue({ selectedPackage: value })}
        >
          <Select.Option key={0} value={null}>
            Select
          </Select.Option>
          {options.map(
            (option: any) =>
              !option.disabled && (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </Select.Option>
              )
          )}
        </Select>
      ),
      okText: "Add Sale Log",
      onOk: () => handleAddSaleLog(),
      onCancel: () => {},
    });
  };

  useEffect(() => {
    if (customer) {
      getPackages();
    }
    // eslint-disable-next-line
  }, []);

  const handleUpdateContact = async () => {
    setLoading(true);
    const values = await contactForm.validateFields();
    CustomerService.updateContact(Number(id), values)
      .then((response: any) => {
        openNotification(
          "success",
          "Contact Updated",
          "Customer contact information has been updated successfully."
        );
        setIsEditingPhone(false);
        setCustomer(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
        openNotification(
          "error",
          "Update Failed",
          "Failed to update contact information."
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Row justify={"start"} align={"middle"}>
        <Button
          type="default"
          onClick={() => navigate(-1)}
          style={{ marginRight: 16 }}
        >
          <ArrowLeftOutlined />
        </Button>

        <p className="page-title">Customer Detail</p>
      </Row>
      <Row justify={"space-between"} align={"middle"}>
        <Row style={{ flexDirection: "column", alignItems: "start" }}>
          <Row>
            <Tag
              color={customer?.state !== "Suspended" ? "green" : "volcano"}
              style={{ textTransform: "capitalize" }}
            >
              {customer?.state}
            </Tag>
            {customer?.level ? (
              <Tag color={getLevelColor(customer?.level)}>
                LVL {customer?.level}
              </Tag>
            ) : null}
            {/* {customer?.goldUser ?
                            // <img style={{ marginLeft: 10 }} src={goldUser} alt='gold-icon' /> 
                            <Tooltip placement="top" title={"Gold User"}>
                                <Tag color='gold'>G.U</Tag>
                            </Tooltip>
                            : null}
                        {customer?.goldUser ?
                            // <img style={{ marginLeft: 10 }} src={goldUser} alt='gold-icon' /> 
                            <Tooltip placement="top" title={"Seller Shield"}>
                                <Tag color='green'>S.H</Tag>
                            </Tooltip>
                            : null}
                        {customer?.goldUser ?
                            // <img style={{ marginLeft: 10 }} src={goldUser} alt='gold-icon' /> 
                            <Tooltip placement="top" title={"Launch"}>
                                <Tag color='gray'>Lch</Tag>
                            </Tooltip>
                            : null} */}
            <div>
              {packageData?.map((item: any) => {
                // Adından kısaltma oluşturma
                const words = item.name.split(" ");
                const abbreviation =
                  words.length > 1
                    ? `${words[0][0]}.${words[words.length - 1][0]}`
                    : item.name.substring(0, 2); // Tek kelime ise ilk iki harf

                return (
                  <Tooltip key={item.id} placement="top" title={item.name}>
                    <Tag color="cyan">{abbreviation}</Tag>
                  </Tooltip>
                );
              })}
            </div>
          </Row>
          <p
            style={{
              fontSize: 24,
              fontWeight: "bold",
              margin: 0,
              marginTop: 10,
            }}
          >
            {customer?.fullName}
          </p>
          <Row align={"middle"}>
            <Form form={contactForm} layout="inline">
              <p
                style={{
                  marginRight: 20,
                  color: "#292D32",
                  fontSize: 12,
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={phoneIcon}
                    alt="phone-icon"
                    style={{ marginRight: 2, marginTop: 2 }}
                  />
                  <Form.Item name="phone">
                    {isEditingPhone ? (
                      <Input
                        style={{ width: 200 }}
                        placeholder="Phone Number"
                        defaultValue={customer?.phone}
                        allowClear
                        type="number"
                        addonAfter={
                          <span>
                            <CheckOutlined onClick={handleUpdateContact} />
                            <CloseOutlined
                              onClick={() => setIsEditingPhone(false)}
                              style={{ marginLeft: 8 }}
                            />
                          </span>
                        }
                      />
                    ) : (
                      <>
                        <CopyClipboard text={customer?.phone} />
                        <EditOutlined
                          style={{
                            marginLeft: 5,
                            cursor: "pointer",
                            fontSize: 16,
                          }}
                          onClick={() => setIsEditingPhone(true)}
                          title="Edit Phone Number"
                        />
                      </>
                    )}
                  </Form.Item>
                </div>{" "}
              </p>
              <p
                style={{
                  marginRight: 10,
                  color: "#292D32",
                  fontSize: 12,
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={smsIcon}
                    alt="sms-icon"
                    style={{ marginRight: 2, marginTop: 2 }}
                  />
                  <Form.Item name="email">
                    <CopyClipboard text={customer?.email} />
                  </Form.Item>
                </div>
              </p>
            </Form>
          </Row>
        </Row>
        {roleName !== "SUCCESS_TEAM_REPORTER" ? (
          <Row>
            <Row>
              <Popconfirm
                title="Are you sure to send this feedback request?"
                onConfirm={handleFeedback}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{ fontSize: 10, fontWeight: "bold", marginRight: 8 }}
                >
                  Feedback Request
                </Button>
              </Popconfirm>
            </Row>
            <Row>
              <Button type="primary" onClick={showModal}>
                <ShoppingCartOutlined /> Sales
              </Button>
              <Modal
                title="Sales"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Row justify={"end"}>
                  <Button
                    type="primary"
                    style={{ marginBottom: 16 }}
                    onClick={showAddPackageModal}
                  >
                    Add Sale Log
                  </Button>
                </Row>
                <Table
                  dataSource={salesLog}
                  columns={salesLogColumns}
                  rowKey="id"
                />
              </Modal>
            </Row>
            <Row>{callForm()}</Row>
          </Row>
        ) : null}
      </Row>
      {customer?.state === "Active" &&
      customer?.memberFlag === "SUCCESS_TEAM" ? (
        <Row justify={"end"} style={{ marginBottom: 10 }}>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="packageOfInterest"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Package of Interest
            </label>
            <Select
              title="Select Package of Interest"
              id="packageOfInterest"
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangePackageOfInterestStatusForStm(
                  selectedStatus,
                  customer?.id
                )
              }
              key={customer?.id}
              defaultValue={
                customer?.packageOfInterestSTM &&
                customer?.packageOfInterestSTM.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {packageOfInterestStatusForStmOptions.map((mainStatus) => (
                <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                  {mainStatus.subStatuses.map((subStatus: any) => (
                    <Select.Option key={subStatus.id} value={subStatus.id}>
                      {subStatus.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Row>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="oldPackageOfInterest"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Old Package of Interest
            </label>
            <Select
              title="Select Old Package of Interest"
              id="oldPackageOfInterest"
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangeOldPackageOfInterestStatusForStm(
                  selectedStatus,
                  customer?.id
                )
              }
              key={customer?.id}
              defaultValue={
                customer?.oldPackageOfInterestSTM &&
                customer?.oldPackageOfInterestSTM.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {oldPackageOfInterestStatusForStmOptions.map((mainStatus) => (
                <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                  {mainStatus.subStatuses.map((subStatus: any) => (
                    <Select.Option key={subStatus.id} value={subStatus.id}>
                      {subStatus.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Row>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="retentionStatus"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Retention Status
            </label>
            <Select
              title="Retention Status"
              onChange={(selectedStatus) =>
                handleChangeStatus(selectedStatus, customer?.id)
              }
              options={customerStatusOptions}
              key={customer?.id}
              {...(customer?.status?.id && {
                defaultValue: customer.status.id,
              })}
              placeholder={"Select"}
              style={{ width: "180px" }}
            ></Select>
          </Row>
        </Row>
      ) : null}

      {customer?.state === "Suspended" &&
      customer?.memberFlag === "SUCCESS_TEAM" ? (
        <Row justify={"end"} style={{ marginBottom: 10 }}>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="softwareSuspendedStatus"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Software Suspended Status
            </label>
            <Select
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangeSoftwareSuspendedStatus(
                  selectedStatus,
                  customer?.id
                )
              }
              key={customer?.id}
              defaultValue={
                customer?.suspendStatus && customer?.suspendStatus.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {softwareSuspendedStatusOptions.map((mainStatus) => (
                <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                  {mainStatus.subStatuses.map((subStatus: any) => (
                    <Select.Option key={subStatus.id} value={subStatus.id}>
                      {subStatus.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Row>
        </Row>
      ) : null}

      {customer?.state === "Active" &&
      customer?.memberFlag === "ACCOUNT_MANAGER" ? (
        <Row justify={"end"} style={{ marginBottom: 10 }}>
          {/* <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="packageOfInterest"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Package of Interest
            </label>
            <Select
              title="Select Package of Interest"
              id="packageOfInterest"
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangePackageOfInterestStatusForStm(
                  selectedStatus,
                  customer?.id
                )
              }
              key={customer?.id}
              defaultValue={
                customer?.packageOfInterestSTM &&
                customer?.packageOfInterestSTM.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {packageOfInterestStatusForStmOptions.map((mainStatus) => (
                <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                  {mainStatus.subStatuses.map((subStatus: any) => (
                    <Select.Option key={subStatus.id} value={subStatus.id}>
                      {subStatus.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Row>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="oldPackageOfInterest"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Old Package of Interest
            </label>
            <Select
              title="Select Old Package of Interest"
              id="oldPackageOfInterest"
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangeOldPackageOfInterestStatusForStm(
                  selectedStatus,
                  customer?.id
                )
              }
              key={customer?.id}
              defaultValue={
                customer?.oldPackageOfInterestSTM &&
                customer?.oldPackageOfInterestSTM.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {oldPackageOfInterestStatusForStmOptions.map((mainStatus) => (
                <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                  {mainStatus.subStatuses.map((subStatus: any) => (
                    <Select.Option key={subStatus.id} value={subStatus.id}>
                      {subStatus.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Row> */}
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="accountManagerStatus"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Account Manager Status
            </label>
            <Select
              title="Account Manager Status"
              onChange={(selectedStatus) =>
                handleChangeACMStatus(selectedStatus, customer?.id)
              }
              options={customerACMStatusOptions}
              key={customer?.id}
              {...(customer?.acmStatus?.id && {
                defaultValue: customer.acmStatus.id,
              })}
              placeholder={"Select"}
              style={{ width: "180px" }}
            ></Select>
          </Row>
        </Row>
      ) : null}

      {customer?.memberFlag === "HI5_ACM_MEMBER" ? (
        <Row justify={"end"} style={{ marginBottom: 10 }}>
          {/* <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="packageOfInterest"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Package of Interest
            </label>
            <Select
              title="Select Package of Interest"
              id="packageOfInterest"
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangePackageOfInterestStatusForStm(
                  selectedStatus,
                  customer?.id
                )
              }
              key={customer?.id}
              defaultValue={
                customer?.packageOfInterestSTM &&
                customer?.packageOfInterestSTM.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {packageOfInterestStatusForStmOptions.map((mainStatus) => (
                <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                  {mainStatus.subStatuses.map((subStatus: any) => (
                    <Select.Option key={subStatus.id} value={subStatus.id}>
                      {subStatus.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Row>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="oldPackageOfInterest"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Old Package of Interest
            </label>
            <Select
              title="Select Old Package of Interest"
              id="oldPackageOfInterest"
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangeOldPackageOfInterestStatusForStm(
                  selectedStatus,
                  customer?.id
                )
              }
              key={customer?.id}
              defaultValue={
                customer?.oldPackageOfInterestSTM &&
                customer?.oldPackageOfInterestSTM.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {oldPackageOfInterestStatusForStmOptions.map((mainStatus) => (
                <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                  {mainStatus.subStatuses.map((subStatus: any) => (
                    <Select.Option key={subStatus.id} value={subStatus.id}>
                      {subStatus.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Row> */}
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="hi5AccountManagerStatus"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Hi5 ACM Status
            </label>
            <Select
              id="hi5AccountManagerStatus"
              title="Hi5 Account Manager Status"
              onChange={(selectedStatus) =>
                handleChangeHi5ACMStatus(selectedStatus, customer?.id)
              }
              options={customerHi5ACMStatusOptions}
              key={customer?.id}
              {...(customer?.hi5AcmStatus?.id && {
                defaultValue: customer.hi5AcmStatus.id,
              })}
              placeholder={"Select"}
              style={{ width: "180px" }}
            />
          </Row>
        </Row>
      ) : null}

      {customer?.memberFlag === "MAS_TEAM_MEMBER" ? (
        <Row justify={"end"} style={{ marginBottom: 10 }}>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="feedbackStatus"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Feedback Status
            </label>
            <Select
              id="feedbackStatus"
              title="Feedback Status"
              onChange={(selectedStatus) =>
                handleChangeFeedbackStatus(selectedStatus, customer?.id)
              }
              options={feedbackStatusOptions}
              key={customer?.id}
              {...(customer?.feedbackStatus?.id && {
                defaultValue: customer.feedbackStatus.id,
              })}
              placeholder={"Select"}
              style={{ width: "180px" }}
            />
          </Row>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label
              htmlFor="feedbackChannel"
              style={{ margin: "4px 0 2px 4px" }}
            >
              Feedback Channel
            </label>
            <Select
              id="feedbackChannel"
              title="Feedback Channel"
              onChange={(selectedChannel) =>
                handleChangeFeedbackChannel(selectedChannel, customer?.id)
              }
              options={feedbackChannelOptions}
              key={customer?.id}
              {...(customer?.feedbackChannel?.id && {
                defaultValue: customer.feedbackChannel.id,
              })}
              placeholder={"Select"}
              style={{ width: "180px" }}
            />
          </Row>
        </Row>
      ) : null}

      {roleName !== "SUCCESS_TEAM_REPORTER" &&
      customer?.state !== "Active" &&
      customer?.memberFlag !== "SUCCESS_TEAM" &&
      customer?.memberFlag !== "HI5_ACM_MEMBER" &&
      customer?.memberFlag !== "ACCOUNT_MANAGER" ? (
        <Row justify={"end"} style={{ marginBottom: 10 }}>
          <Row
            style={{
              flexDirection: "column",
              alignItems: "start",
              marginLeft: 8,
            }}
          >
            <label htmlFor="status" style={{ margin: "4px 0 2px 4px" }}>
              Status
            </label>
            <Select
              title="Status"
              onChange={(selectedStatus) =>
                handleChangeStatus(selectedStatus, customer?.id)
              }
              options={customerStatusOptions}
              key={customer?.id}
              {...(customer?.status?.id && {
                defaultValue: customer.status.id,
              })}
              placeholder={"Select"}
              style={{ width: "180px" }}
            ></Select>
          </Row>
        </Row>
      ) : null}

      <Row gutter={[16, 16]}>
        <Col sm={24} xl={12}>
          <Row>
            <Col span={24}>
              <SalesCard
                title="Total Sales"
                description={`${customer?.totalOrderCount} Sales`}
                orderCount={customer?.totalOrderCount}
                last30DaysSales={customer?.last30DaysSales}
                id={customer?.id}
              />
            </Col>
            <Col span={24} style={{ marginTop: "16px" }}>
              <AdditionalPackageHistoryCard
                id={customer?.id}
                autoGoldSubscriptionRenewalEnabled={
                  customer?.autoGoldSubscriptionRenewalEnabled
                }
                autoSlpSubscriptionRenewalEnabled={
                  customer?.autoSlpSubscriptionRenewalEnabled
                }
              />
            </Col>
          </Row>
        </Col>
        <Col sm={24} xl={12}>
          <CustomerInfo customer={customer} learner={learnerDetail} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col sm={24} xl={12}>
          <StoreInformation customer={customer} />
        </Col>
        <Col sm={24} xl={12}>
          <CallHistory
            customer={customer}
            isCustomer={isCustomer}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col sm={24} md={6}>
          <InfoCard
            title="Current Package"
            description={""}
            count={customer?.currentPackage}
          />
        </Col>
        <Col sm={24} md={6}>
          <InfoCard
            title="Product Count"
            description="qty"
            count={customer?.totalProductCount}
          />
        </Col>
        <Col sm={24} md={6}>
          <InfoCard
            title="Order Count"
            description="Product Sales"
            count={customer?.totalOrderCount}
          />
        </Col>
        <Col sm={24} md={6}>
          <InfoCard
            title="Oneamz Order Count"
            description="Product Sales"
            count={customer?.totalOneamzOrderCount}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col sm={24} xl={12}>
          <Notes
            notes={customer?.notes}
            customerId={customer?.id}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>
        <Col sm={24} xl={12}>
          <Tasks
            customerName={customer?.fullName}
            tasks={customer?.tasks}
            customerId={customer?.id}
            setTriggerUpdate={setTriggerUpdate}
            userOptions={roleName === "ADMIN" ? adminUserOptions : userOptions}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomerDetail;
