import { Input, Row, Space } from "antd";
import React, { Component, useCallback, useEffect, useState } from "react";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";
import SuspendedFilter from "./SuspendedFilter";

export const SuspendedFilterField: React.FC<any> = ({
  onChangeSearch,
  setFilterText,
  pageSize,
  setFormFields,
  formFields,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filterOptions, setFilterOptions] = useState("");

  const debouncedSearch = useCallback(_.debounce(onChangeSearch, 500), [
    onChangeSearch,
  ]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    debouncedSearch(newValue);
  };

  const clearInput = () => {
    setSearchText("");
    debouncedSearch("");
  };

  const handleOnChangeFilter = (filterForm: any) => {
    let filterQuery = `?page=0&size=${pageSize}&search=${searchText}&state=Suspended`;

    if (filterForm.state && filterForm.state != null) {
      filterQuery += `&sessionStatus=${filterForm.state}`;
    }

    if (
      filterForm.accountManagerMember &&
      filterForm.accountManagerMember != null
    ) {
      filterQuery += `&userId=${filterForm.accountManagerMember}`;
    }

    if (filterForm.callTeamMember && filterForm.callTeamMember != null) {
      filterQuery += `&callCenterMember=${filterForm.callTeamMember}`;
    }

    if (filterForm.successTeamMember && filterForm.successTeamMember != null) {
      filterQuery += `&successTeamMember=${filterForm.successTeamMember}`;
    }

    if (filterForm.suspendStatus && filterForm.suspendStatus != null) {
      filterQuery += `&suspendStatusIds=${filterForm.suspendStatus}`;
    }

    if (filterForm.status && filterForm.status != null) {
      filterQuery += `&statusIds=${filterForm.status}`;
    }

    if (filterForm.currentPackage && filterForm.currentPackage != null) {
      filterQuery += `&currentPackage=${filterForm.currentPackage}`;
    }

    if (filterForm.startPassiveDay) {
      filterQuery += `&startPassiveDays=${filterForm.startPassiveDay}`;
    }

    if (filterForm.endPassiveDay) {
      filterQuery += `&endPassiveDays=${filterForm.endPassiveDay}`;
    }

    if (filterForm.sellerLevel) {
      filterQuery += `&sellerLevel=${filterForm.sellerLevel}`;
    }

    if (filterForm.freeDays) {
      filterQuery += `&freeDays=${filterForm.freeDays}`;
    }
    if (filterForm.level) {
      filterQuery += `&level=${filterForm.level}`;
    }
    if (filterForm.subscriptionEndDate !== undefined) {
      if (filterForm.subscriptionEndDate.length > 0) {
        let startDate = dayjs(filterForm.subscriptionEndDate[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.subscriptionEndDate[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&subscriptionEndDateStartDate=${startDate}&subscriptionEndDateEndDate=${endDate}`;
      }
    }

    if (filterForm.createdAtRange !== undefined) {
      if (filterForm.createdAtRange.length > 0) {
        let startDate = dayjs(filterForm.createdAtRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.createdAtRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startCreatedAt=${startDate}&endCreatedAt=${endDate}`;
      }
    }

    // if (filterForm.subscriptionEndDate !== undefined) {
    //     let startDate, endDate;

    //     if (filterForm.subscriptionEndDate === 'pending') {
    //       startDate = dayjs().add(-7, 'day').format('YYYY-MM-DD');
    //       endDate = dayjs().format('YYYY-MM-DD');
    //     } else {
    //       startDate = dayjs('2020-01-01').format('YYYY-MM-DD');
    //       endDate = dayjs().startOf('week').subtract(1, 'week').format('YYYY-MM-DD');
    //     }

    //     filterQuery += `&subscriptionEndDateStartDate=${startDate}&subscriptionEndDateEndDate=${endDate}`;
    //   }

    if (filterForm.unassigned) {
      filterQuery += `&assignedUser=${!filterForm.unassigned}`;
    }
    setFilterText(filterQuery);
  };

  return (
    <Row justify="end">
      <Space align="start">
        <Input
          prefix={<SearchOutlined />}
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={clearInput}
                style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
              />
            )
          }
          placeholder="Search"
          value={searchText}
          onChange={onSearchChange}
          style={{ maxWidth: 200 }}
        />
        <SuspendedFilter
          onChangeFilter={handleOnChangeFilter}
          setFormFields={setFormFields}
          formFields={formFields}
          setFilterOptions={setFilterOptions}
        />
      </Space>
    </Row>
  );
};
