import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Drawer,
  Popconfirm,
  Row,
  Tooltip,
} from "antd";
import "./info-card.css";
import {
  PushpinOutlined,
  PushpinFilled,
  DeleteOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import {
  formatDateString,
  getProfilePhotoPath,
  openNotification,
} from "../../utils";
import PhoneCall from "./../../assets/PhoneCall.svg";
import Pen from "./../../assets/Pen.svg";
import CustomerNoteCreate from "./CustomerNoteCreate";
import AmazonSuspendedApplicantService from "../../services/AmazonSuspendedApplicantService";

const Notes: React.FC<any> = ({ customerId, setTriggerUpdate }) => {
  const [open, setOpen] = useState(false);
  const authUserId = localStorage.getItem("id");

  const sortNotes = (notesArray: any) => {
    return notesArray.sort((a: any, b: any) => {
      if (a.pinned === b.pinned) {
        return (
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
        );
      }
      return a.pinned ? -1 : 1;
    });
  };

  const [allNotes, setAllNotes] = useState([]);

  const getNotes = () => {
    AmazonSuspendedApplicantService.getNotes(customerId)
      .then((response: any) => {
        setAllNotes(
          sortNotes(Array.isArray(response.data) ? response.data : [])
        );
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (customerId) {
      getNotes();
    }
    // eslint-disable-next-line
  }, [customerId]);

  const handleNoteAdded = () => {
    setTriggerUpdate((prev: number) => prev + 1);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const confirmDeleteNote = (id: any, userId: any) => {
    // eslint-disable-next-line
    if (authUserId == userId) {
      AmazonSuspendedApplicantService.removeNote(id)
        .then((response: any) => {
          openNotification("success", "Success", "Note deleted");
          handleNoteAdded();
          getNotes();
        })
        .catch((e: Error) => {});
    } else {
      openNotification(
        "error",
        "Error",
        "You cannot delete notes that you did not create"
      );
    }
  };

  const togglePin = (id: number) => {
    AmazonSuspendedApplicantService.pinNote(id)
      .then(() => {
        handleNoteAdded();
        getNotes();
      })
      .catch((error) => {
        console.error("Error pinning the note:", error);
      });
  };

  useEffect(() => {
    if (Array.isArray(allNotes)) {
      setAllNotes(sortNotes(allNotes));
    }
  }, [allNotes]);

  return (
    <>
      <Drawer
        width={450}
        placement="right"
        closable={true}
        onClose={onClose}
        open={open}
      >
        <CustomerNoteCreate
          getNotes={getNotes}
          id={customerId}
          onNoteAdded={handleNoteAdded}
          onClose={onClose}
        />
      </Drawer>
      <Card bordered={false} className={"card"}>
        <Row justify={"space-between"}>
          <p className="title">Notes</p>
          <Button type="primary" onClick={showDrawer}>
            Add Note
          </Button>
        </Row>
        <p className="description">{allNotes?.length} Notes</p>
        <div className="notes-container">
          <Row
            justify="space-between"
            align="middle"
            className="note-row-title"
          >
            <Col span={2} className="note-table-title"></Col>
            <Col span={11} className="note-table-title">
              TITLE
            </Col>
            <Col span={2} className="note-table-title" style={{ textAlign: "center" }}>
              NOTE TYPE
            </Col>
            <Col
              span={5}
              className="note-table-title"
              style={{ textAlign: "center" }}
            >
              DATE
            </Col>
            <Col span={4} className="note-table-title"></Col>
          </Row>
          {allNotes?.map((item: any) => (
            <Row
              key={item.id}
              justify="space-between"
              align="middle"
              className="note-row"
            >
              <Col span={2} className="note-text">
                <Tooltip
                  title={item.user?.firstName + " " + item.user?.lastName}
                  placement="bottom"
                >
                  <Avatar
                    size={40}
                    src={getProfilePhotoPath(item.user?.profilePhoto ?? 1)}
                  />
                </Tooltip>
              </Col>
              <Col
                span={11}
                className="note-text"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingLeft: "15px",
                }}
              >
                <Row>
                  <div>
                    <Tooltip title={`${item?.description}`}>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: "500",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "24ch",
                        }}
                      >
                        {item?.description}
                      </p>
                    </Tooltip>
                  </div>
                </Row>
              </Col>
              <Col span={2} className="note-text" style={{ textAlign: "center" }}>
                <Tooltip title={item.type} placement="bottom">
                  {item.type === "CONTACT" ? (
                    <img
                      src={PhoneCall}
                      alt="call-icon"
                      style={{ marginTop: 0 }}
                    />
                  ) : item.type === "INTERNAL" ? (
                    <img
                      src={Pen}
                      alt="call-icon"
                      style={{ marginTop: 0 }}
                    />
                  ) : (
                    <GlobalOutlined
                      style={{ marginTop: 0 }}
                    />
                  )}
                </Tooltip>
              </Col>
              <Col span={5} className="note-text" style={{ textAlign: "center" }}>
                {formatDateString(item.createdAt)}
              </Col>
              <Col span={4} className="note-icon-container">
                <div
                  className={`icon-wrapper ${item.pinned ? "checked" : ""}`}
                  onClick={() => togglePin(item.id)}
                >
                  {item.pinned ? <PushpinFilled /> : <PushpinOutlined />}
                </div>
                <Popconfirm
                  title="Delete the note"
                  description="Are you sure to delete this note?"
                  onConfirm={() => confirmDeleteNote(item.id, item.user.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    className={`icon-wrapper ${
                      item.isChecked ? "checked" : ""
                    }`}
                  >
                    <DeleteOutlined style={{}} />
                  </div>
                </Popconfirm>
              </Col>
            </Row>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Notes;
