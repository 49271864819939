import React, { useState, useEffect } from "react";
import { Card } from "antd";
import CustomerService from "../../services/CustomerService";

interface SalesCardProps {
  title: string;
  description: string;
  orderCount: number;
  last30DaysSales: number;
  id: number;
}

const SalesCard: React.FC<SalesCardProps> = ({
  title,
  description,
  last30DaysSales,
  id,
}) => {
  const [orderCount, setOrderCount] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [salesData, setSalesData] = useState<any[]>([]);
  const roleName = localStorage.getItem("roleName");
  // const options: ApexOptions = {
  //   chart: {
  //     type: 'area',
  //     toolbar: {
  //       tools: {
  //         zoom: false,
  //         zoomin: false,
  //         zoomout: false,
  //         pan: false,
  //         reset: false,
  //         download: false
  //       },
  //     },
  //   },
  //   grid: {
  //     show: false,
  //   },
  //   colors: ['#ffcb2b'],

  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     width: 2,
  //     curve: 'smooth',
  //   },
  //   xaxis: {
  //     type: 'datetime',

  //     labels: {
  //       show: false,
  //     },
  //     axisBorder: {
  //       show: false,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //   },
  //   yaxis: {
  //     show: false,
  //     labels: {
  //       formatter: function(value) {
  //         return value.toFixed(0);
  //       }
  //     }
  //   },
  //   tooltip: {
  //     x: {
  //       format: 'dd/MM/yyyy',
  //     },
  //   },
  //   legend: {
  //     position: 'top',
  //     horizontalAlign: 'left'
  //   }
  // };

  const getCustomerSalesDetail = () => {
    if (id) {
      CustomerService.getSalesCountLastWeek(`?id=${id}`)
        .then((response) => {
          const formattedData = [
            {
              name: "Order Count",
              data: response.data.map((item: any) => {
                const dateParts = item.updatedAt
                  .split("-")
                  .map((part: any) => parseInt(part, 10));
                const utcDate = Date.UTC(
                  dateParts[0],
                  dateParts[1] - 1,
                  dateParts[2]
                );
                return {
                  x: utcDate,
                  y: item.totalOrderCount,
                };
              }),
            },
          ];

          //toplamini al
          const totalOrderCount = response.data.reduce(
            (total: number, item: any) => {
              return total + item.totalOrderCount;
            },
            0
          );
          setOrderCount(totalOrderCount);
          setSalesData(formattedData);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    getCustomerSalesDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card bordered={false} className={"sales-card"}>
      <p className="title">{title}</p>
      <p className="description">{description}</p>
      {roleName === "SUCCESS_TEAM_MANAGER" || roleName === "SUCCESS_TEAM" ? (
        <>
          <p className="date-range-info">Last 30 Days</p>
          <p className="sales-card-info">
            <span style={{ fontSize: 32 }}>+{last30DaysSales}</span> Product
            Sales
          </p>
        </>
      ) : (
        <>
          <p className="date-range-info">Last 7 Days</p>
          <p className="sales-card-info">
            <span style={{ fontSize: 32 }}>+{orderCount}</span> Product Sales
          </p>
        </>
      )}
      {/* <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
       <ReactApexChart
          options={options}
          series={salesData}
          type="area"
          height={175}
        />
      </div> */}
    </Card>
  );
};

export default SalesCard;
