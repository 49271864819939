import React, { useState, useEffect } from "react";
import { Card, Input, Button } from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "./info-card.css";
import AmazonSuspendedApplicantService from "../../services/AmazonSuspendedApplicantService";
import { openNotification } from "../../utils";
import { CopyClipboard } from "../General/CopyClipboard";

interface EmailValues {
  sellerEmail: string;
  buyerEmail: string;
  oneamzEmail: string;
  serverIp: string;
  serverPassword: string;
  accountPassword: string;
  [key: string]: string;
}

const CustomerInfo: React.FC<any> = ({ customer, setTriggerUpdate }) => {
  const [editingField, setEditingField] = useState<string | null>(null);
  const [emailValues, setEmailValues] = useState<EmailValues>({
    sellerEmail: customer?.sellerEmail ?? "",
    buyerEmail: customer?.buyerEmail ?? "",
    oneamzEmail: customer?.oneamzEmail ?? "",
    serverIp: customer?.serverIp ?? "",
    serverPassword: customer?.serverPassword ?? "",
    accountPassword: customer?.accountPassword ?? "",
  });

  useEffect(() => {
    setEmailValues({
      sellerEmail: customer?.sellerEmail ?? "",
      buyerEmail: customer?.buyerEmail ?? "",
      oneamzEmail: customer?.oneamzEmail ?? "",
      serverIp: customer?.serverIp ?? "",
      serverPassword: customer?.serverPassword ?? "",
      accountPassword: customer?.accountPassword ?? "",
    });
  }, [customer]);

  const handleEdit = (field: string) => {
    setEditingField(field);
  };

  const handleCancel = () => {
    setEmailValues({
      sellerEmail: customer?.sellerEmail ?? "",
      buyerEmail: customer?.buyerEmail ?? "",
      oneamzEmail: customer?.oneamzEmail ?? "",
      serverIp: customer?.serverIp ?? "",
      serverPassword: customer?.serverPassword ?? "",
      accountPassword: customer?.accountPassword ?? "",
    });
    setEditingField(null);
  };

  const handleSave = async (field: string) => {
    const data = {
      ...emailValues,
    };
    try {
      await AmazonSuspendedApplicantService.updateEmail(customer?.id, data);
      openNotification("success", "Success", "Updated successfully");
      setEditingField(null);
      handleNoteAdded();
    } catch (error) {
      console.error("Error in update:", error);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setEmailValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleNoteAdded = () => {
    setTriggerUpdate((prev: number) => prev + 1);
  };

  const renderEditableField = (
    field: string,
    label: string,
    placeholder: string
  ) => {
    return (
      <div className="border-bottom">
        <p className="info-header">{label}</p>
        {editingField === field ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Input
              value={emailValues[field]}
              placeholder={placeholder}
              onChange={(e) => handleInputChange(field, e.target.value)}
              allowClear
              style={{ marginRight: 8 }}
            />
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => handleSave(field)}
              style={{ marginRight: 8 }}
            />
            <Button icon={<CloseOutlined />} onClick={handleCancel} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="info-description" style={{ marginRight: 8 }}>
              {emailValues[field] ? (
                <CopyClipboard text={emailValues[field] || "-"}></CopyClipboard>
              ) : (
                "-"
              )}
            </p>
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEdit(field)}
              style={{ marginTop: "-16px" }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Card bordered={false} className={"card"}>
      <p className="title">Customer Info</p>
      <div className="lesson-card-container">
        <div>
          <p className="info-header">Source:</p>
          <p className="info-description" style={{ textAlign: "center" }}>
            <CopyClipboard text={customer?.source?.name ?? "-"}></CopyClipboard>
          </p>
        </div>
        <div>
          <p className="info-header" style={{ textAlign: "right" }}>
            Suspend Team Member:
          </p>
          <p className="info-description" style={{ textAlign: "center" }}>
            <CopyClipboard
              text={
                customer?.suspendTeamMember?.firstName &&
                customer?.suspendTeamMember?.lastName
                  ? `${customer?.suspendTeamMember?.firstName} ${customer?.suspendTeamMember?.lastName}`
                  : "-"
              }
            ></CopyClipboard>
          </p>
        </div>
      </div>

      <div className="lesson-card-container">
        <div>
          <p className="info-header">OneAmz User</p>
          <p className="info-description" style={{ textAlign: "center" }}>
            {customer?.oneamzEmail ? "Yes" : "No"}
          </p>
        </div>
        <div>
          <p className="info-header" style={{ textAlign: "right" }}>
            Seller Shield
          </p>
          <p className="info-description" style={{ textAlign: "center" }}>
            {customer?.additionalPackageHistory ? "Yes" : "No"}
          </p>
        </div>
      </div>
      {renderEditableField("sellerEmail", "Seller Email", "Enter seller email")}
      {renderEditableField("buyerEmail", "Buyer Email", "Enter buyer email")}
      {renderEditableField("oneamzEmail", "OneAmz Email", "Enter OneAmz email")}
      {renderEditableField("serverIp", "Server IP", "Enter server IP")}
      {renderEditableField(
        "serverPassword",
        "Server Password",
        "Enter server password"
      )}
      {renderEditableField(
        "accountPassword",
        "Account Password",
        "Enter account password"
      )}
    </Card>
  );
};

export default CustomerInfo;
