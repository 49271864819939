import http from "../http-common";

const learnerPhoneCallHistory = (id: number, criteria:any) => {
  return http.get<any>(`/learners/${id}/phone-call-histories${criteria}`);
};

const learnerPhoneCallHistoryStatisticsByUser = (criteria:any) => {
  return http.get<any>(`me/phone-call-summary${criteria}`);
};

const learnerPhoneCallHistoryStatisticsGroupBySource = (criteria:any) => {
  return http.get<any>(`/phone-call-histories/report/summary${criteria}`);
};

const learnerPhoneCallHistoryStatistics = (criteria:any) => {
  return http.get<any>(`/phone-call-summary${criteria}`);
};

const PhoneCallService = {
    learnerPhoneCallHistory,
    learnerPhoneCallHistoryStatisticsByUser,
    learnerPhoneCallHistoryStatistics,
    learnerPhoneCallHistoryStatisticsGroupBySource
};

export default PhoneCallService;