import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, Checkbox, Col, Modal, Row, Select } from "antd";
import "./info-card.css";
import { getProfilePhotoPath, openNotification } from "../../utils";
import moment from "moment";
import ListCheck from "./../../assets/list-checks.svg";
import Missed from "./../../assets/missed.svg";
import Answered from "./../../assets/answered.svg";
import Clock from "./../../assets/clock.svg";
import CallTeamService from "../../services/CallTeamService";
import LearnerNoteService from "../../services/LearnerNoteService";
import CallSalesPackageService from "../../services/CallSalesPackageService";
import SalesPackageServeyService from "../../services/SalesPackageServeyService";

const CallHistory: React.FC<any> = ({ learner, setTriggerUpdate }) => {
  const [callInfo, setCallInfo] = useState([]);
  const [ratio, setRatio] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [packages, setPackages] = useState<any>([]);
  const [surveys, setSurveys] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState();
  const [logs, setLogs] = useState<any>([]);
  const [buttonActive, setButtonActive] = useState(false);
  const roleName = localStorage.getItem("roleName");
  if (!roleName) {
    throw new Error("Role name not found in local storage");
  }

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        setPackages(response.data.filter((pkg: any) => pkg.isActive));
        setSelectedPackageId(
          response.data.filter((pkg: any) => pkg.isActive)[0]?.id
        );
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCallSalesPackageServey = () => {
    SalesPackageServeyService.getAll()
      .then((response: any) => {
        let data = response.data;
        setSurveys(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getAllSalesPackageServeyLog = () => {
    if (learner) {
      SalesPackageServeyService.getLog(`?learnerId=${learner?.id}`)
        .then((response: any) => {
          let data = response.data;

          const latestLogs = data.reduce((acc: any, log: any) => {
            if (
              !acc[log.callCenterSalesPackageServeyId] ||
              acc[log.callCenterSalesPackageServeyId].id < log.id
            ) {
              acc[log.callCenterSalesPackageServeyId] = log;
            }
            return acc;
          }, {});
          const latestLogsArray = Object.values(latestLogs);
          setLogs(latestLogsArray);

          const checkedItemsFromLogs = latestLogsArray
            .filter((log: any) => log.isConfirm)
            .map((log: any) => log.callCenterSalesPackageServeyId);
          setCheckedItems(checkedItemsFromLogs);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (learner) {
      getPackages();
      getCallSalesPackageServey();
    }
  }, [learner]);

  const showModal = () => {
    setIsVisible(true);
    getAllSalesPackageServeyLog();
  };

  useEffect(() => {
    if (learner?.id) {
      CallTeamService.callHistory(learner?.id, `?page=0&size=50`)
        .then((response: any) => {
          setCallInfo(response.data.data);
          const answeredCalls = response.data.data.filter(
            (call: any) => call.durationInSeconds > 0
          ).length;
          const totalCalls = response.data.data.length;
          // Cevaplanma oranının hesaplanması ve yuvarlanması
          const ratio =
            totalCalls > 0 ? Math.round((answeredCalls / totalCalls) * 100) : 0;
          setRatio(ratio);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  }, [learner]);

  const sortCallInfo = (callInfoArray: any) => {
    return callInfoArray.sort((a: any, b: any) => {
      if (a.pinned === b.pinned) {
        return (
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
        );
      }
      return a.pinned ? -1 : 1;
    });
  };
  const [allCallInfo, setAllCallInfo] = useState(
    sortCallInfo(Array.isArray(callInfo) ? callInfo : [])
  );

  useEffect(() => {
    if (Array.isArray(callInfo)) {
      setAllCallInfo(sortCallInfo(callInfo));
    }
  }, [callInfo]);

  const createNote = (item: any) => {
    let internal = {
      description:
        item.durationInSeconds > 0
          ? `${moment(item.createdAt).format("DD/MM/YYYY HH:mm")} Not reached.`
          : `${moment(item.createdAt).format(
              "DD/MM/YYYY HH:mm"
            )} Contacted by phone.`,
      type: "CONTACT",
    };
    LearnerNoteService.createNote(learner.id, internal)
      .then((response: any) => {
        openNotification("success", "Success", "Note added");
        setTriggerUpdate((prev: number) => prev + 1);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleSave = () => {
    console.log("Kaydedilen ID'ler:", checkedItems);
    setIsVisible(false);
  };

  const handleCheckboxChange = (checked: boolean, surveyId: any) => {
    setCheckedItems((prev) =>
      checked ? [...prev, surveyId] : prev.filter((item) => item !== surveyId)
    );

    const existingLog = logs.find(
      (log: any) => log.callCenterSalesPackageServeyId === surveyId
    );

    let payload = {
      learnerId: learner.id,
      isConfirm: checked,
      callCenterSalesPackageServeyId: surveyId,
      ...(existingLog && { id: existingLog.id }),
    };

    SalesPackageServeyService.createLog(payload)
      .then((response) => {})
      .catch((error) => {
        // Hata durumunda yapılacak işlemler
      });
  };

  // @ts-ignore
  const handleCloseModal = (e) => {
    const obj = e.target.closest(".ant-modal-close")
      ? e.target.closest(".ant-modal-close")
      : e.target;
    if (obj.classList.contains("ant-modal-close")) setIsVisible(false);
  };

  useEffect(() => {
    const items = surveys.filter(
      (survey: any) => survey.callCenterSalesPackageId === selectedPackageId
    );
    setButtonActive(items.length <= checkedItems.length);
  }, [surveys, checkedItems]);

  return (
    <>
      <Card bordered={false} className={"card"} style={{ height: 430 }}>
        <Row justify={"space-between"}>
          <p className="title">Call History</p>
          {roleName !== "SUCCESS_TEAM_REPORTER" ? (
            <Button type="default" onClick={showModal}>
              <Row>
                <img
                  src={ListCheck}
                  height="22"
                  style={{ marginRight: 5 }}
                  alt=""
                  className="list-check-svg-icon"
                />{" "}
                Call Checklist
              </Row>
            </Button>
          ) : null}
        </Row>
        <Modal
          title="Call Checklist"
          open={isVisible}
          className="call-history-card modal-backdrop"
          onOk={handleSave}
          onCancel={(e) => handleCloseModal(e)}
          footer={[
            <Button
              disabled={!buttonActive}
              style={{ width: "100%" }}
              key="save"
              type="primary"
              onClick={handleSave}
            >
              Completed
            </Button>,
          ]}
        >
          <Select
            placeholder={"Select"}
            style={{ width: "100%" }}
            defaultValue={packages[0]?.id}
            onChange={(value) => setSelectedPackageId(value)}
          >
            {packages?.map((option: any) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <p>
            <b>Important Questions</b>
          </p>
          <div style={{ marginTop: 10, overflowY: "auto" }}>
            {surveys
              .filter(
                (survey: any) =>
                  survey.callCenterSalesPackageId === selectedPackageId &&
                  survey.questionType === "IMPORTANT"
              )
              .map((survey: any) => (
                <Row style={{ marginBottom: 10 }}>
                  <Checkbox
                    key={survey.id}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, survey.id)
                    }
                    checked={checkedItems.includes(survey.id)}
                  >
                    {survey.question}
                  </Checkbox>
                </Row>
              ))}
          </div>
          <p style={{ marginTop: 30 }}>
            <b>Check List</b>
          </p>

          <div style={{ height: "auto", overflowY: "auto" }}>
            {surveys
              .filter(
                (survey: any) =>
                  survey.callCenterSalesPackageId === selectedPackageId &&
                  survey.questionType === "CHECKLIST"
              )
              .map((survey: any) => (
                <Row style={{ marginBottom: 10 }}>
                  <Checkbox
                    key={survey.id}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, survey.id)
                    }
                    checked={checkedItems.includes(survey.id)}
                  >
                    {survey.question}
                  </Checkbox>
                </Row>
              ))}
          </div>
        </Modal>
        <p className="description" style={{ fontSize: 14 }}>
          Average Call Duration: %{ratio}
        </p>
        <div className="callInfo-container">
          <Row
            justify="space-between"
            align="middle"
            className="note-row-title"
          >
            <Col span={8} className="note-table-title">
              OneAmz MEMBER
            </Col>
            <Col span={4} className="note-table-title">
              Date
            </Col>
            <Col span={5} className="note-table-title">
              Call Status
            </Col>
            <Col span={3} className="note-table-title">
              Call Duration
            </Col>
            <Col span={4} className="note-table-title"></Col>
          </Row>
          {allCallInfo?.map((item: any) => (
            <Row
              key={item.id}
              justify="space-between"
              align="middle"
              className="note-row"
            >
              <Col span={8} className="note-text">
                <Row>
                  <Avatar
                    style={{ marginRight: "15px" }}
                    size={40}
                    src={getProfilePhotoPath(item.user?.profilePhoto ?? 1)}
                  />
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      {item.user?.firstName + " " + item.user?.lastName}{" "}
                    </p>
                    <p style={{ fontSize: 10, color: "gray", marginTop: 0 }}>
                      {item.user?.roleName}{" "}
                    </p>
                  </div>
                </Row>
              </Col>
              <Col
                span={4}
                className="note-text"
                style={{ paddingRight: 25, whiteSpace: "pre-wrap" }}
              >
                {moment(item?.callDateTime).format("DD/MM/YYYY HH:mm")}
              </Col>
              <Col span={5} className="note-text">
                <Row align={"middle"}>
                  <img
                    src={item.durationInSeconds > 0 ? Answered : Missed}
                    alt="call-icon"
                    style={{ marginRight: 5 }}
                  />
                  <span>
                    {" "}
                    {item.durationInSeconds > 0 ? "Answered" : "Missed"}
                  </span>
                </Row>
              </Col>
              <Col span={3} className="note-text">
                <Row align={"middle"}>
                  <img src={Clock} alt="call-icon" style={{ marginRight: 5 }} />
                  <span> {item.durationInSeconds}s</span>
                </Row>
              </Col>
              <Col span={4} className="note-text">
                <Row align={"middle"}>
                  <Button type="primary" onClick={() => createNote(item)}>
                    Add Note
                  </Button>
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      </Card>
    </>
  );
};

export default CallHistory;
