import { Avatar, Button, Col, Row, Segmented, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ILearnerResponseData } from '../../types/Learner'
import { can } from '../../utils';
import LearnerService from '../../services/LearnerService';
import Table, { ColumnsType } from 'antd/es/table';
import { CopyClipboard } from '../General/CopyClipboard';
import moment from 'moment';
import LearnerDetail from '../../pages/LearnerDetail';
import { StartCall } from '../General/StartCall';
import Avatar1Icon from "../../assets/avatar1.svg";
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

interface LearnerFilterProps {
    startDate: string;
    endDate: string;
}

const CustomerStatistics: React.FC<LearnerFilterProps> = ({ startDate,  endDate}) => {

    const [learnerList, setLearnerList] = useState<ILearnerResponseData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handeLearnerList = () => {
    };

    const currentUser: any = {
        canLearnersGetAll: can("learners:getAll")
    };

    const columns: ColumnsType<ILearnerResponseData> = [
        {
            title: 'Learner',
            dataIndex: 'firstName',
            key: 'action',
            render: (_, row) => (
                <Row justify={'start'} align={'middle'}>
                    {/* <Col className='mr-15'>
                        <Avatar style={{ background: '#87B2B2' }} size="large" >
                            {row.firstName.charAt(0).toUpperCase()}
                        </Avatar>
                    </Col> */}
                    <Col>
                        <Title className='customer-fullname' level={5}><b>{row.firstName} {row.lastName}</b></Title>
                        <span className='customer-fullname'><CopyClipboard text={row.email}></CopyClipboard>
                        </span>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'Phone',
            render: (_, row) => {
                return (
                    <StartCall text={row?.phoneNumber} />
                );
            },
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     sorter: true,
        //     render: (_, row) => {
        //         if (row.status) {
        //             return <Tag color="green">{row.status?.name}</Tag>;
        //         }
        //     }
        // },
        // {
        //     title: 'Oneamz',
        //     dataIndex: 'oneamzEmail',
        //     key: 'oneamzEmail',
        //     sorter: true,
        // },
        ...currentUser.canLearnersGetAll ? [{
            title: 'Success Team Member',
            dataIndex: 'agent',
            key: 'agent',
            render: (_: any, row: any) => {
                if (row.user === null) {
                    return;
                } else {
                    return <Row justify={'start'} align={'middle'}>
                        {/* <Col className='mr-15'>
                            <img src={Avatar1Icon} alt='avatar-icon' />
                        </Col> */}
                        <Col>
                            <Title className='customer-fullname' level={5}><b>{row.user?.firstName} {row.user?.lastName}</b></Title>
                        </Col>
                    </Row>
                }
            },
        }] : [],
        // {
        //     title: 'Lesson Completed',
        //     dataIndex: 'lessonsCompleted',
        //     key: 'lessonsCompleted',
        //     width: 50,
        //     render: (_, row) => {
        //         if (row.lessonsCompleted < 10) {
        //             return <Tag color="red">{row.lessonsCompleted}</Tag>;
        //         } else if (row.lessonsCompleted >= 10 && row.lessonsCompleted < 20) {
        //             return <Tag color="volcano">{row.lessonsCompleted}</Tag>;
        //         } else if (row.lessonsCompleted >= 20 && row.lessonsCompleted < 40) {
        //             return <Tag color="orange">{row.lessonsCompleted}</Tag>;
        //         } else {
        //             return <Tag color="green">{row.lessonsCompleted}</Tag>;
        //         }
        //     }
        // },
        {
            title: 'Last Login At',
            dataIndex: 'lastLoginAt',
            key: 'lastLoginAt',
            render: (_, row) => {
              if (row.lastLoginAt) {
                return moment(row.lastLoginAt).format("DD/MM/YYYY");
              }
            }
          },
          {
            title: 'Integrated At',
            dataIndex: 'becomeCustomerAt',
            key: 'becomeCustomerAt',
            render: (_, row) => {
              if (row.becomeCustomerAt) {
                return moment(row.becomeCustomerAt).format("DD/MM/YYYY");
              }
            }
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, row) => {
              if (row.createdAt) {
                return moment(row.createdAt).format("DD/MM/YYYY");
              }
            }
          },
        {
            title: '',
            dataIndex: 'id',
            render: (_, row) => (
                <Button type='primary' onClick={() => navigate(`/learners/detail/${row.id}`)}>Learner Detail</Button>
                // <LearnerDetail key={row.id} id={row.id} onHandleLearnerList={handeLearnerList} />
            ),
        },
    ];


    useEffect(() => {
        let filterCriteria = `?sort=becomeCustomerAt,desc&becomeCustomer=true&startBecomeCustomerAt=${startDate}T00:00:00&&endBecomeCustomerAt=${endDate}T23:59:59`;
        getLearner(filterCriteria);
    }, [startDate,endDate])

    const getLearner = (filterCriteria: string) => {
        setLoading(true);
        let canPermission = can("learners:getAll");

        if (canPermission) {
            LearnerService.getAllWithoutPagination(filterCriteria)
                .then((response: any) => {
                    let data = response.data;
                    setLearnerList(data);
                    setLoading(false);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        } else {
            let id = localStorage.getItem('id');
            filterCriteria += `&userId=${id}`;
            LearnerService.getMyLearnersWithoutPagination(filterCriteria)
                .then((response: any) => {
                    let data = response.data;
                    setLearnerList(data);
                    setLoading(false);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <Row style={{ marginBottom: '10px' }} justify="center">
                <Table loading={loading} scroll={{ x: '100%' }}  style={{ marginTop: '20px', width: "100%" }} key={'id'} columns={columns} dataSource={learnerList} pagination={{ pageSize: 8}} />
            </Row>
        </>
    )
};

export default CustomerStatistics