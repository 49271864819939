import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Progress, Modal, Tag, Space, Input, Row, Col } from 'antd';
import './info-card.css';
import moment from 'moment';
import LearnerNoteService from '../../services/LearnerNoteService';
import { convertDate, openNotification } from '../../utils';
import LearnerService from '../../services/LearnerService';
import { CheckCircleOutlined, SettingOutlined } from '@ant-design/icons';



const LearnerInfo: React.FC<any> = ({ learner, setTriggerUpdate }) => {

    const [selectedValue, setSelectedValue] = useState()
    const [makeCustomerForm] = Form.useForm();
    const [makeCustomerFormLoading, setMakeCustomerFormLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateContactInfoForm] = Form.useForm();
    const roleName = localStorage.getItem('roleName');

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    interface EcudationStatusProps {
        completed?: number;
    }

    const EcudationStatus = ({ completed }: EcudationStatusProps) => {
        if (completed === undefined) {
            return <Progress strokeColor={'red'} type="line" percent={0} format={(percent) => `${0}`} />;
        }
        const lessonTotalCount = 83;
        let percent: number = parseInt((completed / lessonTotalCount * 100).toString());

        let color: string = 'red';

        if (completed < 10) {
            color = 'red';
        } else if (completed >= 10 && completed < 20) {
            color = 'volcano';
        } else if (completed >= 20 && completed < 40) {
            color = 'orange';
        } else {
            color = 'green';
        }

        return <><Progress strokeColor={color} type="line" percent={percent} format={(percent) => `${completed}`} /></>;
    };


    const handleRefresh = () => {
        setTriggerUpdate((prev: number) => prev + 1);
    };


    const DescriptionItem = ({ title, content }: any) => (
        <div style={{ marginBottom: '10px' }}>
            <p style={{ marginBottom: '2px', marginTop: '0px' }}><b>{title}:</b></p>
            <span style={{ fontWeight: "normal" }}>{content}</span>
        </div>
    );

    const makeCustomer = (values: any) => {
        setMakeCustomerFormLoading(true);
        let postObj = {
            email: values.email
        }
        LearnerService.makeCustomer(learner.id, postObj).then((response: any) => {
            openNotification('success', 'Success', 'Customer made customer');
            LearnerService.attendLearnerToNewCourse(learner.id).then((response: any) => {
                openNotification('success', 'Success', "customer's advanced level education opened");
                setMakeCustomerFormLoading(false);
                handleRefresh();
            })
                .catch((e: Error) => {
                    console.log(e);
                    setMakeCustomerFormLoading(false);
                });
        })
            .catch((e: Error) => {
                console.log(e);
                setMakeCustomerFormLoading(false);
            });
    };

    const updateContactInfo = (values: any) => {
        let postObj = {
            email: values.email,
            phoneNumber: values.phoneNumber,
        }

        LearnerService.updateContactInfo(learner.id, postObj).then((response: any) => {
            openNotification('success', 'Success', 'Learner contact information updated');
            handleRefresh();
            setIsModalVisible(false);
        })
            .catch((e: Error) => {
                console.log(e);
            });
    };
    useEffect(() => {
        if (isModalVisible) {
            updateContactInfoForm.resetFields();
            updateContactInfoForm.setFieldsValue({
                email: learner?.email,
                phoneNumber: learner?.phoneNumber,
            });
        }
    }, [isModalVisible, learner, updateContactInfoForm]);


    return (
        <Card bordered={false} className={'card learner-info'} style={{ height: '100%' }}>
            <div>
                <Row justify={'space-between'}>
                    <p className='title'>Learner Info</p>
                    <SettingOutlined onClick={toggleModal} />
                </Row>
                <p className='sub-title'>Lesson</p>
                <EcudationStatus completed={learner?.lessonsCompleted} />
                <div className='lesson-card-container'>
                    <div>
                        <p className='date-header'>Integrated At:</p>
                        <p className='date-description'>{convertDate(learner?.becomeLearnerAt)}</p>
                    </div>
                    <div>
                        <p className='date-header'>Last Login At:</p>
                        <p className='date-description'>{convertDate(learner?.lastLoginAt)}</p>
                    </div>
                </div>
                <div className='lesson-card-container'>
                    <div>
                        <p className='date-header'>E-Mail:</p>
                        <p className='date-description' style={{ fontSize: 11 }}>{learner?.email}</p>
                    </div>
                    <div>
                        <p className='date-header'>Phone:</p>
                        <p className='date-description' style={{ fontSize: 11 }}>{learner?.phoneNumber}</p>
                    </div>
                </div>
                {/* <div className='border-bottom'>
                    <div className='lesson-card-container'>
                        <div>
                            <p style={{ fontSize: 12 }}>Consulting Package: <br />
                                <b> Mini Destek Paketi</b></p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12 }}>Package Price:: <br />
                                <b> $99.99</b></p>
                        </div>
                    </div>
               </div> */}
                <div className='border-bottom'>
                    <div className='lesson-card-container'>
                        <div>
                            <p style={{ fontSize: 12 }}>Last Contact Date: <br />
                                <b>{convertDate(learner?.lastContactDate)}</b></p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12 }}>Assigneed STM:<br />
                                <b>{learner?.user.firstName} {learner?.user.lastName}</b></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lesson-card-container' style={{ paddingBottom: 10 }}>
                {
                    (learner?.oneamzEmail) ?
                        <DescriptionItem title="Oneamz Email" content={<> {learner?.oneamzEmail}  <Tag style={{ marginLeft: "5px" }} icon={<CheckCircleOutlined />} color="success">Verified
                        </Tag></>} />
                        :
                        <Form form={makeCustomerForm} layout="vertical" onFinish={makeCustomer}>
                            <Form.Item
                                name="email"

                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    }
                                ]}
                            >
                                {roleName !== "SUCCESS_TEAM_REPORTER" ?
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Input placeholder='Oneamz email address'
                                            style={{ width: '55%' }}
                                        />
                                        <Button style={{ width: '40%', fontSize: 11, padding: 5 }} htmlType="submit" loading={makeCustomerFormLoading} type="primary">Make Customer</Button>
                                    </div>
                                    : null}
                            </Form.Item>
                        </Form>
                }
            </div>
            <Modal
                title="Update Contacts"
                visible={isModalVisible}
                onOk={toggleModal}
                onCancel={toggleModal}
                footer={false}
            // Add more props as needed for your modal
            >
                <Row>
                    <Form form={updateContactInfoForm} style={{ width: '100%' }} layout="vertical" onFinish={updateContactInfo}>
                        <Row>
                            <Form.Item
                                style={{ width: '260px' }}
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    }
                                ]}
                            >
                                <Input placeholder='Email' defaultValue={learner?.email} />

                            </Form.Item>
                            <Form.Item
                                style={{ width: '200px', marginLeft: '10px' }}
                                label="Phone"
                                name="phoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your phoneNumber!',
                                    }
                                ]}
                            >
                                <Input placeholder='Phone' defaultValue={learner?.phoneNumber} />
                            </Form.Item>
                        </Row>

                        <Row>
                            <Form.Item style={{ marginLeft: 'auto' }}>
                                <Button htmlType="submit" type="primary">Save</Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </Row>
            </Modal>
        </Card>
    );
};

export default LearnerInfo;
