import http from "../http-common";
import { ILeaveRequestData } from "../types/LeaveRequest";

const getAll = () => {
    return http.get<Array<ILeaveRequestData>>("/leave-requests");
};

const getByMemberId = (memberId: number) => {
    return http.get<Array<ILeaveRequestData>>(`/leave-requests/member/${memberId}`);
};

const getByManagerMembers = (roleName: string = "") => {
    return http.get<Array<ILeaveRequestData>>(`/leave-requests/manager/members${roleName}`);
};

const createSelf = (data: ILeaveRequestData) => {
    return http.post<ILeaveRequestData>("/leave-requests/self", data);
};

const createForManager = (memberId: number, data: ILeaveRequestData) => {
    return http.post<ILeaveRequestData>(`/leave-requests/manager/${memberId}`, data);
};

const update = (id: number, data: ILeaveRequestData) => {
    return http.put<ILeaveRequestData>(`/leave-requests/${id}`, data);
};

const remove = (id: number) => {
    return http.delete<void>(`/leave-requests/${id}`);
};

const LeaveRequestService = {
    getAll,
    getByMemberId,
    getByManagerMembers,
    createSelf,
    createForManager,
    update,
    remove,
};

export default LeaveRequestService;
