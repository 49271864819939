import { Card, Drawer, Divider, Switch, Popconfirm, Button, DatePicker, Form } from 'antd';
import React, { useState } from 'react';
import TaskService from '../../../services/TaskService';
import { ITaskResponseData } from '../../../types/Task';
import { openNotification } from '../../../utils';
import './task-detail.css';
import dayjs from 'dayjs';

interface TaskProps {
    item: any;
    onHandleTaskList: () => void;
    getTasks: () => void;
}

const TaskDetail: React.FC<TaskProps> = ({ item, onHandleTaskList, getTasks }) => {
    const [status, setStatus] = useState(item.status);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [task, setTask] = useState<ITaskResponseData>();
    const roleName = localStorage.getItem('roleName');
    const [dueDate, setDueDate] = useState(dayjs(item.dueDate));
    //MODAL SETTINGS
    const showModal = () => {
        setLoading(true);
        setIsModalOpen(true);
        getTask(item.id)
    };

    const getTask = (id: number) => {
        TaskService.get(id)
            .then((response: any) => {
                let data = response.data
                setTask(data)
                setStatus(response.data.status);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e)
            });
    }

    const confirmDeleteTask = () => {
        TaskService.remove(item.id)
            .then((response: any) => {
                openNotification('success', 'Success', 'Deleted task');
                onHandleTaskList();
                getTasks();
            })
            .catch((e: Error) => {
                console.log(e)
            });
    };

    // const cancel = (e: React.MouseEvent<HTMLElement>) => {
    //     console.log(e);
    // };

    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onChange = (checked: boolean) => {
        let status = 'TODO';

        if (checked) {
            status = 'DONE';
            setStatus('DONE');
        } else {
            setStatus('TODO');
        }

        TaskService.update(item.id, { status: status })
            .then((response: any) => {
                openNotification('success', 'Success', 'Changed status');
                getTasks();
                onHandleTaskList();
            })
            .catch((e: Error) => {
                console.log(e)
            });
    };

    const handleDueDateChange = (value: any) => {
        if (value) {
            const formattedDate = value.format("YYYY-MM-DDTHH:mm");
            TaskService.update(item.id, { dueDate: formattedDate })
                .then(() => {
                    openNotification('success', 'Success', 'Due date updated successfully!');
                    setDueDate(value);
                    onHandleTaskList();
                })
                .catch((e) => {
                    console.error(e);
                    openNotification('error', 'Error', 'An error occurred while updating the due date.');
                });
        }
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>Detail</Button>
            {/* <Button className='mr-15 task-detail' key={item?.id} onClick={showModal} type={item.status == 'DONE' ? 'default' : 'primary'} size="small">{item?.title}</Button> */}
            {/* extra={<TaskCustomerDetail buttonText="Customer Detail" key={task?.learner.id} id={task?.learner.id} onHandleLearnerList={handeLearnerList} />} */}
            <Drawer title="Task Detail" placement="right" onClose={handleCancel} open={isModalOpen} style={{ padding: '0px', margin: '0px', border: 'none' }}>
                <Card loading={loading} className='task-detail-card' style={{ padding: '0px', margin: '0px', border: 'none' }}>

                    <h4>Title</h4>
                    <p>{task?.title}</p>
                    <Divider />

                    <h4>Description</h4>
                    <p>{task?.description}</p>
                    <Divider />

                    <h4>Tag</h4>
                    <p>{task?.tag}</p>
                    <Divider />

                    <h4>Due Date</h4>

                    <Form.Item
                        name="dueDate"
                        rules={[{ required: true, message: 'Please select a date!' }]}
                    >
                        <DatePicker
                            showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), minuteStep: 5 }}
                            style={{ width: "100%" }}
                            format="DD/MM/YYYY HH:mm"
                            placeholder="Select Date"
                            defaultValue={dueDate}
                            onChange={handleDueDateChange}
                        />


                    </Form.Item>
                    <Divider />

                    <h4>Customer</h4>
                    <p> {task?.customer ? task?.customer?.fullName : `${task?.learner?.firstName} ${task?.learner?.lastName}`}</p>
                    <Divider />

                    <h4>Assignee</h4>
                    <p>{task?.assignee?.firstName} {task?.assignee?.lastName}</p>
                    <Divider />

                    {roleName === 'CALL_TEAM_MANAGER' || roleName === 'SUCCESS_TEAM_MANAGER' || roleName === 'ADMIN' ?
                        <>
                            <h4>Reporter</h4>
                            <p>{task?.assignee?.firstName} {task?.assignee?.lastName}</p>
                        </>
                        : null}
                    <Divider />
                    <Switch checkedChildren={<b>DONE</b>} unCheckedChildren={<b>TODO</b>} onChange={onChange} checked={status == 'DONE' ? true : false} />
                    <Popconfirm
                        onConfirm={confirmDeleteTask}
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button style={{ marginLeft: '10px' }} size='small' danger>Delete</Button>
                    </Popconfirm>
                </Card>
            </Drawer>
        </>
    );
}
export default TaskDetail;
