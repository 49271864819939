import { Input, Row, Space } from "antd";
import React, { useCallback, useState } from "react";
import Filter from "./Filter";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";

export const FilterField: React.FC<any> = ({
  onChangeSearch,
  setFilterText,
  pageSize,
  setFormFields,
  formFields,
  pendingStatusId,
}) => {
  const [searchText, setSearchText] = useState("");
  const roleName = localStorage.getItem("roleName");
  const authUserId = localStorage.getItem("id");
  //eslint-disable-next-line
  const debouncedSearch = useCallback(_.debounce(onChangeSearch, 500), [
    onChangeSearch,
  ]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    debouncedSearch(newValue);
  };

  const clearInput = () => {
    setSearchText("");
    debouncedSearch("");
  };

  const handleOnChangeFilter = (filterForm: any) => {
    let filterQuery = `?page=0&size=${pageSize}${
      roleName === "CALL_TEAM" ? `&callCenterMemberId=${authUserId}` : ""
    }`;

    if (filterForm.callTeamMember && filterForm.callTeamMember != null) {
      filterQuery += `&callCenterMemberId=${filterForm.callTeamMember}`;
    }

    if (filterForm.status && filterForm.status != null) {
      filterQuery += `&callCenterStatusIds=${filterForm.status}`;
    }

    if (filterForm.suspendStatus && filterForm.suspendStatus != null) {
      filterQuery += `&suspendStatusIds=${filterForm.suspendStatus}`;
    }

    if (filterForm.source) {
      filterQuery += `&sourceId=${filterForm.source}`;
    }

    if (filterForm.createdAtRange !== undefined) {
      if (filterForm.createdAtRange.length > 0) {
        let startDate = dayjs(filterForm.createdAtRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.createdAtRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startDate=${startDate}&endDate=${endDate}`;
      }
    }

    setFilterText(filterQuery);
  };

  return (
    <Row justify="end">
      <Space align="start">
        <Input
          prefix={<SearchOutlined />}
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={clearInput}
                style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
              />
            )
          }
          placeholder="Search"
          value={searchText}
          onChange={onSearchChange}
          style={{ maxWidth: 200 }}
        />
        <Filter
          onChangeFilter={handleOnChangeFilter}
          setFormFields={setFormFields}
          formFields={formFields}
          pendingStatusId={pendingStatusId}
        />
      </Space>
    </Row>
  );
};
