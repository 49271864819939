import React, { useEffect, useState } from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./acm-dashboard.css";
import CustomerService from "../../../services/CustomerService";

interface SalesCardProps {
  title: string;
  description: string;
  dateRange: any;
}

const SalesCard: React.FC<SalesCardProps> = ({
  title,
  description,
  dateRange,
}) => {
  const [salesData, setSalesData] = useState<any[]>([]);
  const roleName = localStorage.getItem("roleName");
  const [newOrderCount, setNewOrderCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const [startDate, endDate] = dateRange;

    let formattedDateRange;
    if (startDate && endDate) {
      formattedDateRange = `startDate=${startDate}&endDate=${endDate}`;
    }

    if (startDate === undefined || endDate === undefined) {
      return;
    }
    if (
      roleName === "ACCOUNT_MANAGER_MANAGER" ||
      roleName === "HI5_ACM_MANAGER"
    ) {
      CustomerService.getSalesCountLastWeek(`?${formattedDateRange}`)
        .then((response) => {
          const formattedData = [
            {
              name: "Order Count",
              data: response.data.map((item: any) => {
                return {
                  x: item.date,
                  y: item.orderCount,
                };
              }),
            },
          ];

          const totalOrderCount = response.data.reduce(
            (total: number, item: any) => {
              return total + item.orderCount;
            },
            0
          );
          setNewOrderCount(totalOrderCount);
          setSalesData(formattedData);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    } else {
      CustomerService.getSalesCountLastWeekMe(`?${formattedDateRange}`)
        .then((response) => {
          const formattedData = [
            {
              name: "Order Count",
              data: response.data.map((item: any) => {
                return {
                  x: item.date,
                  y: item.orderCount,
                };
              }),
            },
          ];

          const totalOrderCount = response.data.reduce(
            (total: number, item: any) => {
              return total + item.orderCount;
            },
            0
          );
          setNewOrderCount(totalOrderCount);
          setSalesData(formattedData);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
    setLoading(false);
  }, [dateRange]);

  const options: ApexOptions = {
    chart: {
      type: "area",
      toolbar: {
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          download: false,
        },
      },
    },
    grid: {
      show: false,
    },
    colors: ["#ffcb2b"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      x: {
        format: "dd/MM/yyyy",
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  return (
    <Card bordered={false} className={"sales-card"} loading={loading}>
      <p className="title">{title}</p>
      <p className="description">{description}</p>
      {/* <p className='date-range-info'>Last 7 Days</p> */}
      <p className="sales-card-info">
        <span style={{ fontSize: 32 }}>+{newOrderCount}</span> Order
      </p>
      <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%" }}>
        <ReactApexChart
          options={options}
          series={salesData}
          type="area"
          height={175}
        />
      </div>
    </Card>
  );
};

export default SalesCard;
