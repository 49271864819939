import http from "../http-common";
import { ILearnerStatusResponseData, ILearnerStatusRequestData } from "../types/LearnerStatus";

const getAll = (query = '') => {
  return http.get<Array<ILearnerStatusResponseData>>(`learners/statuses${query}`);
};

const get = (id:any) => {
  return http.get<Array<ILearnerStatusResponseData>>(`learners/statuses/${id}`);
};

const create = (data: ILearnerStatusRequestData) => {
  return http.post<ILearnerStatusRequestData>("/learners/statuses", data);
};

const updateIsActive = (id: any, data: any) => {
  return http.patch<any>(`/learners/statuses/${id}/update-is-active`, data);
};

const LearnerStatusService = {
  getAll,
  get,
  create,
  updateIsActive
};

export default LearnerStatusService;