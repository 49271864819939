import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Modal, Row } from 'antd';
import UserService from '../services/UserService';
import { EditOutlined } from '@ant-design/icons';
import { getProfilePhoto, getProfilePhotoPath } from '../utils';

const AvatarForm: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [selectedProfilePicture, setSeletedProfilePicture] = useState<any>(null);
    const [selectedId, setSelectedId] = useState<any>(null);
    const avatarList = [];

    useEffect(() => {
        let photoObj = getProfilePhoto();
        setSeletedProfilePicture(photoObj);
        setSelectedId(photoObj?.id)
    }, [])

    const handleCancel = (id: number) => {
        let obj: any = selectedProfilePicture;
        obj.id = id;
        obj.photo = getProfilePhotoPath(id);
        setSeletedProfilePicture(obj);
        setSelectedId(id);
    };


    for (let i = 1; i <= 116; i++) {
        const avatarSrc = require(`../assets/animojis/Number=${i}.png`);

        avatarList.push(
            <Col key={`avatar-${i}`}>
                <Image
                    style={{
                        cursor: 'pointer',
                        width: 100,
                        border: selectedId == i ? '4px solid black' : 'none'
                    }}
                    preview={false}
                    src={avatarSrc}
                    alt={`Avatar ${i}`}
                    onClick={() => handleCancel(i)}
                />
            </Col>
        );
    }

    const updateProfilePicture = () => {
        if (selectedProfilePicture !== null) {

            let data: any = {
                "profilePhoto": selectedProfilePicture.id
            };

            UserService.update(data).then((response: any) => {
                localStorage.setItem('profilePhoto', selectedProfilePicture.id);
                window.location.reload();
            }).catch((e: Error) => {
                console.log(e);
            });

        }
        setOpen(false);
    };

    return (
        <>
            <Button type="text" className='edit-button-pp' onClick={() => setOpen(true)}>
                <EditOutlined />
            </Button>
            <Modal
                title="Profile Avatar Change"
                centered
                open={open}
                onOk={() => updateProfilePicture()}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <Row gutter={[16, 16]}>
                    {avatarList}
                </Row>
            </Modal>

        </>

    );
};

export default AvatarForm;
