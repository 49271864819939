import http from "../http-common";

const getAll = (filteredDate: string = "") => {
  return http.get<any>(`/prospect-customers${filteredDate}`);
};

const get = (id: any) => {
  return http.get<any>(`/prospect-customers/${id}`);
};

const post = (data: any) => {
  return http.post<any>(`/prospect-customers`, data);
};

const getAllWithoutPagination = (filterCriteria: string = "") => {
  return http.get<Array<any>>(`/prospect-customers/all${filterCriteria}`);
};

const update = (id: any, data: any) => {
  return http.put<any>(`/prospect-customers/${id}/status`, data);
};

const getAllStatus = (query = "") => {
  return http.get<Array<any>>(`prospect-customers/statuses${query}`);
};

const updateAssigneUser = (id: any, data: any) => {
  return http.put<any>(
    `/prospect-customers/${id}/updateCallCenterMember?callCenterMemberId=${data}`
  );
};

const getTasks = (id: any) => {
  return http.get<Array<any>>(`/prospect-customers/${id}/tasks`);
};

const getNotes = (id: any) => {
  return http.get<Array<any>>(`/prospect-customers/${id}/notes`);
};

const pin = (noteId: any) => {
  return http.patch<any>(`/prospect-customers/notes/${noteId}/pin`);
};

const remove = (noteId: any) => {
  return http.delete<any>(`/prospect-customers/notes/${noteId}`);
};

const createNote = (id: number, data: any) => {
  return http.post<any>(`/prospect-customers/${id}/notes`, data);
};

const getSources = () => {
  return http.get<Array<any>>(`/prospect-customers/source`);
};

const assignProspectCustomerToSuspendStatus = (data: any) => {
  return http.put<any>(`/prospect-customers/suspend-status`, data);
};

const importExcel = (data: any) => {
  return http.post<any>(`/prospect-customers/import-excel`, data);
};

const updateContact = (id: number, data: any) => {
  return http.patch<any>(`/prospect-customers/${id}/update-contact`, data);
};

const CustomerService = {
  getAll,
  get,
  post,
  getAllWithoutPagination,
  update,
  getAllStatus,
  updateAssigneUser,
  getTasks,
  getNotes,
  pin,
  remove,
  createNote,
  getSources,
  assignProspectCustomerToSuspendStatus,
  importExcel,
  updateContact
};

export default CustomerService;
