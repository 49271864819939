import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Col,
  Row,
  Typography,
  Switch,
} from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import "./profile.css";
import { motion } from "framer-motion";
import AvatarForm from "../components/AvatarForm";
import UserService from "../services/UserService";
import { IUserResponseData } from "../types/User";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// import { ColumnsType } from 'antd/es/table';
import PasswordChangeForm from "../components/Profile/PasswordChangeForm";

let avatarSrc = require(`../assets/animojis/Number=1.png`);

// interface DataType {
//   key: string;
//   name: string;
//   registeredAt: string;
//   earnings: string;
// }

// const columns: ColumnsType<DataType> = [
//   {
//     title: 'Customer Name',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Registered At',
//     dataIndex: 'registeredAt',
//     key: 'registeredAt',
//   },
//   {
//     title: 'Earnings',
//     dataIndex: 'earnings',
//     render: (_, { earnings }) => (
//       <>
//         <NavLink to={'fba/detail'}>
//           <p style={{ margin: "0", color: "#43D764" }}>$ {earnings}</p>
//         </NavLink>
//       </>
//     ),
//     key: 'earnings',
//   },
// ];

// const data: DataType[] = [
//   {
//     key: '1',
//     name: 'Oğuz Atay',
//     registeredAt: "09/11/2023 16:54",
//     earnings: '10.78',
//   },
//   {
//     key: '2',
//     name: 'Anakin Skywalker',
//     registeredAt: "09/11/2023 16:54",
//     earnings: '5.83',
//   },
//   {
//     key: '3',
//     name: 'Nazım Hikmet',
//     registeredAt: "09/11/2023 16:54",
//     earnings: '120.27',
//   },
//   {
//     key: '2',
//     name: 'Obi-wan Kenobi',
//     registeredAt: "09/11/2023 16:54",
//     earnings: '5.83',
//   },
//   {
//     key: '3',
//     name: 'Nazım Hikmet',
//     registeredAt: "09/11/2023 16:54",
//     earnings: '120.27',
//   },
// ];

const { Title } = Typography;

const darkMode = !!localStorage.getItem("darkMode");

const handleDarkMode = () => {
  const dm = String(!!localStorage.getItem("darkMode"));
  if (dm === "false") {
    localStorage.setItem("darkMode", "true");
  } else {
    localStorage.removeItem("darkMode");
  }

  window.location.reload();
};

export const Profile = () => {
  const [user, setUser] = useState<IUserResponseData>();

  useEffect(() => {
    let profilePhoto = localStorage.getItem("profilePhoto");

    if (localStorage.getItem("profilePhoto") !== null) {
      avatarSrc = require(`../assets/animojis/Number=${profilePhoto}.png`);
    }

    getUser();
  }, []);

  const getUser = () => {
    UserService.getMe()
      .then((response: any) => {
        let data: IUserResponseData = response.data;
        console.log(data);
        setUser(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              Account Overview
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Profile",
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Badge className="profile-avatar">
              <Avatar shape="square" size={180} src={avatarSrc} />
              <AvatarForm></AvatarForm>
            </Badge>
          </Col>
          <Col>
            <Title style={{ marginTop: "40px" }} level={4}>
              {user?.firstName} {user?.lastName}
            </Title>
            <Row style={{ marginBottom: "10px" }} gutter={[10, 10]}>
              <Col>
                <Button type="text" block>
                  {user?.roles.map((role, index) => (
                    <div key={index}>
                      <UserOutlined /> {role.description}
                    </div>
                  ))}
                </Button>
              </Col>
              <Col>
                <Button type="text" block>
                  <MailOutlined /> {user?.username}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "3px" }}>
                    <Switch checked={darkMode} onChange={handleDarkMode} />
                  </span>
                  <span>Dark Mode</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <h2 style={{ padding: "0 0 0 1em", marginBottom: "0" }}>
            Change my password
          </h2>
          <PasswordChangeForm></PasswordChangeForm>
        </Row>
        {/* <Card style={{ borderTop: "none", borderLeft: "none" }}>
          <Row>
            <Col span={16}>
              <Row style={{ border: "1px solid #E8E8E8", borderRight: "none", padding: "2em" }} justify={'space-between'} align={'middle'}>
                <h2 style={{ margin: 0, fontSize: "2em" }}>Earnings</h2>
              </Row>
              <ProfileChart></ProfileChart>
              <div style={{
                margin: "0 2em",
                height: "56%",
                width: "100%",
                background: "linear-gradient(180deg, rgba(134,164,200,0.08) 0%, rgba(134,164,200,0.3) 100%)",
                position: "absolute",
                zIndex: 0,
                top: "365px",
              }} />
              <Row>
                <Col style={{ margin: "2em 0 0 0" }} span={16}>
                  <Row justify={'space-around'} style={{ padding: "2em" }}>
                    <Col span={6}>
                      <Card>
                        <Row justify={'center'}>
                          <Avatar style={{ backgroundColor: 'rgba(134,164,200,0.2)', color: "#86A4C8", margin: "0 0 0.5em 0" }} size={100} icon={<UsergroupAddOutlined />}></Avatar>
                        </Row>
                        <Row>
                          <h3 style={{ margin: 0, fontSize: "1.5em", lineHeight: "0.5em" }}>Learners</h3>
                          <p style={{ margin: 0, fontSize: "1em" }}>Last Week</p>
                        </Row>
                        <Row>
                          <p style={{ margin: "0.5em 0 0 0", fontSize: "1.6em", color: "#43D764" }}>+ 810</p>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card>
                        <Row justify={'center'}>
                          <Avatar style={{ backgroundColor: 'rgba(134,164,200,0.2)', color: "#86A4C8", margin: "0 0 0.5em 0" }} size={100} icon={<UserSwitchOutlined />}></Avatar>
                        </Row>
                        <Row>
                          <h3 style={{ margin: 0, fontSize: "1.5em", lineHeight: "0.5em" }}>Customers</h3>
                          <p style={{ margin: 0, fontSize: "1em" }}>Last Week</p>
                        </Row>
                        <Row>
                          <p style={{ margin: "0.5em 0 0 0", fontSize: "1.6em", color: "#43D764" }}>+ 52</p>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card>
                        <Row justify={'center'}>
                          <Avatar style={{ backgroundColor: 'rgba(134,164,200,0.2)', color: "#86A4C8", margin: "0 0 0.5em 0" }} size={100} icon={<DollarOutlined />}></Avatar>
                        </Row>
                        <Row>
                          <h3 style={{ margin: 0, fontSize: "1.5em", lineHeight: "0.5em" }}>Earnings</h3>
                          <p style={{ margin: 0, fontSize: "1em" }}>Last Week</p>
                        </Row>
                        <Row>
                          <p style={{ margin: "0.5em 0 0 0", fontSize: "1.6em", color: "#43D764" }}>+ $165</p>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col style={{ margin: "1em 0 0 0" }} span={8}>
                  <Row justify={'center'}>
                    <Col span={18}>
                      <h2 style={{ margin: "0.3em 0 0.3em 0" }}>Learners</h2>
                      <Card style={{ cursor: "pointer" }}>
                        <Row>
                          <Col span={12}>
                            <p style={{ margin: 0, fontSize: "1em" }}>Last Week</p>
                            <p style={{ margin: "0 0 0 0", fontSize: "1em", color: "#43D764" }}><b>+ 810</b></p>
                          </Col>
                          <Col span={12}>
                            <Image preview={false} src={GraphImage}></Image>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row justify={'center'}>
                    <Col span={18}>
                      <h2 style={{ margin: "0.em 0 0.3em 0" }}>Customers</h2>
                      <Card style={{ cursor: "pointer" }}>
                        <Row>
                          <Col span={12}>
                            <p style={{ margin: 0, fontSize: "1em" }}>Last Week</p>
                            <p style={{ margin: "0 0 0 0", fontSize: "1em", color: "#43D764" }}><b>+ 52</b></p>
                          </Col>
                          <Col span={12}>
                            <Image preview={false} src={GraphImage}></Image>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Card className='profile-card' style={{ paddingLeft: "2em" }} >
                <Row align={"top"} justify={"space-between"}>
                  <h2 style={{ fontWeight: 400, fontSize: "2em", marginTop: "0px" }}>Total Earnings</h2>
                  <Segmented options={['Week', 'Month', 'Year']}></Segmented>
                </Row>
                <h2 style={{ fontSize: "5em", margin: 0 }}>$3.750</h2>
                <h2 style={{ marginTop: "5em", fontSize: "2em", fontWeight: 400 }}>Transaction History</h2>
                <Row>
                  <Table className='profile-table' pagination={false} scroll={{ x: "400px", y: "400" }} columns={columns} dataSource={data} />
                </Row>
              </Card>
            </Col>
          </Row>
        </Card> */}
      </div>
    </motion.div>
  );
};
