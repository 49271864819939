import {
  Breadcrumb,
  Col,
  Pagination,
  Row,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import type { PaginationProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import UserDetail from "./UserDetail";
import { NavLink, useLocation } from "react-router-dom";
import SettingsRouter from "./SettingsRouter";
import { UserListFilterField } from "./UserListFilterField";

const { Title } = Typography;

interface User {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  salesTarget: number;
  gsmPortalEmployeeId: string;
  roles: {
    id: number;
    name: string;
    description: string;
    permissions: {
      id: number;
      name: string;
      description: string;
    }[];
  }[];
  profilePhoto: string;
  active: boolean;
  autoAssignment: boolean;
}

export const UserList: React.FC<any> = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [filterText, setFilterText] = useState<string>(
    `page=${lastCurrentPage}&size=${lastPageSize}`
  );
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, setFilterOptions] = useState([]);
  const roleName = localStorage.getItem("roleName");

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const roleColors: { [key: string]: string } = {
    ADMIN: "red",
    ACCOUNT_MANAGER: "blue",
    ACCOUNT_MANAGER_MANAGER: "blue",
    HI5_ACM_MANAGER: "cyan",
    HI5_ACM_MEMBER: "cyan",
    SUCCESS_TEAM: "gold",
    SUCCESS_TEAM_MANAGER: "gold",
    SUCCESS_TEAM_REPORTER: "geekblue",
    CALL_TEAM: "purple",
    CALL_TEAM_MANAGER: "purple",
    SUSPEND_TEAM: "magenta",
    SUSPEND_TEAM_MANAGER: "magenta",
    MAS_TEAM_MEMBER: "green",
    MAS_TEAM_MANAGER: "green",
  };

  const columns: ColumnsType<User> = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ellipsis: true,
      width: 250,
      sorter: true,
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      ellipsis: true,
      width: 150,
      sorter: true,
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      ellipsis: true,
      width: 150,
      sorter: true,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "action",
      ellipsis: true,
      width: 200,
      sorter: true,
      render: (_, row) => (
        <>
          {row.roles.map((role: any, index: any) => (
            <Tag key={index} color={roleColors[role.name] || "geekblue"}>
              {role.description}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Auto Assign",
      dataIndex: "autoAssignment",
      key: "autoAssignment",
      width: 150,
      align: "center",
      sorter: true,
      render: (value, row) => (
        <>
          {row.id !== undefined && (
            <Switch
              checked={value}
              onChange={() => handleAutoAssignmentChange(row.id!, value)}
            />
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "action",
      width: 100,
      align: "center",
      sorter: true,
      render: (value, row) => (
        <>
          {row.id !== undefined && (
            <Switch
              checked={value}
              onChange={() => handleChange(row.id!, value)}
            />
          )}
        </>
      ),
    },
    {
      title: "Show",
      dataIndex: "Show",
      key: "action",
      width: 100,
      align: "center",
      render: (_, row) => <UserDetail id={row.id} data={row} />,
    },
  ];

  const getUsersWithPagination = () => {
    setLoading(true);
    UserService.getAllWithPagination(`${filterText}`, roleName)
      .then((response: any) => {
        setUsers(response[0].data);
        setTotalUserCount(response[0].totalElements);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  useEffect(() => {
    getUsersWithPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, roleName, sortField, sortOrder]);

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";
    const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);
    let newFilterText = filterText;
    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${field}&direction=${direction}`
      );
    } else if (field && direction) {
      newFilterText = `${newFilterText}&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  const handleAutoAssignmentChange = async (id: number, status: boolean) => {
    const res = await UserService.updateAutoAssignmentUserById({
      userId: id,
      autoAssignment: !status,
    });
    if (res.status === 200) {
      getUsersWithPagination();
    }
  };

  const handleChange = async (id: number, status: boolean) => {
    const res = await UserService.updateStatusUserById({
      userId: id,
      active: !status,
    });
    if (res.status === 200) {
      getUsersWithPagination();
    }
  };

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row align={"middle"} justify={"space-between"}>
          <Col span={12}>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Users",
                },
              ]}
            />
          </Col>
          <Col span={12}>
          <UserListFilterField
            setFilterText={setFilterText}
            pageSize={pageSize}
            setFormFields={setFormFields}
            formFields={formFields}
            setFilterOptions={setFilterOptions}
          />
          </Col>
        </Row>
        <SettingsRouter />
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              rowKey={"id"}
              columns={columns}
              dataSource={users}
              key={"id"}
              loading={loading}
              scroll={{ x: 1000 }}
              pagination={false}
              onChange={handleTableChange}
            />
            <Row style={{ marginTop: "30px" }} justify={"center"}>
              <Pagination
                showSizeChanger={true}
                total={totalUserCount}
                defaultPageSize={pageSize}
                defaultCurrent={0}
                current={currentPage + 1}
                showQuickJumper
                onChange={paginationOnChange}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </motion.div>
  );
};
