import React, { useState, useEffect } from 'react';
import { Button, Upload, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import AmazonSuspendedApplicantService from '../../services/AmazonSuspendedApplicantService';
import { openNotification } from '../../utils';

interface ExcelData {
  statusName: string;
  inputName: string;
  serverIp: string;
  serverPassword: string;
  fullName: string;
  email: string;
  phone: string | number;
  member: string;
  suspendTypeName: string;
  note: string;
}

interface ImportExcelProps {
  handleUploadExcelData: () => void;
}

const ImportExcel: React.FC<ImportExcelProps> = ({ handleUploadExcelData }) => {
  const [excelData, setExcelData] = useState<ExcelData[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const handleFileUpload = (info: any) => {
    const file = info.fileList[0].originFileObj;
    if (!(file instanceof Blob)) {
      console.error('The uploaded file is not a valid Blob object.');
      return;
    }

    setUploadedFileName(info.fileList[0].name);

    const reader = new FileReader();
    reader.onload = e => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet);

      const formattedData: ExcelData[] = jsonData.map(row => ({
        statusName: row['STATUS'] || '',
        inputName: row['INPUT'] || '',
        serverIp: row['RABISU / BOGAHOST SERVER IP'] || '',
        serverPassword: row['RABISU / BOGAHOST SERVER ŞİFRE'] || '',
        fullName: row['ADI-SOYADI'] || '',
        email: row['E-MAIL'] || '',
        phone: row['TELEFON'] || '',
        member: row['SUSPEND TEAM MEMBER'] || '',
        suspendTypeName: row['SUSPEND/İHLAL TÜRÜ'] || '',
        note: row['NOTES'] || ''
      }));
      setExcelData(formattedData);
    };

    reader.readAsArrayBuffer(file);
    setFileList(info.fileList);
  };

  useEffect(() => {
    if (excelData.length > 0) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excelData]);

  const handleSubmit = () => {
      if (excelData.length === 0) {
        openNotification('error', 'Error', 'The Excel file is empty or not uploaded');
        return;
      }
      AmazonSuspendedApplicantService.importExcel(excelData)
        .then((response) => {
          if(response.status === 200) {
          openNotification('success', 'Success', `The file "${uploadedFileName}" was uploaded successfully`);
          setFileList([]);
          setUploadedFileName(null);
          handleUploadExcelData();
          }
        })
        .catch((error) => {
          if(error.response.status !== 404) {
            openNotification('error', 'Error', 'An error occurred while uploading the Excel file');
          }
          console.error('Error:', error);
        });
  };

  return (
    <div>
      <Upload
        beforeUpload={() => false}
        fileList={fileList}
        onChange={handleFileUpload}
        maxCount={1}
        accept=".xlsx, .xls"
      >
        <Button type="primary" icon={<UploadOutlined />} style={{ marginRight: 10 }}>Import</Button>
      </Upload>
    </div>
  );
};

export default ImportExcel;
