import React, { useEffect, useState } from "react";
import { CopyOutlined, PhoneOutlined } from "@ant-design/icons";
import { openNotification } from "../../utils";
import LearnerService from "../../services/LearnerService";
import CopyToClipboard from "react-copy-to-clipboard";
import { Avatar, Form, Input, Popconfirm, Tooltip } from "antd";

interface CopyClipboardProps {
  text: string;
}

const { Item } = Form;

export const StartCall = ({ text }: CopyClipboardProps) => {
  const [form] = Form.useForm();
  const [gsmPortalEmployeeId, setGsmPortalEmployeeId] = useState("");
  const [message, setMessage] = useState("Calling");

  useEffect(() => {
    let netGsmId = localStorage.getItem("gsmPortalEmployeeId");
    if (netGsmId != null && netGsmId !== "") {
      setGsmPortalEmployeeId(netGsmId);
    }
  }, []);

  const handleCopyClick = (text: string) => {
    openNotification("success", "Copied to clipboard", text);
  };

  const handeStartCall = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        openNotification("success", "Calling", values.phoneNumber, 5);
        LearnerService.learnerCall(gsmPortalEmployeeId, values.phoneNumber)
          .then((response: any) => {
            if (response.data.status === "Success") {
              openNotification(
                "success",
                response.data.message,
                values.phoneNumber,
                5
              );
            } else {
              openNotification(
                "error",
                response.data.message,
                values.phoneNumber,
                5
              );
            }
          })
          .catch((e: Error) => {});
      }
    } catch (error) {
      openNotification(
        "error",
        "Start a call",
        "Phone number must comply with the format"
      );
    }
  };

  const callForm = () => {
    if (
      gsmPortalEmployeeId !== "" &&
      gsmPortalEmployeeId !== null &&
      !isNaN(Number(gsmPortalEmployeeId)) &&
      gsmPortalEmployeeId !== "null" &&
      gsmPortalEmployeeId
    ) {
      return (
        <Popconfirm
          title="Customer the call"
          description={
            <Form form={form} layout="vertical">
              Are you sure you want to call the customer?
              <Item
                style={{ marginTop: "10px" }}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Phone number must be only digits!",
                  },
                  {
                    len: 11,
                    message: "Phone number must be 11 digits long!",
                  },
                ]}
              >
                <Input />
              </Item>
            </Form>
          }
          okText={
            <>
              <PhoneOutlined /> Call
            </>
          }
          cancelText="No"
          onConfirm={handeStartCall}
        >
          {/* <Avatar style={{ marginRight: '10px', backgroundColor: '#87d068', cursor: "pointer" }} icon={<PhoneOutlined />} /> */}
          <PhoneOutlined
            style={{
              marginRight: "10px",
              backgroundColor: "#87d068",
              color: "#fff",
              padding: "4px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
        </Popconfirm>
      );
    }

    return (
      <Tooltip
        placement="top"
        title={"Arama yapmak için yöneticiniz ile iletişime geçiniz"}
      >
        {/* <Avatar
          style={{
            marginRight: "10px",
            backgroundColor: "#87d068",
            cursor: "pointer",
          }}
          icon={<PhoneOutlined />}
        /> */}
        <PhoneOutlined
            style={{
              marginRight: "10px",
              backgroundColor: "#87d068",
              color: "#fff",
              padding: "4px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
      </Tooltip>
    );
  };

  if (text === null || text === "") {
    return <></>;
  }

  return (
    <>
      <CopyToClipboard text={text}>
        {/* <Avatar style={{ marginRight: '10px', cursor: "pointer" }} onClick={() => handleCopyClick(text)} icon={<CopyOutlined />} /> */}
        <CopyOutlined
          onClick={() => handleCopyClick(text)}
          style={{ marginLeft: "5px", marginRight: "10px" }}
        />
      </CopyToClipboard>
      {callForm()}
    </>
  );
};
