import {
  Card,
  Row,
  Table,
  Button,
  Modal,
  Pagination,
  Select
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { UploadOutlined } from "@ant-design/icons";
// import '../pages/customers.css';
import { useEffect, useState } from "react";
import { CopyClipboard } from "../../General/CopyClipboard";
import { StartCall } from "../../General/StartCall";
import {
  can,
  convertDate,
  openNotification,
} from "../../../utils";
import CustomerService from "../../../services/CustomerService";
import { useLocation, useNavigate } from "react-router-dom";
import type { PaginationProps } from "antd";
import { FilterField } from "./FilterField";
import DownloadExcel from "../../CustomerDetail/DownloadExcel";
import { ICustomerStatusResponseData } from "../../../types/CustomerStatus";
import UserService from "../../../services/UserService";
import goldUser from "./../../../assets/goldUser.svg";
import CallTeamService from "../../../services/CallTeamService";
import NewTask from "../../UserDetail/Tasks/NewTask";
import StatusService from "../../../services/StatusService";

interface User {
  id: number;
  createdDate: string;
  fullName: string;
  email: string;
  phone: string;
  currentPackage: number;
  status: any;
  activityStatus: string;
  state: string;
  passiveDays: number;
  productCount: number;
  orderCount: number;
  oneamzOrderCount: number;
  freeDays: number;
  lastSalesDate: string;
  sellerLevel: string;
  feedback: number;
  username: string;
  goldUser: boolean;
  lastLoginDate: string;
  subscriptionStartDate: string;
}

export const CtmCustomerList: React.FC<any> = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const roleName = localStorage.getItem("roleName");
  const authUserId = localStorage.getItem("id");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<
    ICustomerStatusResponseData[]
  >([]);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [filterText, setFilterText] = useState<string>(
    `?page=${lastCurrentPage}&size=${lastPageSize}&state=Active&callCenterMember=${authUserId}`
  );
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  // const [data, setData] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  // const isHomePage = location.pathname === "/";
  const [filterOptions, setFilterOptions] = useState([]);
  // const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getCustomerStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    // newFilterText = newFilterText.replace(/(fullName=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);
    if (search) {
      params.set("nameOrEmailOrPhone", search);
    } else {
      params.delete("nameOrEmailOrPhone");
    }

    const newFilterText = "?" + params.toString();

    setFilterText(newFilterText);
  };

  const getCustomerStatus = () => {
    StatusService.getAll("statusType=CALL_CENTER_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeStatus = (status: any, id: any) => {
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      setIsLoading(true);
      CallTeamService.updateCustomerStatus(id, { callCenterStatusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed assigne user");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      CustomerService.update(id, { statusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed status");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getUsers = () => {
    setIsLoading(true);
    if (can("learners:getAll")) {
      if (roleName === "ACCOUNT_MANAGER_MANAGER") {
        CustomerService.getAll(`${filterText}`)
          .then((response: any) => {
            setUsers(response.data.data);
            setTotalCustomerCount(response.data.totalElements);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
        UserService.getAll("?roleName=ACCOUNT_MANAGER")
          .then((response: any) => {
            let data = response;

            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });

            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else if (roleName === "ACCOUNT_MANAGER") {
        // CustomerService.getAll(`?${filterText}`)
        CustomerService.getAllMe(`${filterText}`)
          // CustomerService.getAllMe(`?statusId=2`)
          .then((response: any) => {
            setUsers(response.data.data);
            setTotalCustomerCount(response.data.totalElements);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      } else {
        CustomerService.getAll(`${filterText}`)
          .then((response: any) => {
            setUsers(response.data.data);
            setTotalCustomerCount(response.data.totalElements);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
        UserService.getAll("?roleName=ACCOUNT_MANAGER")
          .then((response: any) => {
            let data = response;

            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });

            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const columns: ColumnsType<User> = [
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      fixed: "left",
      sorter: true,
      width: 100,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {/* {text} */}
          ACM
        </p>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      sorter: true,
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    {
      title: "Username",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      sorter: true,
      width: 240,
      render: (
        text: string,
        record: {
          phone: string;
          goldUser: boolean;
          email: string;
        }
      ) => (
        <>
          {record.goldUser ? <img src={goldUser} alt="gold-icon" /> : null}
          <div>{text} </div>
          {/* <span><CopyClipboard text={record.email}></CopyClipboard> </span>
                    <br />
                    <span><CopyClipboard text={record.phone}></CopyClipboard> </span> */}
        </>
      ),
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      render: (text: string) => (
        <span>
          {text}
          <StartCall text={text} />
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 240,
      render: (text: string) => <CopyClipboard text={text}></CopyClipboard>,
    },
    ...(roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM"
      ? [
          {
            title: "Status",
            dataIndex: "callCenterStatus1",
            key: "callCenterStatus1",
            // sorter: true,
            width: 200,
            render: (_: any, row: any) => {
              return (
                <Select
                  onChange={(selectedStatus) =>
                    handleChangeStatus(selectedStatus, row.id)
                  }
                  options={customerStatusOptions}
                  showSearch
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  key={row.id}
                  {...(row?.callCenterStatus1?.id && {
                    defaultValue: row.callCenterStatus1.id,
                  })}
                  placeholder={"Select"}
                  style={{ width: "180px" }}
                ></Select>
              );
            },
          },
        ]
      : []),
    ...(roleName !== "ACCOUNT_MANAGER"
      ? [
          {
            title: "Account Manager",
            dataIndex: "username",
            key: "username",
            // sorter: true,
            width: 200,
            render: (_: any, row: any) => {
              if (
                roleName === "SUCCESS_TEAM_REPORTER" ||
                roleName === "CALL_TEAM" ||
                roleName === "CALL_TEAM_MANAGER"
              ) {
                return row.user?.username ? (
                  <span>
                    {" "}
                    {row.user?.firstName} {row.user?.lastName} <br />
                  </span>
                ) : null;
              } else {
                return (
                  <Select
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(selectedUser) =>
                      handeChangeAssigneUser(selectedUser, row.id)
                    }
                    options={userOptions}
                    key={row.id}
                    {...(row?.user?.id && { defaultValue: row.user.id })}
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  ></Select>
                );
              }
            },
          },
        ]
      : []),
    // roleName === 'ACCOUNT_MANAGER_MANAGER' ?
    {
      title: "Last Call Date",
      dataIndex: "lastCallDate",
      key: "lastCallDate",
      sorter: true,
      width: 120,
      render: () => {
        return <span>-</span>;
      },
    },

    // roleName === 'ACCOUNT_MANAGER_MANAGER' ?
    ...(roleName !== "SUCCESS_TEAM_REPORTER"
      ? [
          {
            title: "Detail",
            key: "detail",
            align: "center" as const,
            width: 100,
            fixed: "right" as const,
            render: (_: any, record: any) => (
              <Row justify="center" align="middle" gutter={[0, 8]}>
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(`/users/detail/${record.id}`, {
                      state: {
                        currentPage: currentPage,
                        pageSize: pageSize,
                        formFields: formFields,
                        filterText: filterText,
                      },
                    })
                  }
                >
                  Detail
                </Button>
                {roleName !== "SUCCESS_TEAM_REPORTER" ? (
                    <NewTask
                    customerName={record?.fullName}
                    customerId={record?.id}
                    setTriggerUpdate={() => {}}
                    isCustomer={record?.learner === null}
                    userOptions={userOptions}
                  />
                ) : null}                
              </Row>
            ),
          },
        ]
      : []),
  ];

  const handeChangeAssigneUser = (user: any, id: any) => {
    let data: any = {
      userId: user,
    };
    setIsLoading(true);
    CustomerService.updateAssigneUser(id, data)
      .then((response: any) => {
        openNotification("success", "Success", "Changed assigne user");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });;
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";
    const newFilterText = `?page=${currentPage}&size=${pageSize}&state=Active&callCenterMember=${authUserId}${
      field && order ? `&sort=${field}&direction=${direction}` : ""
    }`;
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";

    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }

    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  // useEffect(() => {
  //     ReportService.customerEarningReport(``)
  //         .then((response) => {
  //             const combinedData: any[] = [];
  //             response.data.forEach((item: any) => {
  //                 const matchedItem = authUserId == item.userId;
  //                 if (matchedItem) {
  //                     combinedData.push({
  //                         ...item,
  //                         user: matchedItem ? matchedItem : null,
  //                     });
  //                 }
  //             });

  //             setData(combinedData);
  //         })
  // }, [])

  // const earningDataSource = data.map((item: any, index: any) => ({
  //     key: index,
  //     oneamzOrder: item.oneamzOrder,
  //     packageChangedIn30Days: item.packageChangedIn30Days,
  //     packageChangedIn60Days: item.packageChangedIn60Days,
  //     packageChangedIn90Days: item.packageChangedIn90Days,
  //     packageUpgrade: item.packageUpgrade,
  //     totalCustomer: item.totalCustomer,
  // }));
  // const earningColumns: ColumnsType<any> = [

  //     {
  //         title: 'Total Customer',
  //         dataIndex: 'totalCustomer',
  //         key: 'totalCustomer',
  //         align: 'center',
  //         width: 100,
  //     },
  //     {
  //         title: 'Product Count Commission',
  //         dataIndex: 'packageUpgrade',
  //         key: 'packageUpgrade',
  //         width: 100,
  //         align: 'center',
  //         render: (text: number) =>
  //             <p >
  //                 {formatMoney(text)}
  //             </p>,
  //     },
  //     {
  //         title: '30 Days First Sale Comission',
  //         dataIndex: 'packageChangedIn30Days',
  //         key: 'packageChangedIn30Days',
  //         align: 'center',
  //         render: (text: number) =>
  //             <p >
  //                 {formatMoney(text)}
  //             </p>,
  //         width: 100,
  //     },
  //     {
  //         title: '60 Days First Sale Comission',
  //         dataIndex: 'packageChangedIn60Days',
  //         key: 'packageChangedIn60Days',
  //         align: 'center',
  //         render: (text: number) =>
  //             <p >
  //                 {formatMoney(text)}
  //             </p>,
  //         width: 100,
  //     },
  //     {
  //         title: '90 Days First Sale Comission',
  //         dataIndex: 'packageChangedIn90Days',
  //         key: 'packageChangedIn9Days',
  //         align: 'center',
  //         render: (text: number) =>
  //             <p >
  //                 {formatMoney(text)}
  //             </p>,
  //         width: 100,
  //     },
  //     {
  //         title: 'OneAmz Order',
  //         dataIndex: 'oneamzOrder',
  //         key: 'oneamzOrder',
  //         align: 'center',
  //         render: (text: number) =>
  //             <p >
  //                 {formatMoney(text ? text * 0.5 : 0)}
  //             </p>,
  //         width: 100,
  //     },

  //     {
  //         title: 'Total Earnings',
  //         dataIndex: 'totalEarnings',
  //         key: 'totalEarnings',
  //         align: 'center',
  //         render: (text: any, record: any) => {
  //             const productCountCommission = record.packageUpgrade;
  //             const firstSaleCommission30Days = record.packageChangedIn30Days ?? 0;
  //             const firstSaleCommission60Days = record.packageChangedIn60Days ?? 0;
  //             const firstSaleCommission90Days = record.packageChangedIn90Days ?? 0;
  //             const oneAmzOrderCommission = record.oneamzOrder ? record.oneamzOrder * 0.5 : 0;
  //             const totalEarnings = productCountCommission + firstSaleCommission30Days + firstSaleCommission60Days + firstSaleCommission90Days + oneAmzOrderCommission;

  //             return <p> <b>{formatMoney(totalEarnings)}</b></p>;
  //         },
  //     },
  // ];

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              User List
            </div>
            <div className="customer-card-info">
              <b>{totalCustomerCount} </b>Users
            </div>
          </div>
        }
        extra={
          <Row style={{ minWidth: 400, display: "flex" }}>
            {/* {isHomePage && data[0] ? (
                            <>
                                <Button type="primary" onClick={showModal} style={{ marginRight: 16 }}>
                                    Earnings
                                </Button>
                                <Modal title="This Month Earnings" width={750} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} >
                                    <Table dataSource={earningDataSource} columns={earningColumns} pagination={false} />
                                </Modal>
                            </>
                        ) : null} */}
            {roleName === "CALL_TEAM_MANAGER" ? (
              <>
                <Button
                  type="primary"
                  onClick={showModal}
                  style={{ marginLeft: 10, marginRight: 10 }}
                  icon={<UploadOutlined />}
                >
                  Send
                </Button>
                <Modal
                  title="Veri Transferi"
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <p>
                    Count: {totalCustomerCount} <br />{" "}
                    {filterOptions.map((filter: any, index) => (
                      <span key={index}>
                        {index > 0 && ", "} <br />
                        {filter.name}:{" "}
                        {Array.isArray(filter.value)
                          ? filter.value.join(", ")
                          : filter.value}
                      </span>
                    ))}
                  </p>
                </Modal>
              </>
            ) : null}

            <FilterField
              onChangeSearch={onChangeSearch}
              setFilterText={setFilterText}
              pageSize={pageSize}
              setFormFields={setFormFields}
              formFields={formFields}
              setFilterOptions={setFilterOptions}
            />
            {/* {!isHomePage && (
                            <Button type='primary' style={{ marginRight: 16 }} onClick={() => window.open(`/suspended-customers/`, '_blank')}>
                                Suspend List
                            </Button>
                        )} */}
            <DownloadExcel filterText={filterText} />
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey="id"
          loading={isLoading}
          scroll={{ x: "100%" }}
          sticky
          columns={columns}
          dataSource={users}
          pagination={false}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};
