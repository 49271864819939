import { Card, Col, Row, Typography, Table, Button, Breadcrumb } from "antd";
import type { ColumnsType } from "antd/es/table";
import { DownloadOutlined } from "@ant-design/icons";
// import '../pages/customers.css';
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import type { PaginationProps } from "antd";
// import { FilterField } from './FilterField';
// import DownloadExcel from '../../CustomerDetail/DownloadExcel';
import React from "react";
import ReportService from "../../../services/ReportService";
import DateRange from "../../Common/DateRange";
import saveAs from "file-saver";
import * as XLSX from "xlsx";
import { ReportsRouter } from "../ReportsRouter";

const { Title } = Typography;

interface Data {
  createdDate: string;
  fullName: string;
  P5000: number;
  P10000: number;
  P25000: number;
  P50000: number;
  P100000: number;
  P150000: number;
  P200000: number;
  P250000: number;
  P500000: number;
  P750000: number;
}

export const CurrentPackage: React.FC<any> = ({}) => {
  const [filterText, setFilterText] = useState<string>(
    "?page=0&size=100&status=Active&fullName="
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(100);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  // eslint-disable-next-line
  const [columnNameMapping, setColumnNameMapping] = useState<any[]>([]);

  function downloadExcel() {
    const dataWithCustomHeaders = filteredData.map((row: any) => {
      const newRow: any = {};
      Object.keys(row).forEach((key: any) => {
        const newKey = columnNameMapping[key] || key;
        newRow[newKey] = row[key];
      });
      return newRow;
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataWithCustomHeaders);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }

    // Save the workbook to a file
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Use FileSaver to save the file
    saveAs(blob, "data.xlsx");
  }

  useEffect(() => {
    if (dateRange[0] !== undefined && dateRange[1] !== undefined) {
      getReport(dateRange[0], dateRange[1]);
    }
  }, [dateRange, filterText]);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    // newFilterText = newFilterText.replace(/(fullName=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
  }, [currentPage, pageSize]);

  useEffect(() => {}, [filterText]);

  const getReport = (startDate: any, endDate: any) => {
    setLoading(true);
    ReportService.customerPackageReport(
      `?startDate=${startDate}&endDate=${endDate}`
    )
      .then((response: any) => {
        setData(response.data);
        setFilteredData(response.data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.error(e);
        setLoading(false);
      });
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const columns: ColumnsType<Data> = [
    {
      title: "Account Manager",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      width: 230,
      render: (text: string, record: Data) => (
        <>
          <div>{text}</div>
        </>
      ),
    },
    {
      title: "5K",
      dataIndex: "P5000",
      key: "P5000",
      width: 100,
      sorter: (a, b) => a.P5000 - b.P5000,
    },
    {
      title: "10K",
      dataIndex: "P10000",
      key: "P10000",
      width: 100,
      sorter: (a, b) => a.P10000 - b.P10000,
    },
    {
      title: "25K",
      dataIndex: "P25000",
      key: "P25000",
      width: 100,
      sorter: (a, b) => a.P25000 - b.P25000,
    },
    {
      title: "50K",
      dataIndex: "P50000",
      key: "P50000",
      width: 100,
      sorter: (a, b) => a.P50000 - b.P50000,
    },
    {
      title: "100K",
      dataIndex: "P100000",
      key: "P100000",
      width: 100,
      sorter: (a, b) => a.P100000 - b.P100000,
    },
    {
      title: "150K",
      dataIndex: "P150000",
      key: "P150000",
      width: 100,
      sorter: (a, b) => a.P150000 - b.P150000,
    },
    {
      title: "200K",
      dataIndex: "P200000",
      key: "P200000",
      width: 100,
      sorter: (a, b) => a.P200000 - b.P200000,
    },
    {
      title: "250K",
      dataIndex: "P250000",
      key: "P250000",
      width: 100,
      sorter: (a, b) => a.P250000 - b.P250000,
    },
    {
      title: "500K",
      dataIndex: "P500000",
      key: "P500000",
      width: 100,
      sorter: (a, b) => a.P500000 - b.P500000,
    },
    {
      title: "750K",
      dataIndex: "P750000",
      key: "P750000",
      width: 100,
      sorter: (a, b) => a.P750000 - b.P750000,
    },
  ];

  return (
    <>
      {/* <Row justify={'space-between'} align={'middle'}>
                <h3 style={{ fontSize: 24, fontWeight: 500 }}> Choose Your Customer Status</h3>
                <Switch
                    style={{
                        backgroundColor: 'black',
                        paddingTop: 10,
                        paddingBottom: 10,
                        height: 40,
                        width: 180
                    }}
                    checked={showActive}
                    checkedChildren="Active Customer"
                    unCheckedChildren="Suspended Customer"
                    onChange={(checked) => setShowActive(checked)} 
                />
            </Row> */}
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Account Manager Reports",
              },
              {
                title: "Current Package",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />

      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Account Manager
            </div>
            <div className="customer-card-info">Current Package Report</div>
          </div>
        }
        extra={
          <Row style={{ minWidth: 400 }}>
            {/* <DownloadExcel filterText={filterText} /> */}
            {/* <FilterField onChangeSearch={onChangeSearch} setFilterText={setFilterText} pageSize={pageSize} /> */}
            <Row justify={"end"}>
              <DateRange onChangeDateRange={setDateRange} />
              <Button
                style={{ marginLeft: 16 }}
                icon={<DownloadOutlined />}
                onClick={downloadExcel}
                type="primary"
              >
                Export
              </Button>
            </Row>
          </Row>
        }
        bordered={true}
        style={{ marginTop: "30px" }}
      >
        <Table
          loading={isLoading}
          scroll={{ x: "100%" }}
          key={"id"}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
        {/* <Row style={{ marginTop: '30px' }} justify={'center'}>
                    <Pagination
                        showSizeChanger={true}
                        defaultPageSize={pageSize}
                        defaultCurrent={0}
                        showQuickJumper
                        onChange={paginationOnChange}
                    />
                </Row> */}
      </Card>
    </>
  );
};
