import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Progress, Row, Select, Table } from "antd";
import "./info-card.css";
import { convertDate, openNotification } from "../../utils";
import CallSalesPackageService from "../../services/CallSalesPackageService";
import moment from "moment";

const CustomerInfo: React.FC<any> = ({ customer }) => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.isActive };
        });

        setOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (customer) {
      getPackages();
    }
  }, [customer]);

  const createSalesPackageLog = () => {
    if (selectedValue) {
      let data = {
        callCenterSalesPackageId: selectedValue,
        prospectCustomerId: customer?.id,
      };

      CallSalesPackageService.createSalesLog(data)
        .then((response: any) => {
          openNotification("success", "Success", "Sales added");
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  return (
    <Card bordered={false} className={"card"}>
      <p className="title">Customer Info</p>
      <div className="lesson-card-container">
        <div>
          <p className="date-header">City:</p>
          <p className="date-description">{customer?.city}</p>
        </div>
      </div>
      <div className="border-bottom">
        <p className="date-header">Last Call Date:</p>
        <p className="date-description">
          {convertDate(customer?.lastCallDate)}
        </p>
      </div>
      <div className="border-bottom">
        <p className="info-header">Source:</p>
        <p className="info-description">{customer?.source?.name ?? "-"}</p>
      </div>
      <div className="border-bottom">
        <p className="info-header">Call Center Member:</p>
        <p className="info-description">
          {customer?.callCenterMember?.firstName &&
          customer?.callCenterMember?.lastName
            ? `${customer.callCenterMember.firstName} ${customer.callCenterMember.lastName}`
            : "-"}
        </p>
      </div>
      <div className="border-bottom" style={{ height: "100px", overflowY: "auto" }}>
        <p className="info-header">Message:</p>
        <p className="info-description">{customer?.message ?? "-"}</p>
      </div>
      {/* <div className='border-bottom'>
                <p className='info-header'>Call Center Status:</p>
                <p className='info-description'>{customer?.callCenterStatus ? customer?.callCenterStatus.name : '-'}</p>
            </div> */}

      {/* <div className="lesson-card-container " style={{ paddingBottom: 10 }}>
        <Select
          onChange={(selectedValue) => setSelectedValue(selectedValue)}
          options={options}
          key={customer?.id}
          // {...(customer?.status?.id && { defaultValue: customer.status.id })}
          placeholder={"Select"}
          style={{ width: "180px", marginRight: 10, marginTop: 22 }}
        ></Select>
        <Button
          onClick={createSalesPackageLog}
          type="primary"
          style={{ fontSize: 12 }}
        >
          Sale Made
        </Button>
      </div> */}
    </Card>
  );
};

export default CustomerInfo;
