import http from "../http-common";

const getReportOfInterest = (
  startDate: string,
  endDate: string,
  interestType: string
) => {
  return http.get<Array<any>>(
    `/reports/report-of-interest?startDate=${startDate}&endDate=${endDate}&interestType=${interestType}`
  );
};

const ReportOfInterestService = {
  getReportOfInterest,
};

export default ReportOfInterestService;
