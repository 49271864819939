import http from "../http-common";

const getAll = () => {
  return http.get<Array<any>>("/bonus-policies?active=true&employeeType=SUCCESS_TEAM");
};

const get = (id:any) => {
  return http.get<Array<any>>(`/bonus-policies/${id}`);
};

const update = (id:any, data:any) => {
  return http.patch<any>(`/bonus-policies/${id}`, data);
};

const BonusPoliciesService = {
  getAll,
  update,
  get
};

export default BonusPoliciesService;