import {
  Card,
  Col,
  Row,
  Typography,
  Input,
  Table,
  Button,
  Breadcrumb,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ReportService from "../../../services/ReportService";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DateRange from "../../Common/DateRange";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../ReportsRouter";

const { Title } = Typography;

export const StmAffiliateReport = () => {
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState<any>(
    dayjs().subtract(6, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(dayjs().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    }
  }, [dateRange]);

  function downloadExcel() {
    const columnNameMapping: any = {};

    const dataWithCustomHeaders = filteredData.map((row: any) => {
      const newRow: any = {};
      Object.keys(row).forEach((key) => {
        const newKey = columnNameMapping[key] || key;
        newRow[newKey] = row[key];
      });
      return newRow;
    });

    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(dataWithCustomHeaders);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }

    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    saveAs(blob, "data.xlsx");
  }

  const handleSearch = (e: any) => {
    const value = e.target.value;
    if (value === "") {
      setSearchText(value);
      setFilteredData(data);
    } else {
      setSearchText(value);
      const filtered = data.filter(
        (item: any) =>
          (item.fullname &&
            item.fullname.toLowerCase().includes(value.toLowerCase())) ||
          (item.affiliateId && item.affiliateId.toString().includes(value))
      );
      setFilteredData(filtered);
    }
  };

  const columns: ColumnsType<any> = [
    // {
    //   title: 'Success Team Members',
    //   dataIndex: 'name',
    //   key: 'action',
    //   fixed: 'left',
    //   width: 270,
    //   sorter: (a: any, b: any) => a.fullname.localeCompare(b.fullname),
    //   render: (_: any, row: any) => (
    //     <Row justify={'start'} align={'middle'} style={{ width: 270 }}>
    //       {/* <Col className='mr-15'>
    //         <Avatar style={{ background: '#87B2B2' }} size="large" >
    //           {row.firstName.charAt(0)}
    //         </Avatar>
    //       </Col> */}
    //       <Col>
    //         <Title className='customer-fullname' level={5}><b>{row.fullname}</b></Title>
    //         <span className='customer-fullname'>{row.username}</span>
    //       </Col>
    //     </Row>
    //   ),
    // },
    {
      title: "Affiliate ID",
      dataIndex: "affiliateId",
      key: "affiliateId ",
      align: "center",
      width: 100,
    },
    {
      title: "Affiliate Name",
      dataIndex: "fullname",
      key: "fullname ",
      align: "center",
      width: 100,
      render: (_: any, row: any) => {
        return (
          <>
            {row?.successTeamMemberFirstName} {row.successTeamMemberLastName}
          </>
        );
      },
    },
    {
      title: "Learners",
      dataIndex: "totalLearner",
      key: "totalLearner",
      align: "center",
      width: 100,
      sorter: (a: any, b: any) => a.totalLearner - b.totalLearner,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      align: "center",
      width: 100,
      sorter: (a: any, b: any) => a.customer - b.customer,
    },
    {
      title: "Ratio",
      dataIndex: "ratio",
      key: "ratio",
      align: "center",
      width: 100,
      sorter: (a: any, b: any) => a.ratio - b.ratio,
    },
  ];

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const getReport = () => {
    setLoading(true);
    ReportService.affiliate(`?startDate=${startDate}&endDate=${endDate}`)
      .then((response: any) => {
        const updatedData = response.data.map((item: any) => ({
          ...item,
          ratio: `%${Math.round((item.customer / item.totalLearner) * 100)}`,
          // eslint-disable-next-line
          fullname:
          // eslint-disable-next-line
            item.affiliateId == 197150
              ? "Özlem Açıkalın"
              // eslint-disable-next-line
              : item.affiliateId == 177908
              ? "Enis Akdeniz"
              : "",
          // stm: item.affiliateId == 82769 ? 'Özlem Açıkalın' : '',
        }));
        setData(updatedData);
        setFilteredData(updatedData);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Success Team Reports",
              },
              {
                title: "Affiliate",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />

      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Success Team
              </div>
              <div className="customer-card-info">Affiliate</div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        bordered={true}
        extra={
          <>
            <Row justify={"end"}>
              {" "}
              <DateRange onChangeDateRange={setDateRange} />
              <Button
                icon={<DownloadOutlined />}
                style={{ marginLeft: 16 }}
                size="large"
                onClick={downloadExcel}
                type="primary"
              >
                Export
              </Button>
            </Row>
          </>
        }
      >
        <Table
          loading={loading}
          key={"id"}
          sticky
          scroll={{ x: "100%" }}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
        />
      </Card>
    </>
  );
};
