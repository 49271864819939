import {
  Badge,
  Card,
  Col,
  DatePicker,
  Row,
  Space,
  TimeRangePickerProps,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./call-statistics.css";
import ReactApexChart from "react-apexcharts";
import PhoneCallService from "../../services/PhoneCallService";
import { can } from "../../utils";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";

interface CallStatisticsProps {
  dateRange: any;
}

export const CallStatistics: React.FC<CallStatisticsProps> = ({
  dateRange,
}) => {
  const { t } = useTranslation();
  const [total, setTotal] = useState<number>(0);
  const [reached, setReached] = useState<number>(0);
  const [notReached, setNotReached] = useState<number>(0);
  const [reachedRatio, setReachedRatio] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#86A4C8",
        },
      },
    },
    labels: ["Answered"],
    colors: ["#C88686"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (dateRange.length > 0) {
      if (can("learners:getAll")) {
        setLoading(true);
        PhoneCallService.learnerPhoneCallHistoryStatistics(
          `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`
        )
          .then((response: any) => {
            setTotal(response?.data.totalCalls);
            setNotReached(response?.data.missed);
            setReached(response?.data.answered);
            let ratio: any = (
              (response?.data.answered / response?.data.totalCalls) *
              100
            ).toFixed(0);
            if (!isNaN(ratio)) {
              setReachedRatio(ratio);
            } else {
              setReachedRatio(0);
            }
            setLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setLoading(false);
          });
      } else {
        setLoading(true);
        PhoneCallService.learnerPhoneCallHistoryStatisticsByUser(
          `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`
        )
          .then((response: any) => {
            setTotal(response?.data.totalCalls);
            setNotReached(response?.data.missed);
            setReached(response?.data.answered);
            let ratio: any = (
              (response?.data.answered / response?.data.totalCalls) *
              100
            ).toFixed(0);
            if (!isNaN(ratio)) {
              setReachedRatio(ratio);
            } else {
              setReachedRatio(0);
            }
            setLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setLoading(false);
          });
      }
    }
  }, [dateRange]);

  return (
    <>
      <Card
        className="call-statistics earnings"
        title="Total Calls"
        loading={loading}
      >
        <Row>
          <Col span={14}>
            <ReactApexChart
              options={options}
              series={[reachedRatio]}
              type="radialBar"
              height={210}
            />
          </Col>
          <Col span={10}>
            <Row align="middle" style={{ height: "200px" }}>
              <Space direction="vertical">
                <Badge
                  color="gray"
                  text={
                    <>
                      Total: <b>{total}</b>
                    </>
                  }
                />
                <Badge
                  color="rgb(200, 134, 134)"
                  text={
                    <>
                      Answered: <b>{reached}</b>
                    </>
                  }
                />
                <Badge
                  color="rgb(134, 164, 200"
                  text={
                    <>
                      Missed: <b>{notReached}</b>
                    </>
                  }
                />
              </Space>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};
