import http from "../http-common";

const getAll = () => {
  return http.get<Array<any>>(`/amazon-suspended-applicant/source`);
};

// const get = (id: any) => {
//   return http.get<Array<any>>(`/amazon-suspended-applicant/source/${id}`);
// };

// const create = (data: any) => {
//   return http.post<any>("/amazon-suspended-applicant/source", data);
// };

// const update = (id: any, data: any) => {
//   return http.put<any>(`/amazon-suspended-applicant/source/${id}`, data);
// };

// const remove = (id: any) => {
//   return http.delete<any>(`/amazon-suspended-applicant/source/${id}`);
// };

const AmazonSuspendedApplicantSourcesService = {
  getAll,
  // get,
  // create,
  // update,
  // remove,
};

export default AmazonSuspendedApplicantSourcesService;
