import { Avatar, Button, Card, Col, Row, Segmented, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ILearnerResponseData } from '../../types/Learner'
import { can, convertDate } from '../../utils';
import LearnerService from '../../services/LearnerService';
import Table, { ColumnsType } from 'antd/es/table';
import { CopyClipboard } from '../General/CopyClipboard';
import LearnerDetail from '../../pages/LearnerDetail';
import Avatar1Icon from "../../assets/avatar1.svg";
import { StartCall } from '../General/StartCall';
import { useNavigate } from 'react-router-dom';


const { Title } = Typography;

interface LearnerFilterProps {
    startDate: string;
    endDate: string;
}

const EducationStatistics: React.FC<LearnerFilterProps> = ({ startDate, endDate }) => {


    const [learnerList, setLearnerList] = useState<ILearnerResponseData[]>([]);
    const [segment, setSegment] = useState<string | number>('Not Logged In');
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();


    const handeLearnerList = () => {
    };

    const currentUser: any = {
        canLearnersGetAll: can("learners:getAll")
    };

    const columns: ColumnsType<ILearnerResponseData> = [
        {
            title: 'Learner',
            dataIndex: 'firstName',
            key: 'action',
            render: (_, row) => (
                <Row justify={'start'} align={'middle'}>
                    <Col>
                        <Title className='customer-fullname' level={5}><b>{row.firstName} {row.lastName}</b></Title>
                        <span className='customer-fullname'><CopyClipboard text={row.email}></CopyClipboard>
                        </span>
                        <br />
                        <span className='customer-fullname'><CopyClipboard text={row.phoneNumber}></CopyClipboard></span>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'Phone',
            render: (_, row) => {
                return (
                    <StartCall text={row?.phoneNumber} />
                );
            },
        },
        ...currentUser.canLearnersGetAll ? [{
            title: 'Success Team Member',
            dataIndex: 'agent',
            key: 'agent',
            render: (_: any, row: any) => {
                if (row.user === null) {
                    return;
                } else {
                    return <Row justify={'start'} align={'middle'}>
                        {/* <Col className='mr-15'>
                            <img src={Avatar1Icon} alt='avatar-icon' />
                        </Col> */}
                        <Col>
                            <Title className='customer-fullname' level={5}><b>{row.user?.firstName} {row.user?.lastName}</b></Title>
                        </Col>
                    </Row>
                }
            },
        }] : [],
        {
            title: 'Lesson',
            dataIndex: 'lessonsCompleted',
            key: 'lessonsCompleted',
            width: 50,
            render: (_, row) => {
                if (row.lessonsCompleted < 10) {
                    return <Tag color="red">{row.lessonsCompleted}</Tag>;
                } else if (row.lessonsCompleted >= 10 && row.lessonsCompleted < 20) {
                    return <Tag color="volcano">{row.lessonsCompleted}</Tag>;
                } else if (row.lessonsCompleted >= 20 && row.lessonsCompleted < 40) {
                    return <Tag color="orange">{row.lessonsCompleted}</Tag>;
                } else {
                    return <Tag color="green">{row.lessonsCompleted}</Tag>;
                }
            }
        },
        {
            title: 'Last Login At',
            dataIndex: 'lastLoginAt',
            key: 'lastLoginAt',
            render: (_, row) => {
                if (row.lastLoginAt) {
                    return convertDate(row.lastLoginAt);
                }
            }
        },
        {
            title: 'Integrated At',
            dataIndex: 'becomeCustomerAt',
            key: 'becomeCustomerAt',
            render: (_, row) => {
                if (row.becomeCustomerAt) {
                    return convertDate(row.becomeCustomerAt);
                }
            }
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, row) => {
                if (row.createdAt) {
                    return convertDate(row.createdAt);
                }
            }
        },
        {
            title: '',
            dataIndex: 'id',
            render: (_, row) => (
                 <Button type='primary' onClick={() => navigate(`/learners/detail/${row.id}`)}>Learner Detail</Button>
            ),
        },
    ];


    useEffect(() => {
        let baseFilter = `?page=0&size=20&sort=lessonsCompleted&direction=asc`;
        let timeFilter = `&startCreatedAt=${startDate.includes("T00:00:00") ? startDate : `${startDate}T00:00:00`}&endCreatedAt=${endDate.includes("T23:59:59") ? endDate : `${endDate}T23:59:59`}`;
        let segmentFilter = '';
    
        if (segment !== "Not Logged In") {
            if (segment === '84+') {
                segmentFilter = `&startLessonsCompleted=84&endLessonsCompleted=2000`;
            } else {
                let [startLessonsCompleted, endLessonsCompleted] = segment.toString().split('-').map(Number);
                segmentFilter = `&startLessonsCompleted=${startLessonsCompleted}&endLessonsCompleted=${endLessonsCompleted}`;
            }
        } else {
            segmentFilter = `&hasLoggedInAtAcademy=false`;
        }
    
        const filterCriteria = `${baseFilter}${segmentFilter}${timeFilter}`;
        getLearner(filterCriteria);
    }, [startDate, endDate, segment]);
    

    const getLearner = (filterCriteria: string) => {
        setLoading(true);
        if (can("learners:getAll")) {
            LearnerService.getAll(filterCriteria)
                .then((response: any) => {
                    let data = response.data.data;
                    setLearnerList(data);
                    setLoading(false);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        } else {
            let id = localStorage.getItem('id');
            filterCriteria += `&userId=${id}`;
            LearnerService.getMyLearners(filterCriteria)
                .then((response: any) => {
                    let data = response.data.data;
                    setLearnerList(data);
                    setLoading(false);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <Card loading={loading}>
                <Row style={{ marginBottom: '10px' }} justify="center">
                    <Segmented style={{ width: '100%', overflow: "auto" }} value={segment} onChange={setSegment} options={['Not Logged In', '0-10', '11-20', '21-30', '31-40', '41-50', '51-60', '61-70', '71-83', '84+']} />
                    <Table scroll={{ x: '100%' }} // Tablonun yatay kaydırmayı etkinleştirecek genişliği
                        style={{ marginTop: '20px', width: "100%" }} key={'id'} columns={columns} dataSource={learnerList} pagination={{ pageSize: 8}}/>
                </Row>
            </Card>
        </>
    )
};

export default EducationStatistics