import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Statistic,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { can, getProfilePhotoPath } from "../../../utils";
import UserService from "../../../services/UserService";
import PhoneCallService from "../../../services/PhoneCallService";
import DateRange from "../../Common/DateRange";
import { PhoneOutlined, ClockCircleOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../ReportsRouter";

const { Title } = Typography;

export const StmCallHistoryReport = () => {
  const [dateRange, setDateRange] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  function downloadExcel(jsonData: any[]) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }

    // Save the workbook to a file
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Use FileSaver to save the file
    saveAs(blob, `call-history-report-${dateRange[0]}-${dateRange[1]}.xlsx`);
  }

  useEffect(() => {
    if (dateRange.length > 0) {
      getCallHistory();
    }
    // eslint-disable-next-line
  }, [dateRange]);

  useEffect(() => {
    if (dateRange.length > 0) {
      getCallHistory();
    }
    // eslint-disable-next-line
  }, [users]);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const getUsers = () => {
    if (can("learners:getAll")) {
      UserService.getAll("?roleName=SUCCESS_TEAM")
        .then((response: any) => {
          let data = response;
          setUsers(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const getUserByGsmPortalEmployeeId = (gsmPortalEmployeeId: any) => {
    let foundUser = users.find(
      (user: any) => user.gsmPortalEmployeeId === gsmPortalEmployeeId
    );
    return foundUser;
  };

  const getCallHistory = () => {
    setLoading(true);
    PhoneCallService.learnerPhoneCallHistoryStatisticsGroupBySource(
      `?startDate=${dateRange[0]}T00:00:00&endDate=${dateRange[1]}T23:59:59&roleName=SUCCESS_TEAM`
    )
      .then((response: any) => {
        let data = response.data;
        let users = data.map((item: any) => ({
          fullname: `${getUserByGsmPortalEmployeeId(item?.source).firstName} ${
            getUserByGsmPortalEmployeeId(item?.source).lastName
          }`,
          ...item,
          user: getUserByGsmPortalEmployeeId(item?.source),
        }));
        users.sort((a: any, b: any) =>
          a?.user?.firstName.localeCompare(b?.user?.firstName)
        );
        setData(users);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Success Team Reports",
              },
              {
                title: "Call History",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Row justify={"end"}>
        <DateRange onChangeDateRange={setDateRange}></DateRange>{" "}
        <Button
          style={{ marginLeft: "20px" }}
          size={"large"}
          icon={<DownloadOutlined />}
          onClick={() => downloadExcel(data)}
          type="primary"
        >
          Export
        </Button>
      </Row>
      {data.map((dataItem: any, index: any) => (
        <Card loading={loading} key={index} style={{ marginTop: "30px" }}>
          <Row justify={"space-between"} gutter={[32, 32]}>
            <Col span={7}>
              <Row justify={"start"} align={"middle"}>
                <Col>
                  <Avatar
                    style={{ marginRight: "15px" }}
                    size={75}
                    src={getProfilePhotoPath(
                      dataItem?.user?.profilePhoto == null
                        ? 1
                        : dataItem?.user?.profilePhoto
                    )}
                  />
                </Col>
                <Col>
                  <h2 style={{ margin: "0px" }}>
                    {dataItem.user?.firstName} {dataItem.user?.lastName}
                  </h2>
                  <h3 style={{ margin: "0px" }}>{dataItem.user?.username}</h3>
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              <Statistic
                title="Total Call"
                value={dataItem?.totalCalls}
                prefix={<PhoneOutlined />}
              />
            </Col>
            <Col span={3}>
              <Statistic
                title="Answered"
                value={dataItem?.answered}
                prefix={<PhoneOutlined />}
                valueStyle={{ color: "#3f8600" }}
              />
            </Col>
            <Col span={3}>
              <Statistic
                title="Missed"
                value={dataItem?.missed}
                prefix={<PhoneOutlined />}
                valueStyle={{ color: "#cf1322" }}
              />
            </Col>
            <Col span={3}>
              <Statistic
                title="Answered Ratio"
                suffix="%"
                value={dataItem?.answeredCallRatio}
              />
            </Col>
            <Col span={3}>
              <Statistic
                title="Average Call Duration"
                suffix="s"
                value={dataItem?.durationTimeAvg}
                prefix={<ClockCircleOutlined />}
              />
            </Col>
          </Row>
        </Card>
      ))}
    </>
  );
};
