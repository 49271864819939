import {
  Card,
  Col,
  Row,
  Typography,
  Input,
  Table,
  Button,
  Breadcrumb,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ReportService from "../../../services/ReportService";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DateRange from "../../Common/DateRange";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../ReportsRouter";

const { Title } = Typography;

export const StmEducationReport = () => {
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState<any>(
    dayjs().subtract(6, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(dayjs().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);

  // const dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
  //   dayjs().subtract(6, 'day'),
  //   dayjs()
  // ];

  // const { RangePicker } = DatePicker;

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    }
  }, [dateRange]);

  // const rangePresets: TimeRangePickerProps['presets'] = [
  //   { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
  //   { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
  //   { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
  //   { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
  // ];

  function downloadExcel() {
    const columnNameMapping: any = {
      totalCount: "Total Number of Learners",
      nullLastLoginCount: "Never Entered the Panel",
      zeroCompletedCount: "Not Started to Courses",
      moreThanZeroCompletedCount: "	Started to Courses",
    };

    // Transform the data to have custom column names
    const dataWithCustomHeaders = filteredData.map((row: any) => {
      const newRow: any = {};
      Object.keys(row).forEach((key) => {
        const newKey = columnNameMapping[key] || key;
        newRow[newKey] = row[key];
      });
      return newRow;
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataWithCustomHeaders);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }

    // Save the workbook to a file
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Use FileSaver to save the file
    saveAs(blob, "data.xlsx");
  }

  const handleSearch = (e: any) => {
    const value = e.target.value;
    if (value === "") {
      setSearchText(value);
      setFilteredData(data);
    } else {
      setSearchText(value);
      const filtered = data.filter((item: any) =>
        item.fullname.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Success Team Members",
      dataIndex: "name",
      key: "action",
      fixed: "left",
      width: 270,
      sorter: (a: any, b: any) => a.fullname.localeCompare(b.fullname),
      render: (_: any, row: any) => (
        <Row justify={"start"} align={"middle"} style={{ width: 270 }}>
          {/* <Col className='mr-15'>
            <Avatar style={{ background: '#87B2B2' }} size="large" >
              {row.firstName.charAt(0)}
            </Avatar>
          </Col> */}
          {/* <Col> */}
            {/* <Title className="customer-fullname" level={5}> */}
              <b>{row.fullname}</b>
            {/* </Title> */}
            {/* <span className="customer-fullname">{row.username}</span> */}
          {/* </Col> */}
        </Row>
      ),
    },
    {
      title: "Total Number of Learners",
      dataIndex: "totalLearnerCount",
      key: "action",
      align: "center",
      width: 200,
      sorter: (a: any, b: any) => a.totalLearnerCount - b.totalLearnerCount,
    },
    {
      title: "Never Entered the Panel",
      dataIndex: "countWithNoLastLogin",
      key: "action",
      align: "center",
      width: 200,
      sorter: (a: any, b: any) =>
        a.countWithNoLastLogin - b.countWithNoLastLogin,
    },
    {
      title: "Not Started to Courses",
      dataIndex: "countWithZeroLessonsCompleted",
      key: "action",
      align: "center",
      width: 200,
      sorter: (a: any, b: any) =>
        a.countWithZeroLessonsCompleted - b.countWithZeroLessonsCompleted,
    },
    {
      title: "Started to Courses",
      dataIndex: "countWithMoreThanZeroLessonsCompleted",
      key: "action",
      align: "center",
      width: 200,
      sorter: (a: any, b: any) =>
        a.countWithMoreThanZeroLessonsCompleted -
        b.countWithMoreThanZeroLessonsCompleted,
    },
  ];

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const getReport = () => {
    setLoading(true);
  
    Promise.all([
      ReportService.learnerEducationStatusByUser(
        `?startDate=${startDate}&endDate=${endDate}`
      ),
      ReportService.learnerStatisticReport(
        `?startDate=${startDate}&endDate=${endDate}`
      ),
    ])
      .then(([userResponse, statsResponse]) => {
        const userStatsMap = new Map(
          statsResponse.data.map((stat: any) => [
            stat.userId,
            stat.totalLearnerCount,
          ])
        );
  
        const updatedUsers = userResponse.data
          .filter(
            (user: any) =>
              user.username !== "az.success.team.crm@oneamz.com" &&
              user.username !== "stm@oneamz.com"
          )
          .map((user: any) => ({
            fullname: `${user.firstName} ${user.lastName}`,
            totalLearnerCount: userStatsMap.get(user.id) || 0,
            ...user,
          }));
  
        setData(updatedUsers);
        setFilteredData(updatedUsers);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  // const getReport = () => {
  //   setLoading(true);

  //   Promise.all([
  //     ReportService.learnerEducationStatusByUser(
  //       `?startDate=${startDate}&endDate=${endDate}`
  //     ),
  //     ReportService.learnerStatisticReport(
  //       `?startDate=${startDate}&endDate=${endDate}`
  //     ),
  //   ])
  //     .then(([userResponse, statsResponse]) => {
  //       const userStatsMap = new Map(
  //         statsResponse.data.map((stat: any) => [
  //           stat.userId,
  //           stat.totalLearnerCount,
  //         ])
  //       );

  //       const updatedUsers = userResponse.data.map((user: any) => ({
  //         fullname: `${user.firstName} ${user.lastName}`,
  //         totalLearnerCount: userStatsMap.get(user.id) || 0,
  //         ...user,
  //       }));

  //       setData(updatedUsers);
  //       setFilteredData(updatedUsers);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Success Team Reports",
              },
              {
                title: "Education",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Success Team
              </div>
              <div className="customer-card-info">Education</div>
            </div>
            <Input
              className="search-input"
              value={searchText}
              onChange={handleSearch}
              addonBefore={<SearchOutlined />}
              placeholder="Search"
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        bordered={true}
        extra={
          <>
            <Row justify={"end"}>
              {" "}
              <DateRange onChangeDateRange={setDateRange} />
              <Button
                icon={<DownloadOutlined />}
                style={{ marginLeft: 16 }}
                size="large"
                onClick={downloadExcel}
                type="primary"
              >
                Export
              </Button>
            </Row>
          </>
        }
      >
        <Table
          loading={loading}
          key={"id"}
          sticky
          scroll={{ x: "100%" }}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
        />
      </Card>
    </>
  );
};
