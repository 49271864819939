import React, { useState } from "react";
import AmazonSuspendedApplicantService from "../../services/AmazonSuspendedApplicantService";
import { generatePassword, openNotification } from "../../utils";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { UploadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import AmazonSuspendedApplicantSourcesService from "../../services/AmazonSuspendedApplicantSourcesService";
import CallSalesPackageService from "../../services/CallSalesPackageService";

const { Item } = Form;

interface ManuelDataProps {
  handleUploadManuelData: () => void;
}

export const ManuelEntry: React.FC<ManuelDataProps> = ({
  handleUploadManuelData,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [customerSources, setCustomerSources] = useState<any[]>([]);
  const [packages, setPackages] = useState<any>([]);
  const [form] = Form.useForm();

  const showModal = () => {
    if (customerSources.length === 0) {
      getSources();
    }
    if (packages.length === 0) {
      getPackages();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getSources = () => {
    AmazonSuspendedApplicantSourcesService.getAll()
      .then((response: any) => {
        let data = response.data;
        const newArray = data
          .filter((item: any) => item.active)
          .map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              sourceKey: item.sourceKey,
            };
          });
        setCustomerSources(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        let data = response.data;
        const newArray = data
          .filter((item: any) => item.isActive)
          .map((item: any) => {
            return { value: item.id, label: item.name };
          });
        setPackages(newArray);
      })
      .catch((e: Error) => {
        console.error(e);
      });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        AmazonSuspendedApplicantService.post(values)
          .then((response) => {
            openNotification("success", "Manuel Entry", "Success");
            form.resetFields();
            setIsModalVisible(false);
            handleUploadManuelData();
          })
          .catch((error) => {
            openNotification("error", "Manuel Entry", error.response.data);
          });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  // const generatePassword = () => {
  //   const length = 12;
  //   const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  //   let password = "";
  //   for (let i = 0, n = charset.length; i < length; ++i) {
  //     password += charset.charAt(Math.floor(Math.random() * n));
  //   }
  //   form.setFieldsValue({ accountPassword: password });
  // };

  return (
    <div>
      <Button type="primary" onClick={showModal} icon={<UploadOutlined />}>
        Manuel Entry
      </Button>
      <Modal
        title="Manuel Entry"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="manual_entry_form">
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Item
                name="fullName"
                label="Full Name"
                rules={[
                  { required: true, message: "Please input the full name!" },
                ]}
              >
                <Input placeholder="Full Name" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="username"
                label="Username"
                // rules={[
                //   { required: true, message: "Please input the username!" },
                // ]}
              >
                <Input placeholder="Username" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="phone"
                label="Phone"
                rules={[
                  { required: true, message: "Please input the phone number!" },
                ]}
              >
                <Input placeholder="Phone" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please input the email!" }]}
              >
                <Input placeholder="Email" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="source"
                label="Source"
                rules={[
                  { required: true, message: "Please input the source!" },
                ]}
              >
                <Select placeholder={"Select Source"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {customerSources?.map((option) => (
                    <Select.Option
                      key={option.sourceKey}
                      value={option.sourceKey}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="packageId"
                label="Package"
                rules={[
                  { required: true, message: "Please input the package!" },
                ]}
              >
                <Select placeholder={"Select Package"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {packages?.map((option: any) => (
                    <Select.Option key={option.value} value={option.id}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="buyerEmail"
                label="Buyer Email"
                // rules={[
                //   { required: true, message: "Please input the buyer email!" },
                // ]}
              >
                <Input placeholder="Buyer Email" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="sellerEmail"
                label="Seller Email"
                // rules={[
                //   { required: true, message: "Please input the seller email!" },
                // ]}
              >
                <Input placeholder="Seller Email" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="oneamzEmail"
                label="OneAmz Email"
                // rules={[
                //   { required: true, message: "Please input the OneAmz email!" },
                // ]}
              >
                <Input placeholder="OneAmz Email" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="accountPassword"
                label="Account Password"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input the account password!",
                //   },
                // ]}
              >
                <Input
                  suffix={
                    <PlusCircleOutlined
                      onClick={() =>
                        form.setFieldsValue({
                          accountPassword: generatePassword(),
                        })
                      }
                    />
                  }
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
