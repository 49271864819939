import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Typography,
  Rate,
  Table,
  Button,
  Form,
  Modal,
  Input,
  Switch,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import LearnerStatusService from "../../services/LearnerStatusService";
import { useEffect, useState } from "react";
import { ILearnerStatusResponseData } from "../../types/LearnerStatus";
import { can, openNotification } from "../../utils";
import SettingsRouter from "./SettingsRouter";
import { NavLink } from "react-router-dom";
const { Item } = Form;
const { Title } = Typography;

export const LearnerStatus = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [learnerStatus, setLearnerStatus] = useState<
    ILearnerStatusResponseData[]
  >([]);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (id: any, checked: boolean) => {
    // alert(checked);
    LearnerStatusService.updateIsActive(id, { active: checked })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Status made ${checked ? "active" : "passive"}`
        );
        getLearnerStatus();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      console.log("Received values:", values);
      postLearnerStatus(values);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns: ColumnsType<ILearnerStatusResponseData> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "action",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
    },
    {
      title: "Active/Passive",
      dataIndex: "active",
      key: "active",
      render: (_, row) => (
        <Switch
          onChange={(checked) => onChange(row?.id, checked)}
          checked={row.active}
        />
      ),
    },
  ];

  useEffect(() => {
    getLearnerStatus();
  }, []);

  const postLearnerStatus = (data: ILearnerStatusResponseData) => {
    setLoading(true);
    LearnerStatusService.create(data)
      .then((response: any) => {
        setLoading(false);
        getLearnerStatus();
        form.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getLearnerStatus = () => {
    setLoading(true);

    LearnerStatusService.getAll()
      .then((response: any) => {
        let data = response.data;
        setLearnerStatus(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Learner Status",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />

        <Button type="primary" onClick={showModal}>
          Create Status
        </Button>
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={learnerStatus}
              scroll={{ x: "max-content" }}
            />
          </Col>
        </Row>

        <Modal
          title="Create New Status"
          open={isModalVisible}
          onOk={handleCreate}
          onCancel={handleCancel}
        >
          <Form form={form} layout="vertical">
            <Item
              name="name"
              label="Learner Status"
              rules={[
                {
                  required: true,
                  message: "Name required",
                },
              ]}
            >
              <Input />
            </Item>
          </Form>
        </Modal>
      </div>
    </motion.div>
  );
};
