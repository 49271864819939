import http from "../http-common";
import { ILearnerNoteRequestData, ILearnerNoteResponseData } from "../types/LearnerNote";


const getAll = () => {
  return http.get<Array<ILearnerNoteResponseData>>(`/learners/notes`);
};

const getMyNotes = () => {
  return http.get<ILearnerNoteResponseData>(`/users/me/learners/notes`);
};

const getLearnerNotes = (learnerId: number) => {
  return http.get<ILearnerNoteResponseData>(`/learners/${learnerId}/notes`);
};

const getNote = (learnerId: number) => {
  return http.get<ILearnerNoteResponseData>(`/learners/${learnerId}/notes`);
};

const createNote = (id: number, data: any) => {
  return http.post<ILearnerNoteRequestData>(`/users/me/learners/${id}/notes`, data);
};

const update = (noteId: any, data: ILearnerNoteRequestData) => {
  return http.put<any>(`/learners/notes/${noteId}`, data);
};
const remove = (noteId: any) => {
  return http.delete<any>(`/learners/notes/${noteId}`);
};

const createNoteMulti = (data: any) => {
  return http.post<ILearnerNoteRequestData>(`/users/me/multi-learners/notes`, data);
};

const pin = (noteId: any) => {
  return http.patch<any>(`/learners/notes/${noteId}/pin`);
};

const LearnerNoteService = {
  getAll,
  getNote,
  getMyNotes,
  getLearnerNotes,
  createNote,
  update,
  remove,
  createNoteMulti,
  pin
};

export default LearnerNoteService;