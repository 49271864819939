import http from "../http-common";
import { ITaskResponseData, ITaskRequestData } from "../types/Task";


const getAll = () => {
  return http.get<Array<ITaskResponseData>>("/tasks/all");
};

const getAllCustomers = (criteria: any) => {
  return http.get<Array<ITaskResponseData>>(`/tasks/all-customers${criteria}`);
};

const getAllLearners = (criteria: any) => {
  return http.get<Array<ITaskResponseData>>(`/tasks/all-learners${criteria}`);
};

const getAllUsers = (criteria: any) => {
  return http.get<Array<ITaskResponseData>>(`/tasks/learner-or-customer${criteria}`);
};


const getAllCriteria = (criteria: any) => {
  return http.get<Array<ITaskResponseData>>(`/tasks/all${criteria}`);
};

const getAllMyTask = (criteria: any) => {
  return http.get<Array<ITaskResponseData>>(`/users/me/tasks/all${criteria}`);
};

// const getAllMyTask = () => {
//   return http.get<Array<ITaskResponseData>>("/users/me/tasks");
// };

const get = (id: any) => {
  return http.get<ITaskResponseData>(`/tasks/${id}`);
};

const create = (data: ITaskRequestData) => {
  return http.post<ITaskRequestData>("/tasks", data);
};
const update = (id: any, data: any) => {
  return http.patch<any>(`/tasks/${id}`, data);
};
const remove = (id: any) => {
  return http.delete<any>(`/tasks/${id}`);
};

const createTaskMulti = (data: any) => {
  return http.post<any>(`/tasks/multi`, data);
};



const TaskService = {
  getAll,
  getAllMyTask,
  get,
  create,
  update,
  remove,
  getAllCriteria,
  getAllCustomers,
  getAllLearners,
  createTaskMulti,
  getAllUsers
};

export default TaskService;