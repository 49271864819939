import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Switch,
  Table,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SettingsRouter from "./SettingsRouter";
import {
  IDepartmentRequestData,
  IDepartmentResponseData,
} from "../../types/Department";
import DepartmentService from "../../services/DepartmentService";
import ButtonGroup from "antd/es/button/button-group";
import { openNotification } from "../../utils";

const { Item } = Form;
const { Title } = Typography;

export const DepartmentList = () => {
  const [form] = Form.useForm();
  const [formEditDepartment] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newDepartmentName, setNewDepartmentName] = useState<
    string | undefined
  >(undefined);
  const [selectedDepartment, setSelectedDepartment] =
    useState<IDepartmentResponseData | null>(null);
  const [departments, setDepartments] = useState<IDepartmentResponseData[]>([]);

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCreateModalCancel = () => {
    setIsCreateModalVisible(false);
  };

  const showEditModal = (departmentData: IDepartmentResponseData) => {
    setSelectedDepartment(departmentData);
    setNewDepartmentName(departmentData.name);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedDepartment(null);
  };

  const showDeleteModal = (departmentData: IDepartmentResponseData) => {
    setSelectedDepartment(departmentData);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedDepartment(null);
  };

  const postDepartment = (data: IDepartmentRequestData) => {
    setLoading(true);
    DepartmentService.create(data)
      .then((response: any) => {
        getDepartments();
        form.resetFields();
        openNotification("success", "Success", `Department created`);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      const newData: IDepartmentRequestData = {
        ...values,
        active: true,
        employees: [],
      };
      postDepartment(newData);
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const getDepartments = () => {
    setLoading(true);
    DepartmentService.getAll()
      .then((response: any) => {
        let data = response.data;
        setDepartments(data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleUpdateDepartment = (id: number, name: string) => {
    setLoading(true);
    const updateData: IDepartmentRequestData = {
      id: id,
      name: name,
      active: true,
      employees: [],
    };
    DepartmentService.update(id, updateData)
      .then((response: any) => {
        openNotification("success", "Success", `Department updated`);
        handleEditModalCancel();
        formEditDepartment.resetFields();
        getDepartments();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const onChange = (id: number, checked: boolean) => {
    const departmentToUpdate = departments.find((dept) => dept.id === id);
    if (departmentToUpdate) {
      const updateData: IDepartmentRequestData = {
        id: departmentToUpdate.id,
        name: departmentToUpdate.name,
        active: checked,
        employees: departmentToUpdate.employees,
      };
      DepartmentService.update(id, updateData)
        .then((response: any) => {
          openNotification(
            "success",
            "Success",
            `Department made ${checked ? "active" : "passive"}`
          );
          getDepartments();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleDeleteDepartment = (id: number) => {
    setLoading(true);
    DepartmentService.remove(id)
      .then((response: any) => {
        openNotification("success", "Success", `Department deleted`);
        handleDeleteModalCancel();
        getDepartments();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<IDepartmentResponseData> = [
    {
      title: "Department",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, row) => <span>{row.name}</span>,
    },
    {
      title: "Active/Passive",
      dataIndex: "active",
      key: "active",
      width: 200,
      align: "center",
      sorter: (a: any, b: any) => a.active - b.active,
      render: (_, row) => (
        <Switch
          onChange={(checked) => onChange(row?.id ?? 0, checked)}
          checked={row.active}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 200,
      render: (_, record) => (
        <ButtonGroup style={{ marginLeft: "10px", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={() => showEditModal(record)}>
            Edit
          </Button>
          <Button onClick={() => showDeleteModal(record)}>Delete</Button>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    formEditDepartment.setFieldsValue({
      selectedDepartment: newDepartmentName,
    });
  }, [newDepartmentName]);

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Departments",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />
        <Button type="primary" onClick={showCreateModal}>
          Create Department
        </Button>
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={departments}
              scroll={{ x: "100%" }}
              rowKey={(record) =>
                record.id?.toString() || `temp_key_${Math.random()}`
              }
            />
          </Col>
        </Row>
        {/* Create Modal */}
        <Modal
          title="Create New Department"
          open={isCreateModalVisible}
          onOk={handleCreate}
          onCancel={handleCreateModalCancel}
        >
          <Form form={form} layout="vertical">
            <Item
              name="name"
              label="Department Name"
              rules={[
                {
                  required: true,
                  message: "Department name required",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Item>
          </Form>
        </Modal>
        {/* {/* Edit Modal */}
        <Modal
          title="Edit Department"
          open={isEditModalVisible}
          onCancel={handleEditModalCancel}
          footer={[
            <Button key="back" onClick={handleEditModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                handleUpdateDepartment(
                  selectedDepartment?.id ?? 0,
                  newDepartmentName ?? ""
                );
              }}
            >
              Save
            </Button>,
          ]}
        >
          <Form form={formEditDepartment} layout="vertical">
            <Item
              name="selectedDepartment"
              label="Edit Department Name"
              rules={[
                {
                  required: true,
                  message: "Department name required",
                },
              ]}
            >
              <Input
                value={newDepartmentName}
                onChange={(e) => setNewDepartmentName(e.target.value)}
              />
            </Item>
          </Form>
        </Modal>
        {/* Delete Modal */}
        <Modal
          title="Delete Department"
          open={isDeleteModalVisible}
          onCancel={handleDeleteModalCancel}
          footer={[
            <Button key="back" onClick={handleDeleteModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                selectedDepartment &&
                selectedDepartment.id !== undefined &&
                handleDeleteDepartment(selectedDepartment.id)
              }
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this department?</p>
        </Modal>
      </div>
    </motion.div>
  );
};
