import {
  Breadcrumb,
  Col,
  Row,
  Typography,
  Table,
  Button,
  Form,
  Modal,
  Input,
  Switch,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { openNotification } from "../../utils";
import SettingsRouter from "./SettingsRouter";
import { NavLink } from "react-router-dom";
import ButtonGroup from "antd/es/button/button-group";
import CallSalesPackageService from "../../services/CallSalesPackageService";

const { Item } = Form;
const { Title } = Typography;

export const CallSalesPackage = () => {
  const [form] = Form.useForm();
  const [formEditPackage] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPackageName, setNewPackageName] = useState<string | undefined>(
    undefined
  );
  const [newPrice, setNewPrice] = useState<number | undefined>(undefined);
  const [newCommissionRate, setNewCommissionRate] = useState<
    number | undefined
  >(undefined);
  const [newAcmCommissionRate, setNewAcmCommissionRate] = useState<
    number | undefined
  >(undefined);
  const [newHi5AcmCommissionRate, setNewHi5AcmCommissionRate] = useState<
    number | undefined
  >(undefined);
  const [newStmCommissionRate, setNewStmCommissionRate] = useState<
    number | undefined
  >(undefined);
  const [newCtmCommissionRate, setNewCtmCommissionRate] = useState<
    number | undefined
  >(undefined);
  const [newSuspendTMCommissionRate, setNewSuspendTMCommissionRate] = useState<
    number | undefined
  >(undefined);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const roleName = localStorage.getItem("roleName");

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCreateModalCancel = () => {
    setIsCreateModalVisible(false);
  };

  const showEditModal = (packageData: any) => {
    setSelectedPackage(packageData);
    setNewPackageName(packageData.name);
    setNewPrice(packageData.price);
    setNewAcmCommissionRate(packageData.acmCommissionRate);
    setNewHi5AcmCommissionRate(packageData.hi5AcmCommissionRate);
    setNewStmCommissionRate(packageData.stmCommissionRate);
    setNewCtmCommissionRate(packageData.callCommissionRate);
    setNewSuspendTMCommissionRate(packageData.suspendCommissionRate);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedPackage(null);
  };

  const showDeleteModal = (packageData: any) => {
    setSelectedPackage(packageData);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedPackage(null);
  };

  // POST
  const postCallSalesPackage = (data: any) => {
    setLoading(true);
    CallSalesPackageService.create(data)
      .then((response: any) => {
        getCallSalesPackage();
        form.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      const newData = {
        ...values,
        price: parseFloat(values.price),
        commissionRate: parseFloat(values.commissionRate),
        acmCommissionRate: parseFloat(values.acmCommissionRate),
        hi5AcmCommissionRate: parseFloat(values.hi5AcmCommissionRate),
        stmCommissionRate: parseFloat(values.stmCommissionRate),
        callCommissionRate: parseFloat(values.callCommissionRate),
        suspendCommissionRate: parseFloat(values.suspendCommissionRate),
      };
      postCallSalesPackage(newData);
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  // GETALL
  const getCallSalesPackage = () => {
    setLoading(true);
    CallSalesPackageService.getAll()
      .then((response: any) => {
        let data = response.data;
        setProcessedData(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  // PUT UPDATE
  const handleChangePackage = (
    id: any,
    name: any,
    price: any,
    commissionRate: any,
    acmCommissionRate: any,
    hi5AcmCommissionRate: any,
    stmCommissionRate: any,
    callCommissionRate: any,
    suspendCommissionRate: any
  ) => {
    setLoading(true);
    CallSalesPackageService.updateIsActive(id, {
      name: name,
      price: price,
      commissionRate: commissionRate,
      acmCommissionRate: acmCommissionRate,
      hi5AcmCommissionRate: hi5AcmCommissionRate,
      stmCommissionRate: stmCommissionRate,
      callCommissionRate: callCommissionRate,
      suspendCommissionRate: suspendCommissionRate,
    })
      .then((response: any) => {
        openNotification("success", "Success", `Call Sales Package updated`);
        handleEditModalCancel();
        form.resetFields();
        getCallSalesPackage();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const onChange = (id: any, checked: boolean) => {
    CallSalesPackageService.updateIsActive(id, { isActive: checked })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Sales Package made ${checked ? "active" : "passive"}`
        );
        getCallSalesPackage();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  // DELETE
  const handleDeletePackage = (id: number) => {
    setLoading(true);
    CallSalesPackageService.remove(id)
      .then((response: any) => {
        openNotification("success", "Success", `Call Sales Package deleted`);
        handleDeleteModalCancel();
        getCallSalesPackage();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Package Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, row) => <span>{row.name}</span>,
    },
    {
      title: "Active/Passive",
      dataIndex: "isActive",
      key: "isActive",
      width: 200,
      align: "center" as "center",
      sorter: (a: any, b: any) => a.isActive - b.isActive,
      render: (_: any, row: any) => (
        <Switch
          onChange={(checked) => roleName === "ADMIN" && onChange(row?.id, checked)}
          checked={row.isActive}
          disabled={roleName !== "ADMIN"}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      sorter: (a, b) => a.price - b.price,
      render: (_, row) => (
        <span>{row.price ? `$${row.price.toFixed(2)}` : ""}</span>
      ),
    },
    {
      title: "ACM Commission Rate",
      dataIndex: "acmCommissionRate",
      key: "acmCommissionRate",
      align: "center",
      sorter: (a, b) => a.acmCommissionRate - b.acmCommissionRate,
      render: (_, row) => (
        <span>{row.acmCommissionRate ? row.acmCommissionRate : ""}</span>
      ),
    },
    {
      title: "Hi5 ACM Commission Rate",
      dataIndex: "hi5AcmCommissionRate",
      key: "hi5AcmCommissionRate",
      align: "center",
      sorter: (a, b) => a.hi5AcmCommissionRate - b.hi5AcmCommissionRate,
      render: (_, row) => (
        <span>{row.hi5AcmCommissionRate ? row.hi5AcmCommissionRate : ""}</span>
      ),
    },
    {
      title: "STM Commission Rate",
      dataIndex: "stmCommissionRate",
      key: "stmCommissionRate",
      align: "center",
      sorter: (a, b) => a.stmCommissionRate - b.stmCommissionRate,
      render: (_, row) => (
        <span>{row.stmCommissionRate ? row.stmCommissionRate : ""}</span>
      ),
    },
    {
      title: "CTM Commission Rate",
      dataIndex: "callCommissionRate",
      key: "callCommissionRate",
      align: "center",
      sorter: (a, b) => a.callCommissionRate - b.callCommissionRate,
      render: (_, row) => (
        <span>{row.callCommissionRate ? row.callCommissionRate : ""}</span>
      ),
    },
    {
      title: "Suspend TM Commission Rate",
      dataIndex: "suspendCommissionRate",
      key: "suspendCommissionRate",
      align: "center",
      sorter: (a, b) => a.suspendCommissionRate - b.suspendCommissionRate,
      render: (_, row) => (
        <span>
          {row.suspendCommissionRate ? row.suspendCommissionRate : ""}
        </span>
      ),
    },
    ...(roleName === "ADMIN"
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            align: "center" as "center",
            width: 200,
            render: (_: any, record: any) => (
              <ButtonGroup
                style={{ marginLeft: "10px", justifyContent: "flex-end" }}
              >
                <Button type="primary" onClick={() => showEditModal(record)}>
                  Edit
                </Button>
                <Button onClick={() => showDeleteModal(record)}>Delete</Button>
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    getCallSalesPackage();
  }, []);

  useEffect(() => {
    formEditPackage.setFieldsValue({
      selectedPackage: newPackageName,
      selectedPrice: newPrice,
      selectedCommissionRate: newCommissionRate,
      selectedAcmCommissionRate: newAcmCommissionRate,
      selectedHi5AcmCommissionRate: newHi5AcmCommissionRate,
      selectedStmCommissionRate: newStmCommissionRate,
      selectedCtmCommissionRate: newCtmCommissionRate,
      selectedSuspendTMCommissionRate: newSuspendTMCommissionRate,
    });
  }, [
    newPackageName,
    newPrice,
    newCommissionRate,
    newAcmCommissionRate,
    newHi5AcmCommissionRate,
    newStmCommissionRate,
    newCtmCommissionRate,
    newSuspendTMCommissionRate,
  ]);

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Call Team Sales Package",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />
        {roleName === "ADMIN" && (
          <Button type="primary" onClick={showCreateModal}>
            Create Sales Package
          </Button>
        )}
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              rowKey={"id"}
              loading={loading}
              columns={columns}
              dataSource={processedData}
              pagination={false}
              scroll={{ x: "max-content" }}
            />
          </Col>
        </Row>
        {/* Create Modal */}
        <Modal
          title="Create New Sales Package"
          open={isCreateModalVisible}
          onOk={handleCreate}
          onCancel={handleCreateModalCancel}
        >
          <Form form={form} layout="vertical">
            <Item
              name="name"
              label="Sales Package Name"
              rules={[
                {
                  required: true,
                  message: "Sales Package name required",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Item>
            <Item
              name="price"
              label="Price"
              rules={[
                {
                  required: true,
                  message: "Price required",
                },
              ]}
            >
              <Input type="number" step="0.01" style={{ width: "100%" }} />
            </Item>
            <Item name="acmCommissionRate" label="ACM Commission Rate">
              <Input type="number" step="0.01" style={{ width: "100%" }} />
            </Item>
            <Item name="hi5AcmCommissionRate" label="Hi5 ACM Commission Rate">
              <Input type="number" step="0.01" style={{ width: "100%" }} />
            </Item>
            <Item name="stmCommissionRate" label="STM Commission Rate">
              <Input type="number" step="0.01" style={{ width: "100%" }} />
            </Item>
            <Item name="callCommissionRate" label="CTM Commission Rate">
              <Input type="number" step="0.01" style={{ width: "100%" }} />
            </Item>
            <Item name="suspendCommissionRate" label="Suspend TM Commission Rate">
              <Input type="number" step="0.01" style={{ width: "100%" }} />
            </Item>
          </Form>
        </Modal>
        {/* Edit Modal */}
        <Modal
          title="Edit Sales Package"
          open={isEditModalVisible}
          onCancel={handleEditModalCancel}
          footer={[
            <Button key="back" onClick={handleEditModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                handleChangePackage(
                  selectedPackage?.id,
                  newPackageName,
                  newPrice,
                  newCommissionRate,
                  newAcmCommissionRate,
                  newHi5AcmCommissionRate,
                  newStmCommissionRate,
                  newCtmCommissionRate,
                  newSuspendTMCommissionRate
                );
              }}
            >
              Save
            </Button>,
          ]}
        >
          <Form form={formEditPackage} layout="vertical">
            <Item
              name="selectedPackage"
              label="Sales Package Name"
              rules={[
                {
                  required: true,
                  message: "Sales Package name required",
                },
              ]}
            >
              <Input
                value={newPackageName}
                onChange={(e) => setNewPackageName(e.target.value)}
              />
            </Item>
            <Item
              name="selectedPrice"
              label="Price"
              rules={[
                {
                  required: true,
                  message: "Price required",
                },
              ]}
            >
              <Input
                value={newPrice}
                type="number"
                step="0.01"
                onChange={(e) => setNewPrice(parseFloat(e.target.value))}
              />
            </Item>
            <Item name="selectedAcmCommissionRate" label="ACM Commission Rate">
              <Input
                value={newAcmCommissionRate}
                type="number"
                step="0.01"
                onChange={(e) =>
                  setNewAcmCommissionRate(parseFloat(e.target.value))
                }
              />
            </Item>
            <Item name="selectedHi5AcmCommissionRate" label="Hi5 ACM Commission Rate">
              <Input
                value={newHi5AcmCommissionRate}
                type="number"
                step="0.01"
                onChange={(e) =>
                  setNewHi5AcmCommissionRate(parseFloat(e.target.value))
                }
              />
            </Item>
            <Item name="selectedStmCommissionRate" label="STM Commission Rate">
              <Input
                value={newStmCommissionRate}
                type="number"
                step="0.01"
                onChange={(e) =>
                  setNewStmCommissionRate(parseFloat(e.target.value))
                }
              />
            </Item>
            <Item name="selectedCtmCommissionRate" label="CTM Commission Rate">
              <Input
                value={newCtmCommissionRate}
                type="number"
                step="0.01"
                onChange={(e) =>
                  setNewCtmCommissionRate(parseFloat(e.target.value))
                }
              />
            </Item>
            <Item name="selectedStuspendTMCommissionRate" label="Suspend TM Commission Rate">
              <Input
                value={newSuspendTMCommissionRate}
                type="number"
                step="0.01"
                onChange={(e) =>
                  setNewSuspendTMCommissionRate(parseFloat(e.target.value))
                }
              />
            </Item>
          </Form>
        </Modal>
        {/* Delete Modal */}
        <Modal
          title="Delete Sales Package"
          open={isDeleteModalVisible}
          onCancel={handleDeleteModalCancel}
          footer={[
            <Button key="back" onClick={handleDeleteModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleDeletePackage(selectedPackage?.id)}
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this sales package?</p>
        </Modal>
      </div>
    </motion.div>
  );
};
