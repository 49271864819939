import React from "react";
import { Avatar, Card, Modal, Row } from "antd";
import { getProfilePhotoPath } from "../../../utils";

interface UserDetailsModalProps {
  visible: boolean;
  onClose: () => void;
  userDetails: any;
}

const InfoRow: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
  <Row justify="space-between" align="middle" style={{ padding: 0 }}>
    <Row justify="start" align="middle">
      <b>{label}: </b>
    </Row>
    <Row justify="end">
      <b>{value}</b>
    </Row>
  </Row>
);

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({
  visible,
  onClose,
  userDetails,
}) => {
  const { user, salesPackageCount, totalCommissionAmount, totalTurnoverAmount } = userDetails || {};

  return (
    <Modal
      open={visible}
      title={
        <Row align="middle">
          <Avatar
            style={{ marginRight: 15 }}
            size={50}
            src={getProfilePhotoPath(user?.profilePhoto || 1)}
          />
          <h3>{`${user?.firstName} ${user?.lastName} Sales Details`}</h3>
        </Row>
      }
      onCancel={onClose}
      footer={null}
    >
      <Card>
        <InfoRow label="Sales Package Amount" value={salesPackageCount || ""} />
      </Card>
      <Card>
        <InfoRow label="Total Commission" value={`$${totalCommissionAmount?.toFixed(2) || ""}`} />
      </Card>
      <Card>
        <InfoRow label="Total Turnover" value={`$${totalTurnoverAmount?.toFixed(2) || ""}`} />
      </Card>
    </Modal>
  );
};

export default UserDetailsModal;
