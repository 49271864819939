import {
  Card,
  Col,
  Row,
  Typography,
  Table,
  Breadcrumb,
  Button,
  Input,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import DateRange from "../../Common/DateRange";
import { ReportsRouter } from "../ReportsRouter";
import * as XLSX from "xlsx";
import ReportOfStatusService from "../../../services/ReportOfStatusService";

const { Title } = Typography;

export const StmSoftwareSuspendedStatusReport: React.FC<any> = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const location = useLocation();

  const getStatusTypeAndTitles = () => {
    if (location.pathname.includes("/stm-reports/software-suspended-status")) {
      return {
        roleName: "SUCCESS_TEAM",
        statusType: "SOFTWARE_SUSPENDED_STATUS",
        columnTitle: "Success Team Member",
        breadcrumbTitle: "Success Team Reports",
        breadcrumbSubTitle: "Software Suspended Status Report",
        cardTitle: "Success Team Member",
        cardSubTitle: "Software Suspended Status Report",
      };
    } else
      return {
        roleName: "",
        statusType: "",
        columnTitle: "Unknown Role",
        breadcrumbTitle: "Unknown Reports",
        breadcrumbSubTitle: "Unknown Report",
        cardTitle: "Unknown Member",
        cardSubTitle: "Unknown Report",
      };
  };

  useEffect(() => {
    const { roleName, statusType } = getStatusTypeAndTitles();
    if (
      dateRange[0] !== undefined &&
      dateRange[1] !== undefined &&
      roleName !== "" &&
      roleName !== undefined &&
      statusType !== "" &&
      statusType !== undefined
    ) {
      getReport(
        `${dateRange[0]}T00:00:00`,
        `${dateRange[1]}T23:59:59`,
        roleName,
        statusType
      );
    }
    // eslint-disable-next-line
  }, [dateRange, location.pathname]);

  const getReport = (
    startDate: string,
    endDate: string,
    roleName: string,
    statusType: string
  ) => {
    setLoading(true);
    ReportOfStatusService.getReportOfStatusWithParent(
      startDate,
      endDate,
      roleName,
      statusType
    )
      .then((response) => {
        const filteredResponseData = response.data.filter(
          (item: any) =>
            item.user.username !== "az.success.team.crm@oneamz.com" &&
            item.user.username !== "stm@oneamz.com"
        );
        setData(filteredResponseData);
        setFilteredData(filteredResponseData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUniqueStatusDataKeys = (data: any[]) => {
    const allKeys = data.reduce((keys, item) => {
      const parentKeys = Object.keys(item.statusMap);
      parentKeys.forEach((parentKey) => {
        const childKeys = Object.keys(item.statusMap[parentKey]);
        childKeys.forEach((childKey) => {
          keys.push(`${parentKey}.${childKey}`);
        });
      });
      return keys;
    }, []);
    return Array.from(new Set(allKeys));
  };

  const statusDataKeys = getUniqueStatusDataKeys(data);

  const {
    columnTitle,
    breadcrumbTitle,
    breadcrumbSubTitle,
    cardTitle,
    cardSubTitle,
  } = getStatusTypeAndTitles();

  const extractNumericValue = (value: string): number => {
    const match = value.match(/^(\d+)/);
    return match ? parseFloat(match[1]) : 0;
  };

  const capitalize = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const columns: ColumnsType<any> = [
    {
      title: columnTitle,
      dataIndex: "user",
      key: "user",
      fixed: "left",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => a?.user?.firstName.localeCompare(b?.user?.firstName),
      defaultSortOrder: "ascend",
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.user?.firstName} ${record?.user?.lastName}`}
          >
            <b>
              {record?.user?.firstName} {record?.user?.lastName}
            </b>
          </div>
        );
      },
    },
    ...Object.keys(data[0]?.statusMap || {}).map((parentKey: string) => {
      return {
        title: (
          <div
            className="column-group-title"
            style={{
              border: "3px solid #e9e9e9",
              paddingBottom: "8px",
              paddingTop: "8px",
              marginBottom: "-16px",
            }}
          >
            {capitalize(parentKey)}
          </div>
        ),
        align: "center" as const,
        children: Object.keys(data[0].statusMap[parentKey]).map(
          (childKey: string) => ({
            title: capitalize(childKey),
            dataIndex: ["statusMap", parentKey, childKey],
            key: `${parentKey}.${childKey}`,
            width: 150,
            align: "center" as const,
            sorter: (a: any, b: any) => {
              const aValue = extractNumericValue(
                a?.statusMap?.[parentKey]?.[childKey] || "0"
              );
              const bValue = extractNumericValue(
                b?.statusMap?.[parentKey]?.[childKey] || "0"
              );
              return aValue - bValue;
            },
            render: (text: string, record: any) => {
              const valueString =
                record?.statusMap?.[parentKey]?.[childKey] || "0 (0)";
              const [outerValue, innerValue] = valueString
                .replace(/[()]/g, "")
                .split(" ")
                .map(Number);

              let sign = "";
              let color = "inherit";

              if (outerValue > innerValue) {
                sign = "+";
                color = "green";
              } else if (outerValue < innerValue) {
                sign = "-";
                color = "red";
              }

              return (
                <span style={{ color }}>
                  {sign} {outerValue} ({innerValue}) qty
                </span>
              );
            },
          })
        ),
      };
    }),
  ];

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item: any) => {
        const fullName =
          `${item?.user?.firstName} ${item?.user?.lastName}`.toLowerCase();
        return fullName.includes(value);
      });
      setFilteredData(filtered);
    }
  };

  const fileName =
    cardSubTitle.length > 31 ? "Software Suspended Status" : cardSubTitle;

  const downloadExcel = () => {
    const ws = XLSX.utils.aoa_to_sheet([]); // Boş bir sayfa oluşturuyoruz

    // Başlıkları eklemek için kullanılan satır sayısı
    let currentRow = 0;

    // Takım ismi için hücreyi birleştir ve "Team Name" başlığını ekle
    const teamNameCell = [`${columnTitle}`];
    ws["!merges"] = ws["!merges"] || [];
    ws["!merges"].push({
      s: { r: currentRow, c: 0 }, // Başlangıç hücresi (satır, sütun)
      e: { r: currentRow + 1, c: 0 }, // Bitiş hücresi (satır, sütun)
    });

    // Status kolonları için üst ve alt başlıklar
    const parentKeys = Object.keys(data[0]?.statusMap || {});
    const firstHeaderRow: any = [];
    const secondHeaderRow: any = [];
    let currentColumn = 1; // İlk sütun 'Team Name' için kullanıldı

    parentKeys.forEach((parentKey) => {
      const childKeys = Object.keys(data[0]?.statusMap[parentKey]);

      // Parent ismi için hücreleri birleştiriyoruz
      firstHeaderRow.push(parentKey);
      for (let i = 1; i < childKeys.length; i++) {
        firstHeaderRow.push(null); // Boş hücreler, aynı parent başlığı için
      }

      // Child başlıklarını ekliyoruz
      secondHeaderRow.push(...childKeys);

      // Parent başlığı child başlıkların sayısı kadar sütunu kaplayacak şekilde birleştiriyoruz
      if (childKeys.length > 1) {
        ws["!merges"]?.push({
          s: { r: currentRow, c: currentColumn }, // Parent başlığının başlangıç hücresi
          e: { r: currentRow, c: currentColumn + childKeys.length - 1 }, // Parent başlığının bitiş hücresi
        });
      }

      currentColumn += childKeys.length; // Sonraki parent için sütun başlangıcını güncelle
    });

    // Excel sayfasına başlıkları ekliyoruz
    XLSX.utils.sheet_add_aoa(ws, [teamNameCell.concat(firstHeaderRow)], {
      origin: currentRow++,
    });
    XLSX.utils.sheet_add_aoa(ws, [[""].concat(secondHeaderRow)], {
      origin: currentRow++,
    });

    // Tablo verileri
    const formattedData = filteredData.map((item: any) => {
      const memberName = `${item.user.firstName} ${item.user.lastName}`;
      const rowData = [memberName];

      parentKeys.forEach((parentKey) => {
        const childKeys = Object.keys(item.statusMap[parentKey]);
        childKeys.forEach((childKey) => {
          rowData.push(`${item.statusMap[parentKey][childKey]} qty`);
        });
      });

      return rowData;
    });

    // Tablo verilerini ekliyoruz
    XLSX.utils.sheet_add_aoa(ws, formattedData, { origin: currentRow });

    // Summary satırını ekliyoruz
    const summaryRow = ["Total"];
    parentKeys.forEach((parentKey) => {
      const childKeys = Object.keys(data[0]?.statusMap[parentKey]);
      childKeys.forEach((childKey) => {
        let totalCurrent = 0;
        let totalPrevious = 0;
        filteredData.forEach((item: any) => {
          const value = item.statusMap[parentKey][childKey];
          const match = value.match(/^(\d+)\s\((\d+)\)$/);
          if (match) {
            totalCurrent += parseFloat(match[1]);
            totalPrevious += parseFloat(match[2]);
          }
        });
        summaryRow.push(`${totalCurrent} (${totalPrevious}) qty`);
      });
    });
    XLSX.utils.sheet_add_aoa(ws, [summaryRow], {
      origin: currentRow + filteredData.length,
    });

    // Çalışma kitabı oluşturma ve Excel dosyasını indirme
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: breadcrumbTitle,
              },
              {
                title: breadcrumbSubTitle,
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                {cardTitle}
              </div>
              <div className="customer-card-info">{cardSubTitle}</div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search By Name"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Button
              style={{ marginLeft: "20px" }}
              size={"large"}
              icon={<DownloadOutlined />}
              onClick={downloadExcel}
              type="primary"
            >
              Export
            </Button>
          </Row>
        }
        bordered={true}
        style={{ marginTop: "30px" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ margin: "0 0 5px 10px" }}>
            <span>
              {" "}
              * The values in parentheses represent the previous period based on
              the range selected from the date filter.
            </span>
          </Col>
        </Row>
        <Table
          loading={isLoading}
          scroll={{ x: "100%" }}
          sticky
          rowKey={(record) => record.user.id.toString()}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          summary={(pageData) => {
            const statusDataTotals = statusDataKeys.map((key: any) => {
              const [parentKey, childKey] = key.split(".");
              const totals = pageData.reduce(
                (total, record) => {
                  const value = record.statusMap[parentKey]?.[childKey];
                  const match = value?.match(/^(\d+)\s\((\d+)\)$/);
                  if (match) {
                    total.current += parseFloat(match[1]);
                    total.previous += parseFloat(match[2]);
                  }
                  return total;
                },
                { current: 0, previous: 0 }
              );
              return `${totals.current} (${totals.previous}) qty`;
            });

            return (
              <Table.Summary.Row className="summary-cell">
                <Table.Summary.Cell
                  index={0}
                  colSpan={1}
                  className="summary-cell"
                >
                  <b>Total</b>
                </Table.Summary.Cell>
                {statusDataKeys.map((key: any, index: any) => (
                  <Table.Summary.Cell
                    index={index + 1}
                    key={index}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{statusDataTotals[index]}</b>
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            );
          }}
        />
      </Card>
    </>
  );
};
