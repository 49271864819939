import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Modal, Row, Select, Tag } from "antd";
import "./info-card.css";
import moment from "moment";
import CustomerService from "../../services/CustomerService";
import ReactCountryFlag from "react-country-flag";
import CallSalesPackageService from "../../services/CallSalesPackageService";
import SalesPackageServeyService from "../../services/SalesPackageServeyService";
import ListCheck from "./../../assets/list-checks.svg";

const StoreInformation: React.FC<any> = ({ customer }) => {
  const [data, setData] = useState<any>({});
  const [isVisible, setIsVisible] = useState(false);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [packages, setPackages] = useState<any>([]);
  const [surveys, setSurveys] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState();
  const [logs, setLogs] = useState<any>([]);
  const [buttonActive, setButtonActive] = useState(false);
  const roleName = localStorage.getItem("roleName");
  if (!roleName) {
    throw new Error("Role name not found in local storage");
  }

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        setPackages(response.data.filter((pkg: any) => pkg.isActive));
        setSelectedPackageId(
          response.data.filter((pkg: any) => pkg.isActive)[0]?.id
        );
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCallSalesPackageServey = () => {
    SalesPackageServeyService.getAll()
      .then((response: any) => {
        let data = response.data;
        setSurveys(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getAllSalesPackageServeyLog = () => {
    if (customer) {
      SalesPackageServeyService.getLog(`?customerId=${customer?.id}`)
        .then((response: any) => {
          let data = response.data;

          const latestLogs = data.reduce((acc: any, log: any) => {
            if (
              !acc[log.callCenterSalesPackageServeyId] ||
              acc[log.callCenterSalesPackageServeyId].id < log.id
            ) {
              acc[log.callCenterSalesPackageServeyId] = log;
            }
            return acc;
          }, {});
          const latestLogsArray = Object.values(latestLogs);
          setLogs(latestLogsArray);

          const checkedItemsFromLogs = latestLogsArray
            .filter((log: any) => log.isConfirm)
            .map((log: any) => log.callCenterSalesPackageServeyId);
          setCheckedItems(checkedItemsFromLogs);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (customer) {
      getPackages();
      getCallSalesPackageServey();
    }
  }, [customer]);

  const showModal = () => {
    setIsVisible(true);
    getAllSalesPackageServeyLog();
  };

  const handleSave = () => {
    setIsVisible(false);
  };

  const handleCheckboxChange = (checked: boolean, surveyId: any) => {
    setCheckedItems((prev) =>
      checked ? [...prev, surveyId] : prev.filter((item) => item !== surveyId)
    );

    const existingLog = logs.find(
      (log: any) => log.callCenterSalesPackageServeyId === surveyId
    );

    let payload = {
      customerId: customer.id,
      isConfirm: checked,
      callCenterSalesPackageServeyId: surveyId,
      ...(existingLog && { id: existingLog.id }),
    };

    SalesPackageServeyService.createLog(payload)
      .then((response) => {})
      .catch((error) => {
        // Hata durumunda yapılacak işlemler
      });
  };

  // @ts-ignore
  const handleCloseModal = (e) => {
    const obj = e.target.closest(".ant-modal-close")
      ? e.target.closest(".ant-modal-close")
      : e.target;
    if (obj.classList.contains("ant-modal-close")) setIsVisible(false);
  };

  useEffect(() => {
    const items = surveys.filter(
      (survey: any) => survey.callCenterSalesPackageId === selectedPackageId
    );
    setButtonActive(items.length <= checkedItems.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveys, checkedItems]);

  useEffect(() => {
    if (customer?.id) {
      CustomerService.getStoreInformation(customer?.id)
        .then((response: any) => {
          setData(response.data[0]);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  }, [customer]);

  return (
    <>
      <Card bordered={false} className={"card"}>
        <Row justify={"space-between"}>
          <p className="title">Store Information</p>
          {roleName === "SUCCESS_TEAM_MANAGER" ||
          roleName === "SUCCESS_TEAM" ||
          roleName === "ADMIN" ? (
            <Button type="default" onClick={showModal}>
              <Row>
                <img
                  src={ListCheck}
                  height="22"
                  style={{ marginRight: 5 }}
                  alt=""
                  className="list-check-svg-icon"
                />{" "}
                Call Checklist
              </Row>
            </Button>
          ) : null}
        </Row>
        <Modal
          title="Call Checklist"
          open={isVisible}
          className="call-history-card modal-backdrop"
          onOk={handleSave}
          onCancel={(e) => handleCloseModal(e)}
          footer={[
            <Button
              disabled={!buttonActive}
              style={{ width: "100%" }}
              key="save"
              type="primary"
              onClick={handleSave}
            >
              Completed
            </Button>,
          ]}
        >
          <Select
            placeholder={"Select"}
            style={{ width: "100%" }}
            defaultValue={packages[0]?.id}
            onChange={(value) => setSelectedPackageId(value)}
          >
            {packages?.map((option: any) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <p>
            <b>Important Questions</b>
          </p>
          <div style={{ marginTop: 10, overflowY: "auto" }}>
            {surveys
              .filter(
                (survey: any) =>
                  survey.callCenterSalesPackageId === selectedPackageId &&
                  survey.questionType === "IMPORTANT"
              )
              .map((survey: any) => (
                <Row style={{ marginBottom: 10 }}>
                  <Checkbox
                    key={survey.id}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, survey.id)
                    }
                    checked={checkedItems.includes(survey.id)}
                  >
                    {survey.question}
                  </Checkbox>
                </Row>
              ))}
          </div>
          <p style={{ marginTop: 30 }}>
            <b>Check List</b>
          </p>

          <div style={{ height: "auto", overflowY: "auto" }}>
            {surveys
              .filter(
                (survey: any) =>
                  survey.callCenterSalesPackageId === selectedPackageId &&
                  survey.questionType === "CHECKLIST"
              )
              .map((survey: any) => (
                <Row style={{ marginBottom: 10 }}>
                  <Checkbox
                    key={survey.id}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, survey.id)
                    }
                    checked={checkedItems.includes(survey.id)}
                  >
                    {survey.question}
                  </Checkbox>
                </Row>
              ))}
          </div>
        </Modal>
        <p className="description" style={{ fontSize: 14 }}>
          {data?.accountCount} Total Shops
        </p>
        <div
          className="notes-container"
          style={{ height: "380px", overflow: "auto", marginTop: "16px" }}
        >
          <Row
            justify="space-between"
            align="middle"
            className="note-row-title"
          >
            <Col span={1} className="note-table-title"></Col>
            <Col span={10} className="note-table-title">
              Country
            </Col>
            <Col span={4} className="note-table-title">
              Status
            </Col>
            <Col span={4} className="note-table-title">
              Buybox
            </Col>
            <Col span={4} className="note-table-title">
              Create Date
            </Col>
          </Row>
          {data?.stores?.map((item: any) => (
            <Row
              key={item.id}
              justify="space-between"
              align="middle"
              className="note-row"
            >
              <Col
                span={1}
                className="note-text"
                style={{ paddingRight: 25, whiteSpace: "pre-wrap" }}
              >
                <p>
                  <b>{item.countryCode}</b>
                </p>
              </Col>
              <Col span={10} className="note-text">
                <Row>
                  <ReactCountryFlag
                    countryCode={item.countryCode.toLowerCase()}
                    svg
                    style={{
                      width: "1.8em",
                      height: "1.8em",
                      marginTop: 12,
                      marginRight: 10,
                    }}
                  />
                  <p>{item.country}</p>
                </Row>
              </Col>
              <Col
                span={4}
                className="note-text"
                style={{ paddingRight: 25, whiteSpace: "pre-wrap" }}
              >
                {item.active ? (
                  <Tag color={"green"}>Active</Tag>
                ) : (
                  <Tag color={"red"}>Passive</Tag>
                )}
              </Col>
              <Col span={4} className="note-text">
                {item.hasBuyBox ? (
                  <Tag color={"green"}>Active</Tag>
                ) : (
                  <Tag color={"red"}>Passive</Tag>
                )}
              </Col>
              <Col span={4} className="note-text">
                <Row align={"middle"}>
                  {moment(item.createDate).format("DD/MM/YYYY")}
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      </Card>
    </>
  );
};

export default StoreInformation;
