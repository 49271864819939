import { Input, Row, Space } from "antd";
import React, { useCallback, useState } from "react";
import Filter from "./Filter";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";

export const FilterField: React.FC<any> = ({
  onChangeSearch,
  setFilterText,
  pageSize,
  sortOrder,
  sortField,
  setFormFields,
  formFields,
  setFilterOptions,
}) => {
  const [searchText, setSearchText] = useState("");

  // eslint-disable-next-line
  const debouncedSearch = useCallback(_.debounce(onChangeSearch, 500), [
    onChangeSearch,
  ]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    debouncedSearch(newValue);
  };

  const clearInput = () => {
    setSearchText("");
    debouncedSearch("");
  };

  const handleOnChangeFilter = (filterForm: any) => {
    const normalizedSortOrder =
      sortOrder === "ascend"
        ? "asc"
        : sortOrder === "descend"
        ? "desc"
        : sortOrder;

    let filterQuery = `?page=0&size=${pageSize}&sort=${sortField}&direction=${normalizedSortOrder}&search=${searchText}`;

    if (filterForm.state && filterForm.state != null) {
      filterQuery += `&sessionStatus=${filterForm.state}`;
    }

    if (filterForm.hi5AcmMember && filterForm.hi5AcmMember != null) {
      filterQuery += `&hi5AcmMember=${filterForm.hi5AcmMember}`;
    }

    if (filterForm.hasNoPackages && filterForm.hasNoPackages != null) {
      filterQuery += `&hasNoPackages=${filterForm.hasNoPackages}`;
    }

    if (filterForm.additionalPackage && filterForm.additionalPackage != null) {
      filterQuery += `&additionalPackgeIds=${filterForm.additionalPackage}`;
    }

    if (filterForm.status && filterForm.status != null) {
      filterQuery += `&statusIds=${filterForm.status}`;
    }

    if (filterForm.hi5AcmStatus && filterForm.hi5AcmStatus != null) {
      filterQuery += `&hi5AcmStatusIds=${filterForm.hi5AcmStatus}`;
    }

    if (filterForm.currentPackage && filterForm.currentPackage != null) {
      filterQuery += `&currentPackage=${filterForm.currentPackage}`;
    }

    if (
      filterForm.packageOfInterestHi5Acm &&
      filterForm.packageOfInterestHi5Acm != null
    ) {
      filterQuery += `&packageOfInterestHi5AcmIds=${filterForm.packageOfInterestHi5Acm}`;
    }

    if (
      filterForm.currentPackageOfInterestHi5Acm &&
      filterForm.currentPackageOfInterestHi5Acm != null
    ) {
      filterQuery += `&currentPackageOfInterestHi5AcmIds=${filterForm.currentPackageOfInterestHi5Acm}`;
    }

    if (filterForm.startPassiveDay) {
      filterQuery += `&startPassiveDays=${filterForm.startPassiveDay}`;
    }

    if (filterForm.endPassiveDay) {
      filterQuery += `&endPassiveDays=${filterForm.endPassiveDay}`;
    }

    if (filterForm.startLastContactedHi5Acm) {
      filterQuery += `&startLastContactedHi5Acm=${filterForm.startLastContactedHi5Acm}`;
    }

    if (filterForm.endLastContactedHi5Acm) {
      filterQuery += `&endLastContactedHi5Acm=${filterForm.endLastContactedHi5Acm}`;
    }

    if (filterForm.startTotalOrderCount) {
      filterQuery += `&startTotalOrderCount=${filterForm.startTotalOrderCount}`;
    }

    if (filterForm.endTotalOrderCount) {
      filterQuery += `&endTotalOrderCount=${filterForm.endTotalOrderCount}`;
    }

    if (filterForm.sellerLevel) {
      filterQuery += `&sellerLevel=${filterForm.sellerLevel}`;
    }

    if (filterForm.level) {
      filterQuery += `&level=${filterForm.level}`;
    }

    if (filterForm.goldPackageHi5ACM) {
      filterQuery += `&goldPackageHi5ACM=${filterForm.goldPackageHi5ACM}`;
    }

    if (filterForm.slpPackage) {
      filterQuery += `&slpPackage=${filterForm.slpPackage}`;
    }

    if (filterForm.inactiveSlp7Days) {
      filterQuery += `&inactiveSlp7Days=${filterForm.inactiveSlp7Days}`;
    }

    if (filterForm.slpPackageEndDate !== undefined) {
      if (filterForm.slpPackageEndDate.length > 0) {
        let startDate = dayjs(filterForm.slpPackageEndDate[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.slpPackageEndDate[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startSlpEndDate=${startDate}&endSlpEndDate=${endDate}`;
      }
    }

    if (filterForm.goldPackageEndDate !== undefined) {
      if (filterForm.goldPackageEndDate.length > 0) {
        let startDate = dayjs(filterForm.goldPackageEndDate[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.goldPackageEndDate[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startGoldPackageEndDate=${startDate}&endGoldPackageEndDate=${endDate}`;
      }
    }

    if (filterForm.lastSalesDay !== undefined) {
      if (filterForm.lastSalesDay.length > 0) {
        let startDate = dayjs(filterForm.lastSalesDay[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.lastSalesDay[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startLastSalesDate=${startDate}&endLastSalesDate=${endDate}`;
      }
    }

    if (filterForm.createdAtRange !== undefined) {
      if (filterForm.createdAtRange.length > 0) {
        let startDate = dayjs(filterForm.createdAtRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.createdAtRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startCreatedAt=${startDate}&endCreatedAt=${endDate}`;
      }
    }

    if (filterForm.assignmentDateRange !== undefined) {
      if (filterForm.assignmentDateRange.length > 0) {
        let startDate = dayjs(filterForm.assignmentDateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.assignmentDateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startAssignmentDate=${startDate}&endAssignmentDate=${endDate}`;
      }
    }

    if (filterForm.subscriptionEndDateRange !== undefined) {
      if (filterForm.subscriptionEndDateRange.length > 0) {
        let startDate = dayjs(filterForm.subscriptionEndDateRange[0]).format(
          "YYYY-MM-DD"
        );
        let endDate = dayjs(filterForm.subscriptionEndDateRange[1]).format(
          "YYYY-MM-DD"
        );
        filterQuery += `&subscriptionEndDateStartDate=${startDate}&subscriptionEndDateEndDate=${endDate}`;
      }
    }

    if (filterForm.registeredDateRange !== undefined) {
      if (filterForm.registeredDateRange.length > 0) {
        let startDate = dayjs(filterForm.registeredDateRange[0]).format(
          "YYYY-MM-DD"
        );
        let endDate = dayjs(filterForm.registeredDateRange[1]).format(
          "YYYY-MM-DD"
        );
        filterQuery += `&registeredAtStartDate=${startDate}&registeredAtEndDate=${endDate}`;
      }
    }

    if (filterForm.hi5AcmAssignmentDateRange !== undefined) {
      if (filterForm.hi5AcmAssignmentDateRange.length > 0) {
        let startDate = dayjs(filterForm.hi5AcmAssignmentDateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.hi5AcmAssignmentDateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&hi5AcmAssignmentDateStartDate=${startDate}&hi5AcmAssignmentDateEndDate=${endDate}`;
      }
    }

    if (filterForm.autoSubscriptionRenewalEnabled) {
      filterQuery += `&autoSubscriptionRenewalEnabled=${filterForm.autoSubscriptionRenewalEnabled}`;
    }

    if (filterForm.autoSlpSubscriptionRenewalEnabled) {
      filterQuery += `&autoSlpSubscriptionRenewalEnabled=${filterForm.autoSlpSubscriptionRenewalEnabled}`;
    }

    if (filterForm.autoGoldSubscriptionRenewalEnabled) {
      filterQuery += `&autoGoldSubscriptionRenewalEnabled=${filterForm.autoGoldSubscriptionRenewalEnabled}`;
    }

    setFilterText(filterQuery);
  };

  return (
    <Row justify="end">
      <Space align="start">
        <Input
          prefix={<SearchOutlined />}
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={clearInput}
                style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
              />
            )
          }
          placeholder="Search"
          value={searchText}
          onChange={onSearchChange}
          style={{ maxWidth: 200 }}
        />
        <Filter
          onChangeFilter={handleOnChangeFilter}
          setFormFields={setFormFields}
          formFields={formFields}
          setFilterOptions={setFilterOptions}
        />
      </Space>
    </Row>
  );
};
