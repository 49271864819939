import {
  Button,
  Modal,
  Form,
  Switch,
  Select,
  DatePicker,
  Row,
  Col,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import LearnerStatusService from "../../services/LearnerStatusService";
import UserService from "../../services/UserService";
import { ILearnerStatusResponseData } from "../../types/LearnerStatus";
import TeamService from "../../services/TeamService"; // Eklendi
import { ITeamResponseData } from "../../types/Team"; // Eklendi
import { IUserResponseData } from "../../types/User";
import { can } from "../../utils";
import dayjs from "dayjs";
import filterIcon from "../../assets/filter.svg";
import filterClearIcon from "../../assets/filter-clear.svg";

const { Item } = Form;

interface LearnerFilterProps {
  onChangeFilter: (values: any) => void;
  setFilterOptions: any;
  param: any;
}

const Filter: React.FC<LearnerFilterProps> = ({
  onChangeFilter,
  setFilterOptions,
  param,
}) => {
  const { RangePicker } = DatePicker;
  const [lastFilter, setlastFilter] = useState(param);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [learnerStatusOptions, setLearnerStatusOptions] = useState<
    ILearnerStatusResponseData[]
  >([]);
  const [teamOptions, setTeamOptions] = useState<ITeamResponseData[]>([]); // Eklendi
  const [userOptions, setUserOptions] = useState<IUserResponseData[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [disableProductCount, setDisableProductCount] = useState(false);
  const roleName = localStorage.getItem("roleName");
  const [callTeamOptions, setCallTeamOptions] = useState<any[]>([]);
  const savedLocalFilters = localStorage.getItem("learnerFilterText");

  const productCounts = [
    { key: 0, value: 0, label: "0" },
    { key: 5000, value: 5000, label: "5K" },
    { key: 10000, value: 10000, label: "10K" },
    { key: 25000, value: 25000, label: "25K" },
    { key: 50000, value: 50000, label: "50K" },
    { key: 100000, value: 100000, label: "100K" },
    { key: 150000, value: 150000, label: "150K" },
    { key: 200000, value: 200000, label: "200K" },
    { key: 250000, value: 250000, label: "250K" },
    { key: 500000, value: 500000, label: "500K" },
    { key: 750000, value: 750000, label: "750K" },
  ];

  const showModal = () => {
    if (learnerStatusOptions.length === 0) {
      getLearnerStatus();
    }
    // Eklendi
    if (teamOptions.length === 0) {
      getTeams();
    }
    if (userOptions.length === 0) {
      getUsers();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    const filters = [];

    if (values.goldUser !== undefined) {
      filters.push({
        name: "Gold User",
        value: values.goldUser === "true" ? "Yes" : "No",
      });
    }

    if (
      values.successTeamMember !== undefined &&
      values.successTeamMember !== null
    ) {
      const selectedUser = userOptions.find(
        (user) => user.id === values.successTeamMember
      );
      const userName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
      filters.push({ name: "Success Team Member", value: userName });
    }
    // Yeni eklendi
    if (values.learnerTeam !== undefined && values.learnerTeam !== null) {
      const selectedTeam = teamOptions.find(
        (team) => team.id === values.learnerTeam
      );
      const id = `${selectedTeam?.id}`;
      filters.push({ name: "Team", value: id });
    }

    if (values.callTeamMember !== undefined && values.callTeamMember !== null) {
      const selectedUser = callTeamOptions.find(
        (user) => user.id === values.callTeamMember
      );
      const userName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
      filters.push({ name: "Call Team", value: userName });
    }

    if (values.learningRange !== undefined && values.learningRange !== null) {
      filters.push({ name: "Lesson", value: values.learningRange });
    }

    if (
      values.lastLoginAtRange !== undefined &&
      values.lastLoginAtRange !== null
    ) {
      filters.push({
        name: "Last Logit At",
        value: `${values.lastLoginAtRange[0].format(
          "DD/MM/YYYY"
        )} - ${values.lastLoginAtRange[1].format("DD/MM/YYYY")}`,
      });
    }

    if (
      values.becomeCustomerAtRange !== undefined &&
      values.becomeCustomerAtRange !== null
    ) {
      filters.push({
        name: "Integrated At",
        value: `${values.becomeCustomerAtRange[0].format(
          "DD/MM/YYYY"
        )} - ${values.becomeCustomerAtRange[1].format("DD/MM/YYYY")}`,
      });
    }

    if (values.createdAtRange !== undefined && values.createdAtRange !== null) {
      filters.push({
        name: "Created At",
        value: `${values.createdAtRange[0].format(
          "DD/MM/YYYY"
        )} - ${values.createdAtRange[1].format("DD/MM/YYYY")}`,
      });
    }

    if (values.becomeCustomer !== undefined) {
      filters.push({
        name: "Customer",
        value: values.becomeCustomer ? "Yes" : "No",
      });
    }

    if (values.hasLoggedInAtAcademy !== undefined) {
      filters.push({
        name: "Not Logged In",
        value: values.hasLoggedInAtAcademy ? "Yes" : "No",
      });
    }

    const selectedStatuses =
      values.status && values.status.length > 0
        ? values.status
            .map((statusId: any) => {
              const statusOption = learnerStatusOptions.find(
                (option) => option.id === statusId
              );
              return statusOption ? statusOption.name : null;
            })
            .filter((name: any) => name !== null)
        : [];

    if (selectedStatuses.length > 0) {
      filters.push({ name: "Status", value: selectedStatuses });
    }

    setFilterOptions(filters);
    onChangeFilter(values);
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getLearnerStatus = () => {
    LearnerStatusService.getAll()
      .then((response: any) => {
        let data = response.data;
        setLearnerStatusOptions(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getTeams = () => {
    TeamService.getAll()
      .then((response: any) => {
        let data = response.data;
        setTeamOptions(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getUsers = () => {
    if (roleName === "CALL_TEAM_MANAGER") {
      UserService.getAll("?roleName=CALL_TEAM")
        .then((response: any) => {
          let data = response;
          setCallTeamOptions(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
    if (can("learners:getAll")) {
      UserService.getAll("?roleName=SUCCESS_TEAM")
        .then((response: any) => {
          let data = response;
          setUserOptions(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setDisableProductCount(false);
    setSelectedItems([]);
    localStorage.removeItem("learnerFilterText");
  };

  const clearLocalFilterAndForm = () => {
    clearForm();
    onFinish({ ...form.getFieldsValue() });
  };

  const selectAll = () => {
    const allIds = learnerStatusOptions.map((option: any) => option.id);
    setSelectedItems(allIds);
    form.setFieldsValue({
      status: allIds,
    });
  };

  const handleChange = (selected: any) => {
    setSelectedItems(selected);
    form.setFieldsValue({
      status: selected,
    });
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { startProductCount, endProductCount } = allValues;
    if (
      startProductCount &&
      (endProductCount < startProductCount || !endProductCount)
    ) {
      form.setFieldsValue({ endProductCount: startProductCount });
    }
  };

  const handleStartCountChange = (newValue: any) => {
    const endValue = form.getFieldValue("endProductCount");
    if (endValue === undefined || endValue <= newValue) {
      const nextValueIndex =
        productCounts.findIndex((item) => item.value === newValue) + 1;
      if (nextValueIndex < productCounts.length) {
        form.setFieldsValue({
          endProductCount: productCounts[nextValueIndex].value,
        });
      }
    }
  };

  useEffect(() => {
    if (userOptions.length > 0 && lastFilter !== "") {
      const queryParams = new URLSearchParams(lastFilter);
      if (queryParams.get("userId")) {
        const selectedUser: any = userOptions.find(
          (option: any) => option.id == queryParams.get("userId")
        );
        form.setFieldsValue({
          successTeamMember: selectedUser.id,
        });
      }
      // Yeni eklendi
      if (queryParams.get("teamId")) {
        const selectedTeam: any = teamOptions.find(
          (option: any) => option.id == queryParams.get("teamId")
        );
        form.setFieldsValue({
          learnerTeam: selectedTeam.id,
        });
      }

      if (queryParams.get("hasLoggedInAtAcademy")) {
        form.setFieldsValue({
          hasLoggedInAtAcademy:
            queryParams.get("hasLoggedInAtAcademy") == "true" ? true : false,
        });
      }
      if (queryParams.get("becomeCustomer")) {
        form.setFieldsValue({
          becomeCustomer:
            queryParams.get("becomeCustomer") == "true" ? true : false,
        });
      }
      if (queryParams.get("goldUser")) {
        form.setFieldsValue({
          goldUser: queryParams.get("goldUser") == "true" ? "true" : "false",
        });
      }

      if (
        queryParams.get("startLessonsCompleted") &&
        queryParams.get("endLessonsCompleted")
      ) {
        form.setFieldsValue({
          learningRange: `${queryParams.get(
            "startLessonsCompleted"
          )}-${queryParams.get("endLessonsCompleted")}`,
        });
      }
      if (
        queryParams.get("remainingFreeDaysStart") &&
        queryParams.get("remainingFreeDaysEnd")
      ) {
        form.setFieldsValue({
          learningRange: `${queryParams.get(
            "remainingFreeDaysStart"
          )}-${queryParams.get("remainingFreeDaysEnd")}`,
        });
      }
      if (queryParams.get("totalProductCountMax")) {
        const selectedCount = productCounts.find(
          (option: any) => option.key == queryParams.get("totalProductCountMax")
        );
        form.setFieldsValue({
          endProductCount: selectedCount?.value,
        });
      }

      if (queryParams.get("totalProductCountMin")) {
        const selectedCount = productCounts.find(
          (option: any) => option.key == queryParams.get("totalProductCountMin")
        );
        form.setFieldsValue({
          startProductCount: selectedCount?.value,
        });
      }

      if (queryParams.get("statusIds")) {
        const statusIdsParam: any = queryParams.get("statusIds");
        const statusIds = statusIdsParam
          .split(",")
          .map((id: any) => parseInt(id));
        setSelectedItems(statusIds);
        form.setFieldsValue({ status: statusIds });
      }

      if (
        queryParams.get("startLastLoginAt") &&
        queryParams.get("endLastLoginAt")
      ) {
        const startMoment = dayjs(
          queryParams.get("startLastLoginAt"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const endMoment = dayjs(
          queryParams.get("endLastLoginAt"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({ lastLoginAtRange: [startMoment, endMoment] });
      }

      if (
        queryParams.get("startBecomeCustomerAt") &&
        queryParams.get("endBecomeCustomerAt")
      ) {
        const startMoment = dayjs(
          queryParams.get("startBecomeCustomerAt"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const endMoment = dayjs(
          queryParams.get("endBecomeCustomerAt"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          becomeCustomerAtRange: [startMoment, endMoment],
        });
      }
      if (
        queryParams.get("startCreatedAt") &&
        queryParams.get("endCreatedAt")
      ) {
        const startMoment = dayjs(
          queryParams.get("startCreatedAt"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const endMoment = dayjs(
          queryParams.get("endCreatedAt"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({ createdAtRange: [startMoment, endMoment] });
      }
    }
  }, [isModalOpen, userOptions]);

  return (
    <>
      <Modal
        title="Learner Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <Row gutter={16}>
            <Col span={24}>
              Status
              <Item name="status">
                <Row gutter={16}>
                  <Col span={18}>
                    <Select
                      allowClear
                      placeholder="Seçiniz"
                      mode="multiple"
                      value={selectedItems}
                      onChange={handleChange}
                      showSearch
                      //   style={{ width: "75%", marginRight: "10px" }}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {learnerStatusOptions.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <Button style={{ float: "right" }} onClick={selectAll}>
                      Select All
                    </Button>
                  </Col>
                </Row>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              {can("learners:getAll") && (
                <div>
                  Success Team Member
                  <Item name="successTeamMember">
                    <Select placeholder={"Select"}>
                      <Select.Option key={0} value={null}>
                        Select
                      </Select.Option>
                      {userOptions.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.firstName} {option.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </div>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              {roleName !== "SUCCESS_TEAM" &&
              roleName !== "SUCCESS_TEAM_MANAGER" &&
              callTeamOptions &&
              roleName !== "CALL_TEAM" ? (
                <>
                  Call Team Member
                  <Item name="callTeamMember">
                    <Select placeholder={"Select"}>
                      <Select.Option key={0} value={null}>
                        Select
                      </Select.Option>
                      {callTeamOptions?.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.firstName} {option.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </>
              ) : null}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              {/* Yeni eklendi */}
              Team
              <Item name="learnerTeam">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {teamOptions.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Lesson
              <Item name="learningRange">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"0-10"} value="0-10">
                    0-10
                  </Select.Option>
                  <Select.Option key={"11-20"} value="11-20">
                    11-20
                  </Select.Option>
                  <Select.Option key={"21-30"} value="21-30">
                    21-30
                  </Select.Option>
                  <Select.Option key={"31-40"} value="31-40">
                    31-40
                  </Select.Option>
                  <Select.Option key={"41-50"} value="41-50">
                    41-50
                  </Select.Option>
                  <Select.Option key={"51-60"} value="51-60">
                    51-60
                  </Select.Option>
                  <Select.Option key={"61-70"} value="61-70">
                    61-70
                  </Select.Option>
                  <Select.Option key={"71-83"} value="71-83">
                    71-83
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Last Logit At
              <Item name="lastLoginAtRange">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Integrated At
              <Item name="becomeCustomerAtRange">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Created At
              <Item name="createdAtRange">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Gold User
              <Item name="goldUser">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"true"} value="true">
                    Yes
                  </Select.Option>
                  <Select.Option key={"false"} value="false">
                    No
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Remaining Free Days
              <Item name="remainingFreeDays">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"0-10"} value="0-10">
                    0-10
                  </Select.Option>
                  <Select.Option key={"11-20"} value="11-20">
                    11-20
                  </Select.Option>
                  <Select.Option key={"21-30"} value="21-30">
                    21-30
                  </Select.Option>
                  <Select.Option key={"31-40"} value="31-40">
                    31-45
                  </Select.Option>
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Flag
              <Item name="flag">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={1} value="green">
                    Green Flag
                  </Select.Option>
                  <Select.Option key={2} value="yellow">
                    Yellow Flag
                  </Select.Option>
                  <Select.Option key={3} value="red">
                    Red Flag
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              Product Count
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="startProductCount"
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      placeholder="Select a Start Count"
                      onChange={handleStartCountChange}
                      disabled={disableProductCount}
                    >
                      {productCounts.map((item) => (
                        <Select.Option key={item.key} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.startProductCount !==
                      currentValues.startProductCount
                    }
                    style={{ marginBottom: 0 }}
                  >
                    {({ getFieldValue }) => {
                      const startValue = getFieldValue("startProductCount");
                      const filteredCounts = productCounts.filter(
                        (item) =>
                          item.value >=
                          (startValue === 750000 ? startValue : startValue + 1)
                      );
                      return (
                        <Form.Item
                          name="endProductCount"
                          style={{ marginBottom: 0 }}
                        >
                          <Select
                            placeholder="Select an End Count"
                            disabled={disableProductCount}
                          >
                            {filteredCounts.map((item) => (
                              <Select.Option key={item.key} value={item.value}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
              {/* </div> */}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Item
                label="Customer"
                name="becomeCustomer"
                valuePropName="checked"
                style={{ marginTop: "10px" }}
              >
                <Switch style={{ marginTop: "10px" }} />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="Not Logged In"
                name="hasLoggedInAtAcademy"
                valuePropName="checked"
                style={{ marginTop: "10px" }}
              >
                <Switch style={{ marginTop: "10px" }} />
              </Item>
            </Col>
          </Row>
          <Item>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
      <Tooltip title="Filters">
        <Button
          style={{ marginRight: "10px" }}
          onClick={showModal}
          type="default"
        >
          <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
      {/* <Button className="mr-15" onClick={showModal} type="primary">
        Filter
      </Button> */}
      {savedLocalFilters && savedLocalFilters.length > 0 ? (
        <Tooltip title="Clear Filters">
          <Button
            style={{ marginRight: "10px" }}
            onClick={clearLocalFilterAndForm}
            type="default"
          >
            <img
              src={filterClearIcon}
              alt="userIcon"
              style={{ margin: "1px" }}
            />
          </Button>
        </Tooltip>
      ) : null}
    </>
  );
};

export default Filter;
