import React from "react";
import { Card, Progress } from "antd";
import "./info-card.css";
import { convertDate } from "../../utils";

const CustomerInfo: React.FC<any> = ({ customer, learner }) => {
  interface EcudationStatusProps {
    completed?: number;
  }
  const roleName = localStorage.getItem("roleName");
  const EcudationStatus = ({ completed }: EcudationStatusProps) => {
    if (completed === undefined) {
      return (
        <Progress
          strokeColor={"red"}
          type="line"
          percent={0}
          format={(percent) => `${0}`}
        />
      );
    }
    const lessonTotalCount = 83;
    let percent: number = parseInt(
      ((completed / lessonTotalCount) * 100).toString()
    );

    let color: string = "red";

    if (completed < 10) {
      color = "red";
    } else if (completed >= 10 && completed < 20) {
      color = "volcano";
    } else if (completed >= 20 && completed < 40) {
      color = "orange";
    } else {
      color = "green";
    }

    return (
      <>
        <Progress
          strokeColor={color}
          type="line"
          percent={percent}
          format={(percent) => `${completed}`}
        />
      </>
    );
  };

  return (
    <Card bordered={false} className={"card"}>
      <p className="title">Customer Info</p>
      {roleName !== "MAS_TEAM_MANAGER" && roleName !== "MAS_TEAM_MEMBER" ? (
        <>
          <p className="sub-title">Lesson</p>
          <EcudationStatus completed={learner?.lessonsCompleted} />
        </>
      ) : null}      
      <div className="lesson-card-container">
        <div>
          <p className="date-header">Integrated At:</p>
          <p className="date-description">
            {convertDate(learner?.becomeCustomerAt)}
          </p>
        </div>
        <div>
          <p className="date-header" style={{textAlign: "right"}}>Last Login At:</p>
          <p className="date-description" style={{textAlign: "right"}}>
            {convertDate(learner?.lastLoginAt)}
          </p>
        </div>
      </div>
      <div className="lesson-card-container">
        <div>
          <p className="date-header">Subscription Start Date:</p>
          <p className="date-description">
            {convertDate(customer?.subscriptionStartDate)}
          </p>
        </div>
        <div>
          <p className="date-header" style={{textAlign: "right"}}>Subscription End Date:</p>
          <p className="date-description" style={{textAlign: "right"}}>
            {convertDate(customer?.subscriptionEndDate)}
          </p>
        </div>
      </div>
      <div className="border-bottom">
        <p className="header">Order</p>
        <p className="info-header">Passive Days:</p>
        <p className="info-description">{customer?.passiveDays ?? 0}</p>
      </div>
      <div className="border-bottom">
        <p className="info-header">Last Sales Date:</p>
        <p className="info-description">
          {convertDate(customer?.lastSalesDate)}
        </p>
      </div>
      <div className="border-bottom">
        <p className="info-header">Last Contact Date:</p>
        <p className="info-description">{convertDate(customer?.contactDate)}</p>
      </div>
    </Card>
  );
};

export default CustomerInfo;
