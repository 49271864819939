import React from 'react';
import { Form, Input, Button, Row, Col, message, Card } from 'antd';
import axios from 'axios';
import { openNotification } from '../../utils';


const PasswordChangeForm = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = React.useState<boolean>(false);

    const passwordRules = [
        { required: true, message: 'Lütfen parolanızı giriniz!' },
        { min: 12, message: 'Parola en az 12 karakter olmalıdır.' },
        {
            pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})'),
            message: 'Parola büyük harf, küçük harf, sayı ve özel karakter içermelidir.'
        },
        // Diğer kurallar (kullanıcı adı kontrolü, önceki parolalarla karşılaştırma vb.)
    ];

    const handleSubmit = (values: any) => {
        setLoading(true)
        const apiUrl = process.env.REACT_APP_EXTERNAL_API_BASE_URL + '/app/setting/password?password=' + values.newPassword;

        let externalToken = localStorage.getItem('externalAccessToken');

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: apiUrl,
            headers: {
                'authority': 'api.oneamz.com',
                'accept': 'application/json',
                'content-type': 'application/json',
                'authorization': `Bearer ${externalToken}`,
            },
        };

        axios.request(config)
            .then((response: any) => {
                if (response.data.result === 'CONTAINS_USER_INFO') {
                    openNotification('error', 'Başarısız', 'Lütfen Şifrenizin Herhangi Bir Bölümünde Kendi İsminizi Kullanmayın!');
                }
                else if (response.data.result === 'USED_BEFORE') {
                    openNotification('error', 'Başarısız', 'Bu Şifre Daha Önceden Kullanılmış.');
                }
                else {
                    openNotification('success', 'Başarılı', 'Şifre Başarıyla Değiştirildi!');
                    console.log(response);
                }
                setLoading(false)
            })
            .catch((error) => {
                openNotification('error', 'Hata', 'Şifre Değiştirilemedi');
                setLoading(false)
                console.log(error);
            });

        console.log('Received values of form: ', values);
    };

    return (
        <>
            <Card loading={loading} style={{width:"100%", marginTop:"2rem"}}>
                <Form style={{ width: "100%" }}
                    name="passwordChange"
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col style={{ padding: "1em" }} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name="oldPassword"
                                rules={[{ required: true, message: 'Lütfen eski şifrenizi giriniz!' }]}
                            >
                                <Input.Password size='large' placeholder="Eski Şifre" />
                            </Form.Item>

                        </Col>
                        <Col style={{ padding: "1em" }} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name="newPassword"
                                rules={passwordRules}
                            >
                                <Input.Password size='large' placeholder="Yeni Şifre" />
                            </Form.Item>
                        </Col>
                        <Col style={{ padding: "1em" }} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name="confirmNewPassword"
                                dependencies={['newPassword']}
                                hasFeedback
                                rules={[
                                    { required: true, message: 'Lütfen yeni şifrenizi tekrar giriniz!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('İki şifre birbiriyle eşleşmiyor!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password size='large' placeholder="Yeni Şifreyi Tekrar Gir" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify={'end'}>
                        <Col style={{ padding: "1em" }}>
                            <Form.Item>
                                <Button type="primary" size='large' htmlType="submit">
                                    Şifre Değiştir
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
};

export default PasswordChangeForm