import http from "../http-common";

const getAll = () => {
  return http.get<Array<any>>(`/prospect-customer-source`);
};

const get = (id: any) => {
  return http.get<Array<any>>(`/prospect-customer-source/${id}`);
};

const create = (data: any) => {
  return http.post<any>("/prospect-customer-source", data);
};

const update = (id: any, data: any) => {
  return http.put<any>(`/prospect-customer-source/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/prospect-customer-source/${id}`);
};

const ProspectCustomerSourcesService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default ProspectCustomerSourcesService;
