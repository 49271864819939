import http from "../http-common";
import { INotificationResponseData } from "../types/Notification";

const getMeNotification = (isRead?: boolean, page: number = 0, size: number = 10, sort: string = "createdAt,desc") => {
  return http.get<INotificationResponseData[]>(`/users/me/notifications`, {
    params: {
      isRead: isRead,
      page: page,
      size: size,
      sort: sort,
    },
  });
};

const readNotification = (id: number) => {
  return http.put<INotificationResponseData>(`/notifications/${id}/read`);
};

const getAllNotifications = () => {
  return http.get<INotificationResponseData[]>(`/notifications`);
};

const getNotificationById = (id: number) => {
  return http.get<INotificationResponseData>(`/notifications/${id}`);
};

const NotificationService = {
  getMeNotification,
  readNotification,
  getAllNotifications,
  getNotificationById,
};

export default NotificationService;
