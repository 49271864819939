import React from "react";
import { Card, Col, Row, Tag, Tooltip } from "antd";
import "./../info-card.css";
import NewTask from "./NewTask";
import moment from "moment";
import TaskDetailCustomer from "../../Task/TaskDetailCustomer";

const Tasks: React.FC<any> = ({
  customerName,
  tasks,
  customerId,
  setTriggerUpdate,
  userOptions,
}) => {
  const userId = localStorage.getItem("id");
  const roleName = localStorage.getItem("roleName");
  const filteredTasksCount = tasks?.filter(
    (task: any) => task?.assignee?.id === userId || task?.reporter?.id === userId
  ).length;

  const handleTaskList = () => {
    setTriggerUpdate((prev: number) => prev + 1);
  };
  return (
    <>
      <Card bordered={false} className={"card"}>
        <Row justify={"space-between"}>
          <p className="title">Tasks</p>
          {roleName !== "SUCCESS_TEAM_REPORTER" ? (
            <NewTask
              customerName={customerName}
              customerId={customerId}
              setTriggerUpdate={setTriggerUpdate}
              userOptions={userOptions}
            />
          ) : null}
          {/* <Button type='primary' >Add Task</Button> */}
        </Row>
        <p className="description">{filteredTasksCount} Tasks</p>
        <div className="notes-container">
          {tasks?.map((item: any) => (
            <>
              {item?.assignee?.id == userId || item?.reporter?.id == userId ? (
                <Row
                  align="middle"
                  style={{ padding: "10px", borderBottom: "1px solid #e8e8e8" }}
                >
                  <Col span={2}>
                    <Tag
                      color="green"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "32px",
                      }}
                    >
                      {item?.status}
                    </Tag>
                  </Col>
                  <Col span={6}>
                    <Row align="middle">
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "600" }}>
                          {item?.customer?.fullName}{" "}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col
                    span={10}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Row>
                      <div>
                        <Tooltip title={`${item?.description}`}>
                          <p
                            style={{
                              fontSize: 12,
                              fontWeight: "500",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "28ch",
                            }}
                          >
                            {item?.title}
                          </p>
                        </Tooltip>
                      </div>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Row>
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "500" }}>
                          {moment(item?.dueDate).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col span={2}>
                    <TaskDetailCustomer
                      item={item}
                      onHandleTaskList={handleTaskList}
                      type={1}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Tasks;
