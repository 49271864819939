import { Button, Col, Form, Row, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import type { TabsProps } from "antd";
import { EditOutlined, PhoneOutlined, GlobalOutlined } from "@ant-design/icons";
import { openNotification } from "../../utils";
import AmazonSuspendedApplicantService from "../../services/AmazonSuspendedApplicantService";

interface AmazonSuspendApplicantProps {
  id: number;
  onNoteAdded: () => void;
  onClose: any;
  getNotes: () => void;
}

const CustomerNoteCreate: React.FC<AmazonSuspendApplicantProps> = ({
  id,
  onNoteAdded,
  onClose,
  getNotes,
}) => {
  const [noteForm] = Form.useForm();
  const [activeTab, setActiveTab] = useState<string>("INTERNAL");
  const [loading, setLoading] = useState(false);

  const createNote = (values: any) => {
    setLoading(true);
    let internal = {
      description: values.description,
      type: activeTab,
    };
    AmazonSuspendedApplicantService.createNote(id, internal)
      .then((response: any) => {
        openNotification("success", "Success", "Note added");
        noteForm.resetFields();
        onNoteAdded();
        getNotes();
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const tabChange = (values: any) => {
    setActiveTab(values);
  };

  const items: TabsProps["items"] = [
    {
      key: "INTERNAL",
      label: (
        <>
          <EditOutlined />
          Note
        </>
      ),
    },
    {
      key: "CONTACT",
      label: (
        <>
          <PhoneOutlined />
          Contact Note
        </>
      ),
    },
    {
      key: "EXTERNAL",
      label: (
        <>
          <GlobalOutlined />
          External Note
        </>
      ),
    },
  ];

  useEffect(() => {
    noteForm.resetFields();
  }, [onClose]);

  return (
    <div>
      <Row style={{ marginTop: "3em" }}>
        <Col span={24}>
          <Tabs
            defaultActiveKey={activeTab}
            items={items}
            onChange={tabChange}
          />
          <Row justify={"center"}>
            <Col span={24}>
              <Form form={noteForm} layout="vertical" onFinish={createNote}>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Note required",
                    },
                    {
                      pattern: new RegExp(/\S/),
                      message: "Note cannot be empty or spaces only",
                    },
                  ]}
                >
                  <TextArea
                    showCount
                    maxLength={1000}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Note"
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 21, span: 24 }}>
                  <Button
                    loading={loading}
                    className="oneamz-primary-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerNoteCreate;
