import React from "react";
import {Card, Col, Row, Tag } from "antd";
import "./../info-card.css";
// import { PushpinOutlined, PushpinFilled } from "@ant-design/icons";
// import LearnerNoteCreate from "../../LearnerDetail/LearnerNoteCreate";
import NewTask from "./NewTask";
import moment from "moment";
import TaskDetail from "../../Task/TaskDetail";

// interface InfoCardProps {
//   count: string;
//   title: string;
//   description: string;
// }

const Tasks: React.FC<any> = ({
  customerName,
  tasks,
  learnerId,
  setTriggerUpdate,
  isCustomer,
  userOptions,
}) => {
  const userId = localStorage.getItem("id");
  const roleName = localStorage.getItem("roleName");

  const filteredTasksCount = tasks?.filter(
    (task: any) => task?.assignee.id == userId || task?.reporter.id == userId
  ).length;

  return (
    <>
      <Card bordered={false} className={"card"}>
        <Row justify={"space-between"}>
          <p className="title">Tasks</p>
          {roleName !== "SUCCESS_TEAM_REPORTER" ? (
            <NewTask
              customerName={customerName}
              customerId={learnerId}
              setTriggerUpdate={setTriggerUpdate}
              isCustomer={isCustomer}
              userOptions={userOptions}
            />
          ) : null}

          {/* <Button type='primary' >Add Task</Button> */}
        </Row>
        <p className="description">{filteredTasksCount} Tasks</p>
        <div className="notes-container">
          {tasks?.map((item: any) => (
            <>
              {item.assignee.id == userId || item.reporter.id == userId ? (
                <Row
                  align="middle"
                  style={{ padding: "10px", borderBottom: "1px solid #e8e8e8" }}
                >
                  <Col span={6}>
                    <Row align="middle">
                      <Tag
                        color="green"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "32px",
                        }}
                      >
                        {item.status}
                      </Tag>
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "600" }}>
                          {item.customer
                            ? `${item.customer?.fullName}`
                            : `${item.learner?.firstName} ${item.learner?.lastName}`}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Row>
                      <div>
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: "500",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "45ch",
                          }}
                        >
                          {item.title}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Row>
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "500" }}>
                          {moment(item.dueDate).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col span={2}>
                    <TaskDetail
                      item={item}
                      type={1}
                      onHandleTaskList={() => undefined}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Tasks;
