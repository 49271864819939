import React, { useEffect } from "react";
import { Card } from "antd";
import "./acm-dashboard.css";

interface InfoCardProps {
  count: string;
  title: string;
  description: string;
  dateRange: any;
  loading: boolean;
}

const InfoCard: React.FC<InfoCardProps> = ({
  count,
  title,
  description,
  dateRange,
  loading,
}) => {
  return (
    <Card bordered={false} className={"card"} loading={loading}>
      <div className="info-card-container">
        <div>
          <p className="title">{title}</p>
          <p className="description">{description}</p>
        </div>
        <p className="count">{count}</p>
      </div>
    </Card>
  );
};

export default InfoCard;
