import http from "../http-common";
import { IDepartmentResponseData, IDepartmentRequestData } from "../types/Department";

const getAll = () => {
  return http.get<Array<IDepartmentResponseData>>("/departments");
};

const get = (id: number) => {
  return http.get<IDepartmentResponseData>(`/departments/${id}`);
};

const create = (data: IDepartmentRequestData) => {
  return http.post<IDepartmentRequestData>("/departments", data);
};

const update = (id: number, data: IDepartmentRequestData) => {
  return http.put<any>(`/departments/${id}`, data);
};

const remove = (id: number) => {
  return http.delete<any>(`/departments/${id}`);
};

const DepartmentService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default DepartmentService;
