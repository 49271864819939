import { Card, Row, Table, Button, Tag, Modal, Pagination, Select } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { CopyClipboard } from "../../General/CopyClipboard";
import { StartCall } from "../../General/StartCall";
import { convertDate, formatMoney, openNotification } from "../../../utils";
import CustomerService from "../../../services/CustomerService";
import { useLocation, useNavigate } from "react-router-dom";
import type { PaginationProps } from "antd";
import { RetentionFilterField } from "./RetentionFilterField";
import DownloadExcel from "../../CustomerDetail/DownloadExcel";
import { ICustomerStatusResponseData } from "../../../types/CustomerStatus";
import UserService from "../../../services/UserService";
import goldUser from "./../../../assets/goldUser.svg";
import ReportService from "../../../services/ReportService";
import StmMultiNote from "./StmMultiNote";
import StmCustomerMultiTask from "./StmCustomerMultiTask";
import StmMultiStatus from "./StmMultiStatus";
import DateRange from "../../Common/DateRange";
import moment from "moment";
import StmMultiAssign from "./StmMultiAssign";
import NewTask from "../../UserDetail/Tasks/NewTask";
import StatusService from "../../../services/StatusService";


interface User {
  id: number;
  createdDate: string;
  fullName: string;
  email: string;
  phone: string;
  currentPackage: number;
  status: any;
  activityStatus: string;
  state: string;
  passiveDays: number;
  productCount: number;
  orderCount: number;
  oneamzOrderCount: number;
  freeDays: number;
  lastSalesDate: string;
  sellerLevel: string;
  feedback: number;
  username: string;
  goldUser: boolean;
  lastLoginDate: string;
  subscriptionStartDate: string;
}

export const RetentionCustomerList: React.FC<any> = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const roleName = localStorage.getItem("roleName");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<
    ICustomerStatusResponseData[]
  >([]);
  const [
    packageOfInterestStatusForStmOptions,
    setPackageOfInterestStatusForStmOptions,
  ] = useState<any[]>([]);
  const [
    oldPackageOfInterestStatusForStmOptions,
    setOldPackageOfInterestStatusForStmOptions,
  ] = useState<any[]>([]);
  // const [sortField, setSortField] = useState("lastContactedDateStm");
  // const [sortOrder, setSortOrder] = useState("desc");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastSortField  = location.state?.sortField ?? "autoSubscriptionRenewalEnabled";
  const lastSortOrder = location.state?.sortOrder ?? "asc";
  const lastFilterText =
    location.state?.filterText ??
    `?page=${lastCurrentPage}&size=${lastPageSize}&sort=${lastSortField}&direction=${lastSortOrder}&state=Active`;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [filterText, setFilterText] = useState<string>(
    localStorage.getItem("retentionFilterText") || lastFilterText
  );
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [sortField, setSortField] = useState(lastSortField);
  const [sortOrder, setSortOrder] = useState(lastSortOrder);
  const authUserId = localStorage.getItem("id");
  const [data, setData] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [successTeamMembers, setSuccessTeamMembers] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<any>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (dateRange[0] !== undefined && dateRange[1] !== undefined) {
      getReport(dateRange[0], dateRange[1]);
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    if (customerStatusOptions.length === 0) {
      getCustomerStatus();
    }
    if (packageOfInterestStatusForStmOptions.length === 0) {
      getPackageOfInterestStatusForStm();
    }
    if(oldPackageOfInterestStatusForStmOptions.length === 0) {
      getOldPackageOfInterestStatusForStm();
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerStatusOptions]);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const loadFilterTextFromLocalStorage = () => {
      const storedFilterText = localStorage.getItem("retentionFilterText");
      if (storedFilterText) {
        setFilterText(storedFilterText);
      }
    };
    loadFilterTextFromLocalStorage();
  }, []);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);
    if (search) {
      params.set("nameOrEmailOrPhone", search);
    } else {
      params.delete("nameOrEmailOrPhone");
    }
    setCurrentPage(0);
    setPageSize(20);
    const newFilterText = "?" + params.toString();
    setFilterText(newFilterText);
  };

  const getCustomerStatus = () => {
    CustomerService.getAllStatus(`?active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getPackageOfInterestStatusForStm = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=PACKAGE_OF_INTEREST_STM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setPackageOfInterestStatusForStmOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getOldPackageOfInterestStatusForStm = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=OLD_PACKAGE_OF_INTEREST_STM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setOldPackageOfInterestStatusForStmOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeStatus = (status: any, id: any) => {
    setIsLoading(true);
    CustomerService.update(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUsers = () => {
    setIsLoading(true);
    if (roleName !== "SUCCESS_TEAM") {
      CustomerService.getAll(`${filterText}&memberFlag=SUCCESS_TEAM`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      if (successTeamMembers.length === 0) {
        UserService.getAll("?roleName=SUCCESS_TEAM")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setSuccessTeamMembers(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    } else {
      CustomerService.getAll(
        `${filterText}&memberFlag=SUCCESS_TEAM&successTeamMember=${authUserId}`
      )
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const columns: ColumnsType<User> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      align: "center",
      sorter: true,
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    {
      title: "Register Date",
      dataIndex: "registeredAt",
      key: "registeredAt",
      fixed: "left" as "left",
      align: "center" as "center",
      sorter: true,
      width: 110,
      render: (text: string) => {
        const getMonthDifference = (startDate: any) => {
          const start = new Date(startDate);
          const end = new Date();
          const months =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());
          return months;
        };
        const monthsPassed = getMonthDifference(text);
        return (
          <div
            style={{
              fontSize: 12,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>{monthsPassed} months</span>
          </div>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      sorter: true,
      width: 240,
      render: (
        text: string,
        record: {
          phone: string;
          goldUser: boolean;
          email: string;
        }
      ) => (
        <>
          {record.goldUser ? <img src={goldUser} alt="gold-icon" /> : null}
          <div>
            <CopyClipboard text={text}></CopyClipboard>{" "}
          </div>
          <span>
            <CopyClipboard text={record.email}></CopyClipboard>{" "}
          </span>
          <br />
          <span>
            <span>{record.phone}</span> <StartCall text={record.phone} />
          </span>
        </>
      ),
    },
    {
      title: "Retention Assignment Date",
      dataIndex: "retentionAssignmentDate",
      key: "retentionAssignmentDate",
      align: "center" as "center",
      sorter: true,
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    {
      title: "Last Contacted",
      dataIndex: "lastContactedStm",
      key: "lastContactedStm",
      align: "center" as "center",
      sorter: true,
      width: 110,
      render: (text: string, row: any) => {
        const isMoreThanSixMonths = (date: any) => {
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
          return new Date(date) < sixMonthsAgo;
        };
        const days = parseInt(text);
        const isOldRegistration = isMoreThanSixMonths(row?.registeredAt);
        const threshold = isOldRegistration ? 14 : 7;
        const color = days > threshold ? "red" : "green";
        return (
          <Tag color={color} style={{ textTransform: "capitalize" }}>
            {text} days ago
          </Tag>
        );
      },
    },
    {
      title: "Retention Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (_: any, row: any) => {
        if (roleName === "SUCCESS_TEAM_REPORTER" || roleName === "ADMIN") {
          const statusOption = customerStatusOptions.find(
            (option: any) => option.value === row.status.id
          );
          return (
            <span>{statusOption ? statusOption?.label : "Undefined"}</span>
          );
        } else {
          return (
            <Select
              onChange={(selectedStatus) =>
                handleChangeStatus(selectedStatus, row.id)
              }
              options={customerStatusOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              key={row.id}
              {...(row?.status?.id && { defaultValue: row.status.id })}
              placeholder={"Select"}
              style={{ width: "180px" }}
            ></Select>
          );
        }
      },
    },
    {
      title: "Package of Interest",
      dataIndex: "packageOfInterestSTM",
      key: "packageOfInterestSTM",
      width: 200,
      render: (_: any, row: any) => {
        if (roleName === "ADMIN") {
          const statusOption = packageOfInterestStatusForStmOptions.find(
            (option: any) => option.value === row.status.id
          );
          return <span>{statusOption ? statusOption?.label : "-"}</span>;
        } else {
          return (
            <Select
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangePackageOfInterestStatusForStm(
                  selectedStatus,
                  row.id
                )
              }
              key={row.id}
              defaultValue={
                row.packageOfInterestSTM && row.packageOfInterestSTM.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {packageOfInterestStatusForStmOptions.map((mainStatus) => (
                <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                  {mainStatus.subStatuses.map((subStatus: any) => (
                    <Select.Option key={subStatus.id} value={subStatus.id}>
                      {subStatus.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          );
        }
      },
    },
    {
      title: "Old Package of Interest",
      dataIndex: "oldPackageOfInterestSTM",
      key: "oldPackageOfInterestSTM",
      width: 200,
      render: (_: any, row: any) => {
        if (roleName === "ADMIN") {
          const statusOption =
            oldPackageOfInterestStatusForStmOptions.find(
              (option: any) => option.value === row.status.id
            );
          return <span>{statusOption ? statusOption?.label : "-"}</span>;
        } else {
          return (
            <Select
              showSearch
              filterOption={(input, option) =>
                option && option.label && typeof option.label === "string"
                  ? option.label.toLowerCase().includes(input.toLowerCase())
                  : false
              }
              onChange={(selectedStatus) =>
                handleChangeOldPackageOfInterestStatusForStm(
                  selectedStatus,
                  row.id
                )
              }
              key={row.id}
              defaultValue={
                row.oldPackageOfInterestSTM &&
                row.oldPackageOfInterestSTM.id
              }
              placeholder={"Select"}
              style={{ width: "180px" }}
            >
              {oldPackageOfInterestStatusForStmOptions.map(
                (mainStatus) => (
                  <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                    {mainStatus.subStatuses.map((subStatus: any) => (
                      <Select.Option key={subStatus.id} value={subStatus.id}>
                        {subStatus.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                )
              )}
            </Select>
          );
        }
      },
    },
    {
      title: "Current Package",
      dataIndex: "currentPackage",
      key: "currentPackage",
      sorter: true,
      width: 100,
    },
    ...(roleName !== "SUCCESS_TEAM"
      ? [
          {
            title: "Success Team Member",
            dataIndex: "successTeamMember",
            key: "successTeamMember",
            width: 200,
            render: (_: any, row: any) => {
              if (
                roleName === "ADMIN" ||
                roleName === "SUCCESS_TEAM_REPORTER"
              ) {
                return (
                  <span>
                    {row.successTeamMember?.firstName}{" "}
                    {row.successTeamMember?.lastName}
                  </span>
                );
              } else {
                return (
                  <Select
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(selectedUser) =>
                      handleChangeAssigneUser(selectedUser, row.id)
                    }
                    options={successTeamMembers}
                    key={row.id}
                    {...(row?.successTeamMember?.id && {
                      defaultValue: row?.successTeamMember?.id,
                    })}
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  ></Select>
                );
              }
            },
          },
        ]
      : []),
    {
      title: "State",
      dataIndex: "sessionStatus",
      key: "sessionStatus",
      width: 100,
      render: (text: string) => (
        <Tag
          color={
            text === "active" ? "green" : text === "static" ? "orange" : "red"
          }
          style={{ textTransform: "capitalize" }}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Passive Days",
      dataIndex: "passiveDays",
      key: "passiveDays",
      sorter: true,
      width: 100,
      render: (text) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Product Count",
      dataIndex: "totalProductCount",
      key: "totalProductCount",
      sorter: true,
      width: 100,
    },
    {
      title: "Order Count",
      dataIndex: "totalOrderCount",
      key: "totalOrderCount",
      sorter: true,
      width: 100,
    },
    {
      title: "Oneamz Order Count",
      dataIndex: "totalOneamzOrderCount",
      key: "totalOneamzOrderCount",
      sorter: true,
      width: 100,
    },

    {
      title: "Last 30 Days Sales",
      dataIndex: "last30DaysSales",
      key: "last30DaysSales",
      sorter: true,
      width: 120,
    },
    {
      title: "Seller Level",
      dataIndex: "level",
      key: "level",
      sorter: true,
      width: 100,
      render: (level: number) => {
        let color = "";
        switch (level) {
          case 0:
            color = "red";
            break;
          case 1:
            color = "volcano";
            break;
          case 2:
            color = "orange";
            break;
          case 3:
            color = "gold";
            break;
          case 4:
            color = "lime";
            break;
          case 5:
            color = "green";
            break;
          case 6:
            color = "cyan";
            break;
          default:
            color = "gray";
        }
        if (level) {
          return <Tag color={color}>LVL {level}</Tag>;
        } else {
          return <Tag color={"red"}>LVL 0</Tag>;
        }
      },
    },
    {
      title: "Feedback",
      dataIndex: "feedbackRating",
      key: "feedbackRating",
      sorter: true,
      width: 100,
      render: (text) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Subscription End Date",
      dataIndex: "subscriptionEndDate",
      key: "subscriptionEndDate",
      sorter: true,
      width: 130,
      align: "center",
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {text ? moment(text).format("DD/MM/YYYY") : ""}
        </p>
      ),
    },
    {
      title: "Auto Subscription Renewal",
      dataIndex: "autoSubscriptionRenewalEnabled",
      key: "autoSubscriptionRenewalEnabled",
      sorter: true,
      align: "center",
      width: 120,
      render: (text: boolean, record: any) => <Tag color={record.autoSubscriptionRenewalEnabled === true ? "green" : "red"}>{record?.autoSubscriptionRenewalEnabled === true ? "Yes" : "No"}</Tag>,
    },
    {
      title: "Detail",
      key: "detail",
      width: 120,
      align: "center",
      fixed: "right" as const,
      render: (_: any, record: any) => (
        <Row justify="center" align="middle" gutter={[0, 8]}>
          <Button
            type="primary"
            onClick={() => {
              let filterToSave = filterText;
              const regex = /nameOrEmailOrPhone=[^&]*/g;
              filterToSave = filterToSave.replace(regex, "");
              filterToSave = filterToSave.replace(/^&|&$/g, "");
              localStorage.setItem("retentionFilterText", filterToSave);
              navigate(`/customers/detail/${record.id}`, {
                state: {
                  currentPage: currentPage,
                  pageSize: pageSize,
                  formFields: formFields,
                  filterText: filterText,
                },
              });
            }}
          >
            Detail
          </Button>
          {roleName !== "SUCCESS_TEAM_REPORTER" ? (
            <NewTask
              customerName={record?.fullName}
              customerId={record?.id}
              setTriggerUpdate={() => {}}
              isCustomer={record?.learner === null}
              userOptions={successTeamMembers}
            />
          ) : null}
        </Row>
      ),
    },
  ];

  const handleChangeAssigneUser = (user: any, id: any) => {
    setIsLoading(true);
    let data: any = {
      userId: user,
    };
    CustomerService.updateAssigneUser(id, data)
      .then((response: any) => {
        openNotification("success", "Success", "Changed assigne user");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePackageOfInterestStatusForStm = (statusId: any, id: any) => {
    setIsLoading(true);
    CustomerService.assignCustomerToPackageOfInterestStatusForStm(id, { statusId })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Changed package of interest status"
        );
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeOldPackageOfInterestStatusForStm = (statusId: any, id: any) => {
    setIsLoading(true);
    CustomerService.assignCustomerToOldPackageOfInterestStatusForStm(id, { statusId })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Changed old package of interest status"
        );
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";

    const sortField =
      field === "lastContactedStm"
        ? "lastContactedDateStm"
        : field === "lastContactedAcm"
        ? "lastContactedDateAcm"
        : field;

    const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);

    let newFilterText = filterText;

    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${sortField}&direction=${direction}`
      );
    } else if (sortField && direction) {
      newFilterText = `${newFilterText}&sort=${sortField}&direction=${direction}`;
    }

    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";

    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }

    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    const field =
      sorter.field === "lastContactedStm"
        ? "lastContactedDateStm"
        : sorter.field;
    updateFilterSort(field, nextSortOrder);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder, triggerUpdate]);

  // useEffect(() => {
    // ReportService.customerEarningReport(``)
    //     .then((response) => {
    //         const combinedData: any[] = [];
    //         response.data.forEach((item: any) => {
    //             const matchedItem = authUserId == item.userId;
    //             if (matchedItem) {
    //                 combinedData.push({
    //                     ...item,
    //                     user: matchedItem ? matchedItem : null,
    //                 });
    //             }
    //         });
    //         setData(combinedData);
    //     })
  // }, []);

  const getReport = (startDate: any, endDate: any) => {
    ReportService.customerEarningReport(
      `?startDate=${startDate}&endDate=${endDate}`
    ).then((response) => {
      const combinedData: any[] = [];
      response.data.forEach((item: any) => {
        const matchedItem = authUserId === item.user;
        if (matchedItem) {
          combinedData.push({
            ...item,
            user: matchedItem ? matchedItem : null,
          });
        }
      });

      setData(combinedData);
    });
  };

  const earningDataSource = data.map((item: any, index: any) => ({
    key: index,
    oneamzOrder: item.oneamzOrder,
    packageChangedIn30Days: item.packageChangedIn30Days,
    packageChangedIn60Days: item.packageChangedIn60Days,
    packageChangedIn90Days: item.packageChangedIn90Days,
    packageUpgrade: item.packageUpgrade,
    totalCustomer: item.totalCustomer,
  }));

  const earningColumns: ColumnsType<any> = [
    {
      title: "Total Customer",
      dataIndex: "totalCustomer",
      key: "totalCustomer",
      align: "center",
      width: 100,
    },
    {
      title: "Product Count Commission",
      dataIndex: "packageUpgrade",
      key: "packageUpgrade",
      width: 100,
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
    },
    {
      title: "30 Days First Sale Comission",
      dataIndex: "packageChangedIn30Days",
      key: "packageChangedIn30Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "60 Days First Sale Comission",
      dataIndex: "packageChangedIn60Days",
      key: "packageChangedIn60Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "90 Days First Sale Comission",
      dataIndex: "packageChangedIn90Days",
      key: "packageChangedIn9Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "OneAmz Order",
      dataIndex: "oneamzOrder",
      key: "oneamzOrder",
      align: "center",
      render: (text: number) => <p>{formatMoney(text ? text * 0.5 : 0)}</p>,
      width: 100,
    },

    {
      title: "Total Earnings",
      dataIndex: "totalEarnings",
      key: "totalEarnings",
      align: "center",
      render: (text: any, record: any) => {
        const productCountCommission = record.packageUpgrade;
        const firstSaleCommission30Days = record.packageChangedIn30Days ?? 0;
        const firstSaleCommission60Days = record.packageChangedIn60Days ?? 0;
        const firstSaleCommission90Days = record.packageChangedIn90Days ?? 0;
        const oneAmzOrderCommission = record.oneamzOrder
          ? record.oneamzOrder * 0.5
          : 0;
        const totalEarnings =
          productCountCommission +
          firstSaleCommission30Days +
          firstSaleCommission60Days +
          firstSaleCommission90Days +
          oneAmzOrderCommission;

        return (
          <p>
            {" "}
            <b>{formatMoney(totalEarnings)}</b>
          </p>
        );
      },
    },
  ];

  const onSelectRow = (record: any) => {
    const newSelectedRowKeys = selectedRowKeys.includes(record.id)
      ? selectedRowKeys.filter((key: any) => key !== record.id)
      : [...selectedRowKeys, record.id];

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: () => ({
      disabled: true,
    }),
  };

  const selectAll = () => {
    if (selectedRowKeys.length === totalCustomerCount) {
      setSelectedRowKeys([]);
    } else {
      setIsLoading(true);
      if (roleName === "SUCCESS_TEAM_MANAGER") {
        CustomerService.getAllCustomersLite(`?${filterText}`)
          .then((response: any) => {
            let data = response.data;
            let customerIds = data.map((user: any) => user.id);
            setSelectedRowKeys(customerIds);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      } else if (roleName === "SUCCESS_TEAM") {
        CustomerService.getAllCustomersLiteMe(`?${filterText}`)
          .then((response: any) => {
            let data = response.data;
            let customerIds = data.map((user: any) => user.id);
            setSelectedRowKeys(customerIds);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Retention Customer List
            </div>
            <div className="customer-card-info">
              <b>{totalCustomerCount} </b>Retention Customers{" "}
            </div>
            {roleName === "SUCCESS_TEAM_MANAGER" ||
            roleName === "SUCCESS_TEAM" ? (
              <Button
                style={{ marginTop: 10, marginLeft: 10 }}
                onClick={() => {
                  selectAll();
                }}
                type="primary"
              >
                Select All
              </Button>
            ) : null}
          </div>
        }
        extra={
          <Row style={{ minWidth: 400, display: "flex" }}>
            {isHomePage && roleName === "SUCCESS_TEAM" ? (
              <>
                {selectedRowKeys.length === 0 && (
                  <Button
                    type="primary"
                    onClick={showModal}
                    style={{ marginRight: 16 }}
                  >
                    Earnings
                  </Button>
                )}
                <Modal
                  title={`This Month Earnings`}
                  width={750}
                  open={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <Row justify={"end"} style={{ marginBottom: 10 }}>
                    <DateRange onChangeDateRange={setDateRange} />
                  </Row>
                  <Table
                    dataSource={earningDataSource}
                    columns={earningColumns}
                    pagination={false}
                  />
                </Modal>
              </>
            ) : null}
            {selectedRowKeys.length > 0 &&
              roleName === "SUCCESS_TEAM_MANAGER" && (
                <StmMultiAssign
                  selectedRowKeys={selectedRowKeys}
                  setTriggerUpdate={setTriggerUpdate}
                />
              )}
            {selectedRowKeys.length > 0 && (
              <StmMultiNote selectedRowKeys={selectedRowKeys} />
            )}
            {selectedRowKeys.length > 0 && (
              <StmCustomerMultiTask selectedRowKeys={selectedRowKeys} />
            )}
            {selectedRowKeys.length > 0 && (
              <StmMultiStatus selectedRowKeys={selectedRowKeys} />
            )}
            <RetentionFilterField
              onChangeSearch={onChangeSearch}
              setFilterText={setFilterText}
              pageSize={pageSize}
              sortOrder={sortOrder}
              sortField={sortField}
              setFormFields={setFormFields}
              formFields={formFields}
              setFilterOptions={setFilterOptions}
            />
            {!isHomePage &&
              (roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN") && (
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => navigate(`/customers/sales-approval`)}
                >
                  Sales Approval
                </Button>
              )}
            <DownloadExcel filterText={filterText} />
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey={"id"}
          sticky
          rowSelection={rowSelection}
          loading={isLoading}
          scroll={{ x: "100%" }}
          key={"id"}
          columns={columns}
          dataSource={users}
          pagination={false}
          onRow={(record) => ({
            onClick: () => onSelectRow(record),
            className: !record.autoSubscriptionRenewalEnabled ? 'highlight-row-red' : '',
          })}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            pageSizeOptions={["10", "20", "50", "100", "250"]}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};
