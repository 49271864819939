import http from "../http-common";
import { ICustomerNoteRequestData, ICustomerNoteResponseData } from "../types/CustomerNote";


const getAll = () => {
  return http.get<Array<ICustomerNoteResponseData>>(`/customers/notes`);
};

const getMyNotes = () => {
  return http.get<ICustomerNoteResponseData>(`/users/me/customers/notes`);
};

const getCustomerNotes = (customerId: number) => {
  return http.get<ICustomerNoteResponseData>(`/customers/${customerId}/notes`);
};

const getNote = (customerId: number) => {
  return http.get<ICustomerNoteResponseData>(`/customers/${customerId}/notes`);
};

const createNote = (id: number, data: any) => {
  return http.post<ICustomerNoteRequestData>(`/users/me/customers/${id}/notes`, data);
};

const pin = (noteId: any) => {
  return http.patch<any>(`/customers/notes/${noteId}/pin`);
};

const update = (noteId: any, data: ICustomerNoteRequestData) => {
  return http.put<any>(`/customers/notes/${noteId}`, data);
};

const remove = (noteId: any) => {
  return http.delete<any>(`/customers/notes/${noteId}`);
};

const createNoteMulti = (data: any) => {
  return http.post<ICustomerNoteRequestData>(`/users/me/multi-customers/notes`, data);
};

const CustomerNoteService = {
  getAll,
  getNote,
  getMyNotes,
  getCustomerNotes,
  createNote,
  update,
  remove,
  pin,
  createNoteMulti
};

export default CustomerNoteService;