import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tag, Tooltip } from "antd";
import "./../info-card.css";
// import { PushpinOutlined, PushpinFilled } from "@ant-design/icons";
// import LearnerNoteCreate from "../../LearnerDetail/LearnerNoteCreate";
import NewTask from "./NewTask";
import moment from "moment";
import TaskDetailCustomer from "./TaskDetailCustomer";
import ProspectCustomerService from "./../../../services/ProspectCustomerService";

const Tasks: React.FC<any> = ({ customerName, customerId, setTriggerUpdate, userOptions }) => {
  const userId = localStorage.getItem("id");
  const [tasks, setTasks] = useState<any>([]);

  const handeTaskList = () => {
    setTriggerUpdate((prev: number) => prev + 1);
  };

  const getTasks = () => {
    ProspectCustomerService.getTasks(customerId)
      .then((response: any) => {
        setTasks(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (customerId) {
      getTasks();
    }
  }, [customerId]);

  return (
    <>
      <Card bordered={false} className={"card"}>
        <Row justify={"space-between"}>
          <p className="title">Tasks</p>
          <NewTask
            customerName={customerName}
            getTasks={getTasks}
            customerId={customerId}
            setTriggerUpdate={setTriggerUpdate}
            userOptions={userOptions}
          />
        </Row>
        <p className="description">{tasks.lenght} Tasks</p>
        <div className="notes-container">
          {tasks?.map((item: any) => (
            <>
              {item.assignee.id == userId || item.reporter.id == userId ? (
                <Row
                  align="middle"
                  style={{ padding: "10px", borderBottom: "1px solid #e8e8e8" }}
                >
                  <Col span={2}>
                    <Tag
                      color="green"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "32px",
                      }}
                    >
                      {item.status}
                    </Tag>
                  </Col>
                  <Col span={6}>
                    <Row align="middle">
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "600" }}>
                          {item?.prospectCustomer?.fullName}{" "}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col
                    span={10}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Row>
                      <div>
                        <Tooltip title={`${item.description}`}>
                          <p
                            style={{
                              fontSize: 12,
                              fontWeight: "500",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "28ch",
                            }}
                          >
                            {item.title}
                          </p>
                        </Tooltip>
                      </div>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Row>
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "500" }}>
                          {moment(item.dueDate).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col span={2}>
                    <TaskDetailCustomer
                      getTasks={getTasks}
                      item={item}
                      onHandleTaskList={handeTaskList}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Tasks;
