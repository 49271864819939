import { Breadcrumb, Card, Col, Row, Typography, Rate, Table, Button, Form, Modal, Input, Switch, InputNumber, Checkbox, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { motion } from "framer-motion";
import CallSalesPackageServeyService from "../../services/SalesPackageServeyService";
import { useEffect, useState } from 'react';
import { can, openNotification } from '../../utils';
import SettingsRouter from './SettingsRouter';
import { NavLink } from 'react-router-dom';
import CallSalesPackageService from '../../services/CallSalesPackageService';
const { Item } = Form;
const { Title } = Typography;

export const CallSalesPackageServey = () => {

    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [CallSalesPackageServey, setCallSalesPackageServey] = useState<[]>([]);
    const [loading, setLoading] = useState(false);
    const [newOption, setNewOption] = useState();
    const [newName, setNewName] = useState();
    const [options, setOptions] = useState([])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onChange = (id: any, checked: boolean) => {
        // alert(checked);
        let questionType = 'CHECKLIST';
        if (checked) {
            questionType = 'IMPORTANT';
        }
        CallSalesPackageServeyService.update(id, { questionType: questionType })
            .then((response: any) => {
                openNotification('success', 'Success', `Sales Package servery updated`);
                getCallSalesPackageServey();
            })
            .catch((e: Error) => {
                console.log(e)
            });
    };
    const onChangePrice = (id: any, callCenterSalesPackageId: any, question: any) => {
        // alert(checked);
        CallSalesPackageServeyService.update(id, { callCenterSalesPackageId: callCenterSalesPackageId, question: question })
            .then((response: any) => {
                openNotification('success', 'Success', `Sales Package servery updated`);
                getCallSalesPackageServey();
            })
            .catch((e: Error) => {
                console.log(e)
            });
    };

    const handleCreate = async () => {
        try {
            const values = await form.validateFields();
             if (values.questionType === true ) {
                values.questionType = 'IMPORTANT'
            }else{
                values.questionType = 'CHECKLIST'
            }
            postCallSalesPackageServey(values);
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };
    const getPackages = () => {
        CallSalesPackageService.getAll()
            .then((response: any) => {
                let data = response.data;
                const newArray = data.map((item: any) => {
                    return { value: item.id, label: item.name };
                });
                setOptions(newArray);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    useEffect(() => {
        getPackages()
    }, [])

    const columns: ColumnsType<any> = [
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            sorter: (a, b) => a.question.localeCompare(b.question),
            defaultSortOrder: 'ascend',
            render: (_, row) => {
                const handleInputChange = (event: any) => {
                    setNewName(event.target.value);
                };

                return (
                    <Row >
                        <Input onChange={handleInputChange} defaultValue={row.question} style={{ width: '200px!important' }} />
                    </Row>
                );
            },
        },
        {
            title: 'Is Important',
            dataIndex: 'questionType',
            key: 'questionType',
            render: (_, row) => (
                <Switch onChange={(checked) => onChange(row?.id, checked)} checked={row.questionType === 'IMPORTANT'} />
            ),
        },
        {
            title: 'Sales Package',
            dataIndex: 'callCenterSalesPackageId',
            key: 'callCenterSalesPackageId',
            render: (_, row) => {
                const handleInputChange = (event: any) => {
                    setNewOption(event.target.value);
                };
                return (
                    <Row >
                        <Select
                            onChange={(selectedValue) => setNewOption(selectedValue)}
                            options={options}
                            {...(row?.callCenterSalesPackageId && { defaultValue: row.callCenterSalesPackageId })}
                            placeholder={"Select"}
                            style={{ width: "180px", marginRight: 10 }}>
                        </Select>
                    </Row>
                );
            },

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, row) => {

                const handleClick = () => {
                    onChangePrice(row?.id, newOption, newName);
                };

                return (
                    <Row >
                        <Button onClick={handleClick}>Update</Button>
                    </Row>
                );
            },

        }

    ];

    useEffect(() => {
        getCallSalesPackageServey();
    }, [])

    const postCallSalesPackageServey = (data: any) => {
        console.log(data);
        setLoading(true);
        CallSalesPackageServeyService.create(data)
            .then((response: any) => {
                setLoading(false);
                getCallSalesPackageServey();
                setIsModalVisible(false);
                form.resetFields();
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getCallSalesPackageServey = () => {
        setLoading(true);
        CallSalesPackageServeyService.getAll()
            .then((response: any) => {
                let data = response.data;
                setCallSalesPackageServey(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (


        <motion.div
            initial={{ y: 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 300, opacity: 0 }}
            transition={{
                type: "spring",
                stiffness: 260,
                damping: 40,
            }}>
            <div>
                <Row>
                    <Col>
                        <Title className='onemaz-page-title' level={3}>{ }</Title>
                        <Breadcrumb
                            items={[
                                {
                                    title: <NavLink to="/" >Home</NavLink>,
                                },
                                {
                                    title: 'Settings',
                                },
                                {
                                    title: 'Call Team Sales Package Servey',
                                },
                            ]} />
                    </Col>
                </Row>
                <SettingsRouter />

                <Button type="primary" onClick={showModal}>
                    Create Sales Package Servey
                </Button>
                <Row style={{ marginTop: '30px' }}>
                    <Col span={24}>
                        <Table loading={loading} columns={columns} dataSource={CallSalesPackageServey} pagination={false} />
                    </Col>
                </Row>

                <Modal
                    title="Create New Sales Package Servey"
                    visible={isModalVisible}
                    onOk={handleCreate}
                    onCancel={handleCancel}
                >
                    <Form form={form} layout="vertical">
                        <Item
                            name="question"
                            label="Sales Package Servey Question"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the question!',
                                },
                            ]}
                        >
                            <Input />
                        </Item>
                        <Item
                            name="questionType"
                            label="Is the question important?"
                        >
                            <Switch />
                        </Item>
                        <Item
                            name="callCenterSalesPackageId"
                            label="Sales Package"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the sales package!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a package"
                                options={options}
                                filterOption={(input: any, option: any) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Item>
                    </Form>
                </Modal>
            </div>
        </motion.div>
    )
}
