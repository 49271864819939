import http from "../http-common";

const getListPackages = () => {
  return http.get<any>(`/additional-package-history/list-packages`);
};

const get = (customerId: any) => {
  return http.get<any>(`/additional-package-history/${customerId}`);
};

const getSuspendTeam = (amazonSuspendedApplicantId: any) => {
  return http.get<any>(`/additional-package-history/suspend-team/${amazonSuspendedApplicantId}`);
};

const AdditionalPackageHistoryService = {
  getListPackages,
  get,
  getSuspendTeam
};

export default AdditionalPackageHistoryService;
