import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Select
} from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "./../../../assets/XCircle.svg";
import { openNotification } from "../../../utils";
import UserService from "../../../services/UserService";
import CustomerService from "../../../services/CustomerService";

const { Item } = Form;

const StmMultiAssign: React.FC<any> = ({ selectedRowKeys, setTriggerUpdate }) => {
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const roleName = localStorage.getItem("roleName");

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [isModalVisible]);

  const confirmAssings = (values: any) => {
    let data = {
      userId: selectedUser,
      ids: selectedRowKeys,
    };
    if (roleName === "SUCCESS_TEAM_MANAGER") {
      CustomerService.assignMulti(data)
        .then((response: any) => {
          openNotification(
            "success",
            "Success",
            "Assignment Process Completed"
          );
          setTriggerUpdate((prev: number) => prev + 1);
          setIsModalVisible(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsModalVisible(false);
        });
    }
  };

  useEffect(() => {
    form.resetFields(["Selected Customers"]);
    form.setFieldsValue({
      "Selected Customers": selectedRowKeys.length.toString(),
    });
    if (roleName === "SUCCESS_TEAM_MANAGER") {
      UserService.getAll("?roleName=SUCCESS_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line
  }, [selectedRowKeys, isModalVisible, form]);

  return (
    <>
      <Row justify={"end"}>
        <Button
          type="primary"
          style={{ marginRight: 15 }}
          onClick={() => setIsModalVisible(true)}
        >
          Assign
        </Button>
      </Row>
      <Modal
        title={<>Assign</>}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={confirmAssings} layout="vertical">
          <Select
            onChange={(item) => setSelectedUser(item)}
            showSearch
            filterOption={(input, option: any) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            options={userOptions}
            placeholder={"Select a Success Team Member"}
            style={{ width: "100%" }}
          ></Select>
          <Item name="Selected Customers" label="Selected Customers">
            <Input disabled />
          </Item>

          <Item>
            <Row>
              <Button
                type="primary"
                className=" w-100 border-radius-8"
                style={{ width: "100%" }}
                htmlType="submit"
              >
                OK
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default StmMultiAssign;
