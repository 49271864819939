import http from "../http-common";

const getSalesLogReport = (
  startDate: string,
  endDate: string,
  roleName: string
) => {
  return http.get<Array<any>>(
    `/call-center-sales-log/report?startDate=${startDate}&endDate=${endDate}&roleName=${roleName}`
  );
};

const CallCenterSalesLogService = {
  getSalesLogReport,
};

export default CallCenterSalesLogService;
