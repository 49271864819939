import { Avatar, Button, Col, Row, Segmented, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ILearnerResponseData } from '../../types/Learner'
import { can } from '../../utils';
import LearnerService from '../../services/LearnerService';
import Table, { ColumnsType } from 'antd/es/table';
import { CopyClipboard } from '../General/CopyClipboard';
import moment from 'moment';
import LearnerDetail from '../../pages/LearnerDetail';
import Avatar1Icon from "../../assets/avatar1.svg";
import { StartCall } from '../General/StartCall';
import { useNavigate } from 'react-router-dom';


const { Title } = Typography;

interface LearnerFilterProps {
    startDate: string;
    endDate: string;
}

const NewLearnerStatistics: React.FC<LearnerFilterProps> = ({ startDate,  endDate}) => {

    const [learnerList, setLearnerList] = useState<ILearnerResponseData[]>([]);
    const [segment, setSegment] = useState<string | number>('Not Logged In');
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handeLearnerList = () => {
    };

    const currentUser: any = {
        canLearnersGetAll: can("learners:getAll")
    };

    const columns: ColumnsType<ILearnerResponseData> = [
        {
            title: 'Learner',
            dataIndex: 'firstName',
            key: 'action',
            render: (_, row) => (
                <Row justify={'start'} align={'middle'}>
                    {/* <Col className='mr-15'>
                        <Avatar style={{ background: '#87B2B2' }} size="large" >
                            {row.firstName.charAt(0).toUpperCase()}
                        </Avatar>
                    </Col> */}
                    <Col>
                        <Title className='customer-fullname' level={5}><b>{row.firstName} {row.lastName}</b></Title>
                        <span className='customer-fullname'><CopyClipboard text={row.email}></CopyClipboard>
                        </span>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'Phone',
            render: (_, row) => {
                return (
                    <StartCall text={row?.phoneNumber} />
                );
            },
        },
        ...currentUser.canLearnersGetAll ? [{
            title: 'Success Team Member',
            dataIndex: 'agent',
            key: 'agent',
            render: (_: any, row: any) => {
                if (row.user === null) {
                    return;
                } else {
                    return <Row justify={'start'} align={'middle'}>
                        {/* <Col className='mr-15'>
                            <img src={Avatar1Icon} alt='avatar-icon' />
                        </Col> */}
                        <Col>
                            <Title className='customer-fullname' level={5}><b>{row.user?.firstName} {row.user?.lastName}</b></Title>
                        </Col>
                    </Row>
                }
            },
        }] : [],
        {
            title: 'Last Login At',
            dataIndex: 'lastLoginAt',
            key: 'lastLoginAt',
            render: (_, row) => {
              if (row.lastLoginAt) {
                return moment(row.lastLoginAt).format("DD/MM/YYYY");
              }
            }
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, row) => {
              if (row.createdAt) {
                return moment(row.createdAt).format("DD/MM/YYYY");
              }
            }
          },
        {
            title: '',
            dataIndex: 'id',
            render: (_, row) => (
                <Button type='primary' onClick={() => navigate(`/learners/detail/${row.id}`)}>Learner Detail</Button>

                // <LearnerDetail key={row.id} id={row.id} onHandleLearnerList={handeLearnerList} />
            ),
        },
    ];

    useEffect(() => {
    }, [])

    useEffect(() => {
        let filterCriteria = `?sort=lessonsCompleted&direction=asc&statusId=1&startCreatedAt=${startDate}T00:00:00&endCreatedAt=${endDate}T23:59:59`;
        getLearner(filterCriteria);
    }, [startDate,endDate])

    const getLearner = (filterCriteria: string) => {
        let canPermission = can("learners:getAll");
        setLoading(true);

        if (canPermission) {
            LearnerService.getAll(filterCriteria)
                .then((response: any) => {
                    let data = response.data.data;
                    setLearnerList(data);
        setLoading(false);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        } else {
            let id = localStorage.getItem('id');
            filterCriteria += `&userId=${id}`;
            LearnerService.getMyLearners(filterCriteria)
                .then((response: any) => {
                    let data = response.data.data;
                    setLearnerList(data);
                    setLoading(false);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <Row style={{ marginBottom: '10px' }} justify="center">
                <Table loading={loading} scroll={{ x: '100%' }}  style={{ marginTop: '20px', width: "100%" }} key={'id'} columns={columns} dataSource={learnerList} pagination={{ pageSize: 8}} />
            </Row>
        </>
    )
};

export default NewLearnerStatistics