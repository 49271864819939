import http from "../http-common";

const learnerStatusByUser = (criteria: any) => {
  return http.get<any>(`/learners/statistics/status-report${criteria}`);
};

const learnerProcessReport = (criteria: any) => {
  return http.get<any>(`/reports/learners-daily${criteria}`);
};

const learnerEducationStatusByUser = (criteria: any) => {
  return http.get<any>(`/learners/statistics/educational-report${criteria}`);
};

const learnerStatisticReport = (criteria: any) => {
  return http.get<any>(`/learners/statistics/learner-report${criteria}`);
};

const successTeamReport = (criteria: any) => {
  return http.get<any>(`/learners/statistics/sales-target-report${criteria}`);
};

const customerStatusByUser = (criteria: any) => {
  return http.get<any>(`/customers/reports/customer-status${criteria}`);
};

const customerStateReport = (criteria: any) => {
  return http.get<any>(`/customers/reports/state${criteria}`);
};

const customerPackageReport = (criteria: any) => {
  return http.get<any>(`/customers/reports/package${criteria}`);
};

const customerEarningReport = (criteria: any) => {
  return http.get<any>(`/customer/commission${criteria}`);
};

const customerEarningReportDetail = (criteria: any) => {
  return http.get<any>(`/commission${criteria}`);
};

const freeDays = () => {
  return http.get<any>(`/users/free-days-report`);
};
const affiliate = (criteria: any) => {
  return http.get<any>(`/learners/reports/affiliate${criteria}`);
};

const getAffiliate = () => {
  return http.get<any>(`/affiliates`);
};

const updateAffiliate = (id: any, data: any) => {
  return http.patch<any>(`/affiliates/${id}/user`, data);
};


const ReportService = {
  learnerStatusByUser,
  learnerEducationStatusByUser,
  successTeamReport,
  customerStatusByUser,
  customerStateReport,
  customerPackageReport,
  learnerProcessReport,
  customerEarningReport,
  freeDays,
  affiliate,
  customerEarningReportDetail,
  getAffiliate,
  updateAffiliate,
  learnerStatisticReport
};

export default ReportService;