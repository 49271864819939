import React, { useState } from "react";
import CustomerService from "../../../services/ProspectCustomerService";
import { openNotification } from "../../../utils";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ProspectCustomerSourcesService from "../../../services/ProspectCustomerSourcesService";

const { Item } = Form;
const { TextArea } = Input;

interface ManuelDataProps {
  handleUploadManuelData: () => void;
}

export const ManuelEntry: React.FC<ManuelDataProps> = ({
  handleUploadManuelData,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [customerSources, setCustomerSources] = useState<any[]>([]);
  const [form] = Form.useForm();

  const showModal = () => {
    if (customerSources.length === 0) {
      getSources();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getSources = () => {
    ProspectCustomerSourcesService.getAll()
      .then((response: any) => {
        let data = response.data;
        const newArray = data
          .filter((item: any) => item.active)
          .map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              sourceKey: item.sourceKey,
            };
          });
        setCustomerSources(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        CustomerService.post(values)
          .then((response) => {
            openNotification("success", "Manuel Entry", "Success");
            form.resetFields();
            setIsModalVisible(false);
            handleUploadManuelData();
          })
          .catch((error) => {
            openNotification("error", "Manuel Entry", error.response.data);
          });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  return (
    <div>
      <Button type="primary" onClick={showModal} icon={<UploadOutlined />}>
        Manuel Entry
      </Button>
      <Modal
        title="Manuel Entry"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="manual_entry_form">
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Item
                name="fullName"
                label="Full Name"
                rules={[
                  { required: true, message: "Please input the full name!" },
                ]}
              >
                <Input placeholder="Full Name" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item name="source" label="Source">
                <Select placeholder={"Select Source"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {customerSources?.map((option) => (
                    <Select.Option
                      key={option.sourceKey}
                      value={option.sourceKey}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="phone"
                label="Phone"
                rules={[
                  { required: true, message: "Please input the phone number!" },
                ]}
              >
                <Input placeholder="Phone" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please input the email!" }]}
              >
                <Input placeholder="Email" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item name="city" label="City">
                <Input placeholder="City" />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item name="category" label="Category">
                <Input placeholder="Category" />
              </Item>
            </Col>
            <Col xs={24}>
              <Item name="message" label="Message">
                <TextArea rows={3} placeholder="Message" />
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
