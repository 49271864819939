import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table, Tag } from "antd";
import AdditionalPackageHistoryService from "../../services/AdditionalPackageHistoryService";
import moment from "moment";

interface AdditionalPackageHistoryCardProps {
  id: number;
}

const AdditionalPackageHistoryCard: React.FC<
  AdditionalPackageHistoryCardProps
> = ({ id }) => {
  const [packageHistory, setPackageHistory] = useState<any[]>([]);

  const getAdditionalPackageHistory = () => {
    if (id) {
      AdditionalPackageHistoryService.getSuspendTeam(id)
        .then((response) => {
          setPackageHistory(response.data);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    getAdditionalPackageHistory();
    // eslint-disable-next-line
  }, [id]);

  const columns = [
    {
      title: <span style={{ fontSize: 12 }}>Package Name</span>,
      dataIndex: "packageName",
      key: "packageName",
      ellipsis: true,
      width: 100,
      sorter: (a: any, b: any) => a.packageName.localeCompare(b.packageName),
      render: (text: any) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: <span style={{ fontSize: 12 }}>Start Date</span>,
      dataIndex: "startDate",
      key: "startDate",
      ellipsis: true,
      width: 90,
      align: "center" as const,
      sorter: (a: any, b: any) => a.startDate - b.startDate,
      defaultSortOrder: "descend" as const,
      render: (text: any) => <span style={{ fontSize: 12 }}>{text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}</span>,
    },
    {
      title: <span style={{ fontSize: 12 }}>End Date</span>,
      dataIndex: "endDate",
      key: "endDate",
      ellipsis: true,
      width: 90,
      align: "center" as const,
      sorter: (a: any, b: any) => a.endDate - b.endDate,
      render: (text: any) => <span style={{ fontSize: 12 }}>{text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}</span>,
    },
    {
      title: <span style={{ fontSize: 12 }}>Status</span>,
      key: "status",
      width: 80,
      align: "center" as const,
      render: (text: any, record: any) => {
        const isActive = moment(record.endDate).isAfter(moment());
        return (
          <Tag style={{ color: isActive ? "green" : "red", fontSize: "11px", textAlign: "center" }}>
            {isActive ? "ACTIVE" : "SUSPENDED"}
          </Tag>
        );
      },
    },
  ];

  return (
    <Card bordered={false} className={"sales-card"} >
      <p className="title">Additional Package History</p>
      <Row style={{ marginTop: "24px" }}>
        <Col span={24}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={packageHistory}
            pagination={false}
            scroll={{ x: "100%" }}
            size="small"
            bordered
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AdditionalPackageHistoryCard;
