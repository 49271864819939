import {
  Breadcrumb,
  Col,
  Row,
  Typography,
  Table,
  Button,
  Form,
  Modal,
  Input,
  Switch,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { openNotification } from "../../utils";
import SettingsRouter from "./SettingsRouter";
import { NavLink } from "react-router-dom";
import ButtonGroup from "antd/es/button/button-group";
import ProspectCustomerSourcesService from "../../services/ProspectCustomerSourcesService";

const { Item } = Form;
const { Title } = Typography;

export const ProspectCustomerSources = () => {
  const [form] = Form.useForm();
  const [formEditSource] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newSource, setNewSource] = useState<string>("");
  const [newSourceKey, setNewSourceKey] = useState<string>("");
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [processedData, setProcessedData] = useState<any[]>([]);

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCreateModalCancel = () => {
    setIsCreateModalVisible(false);
  };

  const showEditModal = (source: any) => {
    setSelectedSource(source);
    setNewSource(source.name);
    setNewSourceKey(source.sourceKey);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedSource(null);
  };

  const showDeleteModal = (source: any) => {
    setSelectedSource(source);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedSource(null);
  };

  // POST
  const postProspectCustomerSource = (data: any) => {
    setLoading(true);
    ProspectCustomerSourcesService.create(data)
      .then((response: any) => {
        getProspectCustomerSource();
        form.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      postProspectCustomerSource(values);
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  // GETALL
  const getProspectCustomerSource = () => {
    setLoading(true);
    ProspectCustomerSourcesService.getAll()
      .then((response: any) => {
        let data = response.data;
        setProcessedData(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  // PUT UPDATE
  const handleChangeSource = (
    id: number,
    name: string,
    sourceKey: string,
    active: boolean,
  ) => {
    setLoading(true);
    let data = {
      active: active,
      name: name,
      sourceKey: sourceKey,
    };
    ProspectCustomerSourcesService.update(id, data)
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Prospect Customer Source updated`
        );
        handleEditModalCancel();
        getProspectCustomerSource();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleActiveChange = (record: any, checked: boolean) => {
    handleChangeSource(record.id, record.name, record.sourceKey, checked);
  };

  // DELETE
  const handleDeleteSource = (id: number) => {
    setLoading(true);
    ProspectCustomerSourcesService.remove(id)
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Prospect Customer Source deleted`
        );
        handleDeleteModalCancel();
        getProspectCustomerSource();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Source Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, row) => <span>{row.name}</span>,
    },
    {
      title: "Source Key",
      dataIndex: "sourceKey",
      key: "sourceKey",
      align: "center",
      sorter: (a, b) => a.sourceKey - b.sourceKey,
      render: (_, row) => <span>{row.sourceKey}</span>,
    },
    {
      title: "Active/Passive",
      dataIndex: "active",
      key: "active",
      width: 200,
      align: "center",
      sorter: (a, b) => a.active - b.active,
      render: (_, record) => (
        <Switch
          checked={record.active}
          onChange={(checked) => handleActiveChange(record, checked)}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 200,
      render: (_, record) => (
        <ButtonGroup style={{ marginLeft: "10px", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={() => showEditModal(record)}>
            Edit
          </Button>
          <Button onClick={() => showDeleteModal(record)}>Delete</Button>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    getProspectCustomerSource();
  }, []);

  useEffect(() => {
    formEditSource.setFieldsValue({ selectedSource: newSource, selectedSourceKey: newSourceKey });
    // eslint-disable-next-line
  }, [newSource, newSourceKey]);

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Call Team Customer Source",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />
        <Button type="primary" onClick={showCreateModal}>
          Create Call Team Customer Source
        </Button>
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={processedData}
              pagination={false}
              scroll={{ x: "max-content" }}
            />
          </Col>
        </Row>
        {/* Create Modal */}
        <Modal
          title="Create New Call Team Customer Source"
          open={isCreateModalVisible}
          onOk={handleCreate}
          onCancel={handleCreateModalCancel}
        >
          <Form form={form} layout="vertical">
            <Item
              name="name"
              label="Call Team Customer Source Name"
              rules={[
                {
                  required: true,
                  message: "Call Team customer source name required",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Item>
            <Item
              name="sourceKey"
              label="Call Team Customer Source Key"
              rules={[
                {
                  required: true,
                  message: "Call Team customer source key required",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Item>
          </Form>
        </Modal>
        {/* Edit Modal */}
        <Modal
          title="Edit Call Team Customer Source"
          open={isEditModalVisible}
          onCancel={handleEditModalCancel}
          footer={[
            <Button key="back" onClick={handleEditModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                handleChangeSource(
                  selectedSource?.id,
                  newSource,
                  newSourceKey,
                  selectedSource?.active
                )
              }
            >
              Save
            </Button>,
          ]}
        >
          <Form form={formEditSource} layout="vertical">
            <Item
              name="selectedSource"
              label="Call Team Customer Source"
              rules={[
                {
                  required: true,
                  message: "Call Team customer source name required",
                },
              ]}
            >
              <Input
                value={newSource}
                onChange={(e) => setNewSource(e.target.value)}
              />
            </Item>
            <Item
              name="selectedSourceKey"
              label="Call Team Customer Source Key"
              rules={[
                {
                  required: true,
                  message: "Call Team customer source key required",
                },
              ]}
            >
              <Input
                value={newSourceKey}
                onChange={(e) => setNewSourceKey(e.target.value)}
              />
            </Item>
          </Form>
        </Modal>
        {/* Delete Modal */}
        <Modal
          title="Delete Call Team Customer Source"
          open={isDeleteModalVisible}
          onCancel={handleDeleteModalCancel}
          footer={[
            <Button key="back" onClick={handleDeleteModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleDeleteSource(selectedSource?.id)}
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this call team customer source?</p>
        </Modal>
      </div>
    </motion.div>
  );
};
