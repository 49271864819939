import React, { useEffect, useState } from "react";
import { openNotification } from "../../../utils";
import {
  Row,
  Input,
  Button,
  Select,
  Form,
  DatePicker,
  Drawer,
  Card,
} from "antd";
import type { DatePickerProps } from "antd";
import TaskService from "../../../services/TaskService";
import dayjs from "dayjs";

interface NewTaskProps {
  customerName: string;
  customerId: number;
  setTriggerUpdate: any;
  isCustomer: any;
  userOptions: any;
}

const { Item } = Form;

const NewTask: React.FC<NewTaskProps> = ({
  customerName,
  customerId,
  setTriggerUpdate,
  isCustomer,
  userOptions,
}) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  // const [userOptions, setUserOptions] = useState<any[]>([]);
  const [assigneeId, setAssigneeId] = useState<any>(null);
  const roleName = localStorage.getItem("roleName");

  const createTask = (values: any) => {
    const dueDate = dayjs(values.dueDate).format("YYYY-MM-DDTHH:mm");
    let taskData: any;
    // eslint-disable-next-line
    if (isCustomer == true) {
      taskData = {
        title: values.title,
        description: values.desc,
        tag: values.tag,
        dueDate: dueDate,
        assigneeId: assigneeId,
        customerId: customerId,
        source: "WEB",
        url: "customers/detail/"
      };
    } else {
      taskData = {
        title: values.title,
        description: values.desc,
        tag: values.tag,
        dueDate: dueDate,
        assigneeId: assigneeId,
        learnerId: customerId,
        source: "WEB",
        url: "users/detail/"
      };
    }
    TaskService.create(taskData)
      .then((response: any) => {
        openNotification("success", "Success", "New task created");
        form.resetFields();
        setTriggerUpdate((prev: number) => prev + 1);
        setOpen(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  useEffect(() => {
    // if (
    //   roleName !== "ACCOUNT_MANAGER" &&
    //   roleName !== "SUCCESS_TEAM" &&
    //   roleName !== "CALL_TEAM" &&
    //   roleName !== "SUSPENDED_TEAM"
    // ) {
    //   getUsers();
    // }
    let currentUserId = localStorage.getItem("id");
    setAssigneeId(currentUserId);
  }, []);

  // const getUsers = () => {
  //   if (roleName === "ACCOUNT_MANAGER_MANAGER") {
  //     UserService.getAll("?roleName=ACCOUNT_MANAGER")
  //       .then((response: any) => {
  //         let data = response;

  //         const newArray = data.map((item: any) => {
  //           let fullName = `${item?.firstName} ${item?.lastName}`;
  //           return { value: item?.id, label: fullName };
  //         });

  //         setUserOptions(newArray);
  //       })
  //       .catch((e: Error) => {
  //         console.log(e);
  //       });
  //   } else if (roleName === "SUCCESS_TEAM_MANAGER") {
  //     UserService.getAll("?roleName=SUCCESS_TEAM")
  //       .then((response: any) => {
  //         let data = response;

  //         const newArray = data.map((item: any) => {
  //           let fullName = `${item?.firstName} ${item?.lastName}`;
  //           return { value: item?.id, label: fullName };
  //         });

  //         setUserOptions(newArray);
  //       })
  //       .catch((e: Error) => {
  //         console.log(e);
  //       });
  //   } else if (roleName === "CALL_TEAM_MANAGER") {
  //     UserService.getAll("?roleName=CALL_TEAM")
  //       .then((response: any) => {
  //         let data = response;

  //         const newArray = data.map((item: any) => {
  //           let fullName = `${item?.firstName} ${item?.lastName}`;
  //           return { value: item?.id, label: fullName };
  //         });

  //         setUserOptions(newArray);
  //       })
  //       .catch((e: Error) => {
  //         console.log(e);
  //       });
  //   } else if (roleName === "SUSPEND_TEAM_MANAGER") {
  //     UserService.getAll("?roleName=SUSPEND_TEAM")
  //       .then((response: any) => {
  //         let data = response;

  //         const newArray = data.map((item: any) => {
  //           let fullName = `${item?.firstName} ${item?.lastName}`;
  //           return { value: item?.id, label: fullName };
  //         });

  //         setUserOptions(newArray);
  //       })
  //       .catch((e: Error) => {
  //         console.log(e);
  //       });
  //   } else if(roleName === "ADMIN") {
  //     UserService.getAll("?roleName=ADMIN")
  //       .then((response: any) => {
  //         let data = response;

  //         const newArray = data.map((item: any) => {
  //           let fullName = `${item?.firstName} ${item?.lastName}`;
  //           return { value: item?.id, label: fullName };
  //         });

  //         setUserOptions(newArray);
  //       })
  //       .catch((e: Error) => {
  //         console.log(e);
  //       });
  //   }
  // };

  return (
    <div>
      <Button type="primary" onClick={showDrawer}>
        New Task
      </Button>
      <Drawer title="New Task" placement="right" onClose={onClose} open={open}>
        <Card className="new-task-card" style={{ border: "none" }}>
          <Form form={form} onFinish={createTask} layout="vertical" initialValues={{ title: customerName }}>
            <Item
              name="title"
              label="Title"
              rules={[
                { required: true, message: "Please enter a title!" },
                {
                  max: 255,
                  message: "You can enter a maximum of 255 characters",
                },
                {
                  pattern: new RegExp(/\S/),
                  message: "Title cannot be empty or spaces only",
                },
              ]}
            >
              <Input disabled/>
            </Item>

            <Item
              name="desc"
              label="Description"
              rules={[
                {
                  max: 255,
                  message: "You can enter a maximum of 255 characters",
                },
                {
                  pattern: new RegExp(/\S/),
                  message: "Description cannot be empty or spaces only",
                },
              ]}
            >
              <Input.TextArea placeholder="" />
            </Item>

            <Item
              name="tag"
              label="Tag"
              rules={[
                {
                  max: 255,
                  message: "You can enter a maximum of 255 characters",
                },
                {
                  pattern: new RegExp(/\S/),
                  message: "Tag cannot be empty or spaces only",
                },
              ]}
            >
              <Input />
            </Item>

            {roleName !== "ACCOUNT_MANAGER" &&
              roleName !== "HI5_ACM_MEMBER" &&
              roleName !== "CALL_TEAM" &&
              roleName !== "SUCCESS_TEAM" &&
              roleName !== "SUSPEND_TEAM" && 
              roleName !== "MAS_TEAM_MEMBER" && (
                <Item name="assigneeId" label="Assigne">
                  <Select
                    onChange={(selectedUser) => setAssigneeId(selectedUser)}
                    options={userOptions}
                    placeholder={"Select"}
                    style={{ width: "100%" }}
                  ></Select>
                </Item>
              )}

            <Item
              name="dueDate"
              label="Due Date"
              rules={[{ required: true, message: "Please select a date!" }]}
            >
              <DatePicker
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                  minuteStep: 5,
                }}
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                format={"DD/MM/YYYY HH:mm"}
                placeholder="Select Date"
              />
            </Item>

            <Item>
              <Row justify={"end"}>
                <Button
                  type="default"
                  onClick={onClose}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Row>
            </Item>
          </Form>
        </Card>
      </Drawer>
    </div>
  );
};

export default NewTask;
