import { Card, DatePicker, Typography, TimeRangePickerProps, Tabs } from 'antd';
import { UserAddOutlined, UsergroupAddOutlined, TeamOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import TabPane from 'antd/es/tabs/TabPane';
import EducationStatistics from './EducationStatistics';
import { useTranslation } from 'react-i18next';
import './learner-statistics.css';
import NewLearnerStatistics from './NewLearnerStatistics';
import CustomerStatistics from './CustomerStatistics';
import { useEffect, useState } from 'react';
import moment from 'moment';
import DateRange from '../Common/DateRange';

const { Title } = Typography;
const { RangePicker } = DatePicker;

export const LearnerStatistics = () => {

    const { t } = useTranslation();
    const [startDate, setStartDate] = useState<any>(dayjs().subtract(6, 'day').format('YYYY-MM-DDT00:00:00'));
    const [endDate, setEndDate] = useState<any>(dayjs().format('YYYY-MM-DDT23:59:59'));
    const [dateRange, setDateRange] = useState<any>([])




      useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
          setStartDate(dateRange[0]);
          setEndDate(dateRange[1]);
        }
      }, [dateRange])


    return (
        <Card className='learner-statistics' style={{ marginTop: '30px' }} title="Learners"
            extra={<>
               <DateRange onChangeDateRange={setDateRange} />
            </>} >
            <Tabs centered={true} defaultActiveKey="1">
                <TabPane
                    tab={
                        <>
                            <TeamOutlined size={100} />
                            {t('learners')}
                        </>
                    }
                    key="1"
                >
                    <EducationStatistics startDate={startDate} endDate={endDate}></EducationStatistics>
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <UserAddOutlined />
                            {t('newLearners')}
                        </>
                    }
                    key="2">
                    <NewLearnerStatistics startDate={startDate} endDate={endDate}></NewLearnerStatistics>
                </TabPane>
                <TabPane
                    tab={
                        <>
                            <UsergroupAddOutlined />
                            {t('customers')}
                        </>
                    }
                    key="3">
                    <CustomerStatistics startDate={startDate} endDate={endDate}></CustomerStatistics>
                </TabPane>
            </Tabs>
        </Card>
    )
}
