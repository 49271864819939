import { Col, Row } from "antd";
import "./dashboard.css";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { HeaderBar } from "../components/Dashboard/HeaderBar";
import { CallStatistics } from "../components/Dashboard/CallStatistics";
import { TargetStatistics } from "../components/Dashboard/TargetStatistics";
import { Earnings } from "../components/Dashboard/Earnings";
import ActivityStatistics from "../components/Dashboard/ActivityStatistics";
import { LearnerStatistics } from "../components/Dashboard/LearnerStatistics";
import { TaskList } from "../components/Dashboard/TaskList";
import { TopTen } from "../components/Dashboard/TopTen";

export const SuccessManagerDashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<any>([]);

  return (
    <div
      style={{ background: "#F0F0F0", padding: "30px" }}
      className="acm-dashboard"
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
      >
        <HeaderBar setDateRange={setDateRange} />
        <Row gutter={[16, 16]} style={{ margin: "0 0 20px 0" }}>
          <Col span={14} xs={24} sm={24} md={24} lg={9}>
            <ActivityStatistics dateRange={dateRange} />
          </Col>
          <Col span={5} xs={24} sm={12} md={12} lg={5}>
            <TopTen dateRange={dateRange} />
          </Col>
          <Col span={5} xs={24} sm={12} md={12} lg={5}>
            <CallStatistics dateRange={dateRange} />
            <TargetStatistics />
          </Col>
          <Col
            span={5}
            xs={24}
            sm={12}
            md={12}
            lg={5}
            style={{ paddingBottom: 20 }}
          >
            <Earnings dateRange={dateRange} />
          </Col>
        </Row>
        {/* <LearnerList/> */}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <LearnerStatistics />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <TaskList />
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};
