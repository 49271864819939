import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Tag, Tooltip } from "antd";
import moment from "moment";
import TaskService from "../../../services/TaskService";
import TaskDetail from "../../AmazonSuspendedApplicantDetail/Tasks/TaskDetailCustomer";

const Tasks: React.FC<any> = ({ dateRange }) => {
  const [tasks, setTasks] = useState<any>([]);
  const roleName = localStorage.getItem("roleName");
  const prevDateRangeRef = useRef();

  const getTasks = () => {
    const prevDateRange = prevDateRangeRef.current;

    const [startDate, endDate] = dateRange;

    let formattedDateRange;
    if (startDate && endDate) {
      formattedDateRange = `?startDueDate=${startDate}T00:00:00&endDueDate=${endDate}T23:59:59`;
    }

    if (startDate === undefined || endDate === undefined) {
      return;
    }
    if (
      prevDateRange?.[0] !== dateRange?.[0] ||
      prevDateRange?.[1] !== dateRange?.[1]
    ) {
      if (roleName === "SUSPEND_TEAM") {
        TaskService.getAllMyTask(formattedDateRange)
          .then((response: any) => {
            let taskData = response.data;
            const todoTasks = taskData.filter(
              (item: any) => item.status === "TODO"
            );
            const reversedTodoTasks = [...todoTasks].reverse();
            setTasks(reversedTodoTasks);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else if (roleName === "SUSPEND_TEAM_MANAGER") {
        TaskService.getAllUsers(formattedDateRange)
          .then((response: any) => {
            let taskData = response.data;
            const todoTasks = taskData.filter(
              (item: any) => item.status === "TODO"
            );
            const reversedTodoTasks = [...todoTasks].reverse();
            setTasks(reversedTodoTasks);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
    prevDateRangeRef.current = dateRange;
  };

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line
  }, [dateRange]);

  return (
    <Card bordered={false} className={"tasks-card"}>
      <div
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        Tasks & Reminders
      </div>
      <p className="description">{tasks?.length} Tasks</p>
      <div
        className="tasks-container"
        style={{
          height: roleName === "SUSPEND_TEAM_MANAGER" ? "360px" : "180px",
          overflow: "auto",
        }}
      >
        <Row justify="space-between" align="middle" className="note-row-title">
          <Col span={3} className="note-table-title">
            Status
          </Col>
          <Col span={5} className="note-table-title">
            Full Name
          </Col>
          <Col span={8} className="note-table-title">
            Title
          </Col>
          <Col span={4} className="note-table-title">
            Due Date
          </Col>
          <Col span={4} className="note-table-title"></Col>
        </Row>
        {tasks?.map((item: any) => (
          <>
            <Row align="middle" className="rowItem">
              <Col span={3}>
                <Tag
                  color="orange"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    minWidth: "42px",
                  }}
                >
                  {item?.status}
                </Tag>
              </Col>
              <Col span={5}>
                <div>
                  <Tooltip
                    title={
                      item?.amazonSuspendedApplicant
                        ? item?.amazonSuspendedApplicant?.fullName
                        : `${item?.amazonSuspendedApplicant?.firstName} ${item?.amazonSuspendedApplicant?.lastName}`
                    }
                  >
                    <p
                      style={{
                        fontSize: 12,
                        fontWeight: "600",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "12ch",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.amazonSuspendedApplicant
                        ? item?.amazonSuspendedApplicant?.fullName
                        : `${item?.amazonSuspendedApplicant?.firstName} ${item?.amazonSuspendedApplicant?.lastName}`}
                    </p>
                  </Tooltip>
                </div>
              </Col>
              <Col
                span={8}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Row>
                  <Tooltip
                    title={
                      <>
                        {item?.title}
                        <br />
                        {item?.description ?? ""}
                      </>
                    }
                  >
                    <p
                      style={{
                        fontSize: 12,
                        fontWeight: "500",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "18ch",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.title}
                    </p>
                  </Tooltip>
                </Row>
              </Col>
              <Col span={4}>
                <Row>
                  <div>
                    <p style={{ fontSize: 12, fontWeight: "500" }}>
                      {moment(item?.dueDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </Row>
              </Col>
              <Col span={4}>
                <TaskDetail
                  item={item}
                  getTasks={getTasks}
                  onHandleTaskList={() => undefined}
                />
              </Col>
            </Row>
          </>
        ))}
      </div>
    </Card>
  );
};

export default Tasks;
