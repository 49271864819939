import { Breadcrumb, Card, Col, Row, Typography, Rate, Table, Button, Form, Modal, Input, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { motion } from "framer-motion";
import { useEffect, useState } from 'react';
import BonusPoliciesService from '../../services/BonusPoliciesService';
import BonusPolicyDetail from './BonusPolicyDetail';
import { NavLink } from 'react-router-dom';
import SettingsRouter from './SettingsRouter';
const { Item } = Form;
const { Title } = Typography;


export const BonusPolicy = () => {

    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [bonusPolicies, setBonusPolicies] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const columns: ColumnsType<any> = [
        {
            title: 'Role',
            dataIndex: 'description',
            key: 'action',
        },
        {
            title: 'Action',
            dataIndex: 'Show',
            key: 'action',
            render: (_, row) => (
                <BonusPolicyDetail id={row.id} />
            ),
        },
    ];

    useEffect(() => {
        getBonusPolicies();
    }, [])


    const getBonusPolicies = () => {
        setLoading(true);
        BonusPoliciesService.getAll()
            .then((response: any) => {
                let data = response.data;

                if (response.data.length > 0) {
                    let highest = null;
                    let num = 0;

                    data.forEach((item: any) => {
                        if (item.id > num) {
                            highest = item;
                            num = item.id;
                        }
                    });

                    setBonusPolicies([highest]);
                }

            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <motion.div
            initial={{ y: 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 300, opacity: 0 }}
            transition={{
                type: "spring",
                stiffness: 260,
                damping: 40,
            }}>
            <div>
                <Row>
                    <Col>
                        <Title className='onemaz-page-title' level={3}>{ }</Title>
                        <Breadcrumb
                            items={[
                                {
                                    title: <NavLink to="/" >Home</NavLink>,
                                },
                                {
                                    title: 'Settings',
                                },
                                {
                                    title: 'Bonus Policies',
                                },
                            ]} />
                    </Col>
                </Row>
                <SettingsRouter />
                <Row style={{ marginTop: '30px' }}>
                    <Col span={24}>
                        <Table columns={columns} dataSource={bonusPolicies} />
                    </Col>
                </Row>
            </div>
        </motion.div>
    )
}
