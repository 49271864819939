import { Breadcrumb, Col, Row, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { motion } from "framer-motion";
import RoleService from "../../services/RoleService";
import { useEffect, useState } from 'react';
import { IRoleResponseData } from '../../types/Role';
import RoleDetail from '../settings/RoleDetail';
import { NavLink } from 'react-router-dom';
import SettingsRouter from './SettingsRouter';
// import { IPermissionResponseData } from '../../types/Permission';
const { Title } = Typography;

export const RoleList = () => {

    const [roles, setRoles] = useState<IRoleResponseData[]>([]);
    const [loading, setLoading] = useState(true);
    // const [permission, setPermissions] = useState<IPermissionResponseData[]>([]);

    const columns: ColumnsType<IRoleResponseData> = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'action',
        },
        {
            title: 'Show',
            dataIndex: 'Show',
            key: 'action',
            render: (_, row) => (
                <RoleDetail id={row.id} />
            ),
        },

    ];

    useEffect(() => {
        getRoles();
    }, [])

    const getRoles = () => {
        setLoading(true);
        RoleService.getAll()
            .then((response: any) => {
                let data = response.data;
                setRoles(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <motion.div
            initial={{ y: 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 300, opacity: 0 }}
            transition={{
                type: "spring",
                stiffness: 260,
                damping: 40,
            }}>
            <div>
                <Row>
                    <Col>
                        <Title className='onemaz-page-title' level={3}>{ }</Title>
                        <Breadcrumb
                            items={[
                                {
                                    title: <NavLink to="/" >Home</NavLink>,
                                },
                                {
                                    title: 'Settings',
                                },
                                {
                                    title: 'Roles',
                                },
                            ]} />
                    </Col>
                </Row>
                <SettingsRouter />

                <Row style={{ marginTop: '30px' }}>
                    <Col span={24}>
                        <Table loading={loading} columns={columns} dataSource={roles} />
                    </Col>
                </Row>

            </div>
        </motion.div>
    )
}
