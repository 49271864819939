import moment from 'moment';
import { notification } from 'antd';
import OneamzAppService from './services/OneamzAppService';
import LoginService from './services/LoginService';
import UserService from './services/UserService';
import dayjs from 'dayjs';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
export const openNotification = (type: NotificationType, status: string, description: string, duration = 2) => {
  const args = {
    message: status,
    description: description,
    duration: duration,
  };
  notification[type](args);
};

export function formatMoney(balance: any): any {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(balance ?? 0);
}

export function convertDateFormat(dateStr: any): string {
  return dayjs(dateStr, 'DD/MM/YYYY').format('YYYY-MM-DD');
}

export function convertDate(dateStr: any): string {
  if (dateStr) {
    return moment(dateStr).format('DD/MM/YYYY');
  } else {
    return '-';
  }
}
export function getCurrentMonthDateRange() {

  let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().add(-7, 'd'), dayjs()
  ];

  return dateRangeDefaultValue;
}

export function getCurrentMonthDateRangeNew() {

  let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().startOf('month'), dayjs()
  ];

  return dateRangeDefaultValue;
}


export function getDateRangeDefault(): any {
  let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().add(-7, 'd'),
    dayjs()
  ];
  return dateRangeDefaultValue;
}

export function formatDateString(dateString: any): string {
  return moment(dateString).format('DD/MM/YYYY HH:mm');
}

export function getProfilePhoto(): any {

  let profilePhotoObj: any = {
    id: 0,
    photo: require(`./assets/animojis/Number=1.png`)
  }

  let profilePhoto = localStorage.getItem('profilePhoto');

  if (profilePhoto !== null) {
    profilePhotoObj.id = profilePhoto;
    profilePhotoObj.photo = require(`./assets/animojis/Number=${profilePhoto}.png`);
  }

  return profilePhotoObj;

}

export function getProfilePhotoPath(id: any): any {
  return require(`./assets/animojis/Number=${id}.png`);
}

export function can(permission: string): boolean {
  const permissions = localStorage.getItem('permissions');

  if (permissions !== null) {
    const permissionsArray: string[] = JSON.parse(permissions);
    return permissionsArray.includes(permission);
  }

  return false;
}

export function getToken(externalToken: string) {

  let data = {
    "externalToken": externalToken
  };

  LoginService.login(data)
    .then((response: any) => {
      let accessToken = response.data.accessToken;
      let refreshToken = response.data.refreshToken;
      let accessTokenExpiredAt = response.data.accessTokenExpiredAt;
      let refreshTokenExpiredAt = response.data.refreshTokenExpiredAt;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('accessTokenExpiredAt', accessTokenExpiredAt);
      localStorage.setItem('refreshTokenExpiredAt', refreshTokenExpiredAt);

      UserService.getMe()
        .then((response: any) => {

          const data = response.data;
          localStorage.setItem('firstName', data.firstName);
          localStorage.setItem('lastName', data.lastName);
          localStorage.setItem('id', data.id);
          localStorage.setItem('salesTarget', data.salesTarget);
          localStorage.setItem('email', data.username);
          localStorage.setItem('profilePhoto', data.profilePhoto || 1);
          localStorage.setItem('role', data.roles[0]?.description);
          localStorage.setItem('gsmPortalEmployeeId', data.gsmPortalEmployeeId);

          let permissions = data.roles[0].permissions;

          let permissionNames = permissions.map((permission: any) => permission.name);
          localStorage.setItem('permissions', JSON.stringify(permissionNames));

        })
    })
    .catch((e: Error) => {

    });
};

export function refrestToken() {


  const currentTime = moment();
  const lastSavedTime = localStorage.getItem('lastCheckTime');
  const lastSavedMoment = lastSavedTime ? moment(lastSavedTime, 'LTS') : null;

  if ((lastSavedMoment && currentTime.diff(lastSavedMoment, 'hours') >= 1) || lastSavedTime === null) {


    console.log(`Son kaydedilen saat üzerinden 1 saat geçti: ${lastSavedTime}`);

    let data = {
      "refreshToken": localStorage.getItem('externalRefreshToken')
    };

    OneamzAppService.refrestToken(data)
      .then((response: any) => {

        if (response.data.code === 401) {
          clearStorage();
          window.location.href = "/login";

        } else {
          localStorage.setItem('lastCheckTime', currentTime.format('LTS'));

          let data = response.data;
          let externalAccessToken = data.accessToken;
          let externalRefreshToken = data.refreshToken;
          localStorage.setItem('externalAccessToken', externalAccessToken);
          localStorage.setItem('externalRefreshToken', externalRefreshToken);

          getToken(externalAccessToken);
        }

      }).catch((e: Error) => {
      });
  }
};

export function clearStorage() {

  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessTokenExpiredAt');
  localStorage.removeItem('refreshTokenExpiredAt');
  localStorage.removeItem('externalAccessToken');
  localStorage.removeItem('externalRefreshToken');
  localStorage.removeItem('lastCheckTime');

  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('id');
  localStorage.removeItem('salesTarget');
  localStorage.removeItem('email');
  localStorage.removeItem('profilePhoto');
  localStorage.removeItem('role');
  localStorage.removeItem('gsmPortalEmployeeId');
  localStorage.removeItem('permissions');

}

export function generatePassword(length: number = 12): string {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
}