import { Button, Modal, Form, Select, DatePicker, Row } from "antd";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { ICustomerStatusResponseData } from "../../../types/CustomerStatus";
import { IUserResponseData } from "../../../types/User";
import { can } from "../../../utils";
import filterIcon from "../../../assets/filter.svg";
import StatusService from "../../../services/StatusService";
const { Item } = Form;

interface CustomerFilterProps {
  onChangeFilter: (values: any) => void;
  setFormFields: any;
  formFields: any;
}

const Filter: React.FC<CustomerFilterProps> = ({
  onChangeFilter,
  setFormFields,
  formFields,
}) => {
  const { RangePicker } = DatePicker;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<
    ICustomerStatusResponseData[]
  >([]);
  const [userOptions, setUserOptions] = useState<IUserResponseData[]>([]);
  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [disableState, setDisableState] = useState(false);
  // eslint-disable-next-line
  const [disablePassiveDays, setDisablePassiveDays] = useState(false);
  const roleName = localStorage.getItem("roleName");
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const showModal = () => {
    if (customerStatusOptions.length === 0) {
      getUsers();
    }
    if (userOptions.length === 0) {
      getUsers();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    onChangeFilter(values);
    setFormFields(form.getFieldsValue());
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = () => {
    if (can("users:getAll")) {
      UserService.getAll("?roleName=ACCOUNT_MANAGER")
        .then((response: any) => {
          let data = response;
          setUserOptions(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setSelectedItems([]);
    setDisablePassiveDays(false);
    setDisableState(false);
    setFormFields(form.getFieldsValue());
  };

  const getCustomerStatus = () => {
    StatusService.getAll("statusType=CALL_CENTER_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getCustomerStatus();
    // eslint-disable-next-line
  }, []);

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { startPassiveDay, endPassiveDay, state } = allValues;
    if (
      startPassiveDay &&
      (endPassiveDay < startPassiveDay || !endPassiveDay)
    ) {
      form.setFieldsValue({ endPassiveDay: startPassiveDay });
    }
    if (startPassiveDay || endPassiveDay) {
      setDisableState(true);
      setDisablePassiveDays(false);
    }
    if (state) {
      setDisablePassiveDays(true);
      setDisableState(false);
    }
  };

  const selectAll = () => {
    const allIds = customerStatusOptions.map((option:any) => option.value);
    setSelectedItems(allIds);
    form.setFieldsValue({
      status: allIds,
    });
  }; 

  const handleChange = (selected: any) => {
    setSelectedItems(selected);
    form.setFieldsValue({
      status: selected,
    });
  };

  return (
    <>
      <Modal
        title="Customer Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          {roleName !== "ACCOUNT_MANAGER" && userOptions ? (
            <>
              Account Manager Member
              <Item name="accountManagerMember">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {userOptions?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.firstName} {option.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </>
          ) : null}
          Status
          <Item name="status">
            <Select
              placeholder={"Select a status"}
              options={customerStatusOptions}
              showSearch
              allowClear
              mode="multiple"
              value={selectedItems}
              style={{ width: "75%", marginRight: "10px" }}
              onChange={handleChange}
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            ></Select>
            <Button style={{ float: "right" }} onClick={selectAll}>
              Select All
            </Button>
          </Item>
          Created At
          <Item name="createdAtRange">
            <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
          </Item>
          <Item>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
      <Button className="mr-15" onClick={showModal} type="default">
        <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
      </Button>
    </>
  );
};

export default Filter;
