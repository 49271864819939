import {
  Button,
  Form,
  Input,
  Modal,
  Row
} from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "./../../../assets/XCircle.svg";
import TextArea from "antd/es/input/TextArea";
import CustomerNoteService from "../../../services/CustomerNoteService";
import { openNotification } from "../../../utils";

const { Item } = Form;

const MultiNote: React.FC<any> = ({ selectedRowKeys }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [isModalVisible]);

  const createNote = (values: any) => {
    let internal = {
      description: values.note,
      type: "INTERNAL",
      customerIds: selectedRowKeys,
    };
    CustomerNoteService.createNoteMulti(internal)
      .then((response: any) => {
        openNotification("success", "Success", "Note added");
        setIsModalVisible(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setIsModalVisible(false);
      });
  };

  useEffect(() => {
    form.resetFields(["Selected Customers"]);
    form.setFieldsValue({
      "Selected Customers": selectedRowKeys.length.toString(),
    });
  }, [selectedRowKeys, isModalVisible, form]);

  return (
    <>
      <Row justify={"end"}>
        <Button
          type="primary"
          style={{ marginRight: 15 }}
          onClick={() => setIsModalVisible(true)}
        >
          Add Note
        </Button>
      </Row>
      <Modal
        title={<>Add Note</>}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={createNote} layout="vertical">
          <Item
            name="note"
            label="Note"
            rules={[
              {
                required: true,
                message: "note is required",
              },
            ]}
          >
            <TextArea rows={4} />
          </Item>
          <Item name="Selected Customers" label="Selected Customers">
            <Input disabled />
          </Item>

          <Item>
            <Row>
              <Button
                type="primary"
                className=" w-100 border-radius-8"
                style={{ width: "100%" }}
                htmlType="submit"
              >
                OK
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default MultiNote;
