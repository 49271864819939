import http from "../http-common";

const getAll = () => {
  return http.get<Array<any>>(`/call-center-sales-package`);
};

const get = (id: any) => {
  return http.get<Array<any>>(`/call-center-sales-package/${id}`);
};

const remove = (id: any) => {
  return http.delete<Array<any>>(`/call-center-sales-package/${id}`);
};

const create = (data: any) => {
  return http.post<any>("/call-center-sales-package", data);
};

const updateIsActive = (id: any, data: any) => {
  return http.patch<any>(`/call-center-sales-package/${id}`, data);
};

const getAllSalesLog = (query = '') => {
  return http.get<Array<any>>(`/call-center-sales-log${query}`);
}

const createSalesLog = (data: any) => {
  return http.post<any>("/call-center-sales-log", data);
};

const getSalesLog = (id:any) => {
  return http.get<any>(`/call-center-sales-log${id}`);
};

const removeSalesLog = (id:any) => {
  return http.delete<any>(`/call-center-sales-log/${id}`);
};

const updateSalesLog = (id:any, data:any) => {
  return http.put<any>(`/call-center-sales-log/${id}`, data);
};

const approveSalesLog = (id: any, approveStatus: boolean) => {
  return http.put<any>(`/call-center-sales-log/${id}/approve?approveStatus=${approveStatus}`);
}


const CallSalesPackageService = {
  getAll,
  get,
  remove,
  create,
  updateIsActive,
  getAllSalesLog,
  createSalesLog,
  getSalesLog,
  removeSalesLog,
  updateSalesLog,
  approveSalesLog
};

export default CallSalesPackageService;