import http from "../http-common";
import { ILearnerResponseData, ILearnerRequestData } from "../types/Learner";

const getAll = (filterCriteria: string = '') => {
  return http.get<Array<ILearnerResponseData>>(`/learners${filterCriteria}`);
};

const getAllWithoutPagination = (filterCriteria: string = "") => {
  return http.get<Array<ILearnerResponseData>>(`/learners/all${filterCriteria}`);
};

const get = (id: any) => {
  return http.get<ILearnerResponseData>(`/learners/${id}`);
};

const getTasksByLearnerId = (id: any) => {
  return http.get<ILearnerResponseData>(`/learners/${id}/tasks`);
};

const getLearnerStatusLogs = (id: any) => {
  return http.get<ILearnerResponseData>(`/learners/${id}/learner-status-logs`);
};

const getMyLearners = (filterCriteria: string = '') => {
  return http.get<Array<ILearnerResponseData>>(`/users/me/learners${filterCriteria}`);
};

const updateAssigneUser = (id: any, data: any) => {
  return http.patch<any>(`/learners/${id}/users`, data);
};

const getMeLearnerStatisticsCount = () => {
  return http.get(`/me/learner-statistics/count`);
};

const getLearnerStatisticsCount = () => {
  return http.get(`/learner-statistics/count`);
};

const getMyLearnersWithoutPagination = (filterCriteria: string = "") => {
  return http.get<Array<ILearnerResponseData>>(`/users/me/learners/all${filterCriteria}`);
};

// const create = (learnerId: any, data: ILearnerRequestData) => {
//   return http.post<ILearnerRequestData>(`/learners/${learnerId}`, data);
// };

const update = (id: any, data: ILearnerRequestData) => {
  return http.put<any>(`/learners/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/learners/notes/${id}`);
};

const getLearnerStatistics = (criteria: any) => {
  return http.get(`/learner-statistics/daily/report${criteria}`);
};

const getMeLearnerStatistics = (criteria: any) => {
  return http.get(`/me/learner-statistics/daily/report${criteria}`);
};

const getMeLearnerEarningsStatistics = (criteria: any) => {
  return http.get(`/users/me/dashboard/learner-summary${criteria}`);
};

const getLearnerEarningsStatistics = (criteria: any) => {
  return http.get(`/learner-summary${criteria}`);
};

const learnerCall = (gsmPortalEmployeeId: any, phoneNumber: number) => {
  return http.get(`/call/customer/${gsmPortalEmployeeId}/${phoneNumber}`);
};

const makeCustomer = (id: any, data: any) => {
  return http.patch<any>(`/learners/${id}/customers`, data);
};

const attendLearnerToNewCourse = (id: any) => {
  const baseURL = process.env.REACT_APP_API_MAKE_CUSTOMER_BASE_URL;
  const config = {
    baseURL: baseURL,
  };
  return http.post<any>(`/learners/${id}/courses/attend`, {}, config);
};

const updateContactInfo = (id: any, data: any) => {
  return http.patch<any>(`/learners/${id}`, data);
};

const getAllLearnersLite = (criteria: string = '') => {
  return http.get<Array<any>>(`/learners/all-lite${criteria}`);
};

const getAllLearnersLiteMe = (criteria: string = '') => {
  return http.get<Array<any>>(`/users/me/learners/all-lite${criteria}`);
};

const updateMultiStatus = (data: any) => {
  return http.put<any>(`/learners/status`, data);
};

const getAffiliate = (id: any) => {
  return http.get<any>(`/affiliates/${id}`);
};

const LearnerService = {
  getAll,
  get,
  getMyLearners,
  getMyLearnersWithoutPagination,
  update,
  remove,
  getAllWithoutPagination,
  getLearnerStatusLogs,
  getTasksByLearnerId,
  updateAssigneUser,
  getMeLearnerStatisticsCount,
  getLearnerStatisticsCount,
  getLearnerStatistics,
  getMeLearnerStatistics,
  getMeLearnerEarningsStatistics,
  getLearnerEarningsStatistics,
  learnerCall,
  makeCustomer,
  updateContactInfo,
  attendLearnerToNewCourse,
  getAllLearnersLite,
  getAllLearnersLiteMe,
  updateMultiStatus,
  getAffiliate
};

export default LearnerService;