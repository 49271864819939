import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Col, Row, Space, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import DashboardService from "../../../services/DashboardService";
import { getProfilePhotoPath } from "../../../utils";

interface SuspendTeamCardProps {
  title: string;
  // description: string;
  dateRange: any;
  // callTeamUsers: any;
}

const SuspendTeamCard: React.FC<SuspendTeamCardProps> = ({
  title,
  // description,
  dateRange,
  // callTeamUsers,
}) => {
  const [phoneCallSummary, setPhoneCallSummary] = useState<any>();
  const [totalCalls, setTotalCalls] = useState<number>(0);
  const [reached, setReached] = useState<number>(0);
  const [notReached, setNotReached] = useState<number>(0);
  const [reachedRatio, setReachedRatio] = useState<number>(0);
  const [phoneCallSummaryMe, setPhoneCallSummaryMe] = useState<any>();
  const [totalCallsMe, setTotalCallsMe] = useState<number>(0);
  const [reachedMe, setReachedMe] = useState<number>(0);
  const [notReachedMe, setNotReachedMe] = useState<number>(0);
  const [reachedRatioMe, setReachedRatioMe] = useState<number>(0);
  const [allCallTeamUsers, setAllCallTeamUsers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  // const [options, setOptions] = useState<any>({
  //   chart: {
  //     type: "donut",
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     lineCap: "round",
  //   },
  //   plotOptions: {
  //     radialBar: {
  //       track: {
  //         background: "#86A4C8",
  //       },
  //     },
  //   },
  //   labels: ["Answered"],
  //   colors: ["#C88686"],
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         chart: {
  //           width: 130,
  //         },
  //         legend: {
  //           position: "bottom",
  //         },
  //       },
  //     },
  //   ],
  // });

  const roleName = localStorage.getItem("roleName");

  const fetchPhoneCallSummary = async (formattedDateRange: string) => {
    try {
      // if (roleName === "CALL_TEAM_MANAGER") {
      //   const summaryResponse = await DashboardService.phoneCallSummary(
      //     `${formattedDateRange}&roleName=CALL_TEAM`
      //   );
      //   const { totalCalls, missed, answered } = summaryResponse.data;
      //   setPhoneCallSummary(summaryResponse.data);
      //   setAllCallTeamUsers(summaryResponse.data.userList);
      //   setTotalCalls(totalCalls);
      //   setNotReached(missed);
      //   setReached(answered);
      //   const ratio = ((answered / totalCalls) * 100).toFixed(0);
      //   setReachedRatio(isNaN(parseFloat(ratio)) ? 0 : parseFloat(ratio));

      //   // if (callTeamUsers.length > 0) {
      //   //   const allResponses = await Promise.all(
      //   //     callTeamUsers.map((user: any) =>
      //   //       DashboardService.phoneCallSummaryByUser(
      //   //         user.gsmPortalEmployeeId,
      //   //         `${formattedDateRange}`
      //   //       )
      //   //     )
      //   //   );
      //   //   const allData = allResponses.map((response, index) => ({
      //   //     ...response.data,
      //   //     firstName: callTeamUsers[index].firstName,
      //   //     lastName: callTeamUsers[index].lastName,
      //   //     profilePhoto: callTeamUsers[index].profilePhoto,
      //   //   }));
      //   //   setAllCallTeamUsers(allData);
      //   // }
      // } else {
      //   const summaryMeResponse = await DashboardService.phoneCallSummaryMe(
      //     `${formattedDateRange}`
      //   );
      //   const { totalCalls, missed, answered } = summaryMeResponse.data;
      //   setPhoneCallSummaryMe(summaryMeResponse.data);
      //   setTotalCallsMe(totalCalls);
      //   setNotReachedMe(missed);
      //   setReachedMe(answered);
      //   const ratioMe = ((answered / totalCalls) * 100).toFixed(0);
      //   setReachedRatioMe(isNaN(parseFloat(ratioMe)) ? 0 : parseFloat(ratioMe));
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      const [startDate, endDate] = dateRange;
      if (startDate && endDate) {
        const formattedDateRange = `?startDate=${startDate}&endDate=${endDate}`;
        setLoading(true);
        fetchPhoneCallSummary(formattedDateRange);
      }
    }
  }, [dateRange, roleName]);

  // const columns = [
  //   {
  //     title: "Name",
  //     dataIndex: "firstName",
  //     key: "firstName",
  //     ellipsis: true,
  //     width: 150,
  //     render: (text: string, record: any) => (
  //       <Row
  //         align="middle"
  //         style={{ overflow: "hidden", textOverflow: "ellipsis" }}
  //       >
  //         <Avatar
  //           style={{ marginRight: 5 }}
  //           size={30}
  //           src={getProfilePhotoPath(record?.user?.profilePhoto || 1)}
  //         />
  //         <p
  //           style={{
  //             margin: "0px",
  //             padding: "0px",
  //             overflow: "hidden",
  //             textOverflow: "ellipsis",
  //           }}
  //         >
  //           {record?.user?.firstName} {record?.user?.lastName}
  //         </p>
  //       </Row>
  //     ),
  //   },
  //   {
  //     title: "Calls",
  //     dataIndex: "totalCalls",
  //     key: "totalCalls",
  //     width: 70,
  //     align: "center" as const,
  //     sorter: (a: any, b: any) => a.totalCalls - b.totalCalls,
  //     defaultSortOrder: "descend" as const,
  //   },
  //   {
  //     title: "Answered",
  //     dataIndex: "answered",
  //     key: "answered",
  //     width: 90,
  //     align: "center" as const,
  //     sorter: (a: any, b: any) => a.answered - b.answered,
  //   },
  //   {
  //     title: "Missed",
  //     dataIndex: "missed",
  //     key: "missed",
  //     width: 70,
  //     align: "center" as const,
  //     sorter: (a: any, b: any) => a.missed - b.missed,
  //   },
  //   {
  //     title: "Ratio",
  //     dataIndex: "answeredCallRatio",
  //     key: "answeredCallRatio",
  //     width: 70,
  //     align: "center" as const,
  //     sorter: (a: any, b: any) => a.answeredCallRatio - b.answeredCallRatio,
  //     render: (text: string, record: any) => (
  //       <p style={{ margin: "0px", padding: "0px" }}>
  //         {record.answeredCallRatio}%
  //       </p>
  //     ),
  //   },
  // ];

  return (
    <Card bordered={false} className="sales-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      {roleName === "SUSPEND_TEAM_MANAGER" && (
        <p className="description" style={{ marginBottom: "-20px" }}>
          {/* {description} */}
        </p>
      )}
      <Row gutter={[24, 24]} align="middle" justify="space-between">
        <Col xs={13} sm={12}>
          {/* <ReactApexChart
            options={options}
            series={
              roleName === "CALL_TEAM_MANAGER"
                ? [reachedRatio]
                : [reachedRatioMe]
            }
            type="radialBar"
            height={210}
          /> */}
        </Col>
        <Col xs={11} sm={12}>
          <Row align="middle" style={{ height: "180px" }}>
            {/* <Space direction="vertical">
              <Badge
                color="gray"
                style={{ whiteSpace: "nowrap" }}
                text={
                  <>
                    Total:{" "}
                    <b>
                      {roleName === "CALL_TEAM_MANAGER"
                        ? totalCalls
                        : totalCallsMe}
                    </b>
                  </>
                }
              />
              <Badge
                color="rgb(200, 134, 134)"
                style={{ whiteSpace: "nowrap" }}
                text={
                  <>
                    Answered:{" "}
                    <b>
                      {roleName === "CALL_TEAM_MANAGER" ? reached : reachedMe}
                    </b>
                  </>
                }
              />
              <Badge
                color="rgb(134, 164, 200)"
                style={{ whiteSpace: "nowrap" }}
                text={
                  <>
                    Missed:{" "}
                    <b>
                      {roleName === "CALL_TEAM_MANAGER"
                        ? notReached
                        : notReachedMe}
                    </b>
                  </>
                }
              />
            </Space> */}
          </Row>
        </Col>
      </Row>
      {roleName === "SUSPEND_TEAM_MANAGER" && (
        <Row>
          <Col span={24}>
            {/* <Table
              rowKey="id"
              columns={columns}
              dataSource={allCallTeamUsers}
              pagination={false}
              scroll={{ x: "100%", y: 150 }}
              size="small"
            /> */}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default SuspendTeamCard;
