import React from "react";
import { ColumnsType } from "antd/es/table";
import { Button, Modal, Table } from "antd";
import moment from "moment";
import { formatMoney } from "../../../utils";
import * as XLSX from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";

interface SlpRetentionSalesLog {
  fullName: string;
  phone: string;
  renewSlpPackageHistoryList: Array<{
    email: string;
    packageName: string;
    paidAmount: number;
    startDate: string;
  }>;
}

interface EarningsSlpRetentionDetailReportModalProps {
  visible: boolean;
  onClose: () => void;
  member: any;
  slpRetentionSalesLogs: SlpRetentionSalesLog[];
}

export const EarningsSlpRetentionDetailReportModal: React.FC<
  EarningsSlpRetentionDetailReportModalProps
> = ({ visible, onClose, member, slpRetentionSalesLogs }) => {

  const modalColumns: ColumnsType<SlpRetentionSalesLog> = [
    {
      title: "Created Date",
      dataIndex: ["renewSlpPackageHistoryList", "startDate"],
      key: "startDate",
      width: 150,
      align: "center",
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        moment(a?.renewSlpPackageHistoryList[0]?.startDate).unix() -
        moment(b?.renewSlpPackageHistoryList[0]?.startDate).unix(),
      render: (text: string, record: any) => moment(record?.renewSlpPackageHistoryList[0]?.startDate).format("YYYY-MM-DD HH:mm"),
    },

    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      ellipsis: true,
      width: 150,
      fixed: "left" as "left",
      sorter: (a: any, b: any) =>
        (a?.fullName || "").localeCompare(b?.fullName || ""),
      render: (text: string, record: any) => (
        <b
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            maxWidth: 150,
          }}
        >
          {text}
        </b>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      render: (text: string) => <>{text}</>,
    },
    {
      title: "Email",
      dataIndex: ["renewSlpPackageHistoryList", "email"],
      key: "email",
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => (
        <>{record?.renewSlpPackageHistoryList[0]?.email}</>
      ),
    },
    {
      title: "Package Name",
      dataIndex: ["renewSlpPackageHistoryList", "packageName"],
      key: "packageName",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) =>
        a?.renewSlpPackageHistoryList[0]?.packageName.localeCompare(
          b?.renewSlpPackageHistoryList[0]?.packageName
        ),
      render: (text: string, record: any) => (
        <b>{record?.renewSlpPackageHistoryList[0]?.packageName}</b>
      ),
    },
    {
      title: "Package Price",
      dataIndex: ["renewSlpPackageHistoryList", "paidAmount"],
      key: "paidAmount",
      width: 150,
      align: "center",
      sorter: (a: any, b: any) =>
        a?.renewSlpPackageHistoryList[0]?.paidAmount -
        b?.renewSlpPackageHistoryList[0]?.paidAmount,
      render: (text: number, record: any) =>
        `${formatMoney(record?.renewSlpPackageHistoryList[0]?.paidAmount)}`,
    },
    {
      title: "Commission Price",
      dataIndex: "",
      key: "index",
      width: 150,
      align: "center",
      fixed: "right",
      render: (text: number) => <b>{formatMoney("6")}</b>,
    },
  ];

  const downloadExcel = (
    slpRetentionSalesLogs: SlpRetentionSalesLog[],
    member: { firstName: string; lastName: string }
  ) => {
    const data = slpRetentionSalesLogs?.map((item: SlpRetentionSalesLog) => {
      const history = item?.renewSlpPackageHistoryList[0];
      const baseData: any = {
        "Created Date": moment(history?.startDate).format("YYYY-MM-DD HH:mm"),
        "Full Name": item?.fullName,
        "Phone Number": item?.phone,
        "Email": history?.email,
        "Package Name": history?.packageName,
        "Commission Price": formatMoney("6"),
        "Package Price": formatMoney(history?.paidAmount)
      };
      return baseData;
    });
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Earnings Report Details`);
    XLSX.writeFile(
      wb,
      `${member?.firstName} ${member?.lastName} - SLP Retention Earnings Report Details.xlsx`
    );
  };

  return (
    <Modal
      title={`${member?.firstName} ${member?.lastName} - SLP Retention Earnings Report Details`}
      open={visible}
      onCancel={onClose}
      width={1000}
      footer={
        <Button
          style={{ marginLeft: "20px" }}
          icon={<DownloadOutlined />}
          onClick={() => downloadExcel(slpRetentionSalesLogs, member)}
          type="primary"
        >
          Export
        </Button>
      }
    >
      <Table
        columns={modalColumns}
        dataSource={slpRetentionSalesLogs}
        pagination={false}
        rowKey="id"
        size="small"
        sticky
        scroll={{ x: "max-content", y: 500 }}
      />
    </Modal>
  );
};
