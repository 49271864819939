import { useEffect, useState } from "react";
import { IDepartmentResponseData } from "../types/Department";
import DepartmentService from "../services/DepartmentService";
import EmployeeService from "../services/EmployeeService";
import { IEmployeeResponseData } from "../types/Employee";
import { ColumnsType } from "antd/es/table";
import { Card, Col, Input, Row, Select, Table } from "antd";
import { CopyClipboard } from "../components/General/CopyClipboard";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

export const EmployeesList = () => {
  const [employees, setEmployees] = useState<Array<IEmployeeResponseData>>([]);
  const [departments, setDepartments] = useState<IDepartmentResponseData[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<
    string | undefined
  >(undefined);
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const getDepartments = () => {
    setLoading(true);
    DepartmentService.getAll()
      .then((response: any) => {
        let data = response.data;
        setDepartments([{ id: "null", name: "All Departments" }, ...data]);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const clearInput = () => {
    setSearchText("");
  };

  const getEmployees = (departmentId?: string, searchText?: string) => {
    setLoading(true);
    let serviceCall;

    if (departmentId && departmentId !== "null") {
      const parsedDepartmentId = parseInt(departmentId);
      if (isNaN(parsedDepartmentId)) {
        setLoading(false);
        return;
      }
      serviceCall = EmployeeService.getByDepartment(parsedDepartmentId);
    } else {
      serviceCall = EmployeeService.getAll(searchText);
    }

    serviceCall
      .then((response: any) => {
        let data = response.data;
        setEmployees(data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<IEmployeeResponseData> = [
    {
      title: "Employee",
      dataIndex: "fullName",
      key: "fullName",
      width: 250,
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (_, row) => (
        <Row justify={"start"} align={"middle"}>
          <Col>
            <div>
              <b>{row.fullName}</b>
            </div>
            <div>
              <CopyClipboard text={row.email}></CopyClipboard>
            </div>
            <div>
              <CopyClipboard text={row.phone}></CopyClipboard>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (_, row) => <span>{row.description}</span>,
    },
    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 250,
      render: (_, row) => <span>{row.tools.join(", ")}</span>,
    },
  ];

  useEffect(() => {
    if (departments.length === 0) {
      getDepartments();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const debounceSearch = debounce(() => {
      getEmployees(selectedDepartment, searchText);
    }, 300);

    debounceSearch();
    return () => {
      debounceSearch.cancel();
    };
  }, [searchText, selectedDepartment]);

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Employee List
            </div>
            <div className="customer-card-info">
              <b>{employees?.length} </b>Employees
            </div>
          </div>
        }
        extra={
          <Row style={{ minWidth: 400, display: "flex" }} gutter={[10, 10]}>
            <Col xs={12}>
              <Input
                prefix={<SearchOutlined />}
                suffix={
                  searchText && (
                    <CloseCircleOutlined
                      onClick={clearInput}
                      style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
                    />
                  )
                }
                placeholder="Search by full name"
                value={searchText}
                onChange={onSearchChange}
                style={{ maxWidth: 200 }}
              />
            </Col>
            <Col xs={12}>
              <Select
                style={{ width: "100%" }}
                options={departments.map((department) => ({
                  value: department.id,
                  label: department.name,
                }))}
                showSearch
                filterOption={(input: any, option: any) =>
                  option.label
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select Department"
                onChange={(value) => {
                  setSelectedDepartment(value);
                }}
              />
            </Col>
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey="id"
          loading={loading}
          scroll={{ x: "100%" }}
          sticky
          columns={columns}
          dataSource={employees}
        />
      </Card>
    </>
  );
};
