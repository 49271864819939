import http from "../http-common";

const getAll = () => {
  return http.get<Array<any>>(`/customers/statuses`);
};

const get = (id: any) => {
  return http.get<Array<any>>(`/customers/statuses/${id}`);
};

const create = (data: any) => {
  return http.post<any>("/customers/statuses", data);
};

const update = (id: any, data: any) => {
  return http.put<any>(`/customers/statuses/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/customers/statuses/${id}`);
};

const RetentionStatusService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default RetentionStatusService;
