import { Card, Col, Progress, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import './target-statistics.css';
import { useTranslation } from 'react-i18next';
import LearnerService from '../../services/LearnerService';
import { can } from '../../utils';
import UserService from '../../services/UserService';

export const TargetStatistics = () => {
    const [sales, setSales] = useState<number>(0)
    const [target, setTarget] = useState<any>(0)
    const [ratio, setRatio] = useState<number>(0)

    useEffect(() => {
        getLearnerStatistics();
        getUsers();
    }, [])

    const calculateTotalSalesTarget = (data: any[]): number => {
        return data.reduce((sum, current) => sum + current.salesTarget, 0);
      };

    const getUsers = () => {
        if (can("learners:getAll")) {
            UserService.getAll("?roleName=SUCCESS_TEAM")
                .then((response: any) => {
                    let data = response;
                    const totalSalesTarget = calculateTotalSalesTarget(data);
                    setTarget(totalSalesTarget);

                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }else{
            setTarget(localStorage.getItem("salesTarget"));
        }

    };

    const getLearnerStatistics = () => {
        let canPermission = can("learners:getAll");


        if (canPermission) {
            LearnerService.getLearnerStatisticsCount()
                .then((response: any) => {
                    setSales(response.data.count)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            LearnerService.getMeLearnerStatisticsCount()
                .then((response: any) => {
                    setSales(response.data.count)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }

    }

    useEffect(() => {
        let salesRatio: any = ((sales / target) * 100).toFixed(0);
        setRatio(salesRatio);
    }, [sales,target])


    const { t } = useTranslation();
    return (
        <div>
            <Card className='target-statistics'>
                <Row justify={'space-between'}>
                    <Col>
                        <h3 className='title'>Integrated</h3>
                        <h1 className='customer-sales'>{sales}</h1>
                    </Col>
                    <Col>
                        <h3 className='title'>Target</h3>
                        <h1 className='customer-target'>{target}</h1>
                    </Col>
                </Row>
                <Progress trailColor='#DFFFEA' strokeColor={'#17C653'} percent={ratio}></Progress>
            </Card>
        </div>
    )
}