import {
  Breadcrumb,
  Col,
  Row,
  Typography,
  Table,
  Button,
  Form,
  Modal,
  Input,
  Select,
  Popconfirm,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { openNotification } from "../../utils";
import SettingsRouter from "./SettingsRouter";
import { NavLink } from "react-router-dom";
import ButtonGroup from "antd/es/button/button-group";
import StatusService from "../../services/StatusService";

const { Item } = Form;
const { Title } = Typography;

export const SoftwareSuspendStatus = () => {
  const [form] = Form.useForm();
  const [formEditStatus] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<string>("");
  const [selectedSubStatus, setSelectedSubStatus] = useState<any>(null);
  const [processedData, setProcessedData] = useState<any[]>([]);

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCreateModalCancel = () => {
    setIsCreateModalVisible(false);
  };

  const showEditModal = (subStatus: any) => {
    setSelectedSubStatus(subStatus);
    setNewStatus(subStatus.name);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedSubStatus(null);
  };

  const showDeleteModal = (subStatus: any) => {
    setSelectedSubStatus(subStatus);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedSubStatus(null);
  };

  // POST
  const postSuspendStatus = (data: any) => {
    setLoading(true);
    StatusService.create({...data, statusType: "SOFTWARE_SUSPENDED_STATUS"})
      .then((response: any) => {
        getSuspendStatus();
        form.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      postSuspendStatus(values);
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  // GETALL
  const getSuspendStatus = () => {
    setLoading(true);
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=SOFTWARE_SUSPENDED_STATUS")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setProcessedData(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  // PUT UPDATE
  const onChangeSubStatus = (id: number, name: string, parentId: number) => {
    setLoading(true);
    StatusService.update(id, { name, parentId, active: true })
      .then((response: any) => {
        openNotification("success", "Success", `Software Suspend Status updated`);
        handleEditModalCancel();
        getSuspendStatus();
        form.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  // DELETE
  const handleDeleteSubStatus = (id: number) => {
    setLoading(true);
    StatusService.remove(id)
      .then((response: any) => {
        openNotification("success", "Success", `Sub status deleted`);
        handleDeleteModalCancel();
        getSuspendStatus();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Main Status",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend",
      width: 150,
      sorter: (a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0),
      render: (_, row) => <b>{row.name || "Unknown"}</b>,
    },
    {
      title: "Sub Status",
      dataIndex: "subStatuses",
      key: "subStatuses",
      width: 400,
      render: (_, row) => (
        <>
          {row.subStatuses.map((subStatus: any) => (
            <div
              key={subStatus.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <span>{subStatus.name}</span>
              <ButtonGroup
                style={{ marginLeft: "10px", justifyContent: "flex-end" }}
              >
                <Button type="primary" onClick={() => showEditModal(subStatus)}>
                  Edit
                </Button>
                <Popconfirm
                  title="Delete sub status?"
                  description="Are you sure to delete this sub status?"
                  onConfirm={() => showDeleteModal(subStatus)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>Delete</Button>
                </Popconfirm>
              </ButtonGroup>
            </div>
          ))}
        </>
      ),
    },
  ];

  useEffect(() => {
    getSuspendStatus();
  }, []);

  useEffect(() => {
    formEditStatus.setFieldsValue({ selectedSubStatus: newStatus });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStatus]);

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Software Suspend Status",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />
        <Button type="primary" onClick={showCreateModal}>
          Create Software Suspend Status
        </Button>
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={processedData}
              pagination={false}
              scroll={{ x: "max-content" }}
              sticky
            />
          </Col>
        </Row>
        {/* Create Modal */}
        <Modal
          title="Create New Software Suspend Status"
          open={isCreateModalVisible}
          onOk={handleCreate}
          onCancel={handleCreateModalCancel}
        >
          <Form form={form} layout="vertical">
            <Item
              name="parentId"
              label="Main Status"
              rules={[
                {
                  required: true,
                  message: "Main status name required",
                },
              ]}
            >
              <Select placeholder={"Select"}>
                {processedData?.map((option: any) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>

            <Item
              name="name"
              label="Sub Status Name"
              rules={[
                {
                  required: true,
                  message: "Sub status name required",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Item>
          </Form>
        </Modal>
        {/* Edit Modal */}
        <Modal
          title="Edit Software Suspend Status"
          open={isEditModalVisible}
          onCancel={handleEditModalCancel}
          footer={[
            <Button key="back" onClick={handleEditModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                onChangeSubStatus(
                  selectedSubStatus?.id,
                  newStatus,
                  selectedSubStatus?.parent?.id
                )
              }
            >
              Save
            </Button>,
          ]}
        >
          <Form form={formEditStatus} layout="vertical">
            <Item
              name="selectedSubStatus"
              label="Sub Status Name"
              rules={[
                {
                  required: true,
                  message: "Sub status name required",
                },
              ]}
            >
              <Input
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
              />
            </Item>
          </Form>
        </Modal>
        {/* Delete Modal */}
        <Modal
          title="Delete Software Suspend Status"
          open={isDeleteModalVisible}
          onCancel={handleDeleteModalCancel}
          footer={[
            <Button key="back" onClick={handleDeleteModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleDeleteSubStatus(selectedSubStatus?.id)}
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this sub status?</p>
        </Modal>
      </div>
    </motion.div>
  );
};
