import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_EXTERNAL_API_BASE_URL,
  headers: {
    "Content-type": "application/json",
    'Accept': '*/*',
  }
});

// İstek interceptor'ı
http.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default http;
