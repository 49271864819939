import { Button, Modal, Form, Switch, Select, Row, Col } from "antd";
import React, { useState } from "react";
import UserService from "../../services/UserService";
import { IUserResponseData } from "../../types/User";
import filterIcon from "../../assets/filter.svg";
import { can } from "../../utils";
import RoleService from "../../services/RoleService";

const { Item } = Form;

interface CustomerFilterProps {
  onChangeFilter: (values: any) => void;
  setFormFields: any;
  formFields: any;
}

const UserListFilter: React.FC<CustomerFilterProps> = ({
  onChangeFilter,
  setFormFields,
  formFields,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userOptions, setUserOptions] = useState<IUserResponseData[]>([]);
  const [roleOptions, setRoleOptions] = useState<any[]>([]);
  const [form] = Form.useForm();
  const roleName = localStorage.getItem("roleName");

  const showModal = () => {
    if (userOptions.length === 0) {
      getUsers();
      getRoles();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    onChangeFilter(values);
    setFormFields(form.getFieldsValue());
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = () => {
    if (can("users:getAll")) {
      if (roleName === "CALL_TEAM_MANAGER") {
        UserService.getAllWithoutActiveFilter("roleName=CALL_TEAM")
          .then((response: any) => {
            let data = response;
            setUserOptions(data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else if (roleName === "SUCCESS_TEAM_MANAGER") {
        UserService.getAllWithoutActiveFilter("roleName=SUCCESS_TEAM")
          .then((response: any) => {
            let data = response;
            setUserOptions(data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else if (roleName === "ACCOUNT_MANAGER_MANAGER") {
        UserService.getAllWithoutActiveFilter("roleName=ACCOUNT_MANAGER")
          .then((response: any) => {
            let data = response;
            setUserOptions(data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else if (roleName === "HI5_ACM_MANAGER") {
        UserService.getAllWithoutActiveFilter("roleName=HI5_ACM_MEMBER")
          .then((response: any) => {
            let data = response;
            setUserOptions(data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else if (roleName === "SUSPEND_TEAM_MANAGER") {
        UserService.getAllWithoutActiveFilter("roleName=SUSPEND_TEAM")
          .then((response: any) => {
            let data = response;
            setUserOptions(data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else if (roleName === "MAS_TEAM_MANAGER") {
        UserService.getAllWithoutActiveFilter("roleName=MAS_TEAM_MEMBER")
          .then((response: any) => {
            let data = response;
            setUserOptions(data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else {
        UserService.getAllWithoutActiveFilter()
          .then((response: any) => {
            let data = response;
            setUserOptions(data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  const getRoles = () => {
    if (can("roles:getAll")) {
      RoleService.getAll()
        .then((response: any) => {
          let data = response.data;
          setRoleOptions(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setFormFields(form.getFieldsValue());
  };

  return (
    <>
      <Modal
        title="User Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          {userOptions ? (
            <>
              Username
              <Item name="username">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {userOptions?.map((option) => (
                    <Select.Option key={option.id} value={option.username}>
                      {option.username}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
              Fullname
              <Item name="fullName">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {userOptions?.map((option) => (
                    <Select.Option
                      key={option.id}
                      value={option.firstName + " " + option.lastName}
                    >
                      {option.firstName} {option.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
              {roleName === "ADMIN" ? (
                <>
                  Role Name
                  <Item name="roleName">
                    <Select placeholder={"Select"}>
                      <Select.Option key={0} value={null}>
                        Select
                      </Select.Option>
                      {roleOptions?.map((option) => (
                        <Select.Option key={option?.id} value={option?.name}>
                          {option?.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </>
              ) : null}
              <Row
                gutter={[16, 16]}
                align={"middle"}
                justify={"space-between"}
                style={{ marginTop: "-10px" }}
              >
                <Col
                  span={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Item
                    label="Status"
                    name="active"
                    valuePropName="checked"
                    style={{ marginTop: "10px" }}
                  >
                    <Switch style={{ marginTop: "10px" }} />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item
                    label="Auto Assignment"
                    name="autoAssignment"
                    valuePropName="checked"
                    style={{ marginTop: "10px" }}
                  >
                    <Switch style={{ marginTop: "10px" }} />
                  </Item>
                </Col>
              </Row>
            </>
          ) : null}
          <Item>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
      <Button onClick={showModal} type="default">
        <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
      </Button>
    </>
  );
};

export default UserListFilter;
