import React, { useEffect, useState } from "react";
import UserService from "../services/UserService";
import { ILeaveRequestData } from "../types/LeaveRequest";
import LeaveRequestService from "../services/LeaveRequestService";
import {
  DatePicker,
  Form,
  Button,
  Select,
  Table,
  Modal,
  Card,
  Row,
  Col,
  Switch,
  Popconfirm,
} from "antd";
import moment from "moment";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import type { GetProps } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { openNotification } from "../utils";

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const { RangePicker } = DatePicker;
const { Item } = Form;

export const TimeOffSchedule: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState<ILeaveRequestData[]>([]);
  const [editingRequest, setEditingRequest] =
    useState<ILeaveRequestData | null>(null);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
  const [isEditVisible, setIsEditVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const roleName = localStorage.getItem("roleName");
  const authUserId = localStorage.getItem("id");

  const managerRoles = [
    "CALL_TEAM_MANAGER",
    "SUCCESS_TEAM_MANAGER",
    "ACCOUNT_MANAGER_MANAGER",
    "SUSPEND_TEAM_MANAGER",
    "HI5_ACM_MANAGER",
    "MAS_TEAM_MANAGER",
  ];

  const isManager = roleName ? managerRoles.includes(roleName) : false;

  const getUsers = () => {
    const roleMapping: { [key: string]: string | string[] } = {
      CALL_TEAM_MANAGER: "CALL_TEAM",
      SUCCESS_TEAM_MANAGER: ["SUCCESS_TEAM", "SUCCESS_TEAM_REPORTER"],
      ACCOUNT_MANAGER_MANAGER: "ACCOUNT_MANAGER",
      SUSPEND_TEAM_MANAGER: "SUSPEND_TEAM",
      HI5_ACM_MANAGER: "HI5_ACM",
      MAS_TEAM_MANAGER: "MAS_TEAM_MEMBER",
      ADMIN: "ADMIN",
    };

    if (roleName) {
      const teamRole = roleMapping[roleName];
      if (teamRole) {
        // Eğer roleName bir dizi ise, birden fazla istek yapılması gerekiyor
        if (Array.isArray(teamRole)) {
          Promise.all(
            teamRole.map((role) => UserService.getAll(`?roleName=${role}`))
          )
            .then((responses) => {
              // Tüm yanıtları birleştir
              const mergedResponse = responses.flat();
              const newArray = mergedResponse.map((item: any) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
              }));
              setUserOptions(newArray);
            })
            .catch((e: Error) => {
              console.log(e);
            });
        } else {
          // Tek rol için istek
          UserService.getAll(`?roleName=${teamRole}`)
            .then((response: any) => {
              const newArray = response.map((item: any) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
              }));
              setUserOptions(newArray);
            })
            .catch((e: Error) => {
              console.log(e);
            });
        }
      }
    }
  };

  const getAllRequests = () => {
    setLoading(true);
    LeaveRequestService.getAll()
      .then((response: any) => {
        setRequests(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const getByManagerMembers = () => {
    setLoading(true);

    const roleMapping: { [key: string]: string | string[] } = {
      CALL_TEAM_MANAGER: "CALL_TEAM",
      SUCCESS_TEAM_MANAGER: ["SUCCESS_TEAM", "SUCCESS_TEAM_REPORTER"],
      ACCOUNT_MANAGER_MANAGER: "ACCOUNT_MANAGER",
      SUSPEND_TEAM_MANAGER: "SUSPEND_TEAM",
      HI5_ACM_MANAGER: "HI5_ACM_MEMBER",
      MAS_TEAM_MANAGER: "MAS_TEAM_MEMBER",
    };

    const teamRole = roleName ? roleMapping[roleName] : "";

    if (teamRole) {
      if (Array.isArray(teamRole)) {
        // Birden fazla rol için istek
        Promise.all(
          teamRole.map((role) =>
            LeaveRequestService.getByManagerMembers(`?roleName=${role}`)
          )
        )
          .then((responses) => {
            // Tüm yanıtları birleştir
            const mergedResponse = responses.flatMap(
              (response) => response.data
            );
            setRequests(mergedResponse);
          })
          .catch((e: Error) => {
            console.log(e);
          })
          .finally(() => setLoading(false));
      } else {
        // Tek rol için istek
        LeaveRequestService.getByManagerMembers(`?roleName=${teamRole}`)
          .then((response: any) => {
            setRequests(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          })
          .finally(() => setLoading(false));
      }
    } else {
      setLoading(false);
    }
  };

  const getRequest = (memberId: number) => {
    setLoading(true);
    LeaveRequestService.getByMemberId(memberId)
      .then((response: any) => {
        setRequests(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = () => {
    form.validateFields().then((values: any) => {
      const requestData: ILeaveRequestData = {
        startDate: dayjs(values.dateRange[0]).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(values.dateRange[1]).format("YYYY-MM-DDTHH:mm:ss"),
        status: "WAITING",
        memberId:
          isManager || roleName === "ADMIN" ? values.memberId : authUserId,
      };
      if (roleName === "ADMIN" || isManager) {
        LeaveRequestService.createForManager(values.memberId, requestData)
          .then((response: any) => {
            openNotification("success", "Success", "Created successfully");
            if (roleName === "ADMIN") {
              getAllRequests();
            }
            if (isManager) {
              getByManagerMembers();
            }
            form.resetFields();
            setIsCreateVisible(false);
          })
          .catch((e: Error) => {
            openNotification(
              "error",
              "Error",
              e?.message || "Failed to create"
            );
          });
      } else {
        LeaveRequestService.createSelf(requestData)
          .then((response: any) => {
            openNotification("success", "Success", "Created successfully");
            if (roleName === "ADMIN") {
              getAllRequests();
            }
            if (!isManager && roleName !== "ADMIN") {
              getRequest(Number(authUserId));
            }
            form.resetFields();
            setIsCreateVisible(false);
          })
          .catch((e: Error) => {
            openNotification(
              "error",
              "Error",
              e?.message || "Failed to create"
            );
          });
      }
    });
  };

  const handleEdit = (record: ILeaveRequestData) => {
    setEditingRequest(record);
    setIsEditVisible(true);
    editForm.setFieldsValue({
      memberId: record.memberId,
      dateRange: [],
    });
  };

  const handleUpdate = () => {
    editForm.validateFields().then((values: any) => {
      const requestData: ILeaveRequestData = {
        startDate: dayjs(values.dateRange[0]).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(values.dateRange[1]).format("YYYY-MM-DDTHH:mm:ss"),
        status: "WAITING",
        memberId: values.memberId,
      };

      LeaveRequestService.update(editingRequest?.id!, requestData)
        .then(() => {
          openNotification("success", "Success", "Updated successfully");
          if (roleName === "ADMIN") getAllRequests();
          if (!isManager && roleName !== "ADMIN")
            getRequest(Number(authUserId));
          if (isManager) getByManagerMembers();
          form.resetFields();
          setIsEditVisible(false);
        })
        .catch((e: Error) => {
          openNotification("error", "Error", e.message || "Failed to update");
        });
    });
  };

  const handleStatusChange = (record: ILeaveRequestData) => {
    const newStatus = record.status === "ACTIVE" ? "WAITING" : "ACTIVE";

    LeaveRequestService.update(record.id!, {
      ...record,
      status: newStatus,
    })
      .then((response: any) => {
        openNotification("success", "Success", "Updated successfully");
        if (roleName === "ADMIN") getAllRequests();
        if (isManager) getByManagerMembers();
      })
      .catch((e: Error) => {
        openNotification("error", "Error", e?.message || "Failed to update");
      });
  };

  const handleDelete = (id: number, memberId: number) => {
    LeaveRequestService.remove(id)
      .then((response: any) => {
        openNotification("success", "Success", "Deleted successfully");
        if (roleName === "ADMIN") getAllRequests();
        if (!isManager && roleName !== "ADMIN") getRequest(Number(authUserId));
        if (isManager) getByManagerMembers();
      })
      .catch((e: Error) => {
        openNotification("error", "Error", e?.message || "Failed to delete");
      });
  };

  useEffect(() => {
    if (userOptions.length === 0) {
      getUsers();
    }
    if (roleName === "ADMIN") {
      getAllRequests();
    }
    if (isManager) {
      getByManagerMembers();
    }
    if (!isManager && roleName !== "ADMIN") {
      getRequest(Number(authUserId));
    }
    // eslint-disable-next-line
  }, [roleName, userOptions]);

  dayjs.extend(customParseFormat);
  dayjs.extend(timezone);
  dayjs.extend(utc);

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const disabledDateTime: RangePickerProps["disabledTime"] = (
    current,
    type
  ) => {
    const now = dayjs();

    if (type === "start") {
      if (current && current.isSame(now, "day")) {
        return {
          disabledHours: () => range(0, now.hour()),
          // disabledMinutes: () => range(0, now.minute() + 1),
        };
      }
    }

    if (type === "end") {
      if (current && current.isSame(now, "day")) {
        return {
          disabledHours: () => range(0, now.hour()),
          // disabledMinutes: () => range(0, now.minute() + 1),
        };
      }
    }

    return {};
  };

  const columns = [
    {
      title: "Member",
      dataIndex: "memberId",
      key: "memberId",
      width: 150,
      render: (text: any, record: ILeaveRequestData) => {
        if (isManager || roleName === "ADMIN") {
          const user = userOptions.find(
            (user) => user?.value === record?.memberId
          );
          return user ? user?.label : "Unknown";
        } else {
          const memberName =
            localStorage.getItem("firstName") +
            " " +
            localStorage.getItem("lastName");
          return memberName || "Unknown";
        }
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 150,
      render: (text: string) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      render: (text: string) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as const,
      width: 50,
      render: (_: any, row: ILeaveRequestData) => (
        <Switch
          checkedChildren="ACTIVE"
          unCheckedChildren="WAITING"
          checked={row?.status === "ACTIVE"}
          disabled={!isManager && roleName !== "ADMIN"}
          onChange={() => handleStatusChange(row)}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: "center" as const,
      width: 100,
      render: (text: any, record: ILeaveRequestData) => (
        <Row gutter={[10, 10]} justify="center">
          {(!isManager &&
            roleName !== "ADMIN" &&
            record?.status === "WAITING") ||
          isManager ||
          roleName === "ADMIN" ? (
            <>
              <Col>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(record)}
                />
              </Col>
              <Col>
                <Popconfirm
                  title="Delete the file"
                  description="Are you sure to delete this file?"
                  onConfirm={() => handleDelete(record.id!, record?.memberId)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </Col>
            </>
          ) : !isManager &&
            roleName !== "ADMIN" &&
            record?.status === "ACTIVE" ? (
            <>
              <Col>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(record)}
                  disabled
                />
              </Col>
              <Col>
                <Popconfirm
                  title="Delete the file"
                  description="Are you sure to delete this file?"
                  onConfirm={() => handleDelete(record.id!, record?.memberId)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} disabled />
                </Popconfirm>
              </Col>
            </>
          ) : null}
        </Row>
      ),
    },
  ];

  return (
    <>
      <Card
        className="card-customers"
        style={{ marginTop: "10px" }}
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Time Off Schedule
            </div>
            <div className="customer-card-info">
              <b>{requests?.length} </b>Request
            </div>
          </div>
        }
        extra={
          <>
            {(isManager || roleName === "ADMIN") && (
              <Row
                style={{
                  minWidth: 400,
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 10,
                }}
              >
                <Select
                  style={{ width: "250px" }}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Select Member"
                  onChange={(value) => {
                    if (value === "") {
                      getAllRequests();
                    } else {
                      getRequest(value);
                    }
                  }}
                >
                  {roleName === "ADMIN" && (
                    <Select.Option value="">ALL</Select.Option>
                  )}
                  {userOptions.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}
                      label={option.label}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            )}
          </>
        }
      >
        <Row
          style={{
            width: "400",
            display: "flex",
            justifyContent: "start",
            marginBottom: 20,
          }}
        >
          <Button type="primary" onClick={() => setIsCreateVisible(true)}>
            Create Request
          </Button>
        </Row>
        <Table
          dataSource={requests}
          columns={columns}
          rowKey="id"
          sticky
          loading={loading}
          scroll={{ x: "100%" }}
        />
        <Modal
          title="Create Request"
          open={isCreateVisible}
          onCancel={() => setIsCreateVisible(false)}
          footer={null}
        >
          <Form form={form} layout="vertical">
            {roleName === "ADMIN" || isManager ? (
              <Item
                name="memberId"
                label="Member"
                rules={[
                  {
                    required: true,
                    message: "Member required",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  options={userOptions.map((user) => ({
                    value: user.value,
                    label: user.label,
                  }))}
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Select Member"
                />
              </Item>
            ) : null}
            <Item
              name="dateRange"
              label="Date Range"
              rules={[
                {
                  required: true,
                  message: "Date Range required",
                },
              ]}
            >
              <RangePicker
                style={{ width: "100%" }}
                showTime={{
                  minuteStep: 1,
                }}
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                format="DD/MM/YYYY HH:mm"
                placeholder={["Start Date", "End Date"]}
              />
            </Item>
            <Item>
              <Button type="primary" onClick={handleCreate}>
                Create
              </Button>
            </Item>
          </Form>
        </Modal>
        <Modal
          title="Edit Request"
          open={isEditVisible}
          onCancel={() => setIsEditVisible(false)}
          footer={null}
        >
          <Form form={editForm} layout="vertical">
            {(isManager || roleName === "ADMIN") && (
              <Item
                name="memberId"
                label="Member"
                rules={[{ required: true, message: "Member required" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  options={userOptions.map((user) => ({
                    value: user.value,
                    label: user.label,
                  }))}
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Select Member"
                />
              </Item>
            )}
            <Item
              name="dateRange"
              label="Date Range"
              rules={[{ required: true, message: "Date Range required" }]}
            >
              <RangePicker
                style={{ width: "100%" }}
                showTime={{
                  minuteStep: 5,
                }}
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                format="DD/MM/YYYY HH:mm"
                placeholder={["Start Date", "End Date"]}
              />
            </Item>
            <Item>
              <Button type="primary" onClick={handleUpdate}>
                Update
              </Button>
            </Item>
          </Form>
        </Modal>
      </Card>
    </>
  );
};
