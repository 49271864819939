import http from "../http-common";

const phoneCallHistoryStatistics = (criteria:any) => {
  return http.get<any>(`/phone-call-histories/report/summary${criteria}`);
};
const phoneCallHistoryStatisticsMe = (criteria:any) => {
  return http.get<any>(`/users/me/phone-call-histories/report/summary${criteria}`);
};

const getPhoneCallsChart = (filteredDate: string = '') => {
  return http.get<any>(`/phone-call-histories/report/daily-summary${filteredDate}`); 
};
const getPhoneCallsChartMe = (filteredDate: string = '') => {
  return http.get<any>(`/users/me/phone-call-histories/report/daily--summary${filteredDate}`); 
};
const totalUserCountMe = (filteredDate: string = '') => {
  return http.get<any>(`/users/me/member-count${filteredDate}`); 
};

const totalUserCount = (filteredDate: string = '') => {
  return http.get<any>(`/users/total-member-count${filteredDate}`); 
};

const assignCustomerToMember = (data:any) => {
  return http.put<any>(`/customers/call-center-member`,data); 
};

const assignLearnerToMember = (data:any) => {
  return http.put<any>(`/learners/call-center-member`,data); 
};


const updateCustomerStatus = (id: any, data: any) => {
  return http.put<any>(`/call-center/statuses/${id}/updateCustomerStatus`, data);
};

const updateLearnerStatus = (id: any, data: any) => {
  return http.put<any>(`/call-center/statuses/${id}/updateLearnerStatus`, data);
};
const callCard = (filteredDate: string = '') => {
  return http.get<any>(`/phone-call-histories/reports/phone-call-count${filteredDate}`); 
};
const callCardChart = (filteredDate: string = '') => {
  return http.get<any>(`/phone-call-histories/reports/daily/phone-call-count${filteredDate}`); 
};

const callHistory = (id:any,filteredDate: string = '') => {
  return http.get<any>(`/learners/${id}/phone-call-histories${filteredDate}`); 
};

const callHistoryCustomer = (id:any,filteredDate: string = '') => {
  return http.get<any>(`/customers/${id}/phone-call-histories${filteredDate}`); 
};

const prospectCallHistory = (id:any,filteredDate: string = '') => {
  return http.get<any>(`/prospect-customers/${id}/phone-call-histories${filteredDate}`); 
};


const CallTeamService = {
  phoneCallHistoryStatistics,
  phoneCallHistoryStatisticsMe,
  getPhoneCallsChart,
  getPhoneCallsChartMe,
  totalUserCountMe,
  totalUserCount,
  assignCustomerToMember,
  updateCustomerStatus,
  updateLearnerStatus,
  assignLearnerToMember,
  callCard,
  callCardChart,
  callHistory,
  prospectCallHistory,
  callHistoryCustomer
};

export default CallTeamService;