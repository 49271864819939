import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import './dashboard.css';

 const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Oops!...Page not found."
      extra={
        <Link to="/">
          <Button type="primary">Go Back Home</Button>
        </Link>
      }
    />
  );
};

export default NotFound;
