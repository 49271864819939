import http from "../http-common";
import { IFilterRequestData, IFilterResponseData } from "../types/Filter";

const getList = () => {
  return http.get<Array<IFilterResponseData>>(`/filter-template/list`);
};

const get = (id: number) => {
  return http.get<Array<IFilterResponseData>>(`/filter-template/${id}`);
};

const create = (data: IFilterRequestData) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("query", JSON.stringify(data.query));

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return http.post<IFilterResponseData>("/filter-template", formData, config);
};

const remove = (id: number) => {
  return http.delete<any>(`/filter-template/${id}`);
};

const FilterService = {
  getList,
  get,
  create,
  remove,
};

export default FilterService;
