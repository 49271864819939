import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, Checkbox, Col, Modal, Row, Select } from "antd";
import "./info-card.css";
import { getProfilePhotoPath } from "../../utils";
import CallSalesPackageServeyService from "../../services/SalesPackageServeyService";
import moment from "moment";
import Missed from "./../../assets/missed.svg";
import Answered from "./../../assets/answered.svg";
import Clock from "./../../assets/clock.svg";
import CallTeamService from "../../services/CallTeamService";
import CallSalesPackageService from "../../services/CallSalesPackageService";
import ListCheck from "./../../assets/list-checks.svg";

const CallHistory: React.FC<any> = ({ customer, isCustomer }) => {
  const [notes, setNotes] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [packages, setPackages] = useState<any>([]);
  const [surveys, setSurveys] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState();
  const [logs, setLogs] = useState<any>([]);

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        setPackages(response.data.filter((pkg: any) => pkg.isActive));
        setSelectedPackageId(
          response.data.filter((pkg: any) => pkg.isActive)[0]?.id
        );
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const getCallSalesPackageServey = () => {
    CallSalesPackageServeyService.getAll()
      .then((response: any) => {
        let data = response.data;
        setSurveys(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getAllSalesPackageServeyLog = () => {
    if (customer) {
      CallSalesPackageServeyService.getLog(
        `?prospectCustomerId=${customer?.id}`
      )
        .then((response: any) => {
          let data = response.data;
          const latestLogs = data.reduce((acc: any, log: any) => {
            if (
              !acc[log.callCenterSalesPackageServeyId] ||
              acc[log.callCenterSalesPackageServeyId].id < log.id
            ) {
              acc[log.callCenterSalesPackageServeyId] = log;
            }
            return acc;
          }, {});
          const latestLogsArray = Object.values(latestLogs);
          setLogs(latestLogsArray);

          const checkedItemsFromLogs = latestLogsArray
            .filter((log: any) => log.isConfirm)
            .map((log: any) => log.callCenterSalesPackageServeyId);
          setCheckedItems(checkedItemsFromLogs);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (customer) {
      getPackages();
      getCallSalesPackageServey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const showModal = () => {
    setIsVisible(true);
    getAllSalesPackageServeyLog();
  };

  useEffect(() => {
    if (customer?.id) {
      CallTeamService.prospectCallHistory(customer?.id, `?page=0&size=50`)
        .then((response: any) => {
          setNotes(response.data.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line
  }, [customer]);

  const sortNotes = (notesArray: any) => {
    return notesArray.sort((a: any, b: any) => {
      if (a.pinned === b.pinned) {
        return (
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
        );
      }
      return a.pinned ? -1 : 1;
    });
  };

  const [allNotes, setAllNotes] = useState(
    sortNotes(Array.isArray(notes) ? notes : [])
  );

  useEffect(() => {
    if (Array.isArray(notes)) {
      setAllNotes(sortNotes(notes));
    }
  }, [notes]);

  const handleSave = () => {
    setIsVisible(false);
  };

  const handleCheckboxChange = (checked: any, surveyId: any) => {
    setCheckedItems((prev) =>
      checked ? [...prev, surveyId] : prev.filter((item) => item !== surveyId)
    );

    const existingLog = logs.find(
      (log: any) => log.callCenterSalesPackageServeyId === surveyId
    );

    let payload = {
      prospectCustomerId: customer.id,
      isConfirm: checked,
      callCenterSalesPackageServeyId: surveyId,
      ...(existingLog && { id: existingLog.id }),
    };

    CallSalesPackageServeyService.createLog(payload)
      .then((response) => {})
      .catch((error) => {
        // Hata durumunda yapılacak işlemler
        console.error("Log kaydedilemedi:", error);
      });
  };

  useEffect(() => {
    const newCheckedItems = logs
      .filter((log: any) => log.isConfirm === true)
      .map((log: any) => log.callCenterSalesPackageServeyId);
    setCheckedItems(newCheckedItems);
  }, [logs]);

  return (
    <>
      <Card bordered={false} className={"card"}>
        <Row justify={"space-between"}>
          <p className="title">Call History</p>
          <Button type="default" onClick={showModal}>
            <Row>
              <img
                src={ListCheck}
                height="22"
                style={{ marginRight: 5 }}
                alt=""
                className="list-check-svg-icon"
              />{" "}
              Call Checklist
            </Row>
          </Button>
        </Row>
        <Modal
          title="Call Checklist"
          open={isVisible}
          className="call-history-card"
          onOk={handleSave}
          onCancel={() => setIsVisible(false)}
          footer={[
            <Button
              style={{ width: "100%" }}
              key="save"
              type="primary"
              onClick={handleSave}
            >
              Close
            </Button>,
          ]}
        >
          <Select
            placeholder={"Select"}
            style={{ width: "100%" }}
            defaultValue={packages[0]?.id}
            onChange={(value) => setSelectedPackageId(value)}
          >
            {packages?.map((option: any) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <p>
            <b>Important Questions</b>
          </p>
          <div style={{ marginTop: 10, overflowY: "auto" }}>
            {surveys
              .filter(
                (survey: any) =>
                  survey.callCenterSalesPackageId === selectedPackageId &&
                  survey.questionType === "IMPORTANT"
              )
              .map((survey: any) => (
                <Row style={{ marginTop: 10 }}>
                  <Checkbox
                    key={survey.id}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, survey.id)
                    }
                    checked={checkedItems.includes(survey.id)}
                  >
                    {survey.question}
                  </Checkbox>
                </Row>
              ))}
          </div>
          <p style={{ marginTop: 30 }}>
            <b>Check List</b>
          </p>

          <div style={{ height: 170, overflowY: "auto" }}>
            {surveys
              .filter(
                (survey: any) =>
                  survey.callCenterSalesPackageId === selectedPackageId &&
                  survey.questionType === "CHECKLIST"
              )
              .map((survey: any) => (
                <Row style={{ marginTop: 10 }}>
                  <Checkbox
                    key={survey.id}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, survey.id)
                    }
                    checked={checkedItems.includes(survey.id)}
                  >
                    {survey.question}
                  </Checkbox>
                </Row>
              ))}
          </div>
        </Modal>
        <p className="description" style={{ fontSize: 14 }}>
          Average Call Duration: %0
        </p>
        <div className="notes-container">
          <Row
            justify="space-between"
            align="middle"
            className="note-row-title"
          >
            <Col span={9} className="note-table-title">
              OneAmz MEMBER
            </Col>
            <Col span={6} className="note-table-title">
              Date
            </Col>
            <Col span={5} className="note-table-title">
              Call Status
            </Col>
            <Col span={4} className="note-table-title">
              Call Duration
            </Col>
          </Row>
          {allNotes?.map((item: any) => (
            <Row
              key={item.id}
              justify="space-between"
              align="middle"
              className="note-row"
            >
              <Col span={9} className="note-text">
                <Row>
                  <Avatar
                    style={{ marginRight: "15px" }}
                    size={40}
                    src={getProfilePhotoPath(item.user?.profilePhoto ?? 1)}
                  />
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      {item.user?.firstName + " " + item.user?.lastName}{" "}
                    </p>
                    <p style={{ fontSize: 10, color: "gray", marginTop: 0 }}>
                      {item.user?.roleName}{" "}
                    </p>
                  </div>
                </Row>
              </Col>
              <Col
                span={6}
                className="note-text"
                style={{ paddingRight: 25, whiteSpace: "pre-wrap" }}
              >
                {moment(item.callDateTime).format("DD/MM/YYYY HH:mm")}
              </Col>
              <Col span={5} className="note-text">
                <Row align={"middle"}>
                  <img
                    src={item.durationInSeconds > 0 ? Answered : Missed}
                    alt="call-icon"
                    style={{ marginRight: 5 }}
                  />
                  <span>
                    {" "}
                    {item.durationInSeconds > 0 ? "Answered" : "Missed"}
                  </span>
                </Row>
              </Col>
              <Col span={3} className="note-text">
                <Row align={"middle"}>
                  <img src={Clock} alt="call-icon" style={{ marginRight: 5 }} />
                  <span> {item.durationInSeconds}s</span>
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      </Card>
    </>
  );
};

export default CallHistory;
