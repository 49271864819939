import { Button, Col, Drawer, Form, Input, Modal, Row, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../../utils';
import LearnerNoteService from '../../services/LearnerNoteService';
import closeIcon from './../../assets/XCircle.svg';


const { Item } = Form;

const LearnerMultiNote: React.FC<any> = ({ selectedRowKeys }) => {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [isModalVisible])

    const createNote = (values: any) => {
        let internal = {
            "description": values.note,
            "type": "INTERNAL",
            "learnerIds": selectedRowKeys
        }
        LearnerNoteService.createNoteMulti(internal)
            .then((response: any) => {
                openNotification('success', 'Success', 'Note added');
                setIsModalVisible(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setIsModalVisible(false);
            });
    };

    useEffect(() => {
        form.resetFields(['Selected Learners']);
        form.setFieldsValue({ 'Selected Learners': selectedRowKeys.length.toString() });
    }, [selectedRowKeys, isModalVisible, form]);

    return (
        <>
            <Row justify={'end'}>
                <Button type='primary' style={{marginRight:15}} onClick={() => setIsModalVisible(true)}>Add Note</Button>
            </Row>
            <Modal
                title={<>Add Note</>}
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                closeIcon={<img src={closeIcon} height={24} alt="close" />}
            >

                <Form form={form} onFinish={createNote} layout="vertical">
                    <Item
                        name="note"
                        label="Note"
                        rules={[
                            {
                                required: true,
                                message: 'note is required',
                            }]}
                    >
                        <TextArea rows={4} />
                    </Item>
                    <Item
                        name="Selected Learners"
                        label="Selected Learners"
                    >
                        <Input disabled />
                    </Item>

                    <Item>
                        <Row>
                            <Button type='primary' className=' w-100 border-radius-8' style={{ width: '100%' }} htmlType="submit">
                                OK
                            </Button>
                        </Row>
                    </Item>
                </Form>
            </Modal>
        </>
    )
}

export default LearnerMultiNote