import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Progress, Row, Select, Table } from 'antd';
import './info-card.css';
import moment from 'moment';
import CustomerNoteService from '../../services/CustomerNoteService';
import { convertDate, openNotification } from '../../utils';
import CallSalesPackageService from '../../services/CallSalesPackageService';


const CustomerInfo: React.FC<any> = ({ customer, learner, setTriggerUpdate, isCustomer }) => {
    const roleName = localStorage.getItem('roleName');

    if (!learner) {
        learner = customer;
    }
    const [options, setOptions] = useState([])
    const [selectedValue, setSelectedValue] = useState()

    interface EcudationStatusProps {
        completed?: number;
    }

   
    const getPackages = () => {
        CallSalesPackageService.getAll()
            .then((response: any) => {
                let data = response.data;
                const newArray = data.map((item: any) => {
                    return { value: item.id, label: item.name, disabled: !item.isActive };
                });
                setOptions(newArray);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    useEffect(() => {
        if (customer) {
            getPackages()
        }

    }, [customer])

    const EcudationStatus = ({ completed }: EcudationStatusProps) => {
        if (completed === undefined) {
            return <Progress strokeColor={'red'} type="line" percent={0} format={(percent) => `${0}`} />;
        }
        const lessonTotalCount = 83;
        let percent: number = parseInt((completed / lessonTotalCount * 100).toString());

        let color: string = 'red';

        if (completed < 10) {
            color = 'red';
        } else if (completed >= 10 && completed < 20) {
            color = 'volcano';
        } else if (completed >= 20 && completed < 40) {
            color = 'orange';
        } else {
            color = 'green';
        }

        return <><Progress strokeColor={color} type="line" percent={percent} format={(percent) => `${completed}`} /></>;
    };

    const createSalesPackageLog = () => {
        console.log(selectedValue);
        if (selectedValue) {
            let data;
            if (isCustomer == true) {
                data = {
                    callCenterSalesPackageId: selectedValue,
                    customerId: customer?.id
                }
            } else {

                data = {
                    callCenterSalesPackageId: selectedValue,
                    learnerId: learner?.id
                }
            }

            CallSalesPackageService.createSalesLog(data)
                .then((response: any) => {
                    openNotification('success', 'Success', 'Sales added');
                    setTrigger();
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }


    };

    const setTrigger = () => {
        setTriggerUpdate((prev: number) => prev + 1);
    };

    return (
        <Card bordered={false} className={'card customer-info'} style={{ height: '100%' }}>
            <div>
                <p className='title'>Customer Info</p>
                <p className='sub-title'>Lesson</p>
                <EcudationStatus completed={customer?.lessonsCompleted} />
                <div className='lesson-card-container'>
                    <div>
                        <p className='date-header'>Integrated At:</p>
                        <p className='date-description'>{convertDate(customer?.becomeCustomerAt)}</p>
                    </div>
                    <div>
                        <p className='date-header' style={{ textAlign: 'right' }}>Last Login At:</p>
                        <p className='date-description' style={{ textAlign: 'right' }}>{convertDate(customer?.lastLoginAt)}</p>
                    </div>
                </div>
                {/* <div className='border-bottom'>
                    <div className='lesson-card-container'>
                        <div>
                            <p style={{ fontSize: 12 }}>Consulting Package: <br />
                                <b> Mini Destek Paketi</b></p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12 }}>Package Price:: <br />
                                <b> $99.99</b></p>
                        </div>
                    </div>
               </div> */}
                <div className='border-bottom'>
                    <div className='lesson-card-container'>
                        <div>
                            <p style={{ fontSize: 12 }}>Last Contact Date: <br />
                                <b>{convertDate(customer?.lastContactDate)}</b></p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12 }}>Assigneed ACM:<br />
                                {customer?.user ?
                                    <b>{customer?.user?.firstName} {customer?.user?.lastName}</b> : <b>-</b>}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* {roleName !== "SUCCESS_TEAM_REPORTER" ?
            <div className='lesson-card-container' style={{ paddingBottom: 10 }}>
                <Select
                    onChange={(selectedValue) => setSelectedValue(selectedValue)}
                    options={options}
                    key={customer?.id}
                    // {...(customer?.status?.id && { defaultValue: customer.status.id })}
                    placeholder={"Select"}
                    style={{ width: "180px", marginRight: 10 }}>
                </Select>
                <Button onClick={createSalesPackageLog} type='primary' style={{ fontSize: 12 }} >Sale Made</Button>
            </div>:null} */}

        </Card>
    );
};

export default CustomerInfo;
