import axios from "axios";
import { notification } from "antd";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const locale = "tr-TR";

type NotificationType = "success" | "info" | "warning" | "error";
const openNotification = (
  type: NotificationType,
  status: number,
  description: string
) => {
  const args = {
    message: status,
    description: description,
    duration: 1000,
  };
  notification[type](args);
};

const responseConvertForm = (error: any) => {
  let errorList = error.response.data.errors;
  let errorMessageList: any = [];
  const keys = Object.keys(errorList);
  errorMessageList = keys.map((key: any) => {
    return { name: key, errors: errorList[key] };
  });
  return errorMessageList;
};

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "*/*",
    "Content-type": "application/json",
    "accept-language": locale,
    authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    let token = localStorage.getItem("accessToken");
    if (
      token ||
      config.url === "/login" ||
      config.url === "/refresh" ||
      config.url === "/authorize/token"
    ) {
      config.headers = {
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };

      if (config.url === "/authorize/token") {
        config.headers = {
          "Content-type": "application/json",
          "accept-language": locale,
          "Access-Control-Allow-Origin": "*",
        };
      }

      return config;
    } else {
      // window.location.href = '/refresh-token';
      window.location.href = "/login";
      throw new axios.Cancel("Operation canceled by the user.");
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    if (response.config.method !== "get" && response.config.url !== "/login") {
      // openNotification('success', response.status, response.statusText);
    }

    // Do something with response data
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      openNotification("error", error.response.status, "Giriş yapınız");
      localStorage.removeItem("accessToken");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    } else if (error.response.status === 500) {
      // openNotification('error', error.response.status, error.response.status);
      // localStorage.removeItem('accessToken');
      // setTimeout(() => {
      //     window.location.href = '/login';
      // }, 1000);
    } else if (error.response.status === 403) {
      openNotification(
        "error",
        error.response.status,
        error.response.data.message
      );
      error.response.data.errors = responseConvertForm(error);
    } else if (error.response.status === 400) {
      openNotification(
        "error",
        error.response.status,
        error.response.data.message
      );
    } else if (error.response.status === 409) {
      openNotification(
        "error",
        error.response.status,
        error.response.data.detail
      );
    } else if (error.response.status === 404) {
      if (error.response?.data?.message.includes("Learner not found with")) {
        return null;
      } else {
        openNotification(
          "error",
          error.response.status,
          error.response.data.message || null
        );
      }
    } else {
      openNotification(
        "error",
        error.response.status,
        error.response.statusText
      );
    }

    return Promise.reject(error);
  }
);

export default instance;
