import http from "../http-common";

const login = (data: any) => {
    return http.post<any>("/authorize/token", data);
};

const LoginService = {
    login,
};

export default LoginService;