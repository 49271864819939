import { Button, Modal, Form, Select, DatePicker, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { IUserResponseData } from "../../../types/User";
import { can } from "../../../utils";
import filterIcon from "../../../assets/filter.svg";
import filterClearIcon from "../../../assets/filter-clear.svg";
import CustomerService from "../../../services/CustomerService";
import StatusService from "../../../services/StatusService";
const { Item } = Form;

interface CustomerFilterProps {
  onChangeFilter: (values: any) => void;
  setFormFields: any;
  formFields: any;
  setFilterOptions: any;
}

const SuspendedFilter: React.FC<CustomerFilterProps> = ({
  onChangeFilter,
  setFormFields,
  formFields,
  setFilterOptions,
}) => {
  const { RangePicker } = DatePicker;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<any[]>([]);
  const [userOptions, setUserOptions] = useState<IUserResponseData[]>([]);
  const [callTeamOptions, setCallTeamOptions] = useState<any[]>([]);
  const [successTeamOptions, setSuccessTeamOptions] = useState<any[]>([]);
  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [disableState, setDisableState] = useState(false);
  const [disablePassiveDays, setDisablePassiveDays] = useState(false);
  const roleName = localStorage.getItem("roleName");
  // eslint-disable-next-line
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [suspendStatusOptions, setSuspendStatusOptions] = useState<any[]>([]);
  const savedLocalFilters = localStorage.getItem("softwareSuspendedFilterText");

  const showModal = () => {
    if (customerStatusOptions.length === 0) {
      getUsers();
    }
    if (userOptions.length === 0) {
      getUsers();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    const filters = [];

    if (values.callTeamMember !== undefined && values.callTeamMember !== null) {
      const selectedUser = callTeamOptions.find(
        (user) => user.id === values.callTeamMember
      );
      const userName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
      filters.push({ name: "Call Team", value: userName });
    }

    if (
      values.successTeamMember !== undefined &&
      values.successTeamMember !== null
    ) {
      const selectedUser = successTeamOptions.find(
        (user) => user.id === values.successTeamMember
      );
      const userName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
      filters.push({ name: "Success Team", value: userName });
    }

    if (values.currentPackage !== undefined && values.currentPackage !== null) {
      filters.push({ name: "Current Package", value: values.currentPackage });
    }

    if (
      values.startPassiveDay &&
      values.startPassiveDay[0] &&
      values.startPassiveDay[1]
    ) {
      filters.push({
        name: "Start Passive Day",
        value: `${values.startPassiveDay[0].format(
          "DD/MM/YYYY"
        )} - ${values.startPassiveDay[1].format("DD/MM/YYYY")}`,
      });
    }

    if (
      values.endPassiveDay &&
      values.endPassiveDay[0] &&
      values.endPassiveDay[1]
    ) {
      filters.push({
        name: "End Passive Day",
        value: `${values.endPassiveDay[0].format(
          "DD/MM/YYYY"
        )} - ${values.endPassiveDay[1].format("DD/MM/YYYY")}`,
      });
    }

    if (
      values.subscriptionEndDate &&
      values.subscriptionEndDate[0] &&
      values.subscriptionEndDate[1]
    ) {
      filters.push({
        name: "Suspended Date",
        value: `${values.subscriptionEndDate[0].format(
          "DD/MM/YYYY"
        )} - ${values.subscriptionEndDate[1].format("DD/MM/YYYY")}`,
      });
    }

    const selectedStatuses =
      values.status && values.status.length > 0
        ? values.status
            .map((statusId: any) => {
              const statusOption = customerStatusOptions.find(
                (option: any) => option.value === statusId
              );
              return statusOption ? statusOption.label : null;
            })
            .filter((name: any) => name !== null)
        : [];

    if (selectedStatuses.length > 0) {
      filters.push({ name: "Status", value: selectedStatuses });
    }

    setFilterOptions(filters);

    onChangeFilter(values);
    setFormFields(form.getFieldsValue());
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = () => {
    if (can("users:getAll")) {
      UserService.getAll("?roleName=ACCOUNT_MANAGER")
        .then((response: any) => {
          let data = response;
          setUserOptions(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
    if (roleName === "CALL_TEAM_MANAGER") {
      UserService.getAll("?roleName=CALL_TEAM")
        .then((response: any) => {
          let data = response;
          setCallTeamOptions(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
    if (roleName === "SUCCESS_TEAM_MANAGER") {
      UserService.getAll("?roleName=SUCCESS_TEAM")
        .then((response: any) => {
          let data = response;
          setSuccessTeamOptions(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setSelectedItems([]);
    setDisablePassiveDays(false);
    setDisableState(false);
    setFormFields(form.getFieldsValue());
    localStorage.removeItem("softwareSuspendedFilterText");
  };

  const clearLocalFilterAndForm = () => {
    clearForm();
    onFinish({ ...form.getFieldsValue() });
  };

  const getCustomerStatus = () => {
    if (
      roleName === "SUCCESS_TEAM" ||
      roleName === "SUCCESS_TEAM_MANAGER" ||
      roleName === "CALL_TEAM" ||
      roleName === "CALL_TEAM_MANAGER"
    ) {
      StatusService.getAll("statusType=CALL_CENTER_STATUS", `&active=true`)
        .then((response: any) => {
          let data = response.data;
          const newArray = data.map((item: any) => {
            return { value: item.id, label: item.name, disabled: !item.active };
          });
          setCustomerStatusOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else {
      CustomerService.getAllStatus()
        .then((response: any) => {
          let data = response.data;
          const newArray = data.map((item: any) => {
            return { value: item.id, label: item.name, disabled: !item.active };
          });
          setCustomerStatusOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const getSuspendStatus = () => {
    if (
      roleName === "CALL_TEAM_MANAGER" ||
      roleName === "CALL_TEAM" ||
      roleName === "SUCCESS_TEAM_MANAGER" ||
      roleName === "SUCCESS_TEAM" ||
      roleName === "ADMIN"
    ) {
      let mainStatuses: any[] = [];
      let subStatuses: any[] = [];
      StatusService.getAll("statusType=SOFTWARE_SUSPENDED_STATUS")
        .then((response: any) => {
          let data = response.data;
          data.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setSuspendStatusOptions(processedData);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getCustomerStatus();
    getSuspendStatus();
    // eslint-disable-next-line
  }, []);

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { startPassiveDay, endPassiveDay, state } = allValues;
    if (
      startPassiveDay &&
      (endPassiveDay < startPassiveDay || !endPassiveDay)
    ) {
      form.setFieldsValue({ endPassiveDay: startPassiveDay });
    }
    if (startPassiveDay || endPassiveDay) {
      setDisableState(true);
      setDisablePassiveDays(false);
    }
    if (state) {
      setDisablePassiveDays(true);
      setDisableState(false);
    }
  };

  // const selectAll = () => {
  //   setSelectedItems([
  //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  //     22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  //   ]);
  //   form.setFieldsValue({
  //     status: [
  //       1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  //       21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
  //       39,
  //     ],
  //   });
  // };

  // const handleChange = (selected: any) => {
  //   setSelectedItems(selected);
  //   form.setFieldsValue({
  //     status: selected,
  //   });
  // };

  return (
    <>
      <Modal
        title="Customer Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          {roleName !== "ACCOUNT_MANAGER" &&
          roleName !== "ACCOUNT_MANAGER_MANAGER" &&
          roleName !== "SUCCESS_TEAM_MANAGER" &&
          roleName !== "SUCCESS_TEAM" &&
          callTeamOptions &&
          roleName !== "CALL_TEAM" ? (
            <>
              Call Team Member
              <Item name="callTeamMember">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {callTeamOptions?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.firstName} {option.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </>
          ) : null}
          {roleName !== "ACCOUNT_MANAGER" &&
          roleName !== "ACCOUNT_MANAGER_MANAGER" &&
          roleName !== "CALL_TEAM_MANAGER" &&
          roleName !== "CALL_TEAM" &&
          callTeamOptions &&
          roleName !== "SUCCESS_TEAM" ? (
            <>
              Success Team Member
              <Item name="successTeamMember">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {successTeamOptions?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.firstName} {option.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </>
          ) : null}
          {roleName !== "ACCOUNT_MANAGER" &&
            roleName !== "ACCOUNT_MANAGER_MANAGER" && (
              <>
                Software Suspend Status
                <Item name="suspendStatus">
                  <Select placeholder={"Select"}>
                    <Select.Option key={0} value={null}>
                      Select
                    </Select.Option>
                    {suspendStatusOptions?.map((mainStatus) => (
                      <Select.Option key={mainStatus.id} value={mainStatus.id}>
                        {mainStatus.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </>
            )}
          Passive Days
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="startPassiveDay"
              style={{ width: "50%", marginRight: 10, marginBottom: 0 }}
            >
              <Select
                placeholder="Select a End Day"
                disabled={disablePassiveDays}
              >
                {Array.from({ length: 30 }, (_, index) => (
                  <Select.Option key={index + 1} value={index + 1}>
                    {index + 1}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ width: "50%", marginLeft: 10, marginBottom: 0 }}
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.startPassiveDay !== currentValues.startPassiveDay
              }
            >
              {({ getFieldValue }) => (
                <Form.Item name="endPassiveDay">
                  <Select
                    placeholder="Select a End Day"
                    disabled={disablePassiveDays}
                  >
                    {Array.from({ length: 30 }, (_, index) => {
                      const startDay = getFieldValue("startPassiveDay") || 0;
                      return (
                        index + 1 >= startDay && (
                          <Select.Option key={index + 1} value={index + 1}>
                            {index + 1}
                          </Select.Option>
                        )
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </Form.Item>
          </div>
          Created At
          <Item name="createdAtRange">
            <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
          </Item>
          Current Package
          <Item name="currentPackage">
            <Select placeholder={"Select a package count"}>
              <Select.Option key={1} value="5000">
                5000
              </Select.Option>
              <Select.Option key={2} value="10000">
                10000
              </Select.Option>
              <Select.Option key={3} value="25000">
                25000
              </Select.Option>
              <Select.Option key={4} value="50000">
                50000
              </Select.Option>
              <Select.Option key={5} value="100000">
                100000
              </Select.Option>
              <Select.Option key={6} value="150000">
                150000
              </Select.Option>
              <Select.Option key={7} value="200000">
                200000
              </Select.Option>
              <Select.Option key={8} value="250000">
                250000
              </Select.Option>
              <Select.Option key={9} value="500000">
                500000
              </Select.Option>
              <Select.Option key={10} value="750000">
                750000
              </Select.Option>
            </Select>
          </Item>
          Suspended Date
          <Item name="subscriptionEndDate">
            <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
          </Item>
          <Item>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
      <Tooltip title="Filters">
        <Button className="mr-15" onClick={showModal} type="default">
          <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
      {savedLocalFilters && savedLocalFilters.length > 0 ? (
        <Tooltip title="Clear Filters">
          <Button
            style={{ marginRight: "10px" }}
            onClick={clearLocalFilterAndForm}
            type="default"
          >
            <img
              src={filterClearIcon}
              alt="userIcon"
              style={{ margin: "1px" }}
            />
          </Button>
        </Tooltip>
      ) : null}
    </>
  );
};

export default SuspendedFilter;
