import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Row,
  Upload,
  Col,
  Tooltip,
  Popconfirm,
  message,
} from "antd";
import {
  UploadOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./info-card.css";
import AmazonSuspendedApplicantService from "../../services/AmazonSuspendedApplicantService";
import { RcFile } from "antd/es/upload/interface";
import { openNotification } from "../../utils";

interface Attachment {
  id: number;
  fileName: string;
}

const allowedTypes = [
  "application/pdf",
  "text/plain",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "application/vnd.ms-excel", // .xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "image/jpeg",
  "image/png",
];

const Attachments: React.FC<any> = ({ customer, setTriggerUpdate }) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (customer?.attachmentList) {
      setAttachments(customer.attachmentList);
    }
  }, [customer]);

  const handleUpload = async (file: RcFile) => {
    setLoading(true);
    try {
      await AmazonSuspendedApplicantService.getUploadFile(customer.id, file);
      openNotification("success", "Success", "File uploaded successfully");
      setTriggerUpdate((prev: number) => prev + 1);
    } catch (error) {
      openNotification("error", "Error", "File upload failed");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (fileName: string) => {
    setLoading(true);
    try {
      const response = await AmazonSuspendedApplicantService.getDownloadFile(customer.id, fileName);
  
      // API'den dönen veriyi çözme
      const { fileName: downloadFileName, base64EncodedData } = response.data;
  
      // Base64 kodunu çözme
      const byteCharacters = atob(base64EncodedData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.headers["content-type"] || "application/octet-stream" });
  
      // İndirme işlemi
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = downloadFileName; // İndirilen dosya adını API'den gelen adla ayarlayın
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
  
      openNotification("success", "Success", "File downloaded successfully");
    } catch (error) {
      openNotification("error", "Error", "File download failed");
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleDelete = async (fileName: string) => {
    setLoading(true);
    try {
      await AmazonSuspendedApplicantService.deleteFile(customer.id, fileName);
      openNotification("success", "Success", "File deleted successfully");
      setAttachments((prev) =>
        prev.filter((attachment) => attachment.fileName !== fileName)
      );
      setTriggerUpdate((prev: number) => prev + 1);
    } catch (error) {
      openNotification("error", "Error", "File deletion failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card bordered={false} className={"card"}>
        <Row justify={"space-between"}>
          <p className="title">Attachments</p>
          <Upload
            customRequest={({ file, onSuccess, onError }: any) => {
              handleUpload(file)
                .then(() => onSuccess())
                .catch(() => onError());
            }}
            showUploadList={false}
            accept=".pdf, .txt, .docx, .xlsx, .xls, .jpg, .jpeg, .png"
            beforeUpload={(file) => {
              if (!allowedTypes.includes(file.type)) {
                message.error("Unsupported file type.");
                return false;
              }
              return true;
            }}
          >
            <Button
              type="primary"
              icon={<UploadOutlined />}
              loading={loading}
            />
          </Upload>
        </Row>
        <p className="description">{attachments.length} Attachments</p>
        <div className="notes-container">
          <Row
            justify="space-between"
            align="middle"
            className="note-row-title"
          >
            <Col span={18} className="note-table-title">
              FILE NAME
            </Col>
            <Col span={6} className="note-table-title"></Col>
          </Row>
          {attachments?.map((item: any) => (
            <Row
              key={item.id}
              justify="space-between"
              align="middle"
              className="note-row"
            >
              <Col
                span={16}
                className="note-text"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Row>
                  <div>
                    <Tooltip title={`${item?.fileName}`}>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: "500",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "24ch",
                        }}
                      >
                        {item?.fileName}
                      </p>
                    </Tooltip>
                  </div>
                </Row>
              </Col>
              <Col
                span={8}
                className="note-icon-container"
                style={{ gap: "10px" }}
              >
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload(item.fileName)}
                />
                <Popconfirm
                  title="Delete the file"
                  description="Are you sure to delete this file?"
                  onConfirm={() => handleDelete(item.fileName)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </Col>
            </Row>
          ))}
          {/* <List
            dataSource={attachments}
            renderItem={(item) => (
              <List.Item
                key={item.fileName}
                className="list-item"
                style={{ display: "flex", alignItems: "center" }}
                actions={[
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => handleDownload(item.fileName)}
                  />,
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(item.fileName)}
                  />,
                ]}
              >
                {item.fileName}
              </List.Item>
            )}
          /> */}
        </div>
      </Card>
    </>
  );
};

export default Attachments;
