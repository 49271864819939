import { Col, Row, Switch } from "antd";
import "./dashboard.css";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { HeaderBar } from "../components/Dashboard/HeaderBar";
import { CtmCustomerList } from "../components/Dashboard/CtmDashboard/CustomerList";
import InfoCard from "../components/Dashboard/CtmDashboard/InfoCard";
import CallCard from "../components/Dashboard/CtmDashboard/CallCard";
import Tasks from "../components/Dashboard/CtmDashboard/Tasks";
import CallTeamService from "../services/CallTeamService";
import { CustomerList } from "../components/Dashboard/AcmDashboard/CustomerList";
import { ProspectCustomers } from "../components/Dashboard/ProspectCustomer/ProspectCustomers";

export const CtmDashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<any>([]);
  // const [targetCount, setTargetCount] = useState<any>(
  //   localStorage.getItem("salesTarget") ?? 0
  // );
  const [showActive, setShowActive] = useState<any>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  // const [allCallTeamUsers, setAllCallTeamUsers] = useState<any>([]);

  const roleName = localStorage.getItem("roleName");

  useEffect(() => {
    setLoading(true);
    const [startDate, endDate] = dateRange;

    let formattedDateRange;
    if (startDate && endDate) {
      // eslint-disable-next-line
      formattedDateRange = `startDate=${startDate}T00:00:00&endDate=${endDate}T00:00:00`;
    }
    if (startDate === undefined || endDate === undefined) {
      return;
    }

    if (roleName === "CALL_TEAM_MANAGER") {
      CallTeamService.totalUserCount()
        .then((response) => {
          if (response.data) {
            setTotalCount(response.data);
          } else {
            setTotalCount(0);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      CallTeamService.totalUserCountMe()
        .then((response) => {
          if (response.data) {
            setTotalCount(response.data);
          } else {
            setTotalCount(0);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [dateRange]);

  // useEffect(() => {
  //   Promise.all([
  //     UserService.getAll("?roleName=CALL_TEAM"),
  //     UserService.getAll("?roleName=CALL_TEAM_MANAGER"),
  //   ])
  //     .then(([callTeamResponse, callTeamManagerResponse]) => {
  //       const callTeamMembers = callTeamResponse;
  //       const callTeamManagers = callTeamManagerResponse;
  //       const allCallTeamUsers = [...callTeamMembers, ...callTeamManagers];
  //       setAllCallTeamUsers(allCallTeamUsers);
  //     })
  //     .catch((error: Error) => {
  //       console.error("Error fetching call team members:", error);
  //     });
  // }, []);

  return (
    <div
      style={{ background: "#F0F0F0", padding: "30px" }}
      className="acm-dashboard"
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
        className="acm-dashboard"
      >
        <HeaderBar setDateRange={setDateRange}></HeaderBar>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col xs={24} sm={24} md={12} lg={8}>
            <CallCard
              title="Call Chart"
              dateRange={dateRange}
              description={`${totalCount}`}
              // callTeamUsers={allCallTeamUsers}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={7}>
            <InfoCard
              title="Total Sales"
              dateRange={dateRange}
              // callTeamUsers={allCallTeamUsers}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={9}>
            <Tasks dateRange={dateRange} />
          </Col>
        </Row>
        <Row
          justify={"space-between"}
          align={"middle"}
          style={{ marginTop: 10 }}
        >
          <h3 style={{ fontSize: 24, fontWeight: 500 }}>
            {" "}
            Choose Your User Status
          </h3>
          <Switch
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              height: 40,
              width: 180,
            }}
            checked={showActive}
            checkedChildren="Call Center Customer"
            unCheckedChildren="User List"
            onChange={(checked) => setShowActive(checked)}
          />
        </Row>
        {roleName === "CALL_TEAM" ? (
          showActive ? (
            <ProspectCustomers />
          ) : (
            <CtmCustomerList />
          )
        ) : showActive ? (
          <ProspectCustomers />
        ) : (
          <CustomerList />
        )}
      </motion.div>
    </div>
  );
};
