import { Breadcrumb, Card, Col, Row, Typography, Rate, Table, Button, Form, Modal, Input, Switch, InputNumber, Checkbox, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { motion } from "framer-motion";
import { useEffect, useState } from 'react';
import { can, openNotification } from '../../utils';
import SettingsRouter from './SettingsRouter';
import { NavLink } from 'react-router-dom';
import ReportService from '../../services/ReportService';
import UserService from '../../services/UserService';
import { SearchOutlined } from '@ant-design/icons';

const { Item } = Form;
const { Title } = Typography;

export const Affiliate = () => {

    const [form] = Form.useForm();
    const [affiliates, setAffiliates] = useState<[]>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([])
    const [searchText, setSearchText] = useState('');


    const onChange = (id: any, selectedValue: any) => {
        ReportService.updateAffiliate(id, { userId: selectedValue })
            .then((response: any) => {
                openNotification('success', 'Success', `Updated`);
                getAffiliates();
            })
            .catch((e: Error) => {
                console.log(e)
            });
    };


    const getUsers = () => {
        UserService.getAll("?roleName=SUCCESS_TEAM")
            .then((response: any) => {
                let data = response;
                const newArray = data.map((item: any) => {
                    let fullName = `${item.firstName} ${item.lastName}`;
                    return { value: item.id, label: fullName };
                });
                setUsers(newArray);
                console.log(newArray);
            })

            .catch((e: Error) => {
                console.log(e);
            });
    }

    const columns: ColumnsType<any> = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),

        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (_, row) => {
                return (
                    <Row >
                        <Select
                            onChange={(selectedValue) => onChange(row.id, selectedValue)}
                            options={users}
                            {...(row.user?.id && { defaultValue: row.user?.id })}
                            placeholder={"Select"}
                            key={row.id}
                            style={{ width: "180px", marginRight: 10 }}>
                        </Select>
                    </Row>
                );
            },

        },

    ];

    useEffect(() => {
        getUsers();
        getAffiliates();
    }, [])


    const getAffiliates = () => {
        setLoading(true);
        ReportService.getAffiliate()
            .then((response: any) => {
                let data = response.data;
                setAffiliates(data);
                setFilteredData(data)
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };
    
    const handleSearch = (e: any) => {
        const value = e.target.value;
        if (value === "") {
          setSearchText(value);
          setFilteredData(affiliates);
        } else {
          setSearchText(value);
          const filtered = affiliates.filter((item: any) =>
            (item.name && item.name.toLowerCase().includes(value.toLowerCase())) ||
            (item.id && item.id.toString().includes(value))
          );
          setFilteredData(filtered);
        }
      };


    return (


        <motion.div
            initial={{ y: 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 300, opacity: 0 }}
            transition={{
                type: "spring",
                stiffness: 260,
                damping: 40,
            }}>
            <div>
                <Row>
                    <Col>
                        <Title className='onemaz-page-title' level={3}>{ }</Title>
                        <Breadcrumb
                            items={[
                                {
                                    title: <NavLink to="/" >Home</NavLink>,
                                },
                                {
                                    title: 'Settings',
                                },
                                {
                                    title: 'Affiliate',
                                },
                            ]} />
                    </Col>
                </Row>
                <SettingsRouter />
                <Row style={{ marginTop: '30px' }}>
                <Input style={{width:200,marginBottom:10}} onChange={handleSearch} className='search-input' addonBefore={<SearchOutlined />} placeholder="Search" />                    
                <Col span={24}>
                        <Table loading={loading} columns={columns} dataSource={filteredData} pagination={false} />
                    </Col>
                </Row>

            </div>
        </motion.div>
    )
}
