import React from "react";
import { Button, Modal, Table, Tooltip } from "antd";
import moment from "moment";
import * as XLSX from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";

interface IUserDetail {
  id: number;
  memberId: number;
  customerName: string;
  customerPhone: string;
  satisfactionRating: string;
  knowledgeRating: string;
  helpfulnessRating: string;
  createdDate: string;
}

interface IFeedbackDetailReportModalProps {
  visible: boolean;
  onCancel: () => void;
  data: IUserDetail[];
}

const modalColumns = [
  {
    title: "Created Date",
    dataIndex: "createdDate",
    key: "createdDate",
    ellipsis: true,
    width: 150,
    sorter: (a: any, b: any) =>
      moment(a.createdDate).unix() - moment(b.createdDate).unix(),
    render: (text: string) => moment(text).format("YYYY-MM-DD HH:mm"),
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
    ellipsis: true,
    width: 150,
    sorter: (a: any, b: any) => a.customerName.localeCompare(b.customerName),
    render: (text: string) => <b>{text}</b>,
  },
  {
    title: "Customer Phone",
    dataIndex: "customerPhone",
    key: "customerPhone",
    width: 150,
  },
  {
    title: (
      <Tooltip
        title={
          "Yaptığınız telefon görüşmesi deneyiminden ne kadar memnun kaldınız?"
        }
        placement="bottom"
      >
        <span>Question 1</span>
      </Tooltip>
    ),
    dataIndex: "satisfactionRating",
    key: "satisfactionRating",
    align: "center" as "center",
    width: 150,
    sorter: (a: any, b: any) =>
      a.satisfactionRating.localeCompare(b.satisfactionRating),
    render: (text: string) => <b>{Number(text)?.toFixed(2)}</b>,
  },
  {
    title: (
      <Tooltip
        title={"Görüşme yaptığınız kişi konuya ne kadar hakimdi ve bilgiliydi?"}
        placement="bottom"
      >
        <span>Question 2</span>
      </Tooltip>
    ),
    dataIndex: "knowledgeRating",
    key: "knowledgeRating",
    align: "center" as "center",
    width: 150,
    sorter: (a: any, b: any) =>
      a.knowledgeRating.localeCompare(b.knowledgeRating),
    render: (text: string) => <b>{Number(text)?.toFixed(2)}</b>,
  },
  {
    title: (
      <Tooltip
        title={"Görüşme yaptığınız kişinin nezaketi ve ilgisi nasıldı?"}
        placement="bottom"
      >
        <span>Question 3</span>
      </Tooltip>
    ),
    dataIndex: "helpfulnessRating",
    key: "helpfulnessRating",
    align: "center" as "center",
    width: 150,
    sorter: (a: any, b: any) =>
      a.helpfulnessRating.localeCompare(b.helpfulnessRating),
    render: (text: string) => <b>{Number(text)?.toFixed(2)}</b>,
  },
  {
    title: "Average Rating",
    key: "averageRating",
    align: "center" as "center",
    width: 150,
    sorter: (a: IUserDetail, b: IUserDetail) => {
      const avgA =
        ((Number(a.satisfactionRating) || 0) +
          (Number(a.knowledgeRating) || 0) +
          (Number(a.helpfulnessRating) || 0)) /
        3;
      const avgB =
        ((Number(b.satisfactionRating) || 0) +
          (Number(b.knowledgeRating) || 0) +
          (Number(b.helpfulnessRating) || 0)) /
        3;
      return avgA - avgB;
    },
    render: (record: IUserDetail) => {
      const satisfaction = Number(record.satisfactionRating) || 0;
      const knowledge = Number(record.knowledgeRating) || 0;
      const helpfulness = Number(record.helpfulnessRating) || 0;
      const average = ((satisfaction + knowledge + helpfulness) / 3).toFixed(2);
      return <b>{average}</b>;
    },
  },
];

const downloadExcel = (data: IUserDetail[]) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Extract column headers
  const headers = modalColumns.map((col) => {
    if (typeof col.title === "object" && col.title.type === Tooltip) {
      return col.title.props.title;
    }
    return col.title;
  });

  // Prepare the data with the headers
  const formattedData = data.map((item) => ({
    createdDate: moment(item.createdDate).format("YYYY-MM-DD HH:mm"),
    customerName: item.customerName,
    customerPhone: item.customerPhone,
    satisfactionRating: Number(item.satisfactionRating)?.toFixed(2),
    knowledgeRating: Number(item.knowledgeRating)?.toFixed(2),
    helpfulnessRating: Number(item.helpfulnessRating)?.toFixed(2),
    averageRating: (
      ((Number(item.satisfactionRating) || 0) +
        (Number(item.knowledgeRating) || 0) +
        (Number(item.helpfulnessRating) || 0)) / 3
    ).toFixed(2),
  }));

  // Create worksheet from data
  const worksheet = XLSX.utils.json_to_sheet(formattedData);

  // Add the headers manually to the worksheet
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback Details");

  // Write the workbook to a file and trigger a download
  XLSX.writeFile(workbook, "Feedback_Details.xlsx");
};

const FeedbackDetailReportModal: React.FC<IFeedbackDetailReportModalProps> = ({
  visible,
  onCancel,
  data,
}) => {
  return (
    <Modal
      title="Member Feedback Details"
      open={visible}
      onCancel={onCancel}
      width={1000}
      footer={
        <Button
          style={{ marginLeft: "20px" }}
          icon={<DownloadOutlined />}
          onClick={() => downloadExcel(data)}
          type="primary"
        >
          Export
        </Button>
      }
    >
      <Table
        columns={modalColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        size="small"
        sticky
        scroll={{ x: "max-content" }}
      />
    </Modal>
  );
};

export default FeedbackDetailReportModal;
