import http from "../http-common";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ITeamResponseData, ITeamRequestData } from "../types/Team";

const getAll = (query = "") => {
  return http.get<Array<ITeamResponseData>>(`learners/teams${query}`);
};

const TeamService = {
  getAll,
};

export default TeamService;
