import React, { useEffect, useState } from 'react'
import { Button, Divider, Drawer, Form, Input, Select, Space, notification } from 'antd';
import UserService from '../../services/UserService';
import RoleService from '../../services/RoleService';
import { IUserResponseData } from "../../types/User";
import { IRoleResponseData } from '../../types/Role';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { can, openNotification } from '../../utils';

interface UserDetailProps {
    id: any;
    data: any;
}

const UserDetail: React.FC<UserDetailProps> = ({ id, data }) => {

    const [updateSalesTargetForm] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState<IUserResponseData>();
    const [roles, setRoles] = useState<IRoleResponseData[]>([]);
    const [selectedRole, setSelectedRole] = useState<Number>();
    const { Item } = Form;
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [updateSalesTargetFormLoading, setUpdateSalesTargetFormLoading] = useState(false);
    const roleName = localStorage.getItem('roleName');

    const showUserDetail = () => {
        setOpen(true);
    };

    useEffect(() => {
        getRoles();
        getUser(id);
    }, [open])


    const onClose = () => {
        setOpen(false);
    };

    const updateRoleByUserId = () => {
        UserService.updateRoleByUserId(id, { roleIdList: [selectedRole] })
            .then((response: any) => {
                openNotification('success', 'Success', 'Changed role');
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const getRoles = () => {
        if (can("roles:getAll")) {

            RoleService.getAll()
                .then((response: any) => {
                    let data = response.data;
                    setRoles(data);
                    // setLoading(false);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }



    const getUser = (id: Number) => {
        if (can("users:get")) {
            UserService.get(id)
                .then((response: any) => {
                    const data = response.data;

                    updateSalesTargetForm.setFieldsValue({
                        salesTarget: data.salesTarget
                    });
                    setUser(data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }

    const handleChange = (value: number) => {
        setSelectedRole(value);
    };

    const updateSalesTarget = (values: any) => {
        let postObj = {
            salesTarget: values.salesTarget
        }
        UserService.updateSalesTargetByUserId(id, postObj).then((response: any) => {
            openNotification('success', 'Success', 'Update sales target');
            updateSalesTargetForm.resetFields();
            getUser(id);
        }).catch((e: Error) => {

        });
    };

    return (
        <>
            <Button type="primary" onClick={showUserDetail}>
                Detail
            </Button>
            <Drawer title="User Detail" placement="right" onClose={onClose} open={open} >
                <h4>Fullname</h4>
                <p>{data?.firstName} {data?.lastName}</p>
                <Divider />
                <h4>Sales Target</h4>
                <Form form={updateSalesTargetForm} layout="vertical" onFinish={updateSalesTarget}>
                    <Form.Item
                        name="salesTarget"
                        rules={[
                            {
                                required: true,
                                message: 'Please input sales target!',
                            },
                        ]}
                    >
                        <Space.Compact style={{ width: '100%' }}>
                            <Input addonBefore={user?.salesTarget} type='number' placeholder='New Sales Target' />
                            <Button htmlType="submit" loading={updateSalesTargetFormLoading} type="primary">Update</Button>
                        </Space.Compact>
                    </Form.Item>
                </Form>
                <Divider />
                {roleName === "ADMIN" ?
                    <>
                        <h4>Role</h4>
                        <Form layout="horizontal"
                            style={{ maxWidth: 600 }}
                            onFinish={updateRoleByUserId}
                            form={form}
                        >
                            <Select placeholder={"Seçiniz"} style={{ width: '100%' }} onChange={handleChange}  >
                                {roles.map(option => (
                                    <Select.Option key={option.id} value={option.id} >
                                        {option.description}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Divider />

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                    : <></>}
            </Drawer>
        </>
    )
}

export default UserDetail;
