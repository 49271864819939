import { Card, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import './earnings.css';
import ReactApexChart from 'react-apexcharts';
import { can } from '../../utils';
import LearnerService from '../../services/LearnerService';

interface EarningsProps {
  dateRange: any;
}

export const Earnings: React.FC<EarningsProps> = ({ dateRange }) => {

  const { t } = useTranslation();
  const [learners, setlearners] = useState<number>(0);
  const [newLearners, setNewLearners] = useState<number>(0);
  const [customers, setCustomers] = useState<number>(0);
  const [integretionRate, setIntegretionRate] = useState<number>(0);
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState<any>({
    plotOptions: {
      radialBar: {
        inverseOrder: false,
        hollow: {
          margin: 4,
          size: "60%",
          background: "transparent"
        },
        track: {
          show: true,
          background: "transparent",
          strokeWidth: "5%",
          opacity: 1,
          margin: 1
        }
      }
    },
    labels: [t('Integration')],
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    }
  });


  const loadCard = (data: any) => {
    setlearners(data.totalLearner);
    setCustomers(data.totalCustomer);
    setNewLearners(data.totalNewLearner);
    if (data.totalCustomer > 0 && data.totalLearner > 0) {
      let ratio: any = parseInt(((data.totalCustomer / data.totalLearner) * 100).toString());
      setIntegretionRate(ratio ?? 0)
    }
    else {
      setIntegretionRate(0)
    }
  }

  const getLearnerEarningsStatistics = () => {
    if (can("learners:getAll")) {
      LearnerService.getLearnerEarningsStatistics(`?startDate=${dateRange[0]}&endDate=${dateRange[1]}`)
        .then((response: any) => {
          let data = response.data;
          loadCard(data);
          setLoading(false)
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false)
        });

    } else {
      let currentUserId = localStorage.getItem("id");

      LearnerService.getMeLearnerEarningsStatistics(`?startDate=${dateRange[0]}&endDate=${dateRange[1]}`)
        .then((response: any) => {
          let data = response.data;
          loadCard(data);
          setLoading(false)
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false)
        });
    }
  }

  useEffect(() => {
    if (dateRange.length > 0) {
      getLearnerEarningsStatistics();
    }
  }, [dateRange])

  return (
    <Card className='earnings' title="Total Intergrated" style={{ height: '100%' }} loading={loading}>
      <Row align={'middle'} justify={'center'}>
        <ReactApexChart options={options} series={[integretionRate]} type="radialBar" width={250} />
      </Row>
      <Row className='border-bottom mx-20' justify="space-between" align="middle">
        <h3 className='sub-title mt-0'>{t('learners')}</h3>
        <h3 className='mt-0'>{learners}</h3>
      </Row>
      <Row className='border-bottom mx-20' justify="space-between" align="middle">
        <h3 className='sub-title'>{t('newLearners')}</h3>
        <h3>{newLearners}</h3>
      </Row>
      <Row className='mx-20' justify="space-between" align="middle">
        <h3 className='sub-title'>{t('customers')}</h3>
        <h3>{customers}</h3>
      </Row>
    </Card>
  )
}