import React, { useEffect } from 'react';
import { Card } from 'antd';
import './info-card.css';

interface InfoCardProps {
    count: string;
    title: string;
    description: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ count, title, description }) => {

    return (
        <Card bordered={false} className={'card'}>
            <p className='title'>{title}</p>
            <div className={'container'}>
                <p className='count'>{count}</p>
                <p className='description'>{description}</p>
            </div>

        </Card>
    );
};

export default InfoCard;
