import http from "../http-common";
import { IEmployeeResponseData, IEmployeeRequestData } from "../types/Employee";

const getAll = (search: string | undefined) => {
  const params = search ? { search } : {};
  return http.get<Array<IEmployeeResponseData>>('employees', { params });
};

const get = (id: number) => {
  return http.get<IEmployeeResponseData>(`/employees/${id}`);
};

const create = (data: IEmployeeRequestData) => {
  return http.post<IEmployeeRequestData>("/employees", data);
};

const update = (id: number, data: IEmployeeRequestData) => {
  return http.put<any>(`/employees/${id}`, data);
};

const remove = (id: number) => {
  return http.delete<any>(`/employees/${id}`);
};

const getByDepartment = (departmentId: number) => {
  return http.get<Array<IEmployeeResponseData>>(`/employees/department/${departmentId}`);
};
  
const EmployeeService = {
  getAll,
  get,
  create,
  update,
  remove,
  getByDepartment,
};

export default EmployeeService;
