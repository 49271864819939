import {
  Card,
  Col,
  Row,
  Typography,
  Input,
  Table,
  Button,
  Breadcrumb,
} from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ReportService from "../../../services/ReportService";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CustomerService from "../../../services/CustomerService";
import { NavLink } from "react-router-dom";
import DateRange from "../../Common/DateRange";
import { ReportsRouter } from "../ReportsRouter";

const { Title } = Typography;

export const Status = () => {
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [fields, setFields] = useState<any>([]);
  const [columnNameMapping, setColumnNameMapping] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);

  function downloadExcel() {
    const dataWithCustomHeaders = filteredData.map((row: any) => {
      const newRow: any = {};
      Object.keys(row).forEach((key: any) => {
        const newKey = columnNameMapping[key] || key;
        newRow[newKey] = row[key];
      });
      return newRow;
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataWithCustomHeaders);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }

    // Save the workbook to a file
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Use FileSaver to save the file
    saveAs(blob, "data.xlsx");
  }

  const handleSearch = (e: any) => {
    const value = e.target.value;
    if (value === "") {
      setSearchText(value);
      setFilteredData(data);
    } else {
      setSearchText(value);
      const filtered = data.filter((item: any) =>
        item.fullname.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    getStatusList();
  }, []);

  const getStatusList = () => {
    CustomerService.getAllStatus()
      .then((response: any) => {
        setStatusList(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (statusList) {
      getReport(statusList);
    }
  }, [statusList, dateRange]);

  const getReport = (statusList: any) => {
    if (dateRange[0] && dateRange[1]) {
      setLoading(true);
      ReportService.customerStatusByUser(
        `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`
      )
        .then((response: any) => {
          let data = response.data;

          const updatedUsers = response.data.map((user: any) => {
            return {
              fullname: `${user.first_name} ${user.last_name}`,
              ...user,
            };
          });

          setData(updatedUsers);
          setFilteredData(updatedUsers);

          data?.forEach((row: any) => {
            let statusKeys = Object.keys(row).filter((key) =>
              key.startsWith("status")
            );

            let fieldList = [
              {
                title: "Account Managers",
                dataIndex: "fullname",
                key: "fullname",
                fixed: "left",
                width: 270,
                sorter: (a: any, b: any) =>
                  a.fullname.localeCompare(b.fullname),
                render: (_: any, row: any) => (
                  <Row
                    justify={"start"}
                    align={"middle"}
                    style={{ width: 270 }}
                  >
                    <Col>
                      <Title className="customer-fullname" level={5}>
                        <b>{row.fullname}</b>
                      </Title>
                      <span className="customer-fullname">{row.username}</span>
                    </Col>
                  </Row>
                ),
              },
            ];

            let fieldMatchArray: any = [];

            statusKeys?.forEach((field: any) => {
              let statusId = field.replace(/[^0-9]/g, "");

              let item = statusList.find(
                (element: any) => element.id == statusId
              );
              fieldMatchArray[field] = item?.name;

              let obj: any = {
                title: item.name,
                dataIndex: field,
                key: field,
                width: 140,
                sorter: (a: any, b: any) => {
                  return a[field] - b[field];
                },
              };

              fieldList.push(obj);
            });
            setFields(fieldList);
            setColumnNameMapping(fieldMatchArray);
          });

          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Account Manager Reports",
              },
              {
                title: "Status",
              },
            ]}
          />
        </Col>
      </Row>

      <ReportsRouter />
      <div>
        <Row>
          <Col span={24}>
            <Card
              loading={loading}
              className="card-customers"
              title={
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <div
                    className="customer-card-title"
                    style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
                  >
                    Account Manager
                  </div>
                  <div className="customer-card-info">Status Report</div>
                </div>
              }
              bordered={true}
              style={{ marginTop: "30px" }}
              extra={
                <>
                  <Row justify={"end"}>
                    <Input
                      className="search-input"
                      addonBefore={<SearchOutlined />}
                      placeholder="Search"
                      value={searchText}
                      style={{ marginRight: 16 }}
                      onChange={handleSearch}
                    />
                    <DateRange onChangeDateRange={setDateRange} />
                    <Button
                      icon={<DownloadOutlined />}
                      style={{ marginLeft: 16 }}
                      onClick={downloadExcel}
                      type="primary"
                    >
                      Export
                    </Button>
                  </Row>
                </>
              }
            >
              <Table
                key={"id"}
                scroll={{ x: 2000 }}
                columns={fields}
                pagination={false}
                dataSource={filteredData}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
