import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, theme } from 'antd';
// import tr_TR from 'antd/lib/locale/tr_TR';
import en_US from 'antd/lib/locale/en_US';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import trTranslation from './locales/tr.json';

const dark = localStorage.getItem('darkMode')

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    tr: { translation: trTranslation },
  },
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <ConfigProvider locale={en_US} theme={
        {
          algorithm: dark ? theme.darkAlgorithm : theme.defaultAlgorithm,
          components: {
            Calendar: {
              /* here is your component tokens */
              itemActiveBg: "e6f4ff",
            },
          },
        }
      }>
      <App />
    </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
