import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tag, Tooltip } from "antd";
import "./../info-card.css";
import NewTask from "./NewTask";
import moment from "moment";
import TaskDetailCustomer from "./TaskDetailCustomer";
import AmazonSuspendedApplicantService from "../../../services/AmazonSuspendedApplicantService";

const Tasks: React.FC<any> = ({
  customerName,
  customerId,
  setTriggerUpdate,
  userOptions,
}) => {
  const userId = localStorage.getItem("id");
  const [tasks, setTasks] = useState<any>([]);

  const handeTaskList = () => {
    setTriggerUpdate((prev: number) => prev + 1);
  };

  const getTasks = () => {
    AmazonSuspendedApplicantService.getTasks(customerId)
      .then((response: any) => {
        setTasks(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (customerId) {
      getTasks();
    }
  }, [customerId]);

  return (
    <>
      <Card bordered={false} className={"card"}>
        <Row justify={"space-between"}>
          <p className="title">Tasks</p>
          <NewTask
            customerName={customerName}
            getTasks={getTasks}
            customerId={customerId}
            setTriggerUpdate={setTriggerUpdate}
            userOptions={userOptions}
          />
        </Row>
        <p className="description">{tasks.lenght} Tasks</p>
        <div className="notes-container">
          <Row
            justify="space-between"
            align="middle"
            className="note-row-title"
          >
            <Col
              span={3}
              className="note-table-title"
            >
              STATU
            </Col>
            <Col span={5} className="note-table-title">
              NAME
            </Col>
            <Col span={8} className="note-table-title">
              DESCRIPTION
            </Col>
            <Col span={4} className="note-table-title">
              DUE DATE
            </Col>
            <Col span={4} className="note-table-title" style={{ textAlign: "end" }}>
              DETAILS
            </Col>
          </Row>
          {tasks?.map((item: any) => (
            <>
              {item.assignee.id == userId || item.reporter.id == userId ? (
                <Row
                  align="middle"
                  style={{ padding: "10px", borderBottom: "1px solid #e8e8e8" }}
                >
                  <Col span={3}>
                    <Tag
                      color="green"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "32px",
                      }}
                    >
                      {item?.status}
                    </Tag>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Row align="middle">
                      <div>
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: "600",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "24ch",
                          }}
                        >
                          {item?.amazonSuspendedApplicant?.fullName}{" "}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col
                    span={8}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Row>
                      <div>
                        <Tooltip title={`${item.description}`}>
                          <p
                            style={{
                              fontSize: 12,
                              fontWeight: "500",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "24ch",
                            }}
                          >
                            {item.title}
                          </p>
                        </Tooltip>
                      </div>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Row>
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "500", textAlign: "center" }}>
                          {moment(item.dueDate).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <TaskDetailCustomer
                      getTasks={getTasks}
                      item={item}
                      onHandleTaskList={handeTaskList}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Tasks;
