import http from "../oneamz-http-common";

const refrestToken = (data:any) => {
    return http.post<any>(`/auth/refresh`, data);
};

const OneamzAppService = {
    refrestToken,
};

export default OneamzAppService;