import { Button, Drawer, Input, Form, Divider, Row } from 'antd';
import React, { useState } from 'react'
import BonusPoliciesService from '../../services/BonusPoliciesService';

interface BonusPolicyDetailProps {
    id: any;
}

const { Item } = Form;

const BonusPolicyDetail: React.FC<BonusPolicyDetailProps> = ({ id }) => {

    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [bonusPolicy, setBonusPolicy] = useState<any>();

    const showDrawer = () => {
        setOpen(true);
        getStatus(id);
    };

    const onClose = () => {
        setOpen(false);
    };

    const getStatus = (id: any) => {
        BonusPoliciesService.get(id)
            .then((response: any) => {
                setBonusPolicy(response.data);

                form.setFieldsValue({
                    customerThreshold: response.data?.customerThreshold,
                    fixedBonusPerCustomer: response.data?.fixedBonusPerCustomer,
                    fixedPremium: response.data?.fixedPremium
                });

            })
            .catch((e: Error) => {
                console.log(e);
            });

    }

    const updateBonusPolicy = (values: any) => {

        BonusPoliciesService.update(id, values)
            .then((response: any) => {
                window.location.reload();
            }).catch((e: Error) => {
            });
    }

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                Detail
            </Button>
            <Drawer title="Bonus Policy Detail" placement="right" onClose={onClose} open={open}>
                <Form form={form} onFinish={updateBonusPolicy} layout="vertical">

                    <h3>{bonusPolicy?.description}</h3>

                    <Item
                        name="fixedBonusPerCustomer"
                        label="Price"
                        rules={[
                            {
                                required: true,
                                message: 'fixedBonusPerCustomer required',
                            }]}
                    >
                        <Input type="number" />
                    </Item>

                    <Divider></Divider>

                    <Item
                        name="customerThreshold"
                        label="Sales target"
                        rules={[
                            {
                                required: true,
                                message: 'customerThreshold required',
                            }]}
                    >
                        <Input type="number" />
                    </Item>


                    <Item
                        name="fixedPremium"
                        label="Total Bonus"
                        rules={[
                            {
                                required: true,
                                message: 'fixedPremium required',
                            }]}
                    >
                        <Input type="number" />
                    </Item>

                    <Item>
                        <Row justify={'end'}>
                            <Button type="default" onClick={onClose} style={{ marginRight: '10px' }}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Row>
                    </Item>
                </Form>

                <Divider />
            </Drawer>
        </>
    )

}

export default BonusPolicyDetail;
