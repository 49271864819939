import { Form, Button, Row, Col, Image, Layout, Rate, Card } from "antd";
import LoginLogo from "../assets/oneamzLoginLogo.svg";
import { Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FeedbackService from "../services/FeedbackService";
import { IFeedbackData } from "../types/Feedback";
import { openNotification } from "../utils";

interface FormValues {
  token?: string;
  satisfactionRating: number;
  knowledgeRating: number;
  helpfulnessRating: number;
}

const Feedback: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const externalFeedbackToken: any = query.get("token");

  useEffect(() => {
    if (externalFeedbackToken) {
      localStorage.setItem("externalFeedbackToken", externalFeedbackToken);
    }
  }, [externalFeedbackToken]);

  const onFinish = async (values: FormValues) => {
    setLoading(true);

    const convertToPoints = (rating: number) => {
      return rating * 20;
    };

    const feedbackData: IFeedbackData = {
      token: localStorage.getItem("externalFeedbackToken") || "",
      satisfactionRating: convertToPoints(values.satisfactionRating),
      knowledgeRating: convertToPoints(values.knowledgeRating),
      helpfulnessRating: convertToPoints(values.helpfulnessRating),
    };

    FeedbackService.submit(feedbackData)
      .then(() => {
        openNotification(
          "success",
          "Success",
          "Geri bildirim başarıyla gönderildi"
        );
        form.resetFields();
      })
      .catch((error) => {
        openNotification("error", "Error", "Geri bildirim gönderilemedi");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <Layout style={{ height: "100vh", backgroundColor: "#EFF3F6" }}>
          <Header style={{ backgroundColor: "#EFF3F6" }}>
            <Image
              style={{ marginTop: "4.5em" }}
              preview={false}
              src={LoginLogo}
            />
          </Header>
          <Row style={{ height: "80vh" }} align={"middle"} justify={"center"}>
            <Col>
              <Card
                title="Fikirlerinizi Önemsiyoruz!"
                headStyle={{ textAlign: "center", fontSize: "1.5em" }}
                style={{
                  maxWidth: 500,
                  margin: "1em",
                  padding: "1em",
                  textAlign: "center",
                  border: "none",
                  boxShadow:
                    "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
                }}
              >
                <Form
                  form={form}
                  name="feedback_form"
                  className="feedback-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <b>
                    Yaptığınız telefon görüşmesi deneyiminden ne kadar memnun
                    kaldınız?
                  </b>
                  <Form.Item
                    name="satisfactionRating"
                    rules={[
                      { required: true, message: "Lütfen bir değer seçin!" },
                    ]}
                  >
                    <Rate />
                  </Form.Item>
                  <b>Görüşme yaptığınız kişi konuya ne kadar hakimdi ve bilgiliydi?</b>
                  <Form.Item
                    name="knowledgeRating"
                    rules={[
                      { required: true, message: "Lütfen bir değer seçin!" },
                    ]}
                  >
                    <Rate />
                  </Form.Item>
                  <b>
                  Görüşme yaptığınız kişinin nezaketi ve ilgisi nasıldı?
                  </b>
                  <Form.Item
                    name="helpfulnessRating"
                    rules={[
                      { required: true, message: "Lütfen bir değer seçin!" },
                    ]}
                  >
                    <Rate />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0" }}>
                    <Button
                      style={{
                        width: "100%",
                        marginTop: "1em",
                        height: "2.5em",
                      }}
                      type="primary"
                      htmlType="submit"
                      className="feedback-form-button"
                      loading={loading}
                    >
                      Gönder
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout>
      </Col>
    </Row>
  );
};

export default Feedback;
