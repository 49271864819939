import { Avatar, Card, DatePicker, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./earnings.css";
import ReactApexChart from "react-apexcharts";
import { can, getProfilePhotoPath } from "../../utils";
import LearnerService from "../../services/LearnerService";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import ReportService from "../../services/ReportService";
import goldIcon from "./../../assets/gold.svg";
import silverIcon from "./../../assets/silver.svg";
import bronzIcon from "./../../assets/bronz.svg";
import UserService from "../../services/UserService";

interface TopTensProps {
  dateRange: any;
}

export const TopTen: React.FC<TopTensProps> = ({ dateRange }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );
  const [currentMonthName, setCurrentMonthName] = useState("");
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState<any>([]);

  const getUsers = () => {
    UserService.getAll("?roleName=SUCCESS_TEAM")
      .then((response: any) => {
        let data = response;
        setUsers(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getReport = (startOfMonth: any, endOfMonth: any) => {
    setLoading(true);
    ReportService.successTeamReport(
      `?startDate=${startOfMonth}&endDate=${endOfMonth}`
    )
      .then((response: any) => {
        let data = response.data;
        let reportData = [];
        for (let i = 0; i < data.length; i++) {
          let reportRow: any = {
            id: "",
            fullname: "",
            username: "",
            totalCustomer: 0,
            fixedBonusPerCustomer: 0,
            profilePhotoId: 0,
          };

          const row = data[i];
          if (row.userId === 17 || row.userId === 123) {
            continue;
          }

          const user = users.find((user: any) => user.id === row.userId);
          reportRow.id = row.userId;
          reportRow.fullname = `${row.firstName} ${row.lastName}`;
          reportRow.username = row.username;
          reportRow.totalCustomer = row.totalCustomer;
          reportRow.profilePhoto = parseInt(user ? user.profilePhoto : 1);
          reportData.push(reportRow);
        }

        reportData.sort((a: any, b: any) => b.totalCustomer - a.totalCustomer);

        setData(reportData);
        setFilteredData(reportData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
    setLoading(false);
  };

  useEffect(() => {
    setCurrentMonthName(dayjs().format("MMMM"));
    getReport(startDate, endDate);
  }, [users]);

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const startOfMonth = date.startOf("month").format("YYYY-MM-DD");
      const endOfMonth = date.endOf("month").format("YYYY-MM-DD");
      setStartDate(startOfMonth);
      setEndDate(endOfMonth);
      setCurrentMonthName(date.format("MMMM"));
      getReport(startOfMonth, endOfMonth);
    }
  };

  return (
    <Card className="earnings" loading={loading} style={{ paddingBottom: 10 }}>
      <Row
        justify="space-between"
        style={{ padding: "0 10px" }}
        align={"middle"}
      >
        <h4>Top 10</h4>
        {/* <p style={{ fontSize: 16, color: '#D0D0D0' }}>{currentMonthName}</p>
         */}
        <DatePicker
          picker="month"
          style={{ height: 40 }}
          onChange={handleDateChange}
          defaultValue={dayjs()}
          format="MMMM"
        />
      </Row>
      <Row
        justify={"space-between"}
        style={{
          padding: 10,
          borderTop: "1px solid #F1F1F2",
          borderBottom: "1px solid #F1F1F2",
        }}
      >
        <h4 style={{ margin: "0px", fontSize: 12 }}>Success Team Member</h4>
        <h4 style={{ margin: "0px", fontSize: 12 }}>Customer</h4>
      </Row>
      <Card style={{ height: 320, overflow: "auto", border: "none" }}>
        {data.map((dataItem: any, index: any) => {
          if (index >= 10) {
            return null;
          }
          return (
            <Card key={index} style={{ marginTop: "16px", border: "none" }}>
              <Row
                justify={"space-between"}
                align={"middle"}
                style={{ paddingRight: 10 }}
              >
                <Row align={"middle"} justify={"space-between"}>
                  <Avatar
                    style={{ marginLeft: "10px", marginRight: 5 }}
                    size={30}
                    src={getProfilePhotoPath(
                      dataItem?.profilePhoto == null
                        ? 1
                        : dataItem?.profilePhoto
                    )}
                  />
                  <p style={{ margin: "0px" }}>
                    {dataItem.fullname} {dataItem.user?.lastName}
                  </p>
                </Row>
                <Row>
                  <img
                    src={
                      index == 0
                        ? goldIcon
                        : index == 1
                        ? silverIcon
                        : index == 2
                        ? bronzIcon
                        : ""
                    }
                    style={{ marginRight: 16 }}
                  />
                  <h4 style={{ margin: "0px" }}>{dataItem.totalCustomer}</h4>
                </Row>
              </Row>
            </Card>
          );
        })}
      </Card>
    </Card>
  );
};
