import { useState } from "react";
import axios from "axios";
import { Form, Input, Button, Checkbox, Row, Col, Image, Layout } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LoginService from "../services/LoginService";
import LoginBackground from "../assets/login-background.png";
import LoginLogo from "../assets/oneamzLoginLogo.svg";
import { Header } from "antd/es/layout/layout";
import UserService from "../services/UserService";
import moment from "moment";
import { openNotification } from "../utils";

interface FormValues {
  username: string;
  password: string;
  remember: boolean;
}

const Login: React.FC<any> = ({ setIsLoggedIn, isLoggedIn }) => {
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const dark = localStorage.getItem("darkMode");

  form.setFieldsValue({
    username: "crm.agent@oneamz.com",
    password: 123,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getToken = (externalToken: string) => {
    let data = {
      externalToken: externalToken,
    };

    LoginService.login(data)
      .then((response: any) => {
        let accessToken = response.data.accessToken;
        let refreshToken = response.data.refreshToken;
        let accessTokenExpiredAt = response.data.accessTokenExpiredAt;
        let refreshTokenExpiredAt = response.data.refreshTokenExpiredAt;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("accessTokenExpiredAt", accessTokenExpiredAt);
        localStorage.setItem("refreshTokenExpiredAt", refreshTokenExpiredAt);

        UserService.getMe().then((response: any) => {
          const data = response.data;
          localStorage.setItem("firstName", data.firstName);
          localStorage.setItem("lastName", data.lastName);
          localStorage.setItem("id", data.id);
          localStorage.setItem("salesTarget", data.salesTarget);
          localStorage.setItem("email", data.username);
          localStorage.setItem("profilePhoto", data.profilePhoto || 1);
          localStorage.setItem("role", data.roles[0]?.description);
          localStorage.setItem("roleName", data.roles[0]?.name);
          localStorage.setItem("gsmPortalEmployeeId", data.gsmPortalEmployeeId);

          let permissions = data.roles[0].permissions;

          let permissionNames = permissions.map(
            (permission: any) => permission.name
          );
          localStorage.setItem("permissions", JSON.stringify(permissionNames));
          navigate("/");
          setIsLoggedIn(!isLoggedIn);
        });
      })
      .catch((e: Error) => {});
  };

  const onFinish = (values: FormValues) => {
    let data = JSON.stringify({
      username: values.username,
      password: values.password,
      source: "crm",
    });

    const apiUrl = process.env.REACT_APP_EXTERNAL_API_BASE_URL + "/auth/login";

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiUrl,
      headers: {
        authority: "api.oneamz.com",
        accept: "application/json",
        "content-type": "application/json",
      },
      data: data,
    };

    const currentTime = moment();

    axios
      .request(config)
      .then((response) => {
        if (response.data.code === 200) {
          let externalAccessToken = response.data.accessToken;
          let externalRefreshToken = response.data.refreshToken;
          localStorage.setItem("externalAccessToken", externalAccessToken);
          localStorage.setItem("externalRefreshToken", externalRefreshToken);
          localStorage.setItem("lastCheckTime", currentTime.format("LTS"));
          getToken(externalAccessToken);
        } else {
          openNotification("error", response.data.code, response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Row justify="center" align="middle">
      <Col xs={0} sm={0} md={12} lg={12} xl={12} span={12}>
        <Layout style={{ height: "100vh", backgroundColor: "#EFF3F6" }}>
          <Header style={{ backgroundColor: "#EFF3F6" }}>
            <Image
              style={{ marginTop: "4.5em" }}
              preview={false}
              src={LoginLogo}
            />
          </Header>
          <Row style={{ height: "80vh" }} align={"middle"} justify={"center"}>
            <Col>
              <Image
                style={{ width: "50vw" }}
                preview={false}
                src={LoginBackground}
              />
            </Col>
          </Row>
          {/* <Footer style={{ backgroundColor: "white" }}>
            <Row justify="center" align="middle">
              <h3 style={{ fontSize: "32px", color: "#1J1E1B", margin: "1em 0 1em 0" }}>Very good works are waiting for you.</h3>
              <h4 style={{ fontSize: "24px", fontWeight: "300", color: "#606676", margin: "0 1em 0 1em" }}>Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</h4>
            </Row>
          </Footer> */}
        </Layout>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        span={12}
        className="loginRight"
      >
        <div style={{ maxWidth: "400px", margin: "0 auto", padding: "20px" }}>
          <Row justify={"center"} align={"middle"}>
            <Col xs={12} sm={24} md={0} lg={0} xl={0} span={0}>
              <Image
                style={{ marginTop: "30px" }}
                preview={false}
                src={LoginLogo}
              />
            </Col>
          </Row>
          <h3
            style={{ fontSize: "32px", color: "#1J1E1B", margin: "1em 0 0 0" }}
          >
            Login
          </h3>
          <p
            style={{
              fontSize: "16px",
              color: dark ? "#fff" : "#606676",
              margin: "1em 0 2em 0",
            }}
          >
            Welcome back, please login to your account.
          </p>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <b>Username:</b>
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Please input your Username!" },
              ]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <b>Password:</b>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
                iconRender={(visible) =>
                  visible ? (
                    <EyeTwoTone onClick={togglePasswordVisibility} />
                  ) : (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  )
                }
              />
            </Form.Item>
            <Form.Item>
              <Row justify={"space-between"}>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <a
                  className="login-form-forgot"
                  href="https://app.oneamz.com/auth/forget-password"
                >
                  Forgot password
                </a>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: "100%", height: "3.3em" }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
