import http from "../http-common";
import { IStatusResponseData, IStatusRequestData } from "../types/Status";

const getAll = (statusType = "", query = "") => {
  return http.get<Array<IStatusResponseData>>(`/status?${statusType}${query}`);
};

const get = (id: number) => {
  return http.get<IStatusResponseData>(`/status/${id}`);
};

const create = (data: IStatusRequestData) => {
  return http.post<IStatusResponseData>("/status", data);
};

const update = (id: number, data: IStatusRequestData) => {
  return http.put<IStatusResponseData>(`/status/${id}`, data);
};

const remove = (id: number) => {
  return http.delete<void>(`/status/${id}`);
};

const StatusService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default StatusService;
