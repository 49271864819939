import {
  Breadcrumb,
  Col,
  Row,
  Typography,
  Table,
  Button,
  Form,
  Modal,
  Input,
  Select,
  Popconfirm,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { openNotification } from "../../../utils";
import SettingsRouter from "../SettingsRouter";
import { NavLink } from "react-router-dom";
import ButtonGroup from "antd/es/button/button-group";
import StatusService from "../../../services/StatusService";

const { Item } = Form;
const { Title } = Typography;

export const PackageOfInterestStatusForHi5Acm = () => {
  const [formCreateMainStatus] = Form.useForm();
  const [formEditMainStatus] = Form.useForm();
  const [isCreateMainStatusModalVisible, setIsCreateMainStatusModalVisible] =
    useState<boolean>(false);
  const [isEditMainStatusModalVisible, setIsEditMainStatusModalVisible] =
    useState<boolean>(false);
  const [isDeleteMainStatusModalVisible, setIsDeleteMainStatusModalVisible] =
    useState<boolean>(false);
  const [newMainStatus, setNewMainStatus] = useState<string>("");
  const [selectedMainStatus, setSelectedMainStatus] = useState<any>(null);
  const [formCreateSubStatus] = Form.useForm();
  const [formEditSubStatus] = Form.useForm();
  const [isCreateSubStatusModalVisible, setIsCreateSubStatusModalVisible] =
    useState<boolean>(false);
  const [isEditSubStatusModalVisible, setIsEditSubStatusModalVisible] =
    useState<boolean>(false);
  const [isDeleteSubStatusModalVisible, setIsDeleteSubStatusModalVisible] =
    useState<boolean>(false);
  const [newSubStatus, setNewSubStatus] = useState<string>("");
  const [selectedSubStatus, setSelectedSubStatus] = useState<any>(null);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const showCreateMainStatusModal = () => {
    setIsCreateMainStatusModalVisible(true);
  };

  const handleCreateMainStatusModalCancel = () => {
    setIsCreateMainStatusModalVisible(false);
  };

  const showEditMainStatusModal = (mainStatus: any) => {
    setSelectedMainStatus(mainStatus);
    setNewMainStatus(mainStatus.name);
    setIsEditMainStatusModalVisible(true);
  };

  const handleEditMainStatusModalCancel = () => {
    setIsEditMainStatusModalVisible(false);
    setSelectedMainStatus(null);
  };

  const showDeleteMainStatusModal = (mainStatus: any) => {
    setSelectedMainStatus(mainStatus);
    setIsDeleteMainStatusModalVisible(true);
  };

  const handleDeleteMainStatusModalCancel = () => {
    setIsDeleteMainStatusModalVisible(false);
    setSelectedMainStatus(null);
  };

  const showCreateSubStatusModal = () => {
    setIsCreateSubStatusModalVisible(true);
  };

  const handleCreateSubStatusModalCancel = () => {
    setIsCreateSubStatusModalVisible(false);
  };

  const showEditSubStatusModal = (subStatus: any) => {
    setSelectedSubStatus(subStatus);
    setNewSubStatus(subStatus.name);
    setIsEditSubStatusModalVisible(true);
  };

  const handleEditSubStatusModalCancel = () => {
    setIsEditSubStatusModalVisible(false);
    setSelectedSubStatus(null);
  };

  const showDeleteSubStatusModal = (subStatus: any) => {
    setSelectedSubStatus(subStatus);
    setIsDeleteSubStatusModalVisible(true);
  };

  const handleDeleteSubStatusModalCancel = () => {
    setIsDeleteSubStatusModalVisible(false);
    setSelectedSubStatus(null);
  };

  // POST PACKAGE OF INTEREST MAIN STATUS
  const postPackageOfInterestMainStatus = (data: any) => {
    setLoading(true);
    StatusService.create({ ...data, statusType: "PACKAGE_OF_INTEREST_HI5_ACM" })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Package of Interest Main Status created"
        );
        getPackageOfInterestStatus();
        formCreateMainStatus.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleMainStatusCreate = async () => {
    try {
      const values = await formCreateMainStatus.validateFields();
      postPackageOfInterestMainStatus(values);
      setIsCreateMainStatusModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  // POST PACKAGE OF INTEREST SUB STATUS
  const postPackageOfInterestSubStatus = (data: any) => {
    console.log("data", data);
    setLoading(true);
    StatusService.create({ ...data, statusType: "PACKAGE_OF_INTEREST_HI5_ACM" })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Package of Interest Sub Status created"
        );
        getPackageOfInterestStatus();
        formCreateSubStatus.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleSubStatusCreate = async () => {
    try {
      const values = await formCreateSubStatus.validateFields();
      postPackageOfInterestSubStatus(values);
      setIsCreateSubStatusModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  // GETALL
  const getPackageOfInterestStatus = () => {
    setLoading(true);
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=PACKAGE_OF_INTEREST_HI5_ACM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setProcessedData(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  // PUT UPDATE MAIN STATUS
  const onChangeMainStatus = (id: number, name: string) => {
    setLoading(true);
    StatusService.update(id, { name, active: true })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Package of Interest Main Status updated`
        );
        handleEditMainStatusModalCancel();
        getPackageOfInterestStatus();
        formCreateMainStatus.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  // PUT UPDATE SUB STATUS
  const onChangeSubStatus = (parentId: number, id: number, name: string) => {
    setLoading(true);
    StatusService.update(id, { name, active: true, parentId })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Package of Interest Sub Status updated`
        );
        handleEditSubStatusModalCancel();
        getPackageOfInterestStatus();
        formCreateSubStatus.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  // DELETE PACKAGE OF INTEREST MAIN STATUS
  const handleDeleteMainStatus = (id: number) => {
    setLoading(true);
    StatusService.remove(id)
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Package of Interest Main Status deleted`
        );
        handleDeleteMainStatusModalCancel();
        getPackageOfInterestStatus();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  // DELETE PACKAGE OF INTEREST SUB STATUS
  const handleDeleteSubStatus = (id: number) => {
    setLoading(true);
    StatusService.remove(id)
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Package of Interest Sub Status deleted`
        );
        handleDeleteSubStatusModalCancel();
        getPackageOfInterestStatus();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Main Status",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend",
      width: 150,
      sorter: (a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0),
      render: (_, row) => <b>{row.name || "Unknown"}</b>,
    },
    {
      title: "Sub Status",
      dataIndex: "subStatuses",
      key: "subStatuses",
      width: 400,
      render: (_, row) => {
        return (
          <>
            {row.subStatuses.map((subStatus: any) => (
              <div
                key={subStatus.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <span>{subStatus.name}</span>
                <Col>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => showEditSubStatusModal(subStatus)}
                    style={{ marginRight: "10px" }}
                  />
                  <Popconfirm
                    title="Delete sub status?"
                    description="Are you sure to delete this sub status?"
                    onConfirm={() => showDeleteSubStatusModal(subStatus)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Col>
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center" as const,
      width: 100,
      render: (_, row) => {
        return (
          <ButtonGroup
            style={{ marginLeft: "10px", justifyContent: "flex-end" }}
          >
            <Button type="primary" onClick={() => showEditMainStatusModal(row)}>
              Edit
            </Button>
            <Popconfirm
              title="Delete sub status?"
              description="Are you sure to delete this main status?"
              onConfirm={() => showDeleteMainStatusModal(row)}
              okText="Yes"
              cancelText="No"
            >
              <Button>Delete</Button>
            </Popconfirm>
          </ButtonGroup>
        );
      },
    },
  ];

  useEffect(() => {
    getPackageOfInterestStatus();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formEditMainStatus.setFieldsValue({ selectedMainStatus: newMainStatus });
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMainStatus]);

  useEffect(() => {
    formEditSubStatus.setFieldsValue({ selectedSubStatus: newSubStatus });
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSubStatus]);

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Package of Interest Status",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />
        <Row gutter={[16, 16]}>
          <Col>
            <Button
              type="primary"
              style={{ width: "150px" }}
              onClick={showCreateMainStatusModal}
            >
              Create Main Status
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ width: "150px" }}
              onClick={showCreateSubStatusModal}
            >
              Create Sub Status
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={processedData}
              pagination={false}
              scroll={{ x: "max-content" }}
              sticky
              rowKey={"id"}
              key={"id"}
            />
          </Col>
        </Row>
        {/* Create Main Status Modal */}
        <Modal
          title="Create Main Status"
          open={isCreateMainStatusModalVisible}
          onOk={handleMainStatusCreate}
          onCancel={handleCreateMainStatusModalCancel}
        >
          <Form form={formCreateMainStatus} layout="vertical">
            <Item
              name="name"
              label="Main Status Name"
              rules={[
                {
                  required: true,
                  message: "Main status name required",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Item>
          </Form>
        </Modal>
        {/* Create Sub Status Modal */}
        <Modal
          title="Create Sub Status"
          open={isCreateSubStatusModalVisible}
          onOk={handleSubStatusCreate}
          onCancel={handleCreateSubStatusModalCancel}
        >
          <Form form={formCreateSubStatus} layout="vertical">
            <Item
              name="parentId"
              label="Main Status"
              rules={[
                {
                  required: true,
                  message: "Main status name required",
                },
              ]}
            >
              <Select placeholder={"Select"}>
                {processedData?.map((option: any) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>

            <Item
              name="name"
              label="Sub Status Name"
              rules={[
                {
                  required: true,
                  message: "Sub status name required",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Item>
          </Form>
        </Modal>
        {/* Edit Main Status Modal */}
        <Modal
          title="Edit Main Status"
          open={isEditMainStatusModalVisible}
          onCancel={handleEditMainStatusModalCancel}
          footer={[
            <Button key="back" onClick={handleEditMainStatusModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                onChangeMainStatus(selectedMainStatus?.id, newMainStatus)
              }
            >
              Save
            </Button>,
          ]}
        >
          <Form form={formEditMainStatus} layout="vertical">
            <Item
              name="selectedMainStatus"
              label="Main Status Name"
              rules={[
                {
                  required: true,
                  message: "Main status name required",
                },
              ]}
            >
              <Input
                value={newMainStatus}
                onChange={(e) => setNewMainStatus(e.target.value)}
              />
            </Item>
          </Form>
        </Modal>
        {/* Edit Sub Status Modal */}
        <Modal
          title="Edit Sub Status"
          open={isEditSubStatusModalVisible}
          onCancel={handleEditSubStatusModalCancel}
          footer={[
            <Button key="back" onClick={handleEditSubStatusModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                onChangeSubStatus(
                  selectedSubStatus?.parent?.id,
                  selectedSubStatus?.id,
                  newSubStatus
                );
              }}
            >
              Save
            </Button>,
          ]}
        >
          <Form form={formEditSubStatus} layout="vertical">
            <Item
              name="selectedSubStatus"
              label="Sub Status Name"
              rules={[
                {
                  required: true,
                  message: "Sub status name required",
                },
              ]}
            >
              <Input
                value={newSubStatus}
                onChange={(e) => setNewSubStatus(e.target.value)}
              />
            </Item>
          </Form>
        </Modal>
        {/* Delete Main Status Modal */}
        <Modal
          title="Delete Main Status"
          open={isDeleteMainStatusModalVisible}
          onCancel={handleDeleteMainStatusModalCancel}
          footer={[
            <Button key="back" onClick={handleDeleteMainStatusModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleDeleteMainStatus(selectedMainStatus?.id)}
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this main status?</p>
        </Modal>
        {/* Delete Sub Status Modal */}
        <Modal
          title="Delete Sub Status"
          open={isDeleteSubStatusModalVisible}
          onCancel={handleDeleteSubStatusModalCancel}
          footer={[
            <Button key="back" onClick={handleDeleteSubStatusModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                handleDeleteSubStatus(
                  selectedSubStatus?.id
                )
              }
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this sub status?</p>
        </Modal>
      </div>
    </motion.div>
  );
};
