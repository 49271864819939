import { Card, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import './activity-statistics.css';
import LearnerService from '../../services/LearnerService';
import { can } from '../../utils';
import dayjs from 'dayjs';
import CustomerService from '../../services/CustomerService';

interface ActivityStatisticsProps {
    dateRange: any;
}

export const ActivityStatistics: React.FC<ActivityStatisticsProps> = ({ dateRange }) => {

    const { t } = useTranslation();

    const [totalNewLearners, setTotalNewLearners] = useState<number>(0)
    const [totalNewCustomers, setTotalNewCustomers] = useState<number>(0)
    const [statisticData, setStatisticData] = useState<number>(0)

    const loadChart = (data: any) => {
        let dates = data.map((item: any) => item.date);
        let learners = data.map((item: any) => item.learner);
        let customers = data.map((item: any) => item.customer);

        try {
            dates.pop()
            learners.pop()
            customers.pop()
        } catch (error) {
            console.log(error);
        }

        setTotalNewLearners(learners.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0));
        setTotalNewCustomers(customers.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0));

        setOptions({
            chart: {
                height: 300,
                type: 'area',
                toolbar: {
                    tools: {
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false,
                        download: false
                    },
                },

            },
            colors: ['#ffcb2b', '#000'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 2,
                curve: 'smooth',
            },
            grid: {
                show: false,
            },
            xaxis: {
                type: 'datetime',
                categories: dates,
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                show: false,
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yyyy',
                },
            },
            legend: {
                show: false,
                position: 'top',
                horizontalAlign: 'left'
            }
        });

        setSeries([
            {
                name: 'Learners',
                data: learners,
            },
            {
                name: 'Customers',
                data: customers,
            },
        ])
    }

    useEffect(() => {
        if (dateRange.length > 0) {
            getLearnerStatistics(`?startDate=${dateRange[0]}&endDate=${dateRange[1]}`);
        }
    }, [dateRange])

    const getLearnerStatistics = async (filterCriteria: any) => {
        if (can("learners:getAll")) {
            try {
                const [learnerResponse, customerResponse] = await Promise.all([
                    LearnerService.getLearnerStatistics(filterCriteria),
                    CustomerService.getCustomerStatistics(filterCriteria)
                ]);

                const learnerData = learnerResponse.data;
                const customerData = customerResponse.data;

                const mergedData = learnerData.filter((ld: any) => {
                    return customerData.some((cd: any) => cd.date === ld.date);
                }).map((ld: any) => {
                    const correspondingCustomerData = customerData.find((cd: any) => cd.date === ld.date);
                    return {
                        date: ld.date,
                        learner: ld.learner,
                        customer: correspondingCustomerData.customer
                    };
                });

                loadChart(mergedData);
            } catch (e) {
                console.error(e);
            }
        }
    }

    const [series, setSeries] = useState<any>([
        {
            name: 'Learners',
            data: [],
        },
        {
            name: 'Customers',
            data: [],
        },
    ]);

    const [options, setOptions] = useState<any>({
        chart: {
            height: 300,
            type: 'area',
            toolbar: {
                tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    download: false
                },
            },
        },
        colors: ['#000'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 2,
            curve: 'smooth',
        },
        grid: {
            show: false,
        },
        xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: false,
        },
        tooltip: {
            x: {
                format: 'dd/MM/yyyy',
            },
        },
        legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left'
        }
    });

    return (
        <>
            <Card className='activity-statistics'
                style={{ height: '100%', maxHeight: '430px' }}>
                <p className='title'>Learner Chart</p>
                <p className='description' style={{ marginBottom: 10 }}>{totalNewLearners} Learner</p>
                <p className='sales-card-info'><span style={{ fontSize: 32, marginTop: '10px' }}><b>+{totalNewCustomers}</b></span> <br /><b>Customer</b></p>
                <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                    <ReactApexChart
                        width={'100%'}
                        options={options}
                        series={series}
                        type="area"
                        height={305}
                    />
                </div>
            </Card>
        </>)
}

export default ActivityStatistics