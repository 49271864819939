import { Alert, Avatar, Badge, Drawer, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationIcon from "../../assets/notification.svg";
import NotificationService from "../../services/NotificationService";
import { INotificationResponseData } from "../../types/Notification";

type NotificationType = "success" | "info" | "warning" | "error";

export const Notification = () => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [notificationList, setNotificationList] = useState<
    INotificationResponseData[]
  >([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    getMeNotification();
    const interval = setInterval(() => {
      getMeNotification();
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const getMeNotification = (isLoadMore: boolean = false) => {
    if (page >= totalPages && !isLoadMore) return;

    NotificationService.getMeNotification(undefined, page, 20)
      .then((response: any) => {
        const { content, totalPages } = response.data;
        const sortedData = content.sort(
          (a: INotificationResponseData, b: INotificationResponseData) => {
            if (a.isRead === b.isRead) {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            }
            return a.isRead ? 1 : -1;
          }
        );

        setNotificationList((prevList) =>
          isLoadMore ? [...prevList, ...sortedData] : sortedData
        );
        setTotalPages(totalPages);
        setPage((prevPage) => prevPage + 1);
        setIsLoadMore(false);

        const unreadCount = sortedData.filter(
          (notif: INotificationResponseData) => !notif.isRead
        ).length;
        setUnreadCount(unreadCount);
      })
      .catch((e: Error) => {
        console.error("Error fetching notifications:", e);
      });
  };

  const getNotificationType = (type: string): NotificationType => {
    const normalizedType = type.toLowerCase() as NotificationType;
    if (normalizedType === "success") {
      return "success";
    } else if (normalizedType === "info") {
      return "info";
    } else if (normalizedType === "warning") {
      return "warning";
    } else if (normalizedType === "error") {
      return "error";
    } else {
      return "info";
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleClose = (
    id?: number,
    title?: string,
    customerId?: number,
    url?: string
  ) => {
    if (id !== undefined) {
      NotificationService.readNotification(id)
        .then(() => {
          setNotificationList((prevList) =>
            prevList.map((notif) =>
              notif.id === id ? { ...notif, isRead: true } : notif
            )
          );
          setUnreadCount((prevCount) => Math.max(0, prevCount - 1));
        })
        .catch((e: Error) => {
          console.error(e);
        });
    }

    const baseURL = process.env.REACT_APP_BASE_URL;
    if (url && baseURL !== undefined && customerId !== null) {
      window.open(`${baseURL}${url}${customerId}`, "_blank");
    }
    if (
      title === "Software Suspend Assignment" &&
      baseURL !== undefined &&
      customerId !== null
    ) {
      window.open(`${baseURL}customers/detail/${customerId}`, "_blank");
    }
  };

  const handleScroll = (e: any) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
      if (totalPages >= page) {
        if (!isLoadMore) {
          setIsLoadMore(true);
          getMeNotification(true);
        }
      }
    }
  };

  const NotificationAlerts = () => (
    <>
      {notificationList?.map(
        (notification: INotificationResponseData, index: number) => (
          <Alert
            style={{
              marginTop: "10px",
              backgroundColor: `${
                notification?.isRead ? "#ffe6e6" : "#e6f4ff"
              }`,
              border: `${
                notification?.isRead ? "1px solid #ff4d4d" : "1px solid #91caff"
              }`,
              cursor: "pointer",
            }}
            key={index}
            description={notification?.description}
            message={notification?.title}
            type={getNotificationType(notification?.type)}
            showIcon
            onClick={() =>
              handleClose(
                notification?.id,
                notification?.title,
                notification?.customerId,
                notification?.url
              )
            }
          />
        )
      )}
    </>
  );

  return (
    <>
      <Badge
        className="search"
        count={unreadCount}
        style={{
          animation: unreadCount > 0 ? "blink 1s infinite" : "none",
          position: "absolute",
          top: "3px",
          right: "3px",
          zIndex: 1,
        }}
      >
        <Avatar
          size={40}
          src={NotificationIcon}
          onClick={showDrawer}
          style={{
            cursor: "pointer",
            padding: "5px",
            marginLeft: "10px",
            background: "black",
            zIndex: 1,
          }}
        />
      </Badge>
      <Drawer
        title="Notification"
        placement="right"
        onClose={onClose}
        open={open}
        height="100%"
        style={{ overflow: "auto" }}
      >
        <div
          onScroll={handleScroll}
          style={{ height: "100%", overflowY: "auto" }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            {NotificationAlerts()}
          </Space>
        </div>
      </Drawer>
      <style>{`
        @keyframes blink {
          0% { opacity: 1; }
          50% { opacity: 0; }
          100% { opacity: 1; }
        }
      `}</style>
    </>
  );
};
